import { render, staticRenderFns } from "./createActivo.vue?vue&type=template&id=21508bc0&"
import script from "./createActivo.vue?vue&type=script&lang=js&"
export * from "./createActivo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports