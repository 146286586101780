<template>
<tr>
    <th scope="row" class="text-center" :class="$night ? 'text-white-50' : ''" style="width:10px;">{{ $index + 1 }}</th>
    <td class="text-center" :class="$night ? 'text-white-50' : ''" style="width:175px;">{{ $item.device }}</td>
    <td class="text-center" :class="$night ? 'text-white-50' : ''">{{ $item.data }}</td>
    <td v-if="$permisos" class="text-center" :class="$night ? 'text-white-50' : ''" style="width:75px;">
        <strong v-if="pass" style="cursor: pointer;" @click.prevent="pass = false">{{ $item.password }}</strong>
        <button v-else type="button" :class="$night ? 'text-white-50' : ''" class="btn btn-outline-black btn-floating" @click.prevent="pass = true">
            <i class="fas fa-eye"></i>
        </button>
    </td>
    <td class="text-center" :class="$night ? 'text-white-50' : ''" style="width:10px;">
        <i v-if="$item.status == 1" class="fas fa-check-circle fa-lg text-success"></i>
        <i v-else class="fas fa-times-circle fa-lg text-danger"></i>
    </td>
    <td class="text-center" :class="$night ? 'text-white-50' : ''" style="width:175px;">{{ getMomentDate($item.updated_at) }}</td>
    <td class="text-center" :class="$night ? 'text-white-50' : ''" style="width:175px;">{{ getMomentDate($item.created_at) }}</td>
</tr>
</template>

<script>
export default {
    props: {
        $permisos: Boolean,
        $index: Number,
        $item: Object,
        $night: Boolean,
    },
    data() {
        return {
            pass: false,
        }
    },
    methods: {
        getMomentDate(date) {
            return moment(date).calendar();
        },
    },
}
</script>
