<template>
<div class="w3-modal d-block">
    <div class="w3-modal-content w3-animate-zoom card border my-3">
        <header class="card-header">
            <button @click.prevent="cancel" class="btn btn-danger btn-sm px-2 float-end">
                <i class="fas fa-times"></i>
            </button>
            <h5 class="card-title text-center">Creando nuevo administrador</h5>
        </header>
        <div class="card-body">
            <div class="input-group mb-3">
                <span class="input-group-text w-25 w3-light-gray fw-bold">Selecciona la organización</span>
                <select class="form-select" v-model="orgId">
                    <option v-for="(item, index) in organizaciones" :key="'lim' + index" :value="item.id">{{ item.name }}</option>
                </select>
            </div>
        </div>
        <footer class="card-footer">
            <div v-if="load">
                <button class="btn btn-secondary btn-rounded btn-block" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Cargando...
                </button>
            </div>
            <div v-else class="row">
                <div class="col">
                    <button class="btn btn-danger btn-sm btn-rounded btn-block" @click.prevent="cancel">
                        <i class="fas fa-ban"></i>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="col">
                    <button class="btn btn-success btn-sm btn-rounded btn-block" @click.prevent="save">
                        <i class="fas fa-save"></i>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </footer>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        p_reload: Function,
    },
    created() {
        this.getorganizaciones();
    },
    data() {
        return {
            organizaciones: [],
            orgId: null,
            load: false,
        }
    },
    methods: {
        save() {
            this.load = true;
            $axios.post(this.$masterRoute + '/api/admincompras', {
                orgId: this.orgId,
            }, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                if (response.data.status) {
                    $swal.fire({
                        icon: 'success',
                        title: response.data.title,
                        text: response.data.menssage,
                    });
                    this.p_reload();
                    this.cancel();
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: response.data.title,
                        text: response.data.menssage,
                    });
                }
                this.load = false;
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });
        },
        getorganizaciones() {
            this.load = true;
            $axios.get(this.$masterRoute + '/api/organizaciones', {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.organizaciones = response.data;
                this.orgId = this.organizaciones[0].id;
                this.load = false;
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });
        },
        cancel() {
            this.$router.go(-1);
        }
    }
}
</script>
