<template>
<div>
    <div class="animate__animated animate__fadeInDownBig">
        <div class="container-fluid px-2 pt-2 w3-card-4 position-sticky left-0 right-0" :class="night ? 'w3-dark-gray text-white' : 'w3-light-gray'" style="top: 60;z-index:100;">
            <!--Titulo de la vista para dispositivos xl lg md-->
            <div class="d-lg-flex d-none align-items-center">
                <div class="w3-medium me-auto ms-auto">
                    <i class="fas fa-chart-bar fa-lg me-2"></i>
                    <strong>ANÁLISIS DATOS</strong>
                </div>
            </div>
            <!--Titulo de la vista para dispositivos sm xs-->
            <div class="d-lg-none d-flex align-items-center">
                <div class="w3-small me-auto ms-auto">
                    <i class="fas fa-chart-bar fa-lg me-2"></i>
                    <strong>ANÁLISIS DATOS</strong>
                </div>
            </div>
        </div>
        <div class="container-fluid pt-3">
            <router-view></router-view>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        $permisos: Object,
    },
    created() {
        this.darkmode();
        this.permisos = this.$permisos;
        if (this.permisos.reports.status) {
            this.$router.push({
                name: 'indexAnalytics',
                params: {
                    $permisos: this.permisos.reports.permisos,
                }
            });
        }
    },
    data() {
        return {
            night: null,
            permisos: {},
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
    },
}
</script>
