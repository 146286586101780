<template>
    <div class="w3-modal d-block">
        <div class="w3-modal-content w3-animate-zoom card border mb-3 mt-lg-auto mt-3">
        <header class="card-header">
        
        </header>
        <body class="card-body">
            
        </body>
        <footer class="card-footer">
        
        </footer>
        </div>        
    </div>
</template>
<script>
export default {
    data() {
        return {
            
        }
    },
}
</script>