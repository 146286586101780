<template>
<div>
    <div class="card" :class="night ? 'bg-dark-gray' : ''">
        <div class="card-body">
            <div class="d-md-flex align-items-center d-none">
                <div class="btn-group dropstart ms-auto mb-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Configuraciones tabla de contenido">
                    <button type="button" class="btn btn-info btn-sm dropdown-toggle" data-mdb-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-table fa-lg"></i>
                        <i class="fas fa-cogs fa-lg"></i>
                    </button>
                    <ul class="dropdown-menu" :class="{ 'dropdown-menu-dark' : night }">
                        <Container @drop="onDrop" lock-axis="y" drag-class="opacity-ghost" drop-class="opacity-ghost-drop" drag-handle-selector=".column-drag-handle">
                            <Draggable v-for="(item, index) in tableFormat" :key="index" class="card">
                                <li class="dropdown-item">
                                    <div class="draggable-item d-flex align-items-center">
                                        <div class="form-check me-2">
                                            <input v-model="tableFormat[index].status" @change="putStorage" class="form-check-input" type="checkbox" />
                                            <label class="form-check-label">
                                                {{ item.label }}
                                            </label>
                                        </div>
                                        <span class="column-drag-handle ms-auto" style="cursor: pointer;">&#x2630;</span>
                                    </div>
                                </li>
                            </Draggable>
                        </Container>
                    </ul>
                    <!--<ul class="dropdown-menu" :class="{ 'dropdown-menu-dark' : night }">
                        <li>
                            <div class="dropdown-item">
                                <div class="form-check">
                                    <input v-model="tableFormat.folio" @change="putStorage" class="form-check-input" type="checkbox" />
                                    <label class="form-check-label">
                                        Folio
                                    </label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="dropdown-item">
                                <div class="form-check">
                                    <input v-model="tableFormat.maxMonto" @change="putStorage" class="form-check-input" type="checkbox" />
                                    <label class="form-check-label">
                                        Monto mayor
                                    </label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="dropdown-item">
                                <div class="form-check">
                                    <input v-model="tableFormat.motivo" @change="putStorage" class="form-check-input" type="checkbox" />
                                    <label class="form-check-label">
                                        Motivo
                                    </label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="dropdown-item">
                                <div class="form-check">
                                    <input v-model="tableFormat.remitente" @change="putStorage" class="form-check-input" type="checkbox" />
                                    <label class="form-check-label">
                                        Remitente
                                    </label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="dropdown-item">
                                <div class="form-check">
                                    <input v-model="tableFormat.categoria" @change="putStorage" class="form-check-input" type="checkbox" />
                                    <label class="form-check-label">
                                        Categoría
                                    </label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="dropdown-item">
                                <div class="form-check">
                                    <input v-model="tableFormat.creacion" @change="putStorage" class="form-check-input" type="checkbox" />
                                    <label class="form-check-label">
                                        Creación
                                    </label>
                                </div>
                            </div>
                        </li>
                    </ul>-->
                </div>
            </div>
            <div class="table-responsive overflow-auto d-none d-md-block" :style="'height:' + screenheightsize + 'px;'">
                <table class="table table-hover table-sm align-middle" :class="night ? 'table-dark' : 'table-light'">
                    <thead style="position: sticky;top: 0;z-index: 1;" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">
                        <tr class="text-center" style="position: sticky;top: 0;" :class="night ? 'border-dark' : 'border'">
                            <th class="fw-bold px-1" style="position: sticky;top: 0;width: 10px;z-index: 2;">
                                <label class="mb-1">#</label>
                                <button v-if="filters" class="btn btn-outline-black btn-rounded btn-sm" @click="cleanFilters">
                                    <i class="fas fa-eraser fa-lg"></i>
                                </button>
                            </th>
                            <th v-if="tableFormat.folio" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Folio</label>
                                <input v-model="folio" type="search" class="form-control form-control-sm" placeholder="Buscar">
                            </th>
                            <th v-if="tableFormat.maxMonto" class="fw-bold px-1 position-sticky top-0">
                                <label class="mb-1">Monto mayor</label>
                                <input type="search" class="form-control form-control-sm invisible" placeholder="Buscar">
                            </th>
                            <th v-if="tableFormat.motivo" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Motivo</label>
                                <input v-model="motivo" type="search" class="form-control form-control-sm" placeholder="Buscar">
                            </th>
                            <th v-if="tableFormat.remitente" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Remitente</label>
                                <select v-model="org" class="form-select form-select-sm">
                                    <option value="" selected>Todos</option>
                                    <option v-for="(item, index) in organizaciones" :key="index" :value="item.id">{{ item.name }}</option>
                                </select>
                            </th>
                            <th v-if="tableFormat.categoria" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Categoría</label>
                                <input v-model="category" type="search" class="form-control form-control-sm" placeholder="Buscar">
                            </th>
                            <th v-if="tableFormat.creacion" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Creación</label>
                                <input v-model="creacion" type="Date" class="form-control form-control-sm" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" placeholder="Buscar">
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="load">
                            <td :colspan="Object.keys(tableFormat).length + 1" class="text-center">
                                <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </td>
                        </tr>
                        <tr v-else v-for="(item, index) in ordCompras" :key="index" class="text-center" @click.prevent="viewPresupuesto(item)">
                            <td width="15">{{ index + 1 }}</td>
                            <td v-if="tableFormat.folio" width="75">{{ item.folio }}</td>
                            <td v-if="tableFormat.maxMonto">$ {{ item.maxtotal.toFixed(2) }}</td>
                            <td v-if="tableFormat.motivo">{{ item.motivo }}</td>
                            <td v-if="tableFormat.remitente">{{ item.orgId.name }}</td>
                            <td v-if="tableFormat.categoria">{{ item.category }}</td>
                            <td v-if="tableFormat.creacion">{{ getMomentDate(item.created_at) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ul class="list-group overflow-auto d-block d-md-none border-top border-bottom border-5" :style="'height:' + screenheightsize + 'px;'">
                <li v-if="load" class="list-group-item text-center" :class="night ? 'w3-dark-gray' : 'w3-white'">
                    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </li>
                <li v-else v-for="(item, index) in ordCompras" :key="'sm' + index" class="list-group-item" @click="viewPresupuesto(item)" :class="night ? 'w3-dark-gray' : 'w3-white'">
                    <div class="d-flex align-items-center mb-2">
                        <small class="mx-auto">{{ getMomentDate(item.created_at) }}</small>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <strong class="me-auto">{{ item.folio }}</strong>
                        <small>{{ item.orgId.name }}</small>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <small class="fw-bold me-1">Motivo:</small>
                        <small>{{ item.motivo }}</small>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <router-view></router-view>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
import moment from 'moment'
import {
    Container,
    Draggable
} from "vue-dndrop";
import {
    applyDrag,
    generateItems
} from "../../../../filters";

export default {
    components: {
        Container,
        Draggable
    },
    created() {
        this.darkmode();
        this.changeSize();
        this.getOrdCompras();
        moment.locale('es-mx');
        this.debounceOrdCompras = _.debounce(this.getOrdCompras, 500);
        if (localStorage.ordBuysRows) {
            let obr = JSON.parse(localStorage.ordBuysRows);
            Object.keys(obr).forEach(element => {
                this.tableFormat[element] = obr[element];
            });
        } else {
            localStorage.ordBuysRows = JSON.stringify(this.tableFormat);
        }
    },
    watch: {
        folio() {
            this.debounceOrdCompras();
        },
        motivo() {
            this.debounceOrdCompras();
        },
        org() {
            this.getOrdCompras();
        },
        category() {
            this.debounceOrdCompras();
        },
        creacion() {
            this.getOrdCompras();
        },
    },
    data() {
        return {
            night: null,
            ordCompras: [],
            organizaciones: [],
            search: '',
            folio: '',
            motivo: '',
            org: '',
            category: '',
            creacion: '',
            screenwidthsize: null,
            screenheightsize: null,
            tableFormat: [{
                    status: true,
                    label: 'Folio',
                },
                {
                    status: true,
                    label: 'Estado',
                },
                {
                    status: true,
                    label: 'Monto Mayor',
                },
                {
                    status: true,
                    label: 'Motivo',
                },
                {
                    status: true,
                    label: 'Remitente',
                },
                {
                    status: true,
                    label: 'Categoría',
                },
                {
                    status: true,
                    label: 'Creación',
                },
            ],
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
        window.Echo.channel('channel-buys').listen('BuysStatusChangeEvent', (e) => {
            this.getOrdCompras();
        });
        window.addEventListener("resize", this.changeSize);
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        putStorage() {
            localStorage.authRows = JSON.stringify(this.tableFormat);
        },
        getOrdCompras() {
            this.load = true;
            let searchData = {
                folio: this.folio,
                motivo: this.motivo,
                org: this.org,
                category: this.category,
                creacion: this.creacion,
            }
            $axios.get(this.$masterRoute + '/api/ordcompras/create?searchData=' + JSON.stringify(searchData), {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                console.log(response.data);
                this.ordCompras = response.data.ordCompras;
                this.organizaciones = response.data.organizaciones;
                this.load = false;
            }).catch(error => {
                console.error(error);
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                this.load = false;
            });
        },
        viewPresupuesto(item) {
            this.$router.push({
                name: 'checkOrdCompra',
                params: {
                    p_item: item,
                    p_reload: this.getOrdCompras,
                },
            });
        },
        getMomentDate(date) {
            return moment(date).format('LLLL');
        },
        cleanFilters() {
            this.folio = '';
            this.motivo = '';
            this.org = '';
            this.category = '';
            this.creacion = '';
        },
        changeSize() {
            if (window.innerWidth < 1336) {
                this.screenwidthsize = 1336;
            } else {
                this.screenwidthsize = window.innerWidth - 60;
            }
            if (window.innerWidth < 576) {
                this.screenheightsize = window.innerHeight - 190;
            } else {
                this.screenheightsize = window.innerHeight - 240;
            }
        },
        onDrop() {

        }
    },
    computed: {
        filters() {
            if (!this.folio && !this.motivo && !this.org && !this.category && !this.estado && !this.creacion) {
                return false;
            }
            return true;
        }
    }
}
</script>
