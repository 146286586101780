<template>
<div class="w3-modal d-block">
    <div class="w3-modal-content w3-animate-zoom card border">
        <header class="card-header">
            <button @click.prevent="cancel" class="btn btn-danger btn-sm px-2 float-end">
                <i class="fas fa-times"></i>
            </button>
            <div class="d-xl-flex d-lg-flex d-md-flex d-none align-items-center w3-large">
                <i class="fas fa-plus-square fa-2x text-primary me-2"></i>
                <span class="me-2">Creando nuevo activo en:</span>
                <strong>{{ _activo.name }}</strong>
            </div>
            <div class="d-xl-none d-lg-none d-md-none d-flex align-items-center w3-medium">
                <i class="fas fa-plus-square fa-2x text-primary me-2"></i>
                <span class="me-2">Creando nuevo activo en:</span>
                <strong>{{ _activo.name }}</strong>
            </div>
        </header>
        <div class="card-body">
            <div class="row g-3">
                <inputForm-createActivo v-for="(input, index) in _datosForm" :key="index" v-bind:$inputForm="input" v-bind:$map="setMap" v-bind:$valid="saveIsEnable"></inputForm-createActivo>
            </div>
        </div>
        <footer class="card-footer">
            <div v-if="load">
                <button class="btn btn-secondary btn-rounded btn-block" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Cargando...
                </button>
            </div>
            <div v-else class="row">
                <div class="col">
                    <button class="btn btn-danger btn-sm btn-rounded btn-block" @click.prevent="cancel">
                        <i class="fas fa-ban"></i>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="col">
                    <button class="btn btn-success btn-sm btn-rounded btn-block" :disabled="btnSaveDisable" @click.prevent="saveActivo">
                        <i class="fas fa-save"></i>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </footer>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        _activo: Object,
        _datosForm: Array,
        _reloadData: Function,
    },
    data() {
        return {
            jsonObject: {},
            saveDisable: {},
            btnSaveDisable: true,
            load: false,
        }
    },
    methods: {
        saveIsEnable(key, status) {
            this.btnSaveDisable = false;
            this.saveDisable[key] = status;
            Object.values(this.saveDisable).forEach(val => {
                if (!val) {
                    this.btnSaveDisable = true;
                }
            });
        },
        setMap(key, value) {
            this.jsonObject[key] = value;
        },
        saveActivo() {
            this.load = true;
            $axios.post(this.$masterRoute + '/api/panel/admin/storeActivo', {
                structureId: this._activo.id,
                data: JSON.stringify(this.jsonObject),
            }, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                if (response.data.status) {
                    $swal.fire(
                        'Acción exitosa!',
                        response.data.menssage,
                        'success'
                    );
                    this._reloadData();
                    this.cancel();
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error!!',
                        text: response.data.menssage,
                    });
                    this.load = false;
                }
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });
        },
        cancel() {
            this.$router.go(-1);
        }
    },
    computed: {

    },
}
</script>
