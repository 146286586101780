<template>
<div>
    <h3 class="text-center fw-bold mb-3">Crear nuevos tipos de reportes</h3>
    <div class="row">
        <div class="offset-md-1 col-md-10 col-12 mb-3">
            <h6 class="text-center mb-3 w3-xlarge">
                <i class="fas fa-chevron-down fa-lg"></i>
            </h6>
            <img :src="$masterRoute + '/image/manuales/newTypeReports/creartiporeporte1.png'" class="img-fluid rounded shadow-2-strong" alt="Sample" />
            <h6 class="text-center mt-3 w3-xlarge">
                <i class="fas fa-chevron-down fa-lg"></i>
            </h6>
        </div>
        <div class="offset-md-1 col-md-10 col-12 mb-3">
            <img :src="$masterRoute + '/image/manuales/newTypeReports/creartiporeporte2.png'" class="img-fluid rounded shadow-2-strong" alt="Sample" />
            <h6 class="text-center mt-3 w3-xlarge">
                <i class="fas fa-chevron-down fa-lg"></i>
            </h6>
        </div>
        <div class="offset-md-1 col-md-10 col-12 mb-3">
            <img :src="$masterRoute + '/image/manuales/newTypeReports/creartiporeporte3.png'" class="img-fluid rounded shadow-2-strong" alt="Sample" />
            <h6 class="text-center mt-3 w3-xlarge">
                <i class="fas fa-chevron-down fa-lg"></i>
            </h6>
        </div>
        <div class="offset-md-1 col-md-10 col-12 mb-3">
            <img :src="$masterRoute + '/image/manuales/newTypeReports/creartiporeporte4.png'" class="img-fluid rounded shadow-2-strong" alt="Sample" />
            <h6 class="text-center mt-3 w3-xlarge">
                <i class="fas fa-chevron-down fa-lg"></i>
            </h6>
        </div>
        <div class="offset-md-1 col-md-10 col-12 mb-3">
            <img :src="$masterRoute + '/image/manuales/newTypeReports/creartiporeporte5.png'" class="img-fluid rounded shadow-2-strong" alt="Sample" />
            <h6 class="text-center mt-3 w3-xlarge">
                <i class="fas fa-chevron-down fa-lg"></i>
            </h6>
        </div>
        <div class="offset-md-1 col-md-10 col-12 mb-3">
            <img :src="$masterRoute + '/image/manuales/newTypeReports/creartiporeporte6.png'" class="img-fluid rounded shadow-2-strong" alt="Sample" />
        </div>
    </div>
</div>
</template>

<script>
export default {

}
</script>
