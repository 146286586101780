<template>
<div class="w3-modal d-block">
    <div class="w3-modal-content w3-animate-zoom card border my-3" :class="night ? 'bg-dark-gray' : ''">
        <header class="card-header" :class="night ? 'w3-dark-gray' : ''">
            <button @click.prevent="cancel" class="btn btn-danger btn-sm px-2 float-end">
                <i class="fas fa-times"></i>
            </button>
            <h5 class="text-center card-title">Visor Orden de compra</h5>
        </header>
        <div class="card-body">
            <h1 v-if="p_item.status == 'Rechazada'" class="text-danger fw-bold text-center mb-3">
                <i class="far fa-window-close"></i>
                Rechazada
            </h1>
            <h1 v-if="p_item.status == 'Aceptada'" class="text-success fw-bold text-center mb-3">
                <i class="far fa-check-square"></i>
                Aceptada
            </h1>
            <div class="row mb-3">
                <div class="col-12 col-lg-7 align-self-center">
                    <table class="table table-sm table-borderless" :class="night ? 'text-white' : ''">
                        <tbody>
                            <tr>
                                <td class="fw-bold">Motivo:</td>
                                <td>{{ p_item.motivo }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Categoría</td>
                                <td>{{ p_item.category }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12 col-lg-5">
                    <table class="table table-sm table-borderless" :class="night ? 'text-white' : ''">
                        <tbody>
                            <tr>
                                <td class="fw-bold">Remitente:</td>
                                <td>{{ p_item.orgId.name }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Fecha:</td>
                                <td>{{ getDate(p_item.created_at) }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Folio:</td>
                                <td>{{ p_item.folio }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <compras-presupuestos-view v-for="(item, index) in presupuesto" :key="index" :$item="item" :$index="index" :$isAuth="false"></compras-presupuestos-view>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import moment from 'moment'
export default {
    props: {
        p_item: Object,
    },
    created() {
        this.darkmode();
        moment.locale('es-mx');
        this.presupuesto = this.p_item.presupuestos;
    },
    data() {
        return {
            night: null,
            presupuesto: {},
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        getDate(date) {
            return moment(date).format('LL');
        },
        cancel() {
            this.$router.go(-1);
        }
    },
}
</script>
