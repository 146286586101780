<template>
<div class="d-flex align-items-center justify-content-center h-100">
    <img :src="$masterRoute + '/image/logo_pintucity_270px.png'" alt="logo" class="img-fluid">
</div>
</template>

<script>
export default {

}
</script>
