<template>
<div class="w3-modal d-block">
    <div class="w3-modal-content w3-animate-zoom card border my-3" :class="night ? 'bg-dark-gray' : ''">
        <header class="card-header" :class="night ? 'w3-dark-gray' : ''">
            <button @click.prevent="cancel" class="btn btn-danger btn-sm px-2 float-end">
                <i class="fas fa-times"></i>
            </button>
            <div class="d-xl-flex d-lg-flex d-md-flex d-none align-items-center w3-large">
                <i class="fas fa-pen-square fa-2x text-warning me-auto"></i>
                <strong class="card-title me-auto">Editando producto</strong>
            </div>
            <div class="d-xl-none d-lg-none d-md-none d-flex align-items-center w3-medium">
                <i class="fas fa-pen-square fa-2x text-warning me-auto"></i>
                <strong class="card-title me-auto">Editando producto</strong>
            </div>
        </header>
        <div class="card-body">
            <div class="input-group mb-3">
                <span class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Nombre:</span>
                <input v-model="name" type="text" class="form-control" :class="!name ? 'is-invalid' : 'is-valid'">
            </div>
            <div class="row">
                <div class="col-12 col-md-7">
                    <div class="input-group mb-3">
                        <span class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Precio unitario:</span>
                        <input v-model="price" type="number" class="form-control" :class="!price ? 'is-invalid' : 'is-valid'">
                    </div>
                </div>
                <div class="col-12 col-md d-flex justify-content-center">
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" v-model="iva" type="checkbox" id="flexSwitchCheckDefault" />
                        <label class="form-check-label" for="flexSwitchCheckDefault">IVA incluido</label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Unidad de medida:</span>
                        <select v-model="unidadmedida" class="form-select" :class="!unidadmedida ? 'is-invalid' : 'is-valid'">
                            <option value="">Selecciona una opcion...</option>
                            <option value="Metros">Metros</option>
                            <option value="Kilogramos">Kilogramos</option>
                            <option value="Litros">Litros</option>
                            <option value="Galones">Galones</option>
                            <option value="Pares">Pares</option>
                            <option value="Piezas">Piezas</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="input-group mb-3 d-none d-md-inline-flex">
                <span class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Descripción:</span>
                <textarea v-model="descripcion" class="form-control" rows="3"></textarea>
            </div>
            <div class="mb-3 d-block d-md-none">
                <label class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Descripción:</label>
                <textarea v-model="descripcion" class="form-control" rows="3"></textarea>
            </div>
        </div>
        <footer class="card-footer" :class="night ? 'w3-dark-gray' : ''">
            <div v-if="load">
                <button class="btn btn-secondary btn-rounded btn-block" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Cargando...
                </button>
            </div>
            <div v-else class="row">
                <div class="col">
                    <button class="btn btn-danger btn-sm btn-rounded btn-block" @click.prevent="cancel">
                        <i class="fas fa-ban"></i>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="col">
                    <button class="btn btn-success btn-sm btn-rounded btn-block" :disabled="false" @click.prevent="save">
                        <i class="fas fa-save"></i>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </footer>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        p_proveedorId: Number,
        p_item: Object,
        p_reload: Function,
    },
    created() {
        this.darkmode();
        this.name = this.p_item.name;
        this.price = this.p_item.precio;
        if (this.p_item.IVA === 1) {
            this.iva = true;
        } else {
            this.iva = false;
        }
        this.unidadmedida = this.p_item.unidadmedida;
        this.descripcion = this.p_item.descripcion;

    },
    data() {
        return {
            night: null,
            load: false,
            name: '',
            price: '',
            iva: false,
            unidadmedida: '',
            descripcion: '',
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        save() {
            let error = this.validate();
            if (!error) {
                $axios.put(this.$masterRoute + '/api/productos/' + this.p_item.id, {
                    name: this.name,
                    price: this.price,
                    iva: this.iva,
                    unidadmedida: this.unidadmedida,
                    descripcion: this.descripcion,
                }, {
                    headers: {
                        Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                    }
                }).then(response => {
                    if (response.data.status) {
                        $swal.fire({
                            icon: 'success',
                            title: response.data.title,
                            text: response.data.menssage,
                        });
                        this.p_reload();
                        this.cancel();
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: response.data.title,
                            text: response.data.menssage,
                        });
                    }
                    this.load = false;
                }).catch(error => {
                    console.error(error);
                    if (error.response.status === 401) {
                        $swal.fire({
                            icon: 'error',
                            title: 'Sesión expirada',
                            text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                        }).then((result) => {
                            this.$router.push({
                                name: 'login'
                            });
                        });
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: 'Error Inesperado!!',
                            text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                        });
                    }
                });
            } else {
                $swal.fire({
                    icon: 'error',
                    title: 'Formulario invalido!',
                    text: error,
                })
            }
        },
        validate() {
            if (!this.name) {
                return 'Es necesario ingresar el nombre';
            }
            if (!this.price) {
                return 'Es necesario ingresar el precio';
            }
            if (!this.unidadmedida) {
                return 'Es necesario seleccionar la unidad de medida';
            }
            return '';
        },
        cancel() {
            this.$router.go(-1);
        },
    }
}
</script>
