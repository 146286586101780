<template>
<div>
    <div class="card" :class="night ? 'bg-dark-gray' : ''">
        <div class="card-body">
            <div class="d-flex align-items-center mb-3">
                <div class="input-group input-group-sm rounded d-md-inline-flex d-none me-2" style="width: 175px;">
                    <span class="input-group-text border-0" :class="night ? 'text-white' : ''">Filas por página:</span>
                    <select v-model="itemperpage" class="form-select form-select-sm rounded">
                        <option value="25" selected>25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div class="input-group input-group-sm rounded ms-auto d-inline-flex d-md-none">
                    <input type="search" v-model="search" class="form-control rounded" placeholder="Buscar" style="width: 175px;" />
                    <span class="input-group-text border-0" id="search-addon">
                        <i class="fas fa-search" :class="night ? 'text-white' : ''"></i>
                    </span>
                </div>
                <div class="btn-group dropstart ms-auto d-md-inline-flex d-none" data-bs-toggle="tooltip" data-bs-placement="top" title="Configuraciones tabla de contenido">
                    <button type="button" class="btn btn-info btn-sm dropdown-toggle" data-mdb-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-table fa-lg"></i>
                        <i class="fas fa-cogs fa-lg"></i>
                    </button>
                    <ul class="dropdown-menu" :class="{ 'dropdown-menu-dark' : night }">
                        <li>
                            <div class="dropdown-item">
                                <div class="form-check">
                                    <input v-model="tableFormat.orden" @change="putStorage" class="form-check-input" type="checkbox" />
                                    <label class="form-check-label">
                                        Orden
                                    </label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="dropdown-item">
                                <div class="form-check">
                                    <input v-model="tableFormat.folio" @change="putStorage" class="form-check-input" type="checkbox" />
                                    <label class="form-check-label">
                                        Folio
                                    </label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="dropdown-item">
                                <div class="form-check">
                                    <input v-model="tableFormat.estado" @change="putStorage" class="form-check-input" type="checkbox" />
                                    <label class="form-check-label">
                                        Estado
                                    </label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="dropdown-item">
                                <div class="form-check">
                                    <input v-model="tableFormat.modalidad" @change="putStorage" class="form-check-input" type="checkbox" />
                                    <label class="form-check-label">
                                        Modalidad
                                    </label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="dropdown-item">
                                <div class="form-check">
                                    <input v-model="tableFormat.pago" @change="putStorage" class="form-check-input" type="checkbox" />
                                    <label class="form-check-label">
                                        Pago
                                    </label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="dropdown-item">
                                <div class="form-check">
                                    <input v-model="tableFormat.categoria" @change="putStorage" class="form-check-input" type="checkbox" />
                                    <label class="form-check-label">
                                        Categoría
                                    </label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="dropdown-item">
                                <div class="form-check">
                                    <input v-model="tableFormat.proveedor" @change="putStorage" class="form-check-input" type="checkbox" />
                                    <label class="form-check-label">
                                        Proveedor
                                    </label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="dropdown-item">
                                <div class="form-check">
                                    <input v-model="tableFormat.remitente" @change="putStorage" class="form-check-input" type="checkbox" />
                                    <label class="form-check-label">
                                        Remitente
                                    </label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="dropdown-item">
                                <div class="form-check">
                                    <input v-model="tableFormat.auth" @change="putStorage" class="form-check-input" type="checkbox" />
                                    <label class="form-check-label">
                                        Autorizó
                                    </label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="dropdown-item">
                                <div class="form-check">
                                    <input v-model="tableFormat.monto" @change="putStorage" class="form-check-input" type="checkbox" />
                                    <label class="form-check-label">
                                        Monto
                                    </label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="dropdown-item">
                                <div class="form-check">
                                    <input v-model="tableFormat.tiempo" @change="putStorage" class="form-check-input" type="checkbox" />
                                    <label class="form-check-label">
                                        Tiempo entrega
                                    </label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="dropdown-item">
                                <div class="form-check">
                                    <input v-model="tableFormat.notas" @change="putStorage" class="form-check-input" type="checkbox" />
                                    <label class="form-check-label">
                                        Notas
                                    </label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="dropdown-item">
                                <div class="form-check">
                                    <input v-model="tableFormat.creacion" @change="putStorage" class="form-check-input" type="checkbox" />
                                    <label class="form-check-label">
                                        Creación
                                    </label>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="table-responsive overflow-auto d-none d-lg-block" :style="'height:' + screenheightsize + 'px;'">
                <table class="table table-hover table-sm align-middle" :class="night ? 'table-dark' : 'table-light'">
                    <thead style="position: sticky;top: 0;z-index: 1;" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">
                        <tr class="text-center" :class="night ? 'border-dark' : 'border'" style="position: sticky;top: 0;">
                            <th class="fw-bold px-1" :class="night ? 'w3-dark-gray' : 'w3-light-gray'" style="position: sticky;left: 0;top: 0;width: 10px;z-index: 2;">
                                <label class="mb-1">Opciones</label>
                                <br>
                                <button class="btn btn-outline-black btn-rounded btn-sm" :class="[night ? 'w3-white' : '', filters ? 'visible' : 'invisible']" @click="cleanFilters">
                                    <i class="fas fa-eraser fa-lg"></i>
                                </button>
                            </th>
                            <th v-if="tableFormat.orden" width="125" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Orden</label>
                                <input v-model="ordcom" type="search" class="form-control form-control-sm" placeholder="Buscar">
                            </th>
                            <th v-if="tableFormat.folio" width="125" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Folio</label>
                                <input v-model="folio" type="search" class="form-control form-control-sm" placeholder="Buscar">
                            </th>
                            <th v-if="tableFormat.estado" width="150" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Estado</label>
                                <select v-model="estado" class="form-select form-select-sm">
                                    <option value="" selected>Todas</option>
                                    <option value="Pendiente">Pendiente</option>
                                    <option value="Por Recibir">Por Recibir</option>
                                    <option value="Adquirido">Adquirido</option>
                                    <option value="Cancelado">Cancelado</option>
                                </select>
                            </th>
                            <th v-if="tableFormat.modalidad" width="150" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Modalidad</label>
                                <select v-model="mode" class="form-select form-select-sm">
                                    <option value="" selected>Todos</option>
                                    <option value="Pendiente">Pendiente</option>
                                    <option value="Contado">Contado</option>
                                    <option value="Crédito">Crédito</option>
                                </select>
                            </th>
                            <th v-if="tableFormat.pago" width="150" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Pago</label>
                                <select v-model="pago" class="form-select form-select-sm">
                                    <option value="" selected>Todos</option>
                                    <option value="Pagado">Pagado</option>
                                    <option value="No Pagado">No Pagado</option>
                                </select>
                            </th>
                            <th v-if="tableFormat.categoria" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Categoría</label>
                                <input v-model="category" type="search" class="form-control form-control-sm" placeholder="Buscar">
                            </th>
                            <th v-if="tableFormat.proveedor" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Proveedor</label>
                                <input v-model="proveedor" type="search" class="form-control form-control-sm" placeholder="Buscar">
                            </th>
                            <th v-if="tableFormat.remitente" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Remitente</label>
                                <select v-model="orgId" class="form-select form-select-sm">
                                    <option value="" selected>Todos</option>
                                    <option v-for="(item, index) in organizaciones" :key="index" :value="item.id">{{ item.name }}</option>
                                </select>
                            </th>
                            <th v-if="tableFormat.auth" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Autorizó</label>
                                <input v-model="auth" type="search" class="form-control form-control-sm" placeholder="Buscar">
                            </th>
                            <th v-if="tableFormat.monto" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Monto</label>
                                <input type="search" class="form-control form-control-sm invisible" placeholder="Buscar">
                            </th>
                            <th v-if="tableFormat.tiempo" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Tiempo entrega</label>
                                <input v-model="tiempo" type="search" class="form-control form-control-sm" placeholder="Buscar">
                            </th>
                            <th v-if="tableFormat.notas" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Notas</label>
                                <input v-model="notas" type="search" class="form-control form-control-sm" placeholder="Buscar">
                            </th>
                            <th v-if="tableFormat.creacion" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Creación</label>
                                <input v-model="creacion" type="Date" class="form-control form-control-sm" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" placeholder="Buscar">
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="load">
                            <td :colspan="Object.keys(tableFormat).length + 1" class="text-center">
                                <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </td>
                        </tr>
                        <tr v-else v-for="(item, index) in compras" :key="index" class="text-center">
                            <td class="px-1" :class="night ? 'w3-dark-gray' : 'w3-light-gray'" style="position:sticky;left:0;">
                                <div class="btn-group shadow-0">
                                    <button v-if="item.status === 'Pendiente' && permisos.request.status" class="btn btn-outline-black px-2" :class="night ? 'w3-white' : ''" @click="selectPayBuy(item.id, 'Por Recibir')" data-bs-toggle="tooltip" data-bs-placement="top" title="Solicitar orden de compra">
                                        <i class="fas fa-clipboard-list fa-lg"></i>
                                    </button>
                                    <button v-if="item.pay === 0 && permisos.pay.status && item.mode != 'N/A'" class="btn btn-outline-black px-2" :class="night ? 'w3-white' : ''" @click="payBuyAction(item.id)" data-bs-toggle="tooltip" data-bs-placement="top" title="Pagar orden de compra">
                                        <i class="fas fa-money-bill-alt fa-lg"></i>
                                    </button>
                                    <button v-if="isActived(item) && permisos.receive.status" class="btn btn-outline-black px-2" :class="night ? 'w3-white' : ''" @click="confirmAdquirido(item.id, 'Adquirido', '', '')" data-bs-toggle="tooltip" data-bs-placement="top" title="Adquirir orden de compra">
                                        <i class="fas fa-dolly fa-lg"></i>
                                    </button>
                                    <button v-if="permisos.print.status" class="btn btn-outline-info px-2" :class="night ? 'w3-white text-info' : ''" @click="printBuy(item.id)" data-bs-toggle="tooltip" data-bs-placement="top" title="Imprimir orden de compra">
                                        <i class="fas fa-print fa-lg"></i>
                                    </button>
                                </div>
                            </td>
                            <td v-if="tableFormat.orden" width="75" @click.prevent="show(item)">{{ item.ordcomprafolio }}</td>
                            <td v-if="tableFormat.folio" width="75" @click.prevent="show(item)">{{ item.folio }}</td>
                            <td v-if="tableFormat.estado" width="125" @click.prevent="show(item)">
                                <span class="badge rounded-pill w-100" :class="{
                                'bg-success' : item.status == 'Adquirido',
                                'bg-danger' : item.status == 'Cancelado',
                                'bg-warning' : item.status == 'Pendiente',
                                'bg-secondary' : item.status == 'Por Recibir',
                            }">{{ item.status }}</span>
                            </td>
                            <td v-if="tableFormat.modalidad" @click.prevent="show(item)">{{ item.mode }}</td>
                            <td v-if="item.pay === 1 && tableFormat.pago" @click.prevent="show(item)">Pagado</td>
                            <td v-else-if="tableFormat.pago" @click.prevent="show(item)">No Pagado</td>
                            <td v-if="tableFormat.categoria" @click.prevent="show(item)">{{ item.category }}</td>
                            <td v-if="item.proveedor.comercialname == 'N/A' && tableFormat.proveedor" @click.prevent="show(item)">{{ item.proveedor.razonsocial }}</td>
                            <td v-else-if="tableFormat.proveedor" @click.prevent="show(item)">{{ item.proveedor.comercialname }}</td>
                            <td v-if="tableFormat.remitente" @click.prevent="show(item)">{{ item.remitente.name }}</td>
                            <td v-if="tableFormat.auth" @click.prevent="show(item)">{{ item.userId.name }}</td>
                            <td v-if="tableFormat.monto" @click="show(item)">$ {{ getMonto(item.products) }}</td>
                            <td v-if="tableFormat.tiempo" @click.prevent="show(item)">{{ item.times }}</td>
                            <td v-if="tableFormat.notas" @click.prevent="show(item)">{{ item.notes }}</td>
                            <td v-if="tableFormat.creacion" @click.prevent="show(item)">{{ getMomentDate(item.created_at) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ul class="list-group overflow-auto d-block d-lg-none border-top border-bottom border-5" :style="'height:' + screenheightsize + 'px;'">
                <li v-if="load" class="list-group-item text-center" :class="night ? 'w3-dark-gray' : 'w3-white'">
                    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </li>
                <li v-else v-for="(item, index) in compras" :key="'sm' + index" class="list-group-item" :class="night ? 'w3-dark-gray' : 'w3-white'">
                    <div class="d-flex align-items-center mb-2">
                        <div class="btn-group shadow-0">
                            <button v-if="item.status === 'Pendiente' && permisos.request.status" class="btn btn-outline-black px-2" :class="night ? 'w3-white' : ''" @click="selectPayBuy(item.id, 'Por Recibir')" data-bs-toggle="tooltip" data-bs-placement="top" title="Solicitar orden de compra">
                                <i class="fas fa-clipboard-list fa-lg"></i>
                            </button>
                            <button v-if="item.pay === 0 && permisos.pay.status && item.mode != 'N/A'" class="btn btn-outline-black px-2" :class="night ? 'w3-white' : ''" @click="payBuyAction(item.id)" data-bs-toggle="tooltip" data-bs-placement="top" title="Pagar orden de compra">
                                <i class="fas fa-money-bill-alt fa-lg"></i>
                            </button>
                            <button v-if="isActived(item) && permisos.receive.status" class="btn btn-outline-black px-2" :class="night ? 'w3-white' : ''" @click="confirmAdquirido(item.id, 'Adquirido', '', '')" data-bs-toggle="tooltip" data-bs-placement="top" title="Adquirir orden de compra">
                                <i class="fas fa-dolly fa-lg"></i>
                            </button>
                            <button v-if="permisos.print.status" class="btn btn-outline-info px-2" :class="night ? 'w3-white text-info' : ''" @click="printBuy(item.id)" data-bs-toggle="tooltip" data-bs-placement="top" title="Imprimir orden de compra">
                                <i class="fas fa-print fa-lg"></i>
                            </button>
                        </div>
                        <small class="ms-auto" @click="show(item)">{{ getMomentDate(item.created_at) }}</small>
                    </div>
                    <div class="d-flex align-items-center mb-2" @click="show(item)">
                        <strong class="me-auto">{{ item.ordcomprafolio + ' - ' + item.folio }}</strong>
                        <div class="text-center">
                            <small class="fw-bold">Remitente:</small>
                            <br>
                            <small>{{ item.remitente.name }}</small>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <small class="fw-bold me-1">Autorizo:</small>
                        <small>{{ item.userId.name }}</small>
                    </div>
                    <div class="d-flex align-items-center mb-2" @click="show(item)">
                        <div class="me-auto">
                            <small class="fw-bold me-1">Monto:</small>
                            <small>$ {{ getMonto(item.products) }}</small>
                        </div>
                        <small class="badge" :class="{
                                'bg-success' : item.status == 'Adquirido',
                                'bg-danger' : item.status == 'Cancelado',
                                'bg-warning' : item.status == 'Pendiente',
                                'bg-secondary' : item.status == 'Por Recibir',
                            }">{{ item.status }}</small>
                    </div>
                    <div class="d-flex align-items-center mb-2" @click="show(item)">
                        <small class="fw-bold me-1">Notas:</small>
                        <small>{{ item.notes }}</small>
                    </div>
                </li>
            </ul>
            <div class="d-xl-flex d-lg-flex d-md-flex align-items-center mt-2">
                <div class="me-auto ms-2 mb-xl-0 mb-lg-0 d-none d-md-inline-flex">
                    <small>Mostrando {{ pagination.from }}-{{ pagination.to }} de {{ pagination.total }}</small>
                </div>
                <div class="ms-auto mb-xl-0 mb-lg-0">
                    <nav class="d-flex">
                        <ul class="pagination pagination-circle pagination-sm mb-0 ms-auto me-auto">
                            <li class="page-item" v-if="pagination.current_page > 1" @click.prevent="changePageActivosData(pagination.current_page - 1)">
                                <a class="page-link" :class="night ? 'w3-text-white w3-hover-white' : ''" href="#" tabindex="-1" aria-disabled="true">Anterior</a>
                            </li>
                            <li class="page-item" style="z-index: 1;" v-for="(page, index) in pagesNumberActivosData" :key="index" :class="[page == isActivedActivosData ? 'active' : '']" @click.prevent="changePageActivosData(page)">
                                <a class="page-link" :class="night && page != isActivedActivosData ? 'w3-text-white w3-hover-white' : ''" href="#">{{ page }}</a>
                            </li>
                            <li class="page-item" v-if="pagination.current_page < pagination.last_page" @click.prevent="changePageActivosData(pagination.current_page + 1)">
                                <a class="page-link" :class="night ? 'w3-text-white w3-hover-white' : ''" href="#">Siguiente</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <router-view></router-view>
</div>
</template>

<script>
import $axios from 'axios'
import moment from 'moment'
import $swal from 'sweetalert2'
export default {
    props: {
        p_permisos: Object,
    },
    created() {
        this.darkmode();
        this.permisos = this.p_permisos;
        this.changeSize();
        this.getCompras();
        moment.locale('es-mx');
        this.debounceOrdCompras = _.debounce(this.getCompras, 500);
        if (localStorage.buysRows) {
            this.tableFormat = JSON.parse(localStorage.buysRows);
        } else {
            this.putStorage();
        }
    },
    watch: {
        ordcom() {
            this.debounceOrdCompras();
        },
        folio() {
            this.debounceOrdCompras();
        },
        estado() {
            this.getCompras();
        },
        pago() {
            this.getCompras();
        },
        category() {
            this.debounceOrdCompras();
        },
        proveedor() {
            this.debounceOrdCompras();
        },
        orgId() {
            this.getCompras();
        },
        auth() {
            this.debounceOrdCompras();
        },
        tiempo() {
            this.debounceOrdCompras();
        },
        notas() {
            this.debounceOrdCompras();
        },
        creacion() {
            this.getCompras();
        },
        itemperpage() {
            this.getCompras();
        },
        search() {
            this.debounceOrdCompras();
        }
    },
    data() {
        return {
            night: null,
            compras: [],
            pagination: {},
            organizaciones: [],
            permisos: {},
            search: '',
            ordcom: '',
            folio: '',
            estado: '',
            mode: '',
            pago: '',
            category: '',
            proveedor: '',
            orgId: '',
            auth: '',
            tiempo: '',
            notas: '',
            creacion: '',
            itemperpage: 25,
            offset: 6,
            screenwidthsize: null,
            screenheightsize: null,
            tableFormat: {
                orden: true,
                folio: true,
                estado: true,
                modalidad: true,
                pago: true,
                categoria: true,
                proveedor: true,
                remitente: true,
                auth: true,
                monto: true,
                tiempo: true,
                notas: true,
                creacion: true,
            },
            load: false,
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
        window.addEventListener("resize", this.changeSize);
        window.Echo.channel('channel-buys').listen('BuysStatusChangeEvent', (e) => {
            this.getCompras();
        });
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        putStorage() {
            localStorage.buysRows = JSON.stringify(this.tableFormat);
        },
        getCompras(page) {
            this.load = true;
            let searchData = {
                ordcom: this.ordcom,
                folio: this.folio,
                estado: this.estado,
                pay: this.pago,
                category: this.category,
                proveedor: this.proveedor,
                orgId: this.orgId,
                auth: this.auth,
                tiempo: this.tiempo,
                notas: this.notas,
                creacion: this.creacion,
                itemperpage: this.itemperpage,
                search: this.search,
            }
            $axios.get(this.$masterRoute + '/api/compras?page=' + page + '&organizaciones=' + sessionStorage.organizaciones +
                '&searchData=' + JSON.stringify(searchData), {
                    headers: {
                        Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                    }
                }).then(response => {
                this.compras = response.data.compras.data;
                this.pagination = response.data.pagination;
                this.organizaciones = response.data.organizaciones;
                this.load = false;
            }).catch(error => {
                console.error(error.response);
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                this.load = false;
            });
        },
        show(item) {
            this.$router.push({
                name: 'showCompras',
                params: {
                    p_item: item,
                    p_reload: this.getCompras,
                    p_permisos: {
                        addimg: this.permisos.addimg.status,
                        delimg: this.permisos.delimg.status,
                        cancelbuy: this.permisos.cancel.status,
                    }
                }
            })
        },
        payBuyAction(id) {
            $swal.fire({
                title: 'Agrega imagen de referencia al pago',
                input: 'file',
                inputAttributes: {
                    'accept': 'image/*',
                },
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Enviar',
                cancelButtonColor: '#ED1848',
                confirmButtonColor: '#03cc9a',
                inputValidator: (value) => {
                    if (!value) {
                        return 'Es necesario seleccionar una imagen'
                    } else {
                        this.load = true;
                        let formData = new FormData();
                        formData.append('image', value);
                        formData.append('comId', id);
                        $axios.post(this.$masterRoute + '/api/imgcompras', formData, {
                            headers: {
                                Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                            }
                        }).then(response => {
                            if (response.data.status) {
                                const reader = new FileReader()
                                reader.onload = (e) => {
                                    $swal.fire({
                                        title: response.data.menssage,
                                        imageUrl: e.target.result,
                                        imageAlt: 'img'
                                    }).then((result) => {
                                        this.changeStatusBuy(id, '', '', 1);
                                    })
                                }
                                reader.readAsDataURL(value)
                            } else {
                                $swal.fire({
                                    icon: 'error',
                                    title: response.data.title,
                                    text: response.data.menssage,
                                })
                            }
                        }).catch(error => {
                            console.error(error);
                            if (error.response.status === 401) {
                                $swal.fire({
                                    icon: 'error',
                                    title: 'Sesión expirada',
                                    text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                                }).then((result) => {
                                    this.$router.push({
                                        name: 'login'
                                    });
                                });
                            } else {
                                $swal.fire({
                                    icon: 'error',
                                    title: 'Error Inesperado!!',
                                    text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                                });
                            }
                            this.load = false;
                        });

                    }
                }
            })
        },
        selectPayBuy(id, status) {
            $swal.fire({
                title: 'Selecciona la modalidad de pago',
                input: 'select',
                inputOptions: {
                    Contado: 'Contado',
                    Crédito: 'Crédito',
                },
                inputPlaceholder: 'Selecciona una opción',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Enviar',
                cancelButtonColor: '#ED1848',
                confirmButtonColor: '#03cc9a',
                inputValidator: (value) => {
                    if (!value) {
                        return 'Es necesario seleccionar una opción'
                    } else {
                        this.changeStatusBuy(id, status, value, '');
                    }
                }
            });
        },
        confirmAdquirido(id, status, mode, pay) {
            $swal.fire({
                title: '¿Confirma que ya se adquirio la compra?',
                showCancelButton: true,
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#03cc9a',
                cancelButtonColor: '#ED1848',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    this.changeStatusBuy(id, status, mode, pay);
                }
            })
        },
        changeStatusBuy(id, status, mode, pay) {
            this.load = true;
            $axios.get(this.$masterRoute + '/api/compras/' + id + '/edit?status=' + status + '&mode=' + mode + '&pay=' + pay, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                if (response.data.status) {
                    $swal.fire({
                        icon: 'success',
                        title: response.data.title,
                        text: response.data.menssage,
                    });
                    this.getCompras();
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: response.data.title,
                        text: response.data.menssage,
                    });
                }
                this.load = false;
            }).catch(error => {
                console.error(error);
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                this.load = false;
            });
        },
        printBuy(id) {
            window.open('/compra/pdf' + id, "PDF document", "width=" + window.innerWidth / 2 + ", height=" + window.innerHeight / 2);
        },
        cleanFilters() {
            this.ordcom = '';
            this.folio = '';
            this.estado = '';
            this.pago = '';
            this.category = '';
            this.proveedor = '';
            this.orgId = '';
            this.auth = '';
            this.tiempo = '';
            this.notas = '';
            this.creacion = '';
        },
        changeSize() {
            this.screenwidthsize = 2300;
            if (window.innerWidth < 768) {
                this.screenheightsize = window.innerHeight - 260;
            } else {
                this.screenheightsize = window.innerHeight - 280;
            }
        },
        getMonto(products) {
            let sub = 0;
            products.forEach(element => {
                if (element.iva == 1) {
                    sub = sub + ((element.cantidad * element.precio) / 1.16);
                } else {
                    sub = sub + (element.cantidad * element.precio);
                }
            });
            return sub.toFixed(2);
        },
        getMomentDate(date) {
            return moment(date).format('LLLL');
        },
        isActived(item) {
            if (item.status === 'Por Recibir' && item.mode === 'Crédito') {
                return true;
            }
            if (item.status === 'Por Recibir' && item.mode === 'Contado' && item.pay === 1) {
                return true;
            }
            return false;
        },
        changePageActivosData(page) {
            this.load = true;
            this.pagination.current_page = page;
            this.getCompras(page);
        },
    },
    computed: {
        isActivedActivosData: function () {
            return this.pagination.current_page;
        },
        pagesNumberActivosData: function () {
            if (!this.pagination.to) {
                return [];
            }

            var from = this.pagination.current_page - (this.offset / 2);
            if (from < 1) {
                from = 1;
            }

            var to = from + (this.offset);
            if (to >= this.pagination.last_page) {
                to = this.pagination.last_page;
            }

            var pagesArray = [];
            while (from <= to) {
                pagesArray.push(from);
                from++;
            }
            return pagesArray;
        },
        filters() {
            if (!this.ordcom && !this.folio && !this.estado &&
                !this.pago && !this.category && !this.proveedor &&
                !this.orgId && !this.auth && !this.tiempo &&
                !this.notas && !this.creacion) {
                return false;
            }
            return true;
        },

    }
}
</script>
