<template>
<div class="w3-modal d-block">
    <div class="w3-modal-content w3-animate-zoom card border mt-3">
        <header class="card-header">
            <button @click.prevent="cancel" class="btn btn-danger btn-sm px-2 float-end">
                <i class="fas fa-times"></i>
            </button>
            <div class="d-xl-flex d-lg-flex d-md-flex d-none align-items-center w3-large">
                <i class="fas fa-pen-square fa-2x text-warning me-2"></i>
                <span class="me-2">Editando elemento seleccionado</span>
            </div>
            <div class="d-xl-none d-lg-none d-md-none d-flex align-items-center w3-medium">
                <i class="fas fa-pen-square fa-2x text-warning me-2"></i>
                <span class="me-2">Editando elemento seleccionado</span>
            </div>
        </header>
        <div class="card-body">
            <div class="row">
                <div class="col-xl col-lg col-12">
                    <div class="mb-3">
                        <div class="input-group">
                            <span class="input-group-text w3-light-gray">Nombre: </span>
                            <input type="text" v-model="element.name" class="form-control" maxlength="100" :class="errors.name ? 'is-valid': 'is-invalid'" />
                            <div class="invalid-feedback ms-2">Este campo es obligatorio</div>
                        </div>
                        <p class="float-end me-2">{{ element.name.length }}/100</p>
                    </div>
                </div>
                <div class="col-xl col-lg col-12">
                    <div class="mb-3">
                        <div class="input-group">
                            <span class="input-group-text w3-light-gray">Tipo de dato:</span>
                            <select class="form-select" v-model="element.type" :class="errors.type ? 'is-valid': 'is-invalid'">
                                <option value="Selector">Selector</option>
                                <option value="Texto">Texto</option>
                                <option value="Numero">Número</option>
                                <option value="Fecha">Fecha</option>
                                <option value="Tiempo">Tiempo</option>
                                <option value="Fecha/Tiempo">Fecha/Tiempo</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div :class="isText ? '' : 'd-none'" class="col-xl col-lg col-12">
                    <div class="mb-3">
                        <div class="input-group w3-light-gray">
                            <span class="input-group-text">Tamaño del dato:</span>
                            <select class="form-select" v-model="element.size" :class="errors.size ? 'is-valid': 'is-invalid'">
                                <option value="10">10 caracteres</option>
                                <option value="25">25 caracteres</option>
                                <option value="50">50 caracteres</option>
                                <option value="75">75 caracteres</option>
                                <option value="100">100 caracteres</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg col-12" v-if="isSelect">
                    <ul class="list-group">
                        <li class="list-group-item" v-for="(item, index) in optionSelect" :key="index">
                            <div class="d-flex align-items-center">
                                <strong>{{ item }}</strong>
                                <button class="btn btn-outline-danger btn-sm ms-auto" type="button" @click.prevent="removeList(index)">
                                    <i class="fas fa-trash fa-lg"></i>
                                    <i class="fas fa-clipboard-list fa-lg"></i>
                                </button>
                            </div>
                        </li>
                    </ul>
                    <div class="input-group mt-2 mb-3">
                        <input type="text" id="inputList" v-model="optionData" class="form-control" :class="errors.list ? 'is-valid': 'is-invalid'">
                        <button class="btn btn-outline-info" type="button" :disabled="!optionData" @click.prevent="addList">
                            <i class="fas fa-plus fa-lg"></i>
                            <i class="fas fa-clipboard-list fa-lg"></i>
                        </button>
                        <div class="invalid-feedback ms-2">Mínimo dos elementos</div>
                    </div>
                </div>

            </div>
        </div>
        <footer class="card-footer">
            <div v-if="load">
                <button class="btn btn-secondary btn-rounded btn-block" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Cargando...
                </button>
            </div>
            <div v-else class="row px-0">
                <div class="col-6">
                    <button class="btn btn-danger btn-rounded btn-sm btn-block" @click.prevent="cancel">
                        <i class="fas fa-ban fa-lg"></i>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="col-6">
                    <button class="btn btn-success btn-rounded btn-sm btn-block" :disabled="isDisableSave" @click.prevent="saveElement">
                        <i class="fas fa-save fa-lg"></i>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </footer>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        _element: Object,
        _reload: Function,
    },
    created() {
        this.element = {
            id: this._element.id,
            name: this._element.name,
            type: this._element.type,
            size: this._element.size,
            isRequire: this._element.isRequire,
            defaultValue: this._element.defaultValue,
        };
        if (this.element.type == 'Selector') {
            this.optionSelect = JSON.parse(this.element.defaultValue);
        }
    },
    data() {
        return {
            element: {},
            load: false,
            errors: {},
            optionSelect: [],
            optionData: '',
        }
    },
    methods: {
        saveElement() {
            this.load = true;
            $axios.put(this.$masterRoute + "/api/formatData/" + this._element.id, this.element, {
                    headers: {
                        Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        $swal.fire(
                            'Acción exitosa!',
                            response.data.menssage,
                            'success'
                        );
                        this._reload(this._element.structureId);
                        this.cancel();
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: 'Error!!',
                            text: response.data.menssage,
                        });
                        this.load = false;
                    }
                })
                .catch(error => {
                    console.log(error);
                    if (error.response.status === 401) {
                        $swal.fire({
                            icon: 'error',
                            title: 'Sesión expirada',
                            text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                        }).then((result) => {
                            this.$router.push({
                                name: 'login'
                            });
                        });
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: 'Error Inesperado!!',
                            text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                        });
                    }
                    this.load = false;
                });
        },
        addList() {
            this.optionSelect.push(this.optionData);
            this.optionData = '';
            this.element.defaultValue = this.optionSelect;
            $('#inputList').focus();
        },
        removeList(index) {
            this.optionSelect.splice(index, 1);
            this.optionData = '';
            this.element.defaultValue = this.optionSelect;
        },
        requireChange() {
            if (this.element.isRequire) {
                this.element.defaultValue = "";
            }
        },
        isSelected(elem, value) {
            if (elem == value) {
                return true;
            }
            return false;
        },
        cancel() {
            this.$router.go(-1);
        }
    },
    computed: {
        isText() {
            if (this.element.type === 'Texto') {
                return true;
            } else {
                this.element.size = 0;
            }
            return false;
        },
        isSelect() {
            if (this.element.type === 'Selector') {
                return true;
            } else {
                this.element.size = 0;
                this.element.isRequire = true;
            }
            return false;
        },
        isNull() {
            if (this.element.defaultValue === null) {
                return 0;
            }
            return this.element.defaultValue.length;
        },
        typeData() {
            if (this.element.type === 'Selector') {
                this.element.defaultValue = this.optionSelect;
                return false;
            } else if (this.element.type === 'Texto') {
                this.element.defaultValue = "";
                return 'text';
            } else if (this.element.type === 'Numero') {
                this.element.defaultValue = "";
                return 'number';
            } else if (this.element.type === 'Fecha') {
                this.element.defaultValue = "";
                return 'date';
            } else if (this.element.type === 'Tiempo') {
                this.element.defaultValue = "";
                return 'time';
            } else if (this.element.type === 'Fecha/Tiempo') {
                this.element.defaultValue = "";
                return 'datetime-local';
            }
        },
        isDisableSave() {
            this.errors = {
                name: true,
                type: true,
                size: true,
                list: true,
            };
            let flag = false;
            if (!this.element.name) {
                this.errors.name = false;
                flag = true;
            }
            if (!this.element.type) {
                this.errors.type = false;
                flag = true;
            }
            if (!this.element.size && this.element.type === 'Texto') {
                this.errors.size = false;
                flag = true;
            }
            if (this.optionSelect.length < 2 && this.element.type === 'Selector') {
                this.errors.list = false;
                flag = true;
            }
            return flag;
        },
    },

}
</script>
