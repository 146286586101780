<template>
<tr>
    <td class="p-1 text-center w3-light-gray border-1 w3-card-4" style="position:sticky;left:0px;width: 50px;">
        <div class="d-flex">
            <button @click.prevent="editActivo" class="btn btn-warning btn-sm btn-floating ms-auto me-1">
                <i class="fas fa-pen fa-lg"></i>
            </button>
            <button @click.prevent="deleteActivo" class="btn btn-danger btn-sm btn-floating ms-1 me-auto">
                <i class="fas fa-trash-alt fa-lg"></i>
            </button>
        </div>
    </td>
    <td class="p-1 text-center" style="width: 10px;">
        <strong>{{ $id }}</strong>
    </td>
    <td class="p-1" v-for="(head, index) in headers" :key="index" v-show="head.isVisible">
        <small v-if="cols[head.name]">{{ isDate(head) }}</small>
        <small v-else>No asignado</small>
    </td>
</tr>
</template>

<script>
import moment from 'moment'
import $swal from 'sweetalert2'
import $axios from 'axios'
export default {
    props: {
        $cols: Object,
        $headers: Array,
        $id: Number,
        $activo: Object,
        $reloadData: Function,
    },
    created() {
        this.cols = JSON.parse(this.$cols.data);
        this.headers = this.$headers;
        this.activo = this.$activo;
        moment.locale('es-mx');
    },
    watch: {
        $cols() {
            this.cols = JSON.parse(this.$cols.data);
            this.headers = this.$headers;
        }
    },
    data() {
        return {
            cols: {},
            headers: {},
            activo: {},
        }
    },
    methods: {
        isDate(data) {
            if (data.type === 'Fecha') {
                return moment(this.cols[data.name]).format('MMMM Do YYYY');
            }
            if (data.type === 'Fecha/Tiempo') {
                return moment(this.cols[data.name]).format('MMMM Do YYYY, h:mm a');
            } else {
                return this.cols[data.name];
            }
        },
        editActivo() {
            this.$router.push({
                name: 'editActivo',
                params: {
                    _activo: this.activo,
                    _datosForm: this.$headers,
                    _datosLine: JSON.stringify(this.cols),
                    _identify: this.$cols.id,
                    _reload: this.reloadData,
                },
            });
        },
        deleteActivo() {
            $swal.fire({
                title: '¿Estás seguro?',
                text: "Estas a punto de eliminar un activo!!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminarlo!'
            }).then((result) => {
                if (result.isConfirmed) {
                    $axios.delete(this.$masterRoute + '/api/panel/admin/deleteActivo' + this.$cols.id, {
                            headers: {
                                Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                            }
                        }).then(response => {
                            if (response.data.status) {
                                $swal.fire(
                                    'Acción exitosa!',
                                    response.data.menssage,
                                    'success'
                                )
                                this.$reloadData();
                            } else {
                                $swal.fire({
                                    icon: 'error',
                                    title: 'Error!!',
                                    text: response.data.menssage,
                                });
                            }
                        })
                        .catch(error => {
                            if (error.response.status === 401) {
                                $swal.fire({
                                    icon: 'error',
                                    title: 'Clave de seguridad expirada',
                                    text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                                }).then((result) => {
                                    this.$router.push({
                                        name: 'login'
                                    });
                                });
                            } else {
                                $swal.fire({
                                    icon: 'error',
                                    title: 'Error Inesperado!!',
                                    text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                                });
                            }
                            console.log(error.response);
                        });
                }
            });
        },
        reloadData() {
            this.$reloadData();
        },
    },
}
</script>
