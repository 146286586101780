<template>
<div class="w3-modal d-block">
    <div class="w3-modal-content w3-animate-zoom card my-3 mt-lg-auto" :class="night ? 'bg-dark-gray' : ''">
        <header class="card-header" :class="night ? 'w3-dark-gray' : ''">
            <button @click.prevent="cancel" class="btn btn-danger btn-sm px-2 float-end">
                <i class="fas fa-times"></i>
            </button>
            <h5 class="card-title text-center">Detalles del reporte {{ _reportData.no_report }}</h5>
        </header>
        <div class="card-body">
            <div class="row">
                <div class="col-lg-6 col-12 mb-3">
                    <h5 class="text-center">Tiempos del reporte</h5>
                    <ul class="list-group">
                        <li class="list-group-item" :class="night ? 'w3-dark-gray' : ''">
                            <strong class="me-2">Enviado:</strong>
                            <small>{{ getMomentDate(_reportData.created_at) }}</small>
                        </li>
                        <li class="list-group-item" :class="night ? 'w3-dark-gray' : ''">
                            <strong class="me-2">Inició proceso:</strong>
                            <small>{{ getMomentDate(_reportData.started_in) }}</small>
                        </li>
                        <li class="list-group-item" :class="night ? 'w3-dark-gray' : ''">
                            <strong class="me-2">Terminó proceso:</strong>
                            <small>{{ getMomentDate(_reportData.finished_in) }}</small>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-6 col-12 mb-3">
                    <h5 class="text-center">Preguntas del reporte</h5>
                    <ul class="list-group">
                        <li v-for="(item, index) in questions" :key="index" class="list-group-item" :class="night ? 'w3-dark-gray' : ''">
                            <strong class="me-2">{{ item.question }}</strong>
                            <small>{{ item.answer }}</small>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="container-fluid mb-3">
                <h5 class="text-center">Imagenes del reporte</h5>
                <div class="row">
                    <div v-for="(img, index) in _reportData.images" :key="index" class="col-lg-2 col-md-3 col-4">
                        <a class="ripple" @click.prevent="showImg(img.image)">
                            <img :src="img.image" alt="imagen" class="img-fluid hover-shadow rounded">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="lightboxImg" class="w3-modal my-3">
        <div class="w3-modal-content w3-animate-bottom">
            <span class="w3-button w3-display-topright" @click.prevent="closeImg">
                <i class="fas fa-times fa-lg"></i>
            </span>
            <img :src="selectImg" class="w-100 mb-3" alt="imagen" />
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        _reportData: Object,
    },
    created() {
        this.nightMode();
        this.questions = JSON.parse(this._reportData.answers);
    },
    data() {
        return {
            night: null,
            questions: [],
            selectImg: '',
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.nightMode();
        });
    },
    methods: {
        nightMode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        cancel() {
            this.$router.go(-1);
        },
        getMomentDate(date) {
            return moment(date).calendar();
        },
        showImg(img) {
            this.selectImg = img;
            $('#lightboxImg').show();
        },
        closeImg() {
            this.selectImg = '';
            $('#lightboxImg').hide();
        }
    }
}
</script>
