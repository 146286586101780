<template>
<div>
    <!-- SideBar -->
    <div class="w3-sidebar w3-bar-block w3-border-right w3-card-2 animate__animated animate__slideInleft animate__faster" :class="night ? 'bg-dark-gray' : ''" style="display:none;z-index:100;padding-bottom:60px;" id="sidebarPrincipal">
        <button @click.prevent="closeSidebar" class="btn btn-danger btn-sm float-end mx-2 px-2 position-sticky" style="margin-top:5px;top:5px;z-index:100;">
            <i class="fas fa-times"></i>
        </button>
        <div class="p-2 border-bottom w3-blue-gray position-sticky" style="top:0px;z-index:99;">
            <div class="d-flex align-items-center">
                <img :src="authData.imagen" class="rounded-circle" height="75" alt="" loading="lazy" />
                <strong class="ms-1 me-auto">{{ authData.user }}</strong>
            </div>
            <strong class="ms-1 mt-2">{{ authData.name }}</strong>
        </div>
        <div class="w3-medium mt-2">
            <a v-if="userPermisos.devices.status" class="w3-bar-item w3-button" :class="view === 'devices' ? 'w3-disabled' : ''" @click.prevent="devicesView">
                <i class="fas fa-desktop me-1"></i>
                <span>Dispositivos</span>
            </a>
            <a v-if="userPermisos.org.status" class="w3-bar-item w3-button" :class="view === 'orgs' ? 'w3-disabled' : ''" @click.prevent="organizacionesView">
                <i class="fas fa-building me-1"></i>
                <span>Organizaciones</span>
            </a>
            <a v-if="userPermisos.users.status" class="w3-bar-item w3-button" :class="view === 'users' ? 'w3-disabled' : ''" @click.prevent="UsersView">
                <i class="fas fa-users me-1"></i>
                <span>Usuarios</span>
            </a>
            <a v-if="userPermisos.activos.status" class="w3-bar-item w3-button" :class="view === 'activos' ? 'w3-disabled' : ''" @click.prevent="ActivosView">
                <i class="fab fa-product-hunt me-1"></i>
                <span>Activos</span>
            </a>
            <a v-if="userPermisos.reportes.status" class="w3-bar-item w3-button" :class="view === 'reports' ? 'w3-disabled' : ''" @click.prevent="ReportesView">
                <i class="fas fa-clipboard-list me-1"></i>
                <span>Reportes</span>
            </a>
            <a v-if="userPermisos.compras.status" class="w3-bar-item w3-button" :class="view === 'buys' ? 'w3-disabled' : ''" @click.prevent="comprasView">
                <i class="fas fa-shopping-cart me-1"></i>
                <span>Compras</span>
            </a>
            <a v-if="userPermisos.providers.status" class="w3-bar-item w3-button" :class="view === 'providers' ? 'w3-disabled' : ''" @click.prevent="proveedoresView">
                <i class="fas fa-people-carry me-1"></i>
                <span>Proveedores</span>
            </a>
            <a v-if="userPermisos.analytics.status" class="w3-bar-item w3-button">
                <i class="fas fa-analytics"></i>
                <span>Análisis datos</span>
            </a>
        </div>
    </div>
    <!--Sidebar-->
    <!-- Overlay -->
    <div class="w3-overlay" @click.prevent="closeSidebar" style="cursor:pointer;z-index: 99;" id="overlayPrincipal"></div>
    <!-- Navbar -->
    <nav class="navbar navbar-expand-lg fixed-top" :class="night ? 'bg-dark-gray navbar-dark' : 'w3-white navbar-light'" style="height:60px;">
        <!-- Container wrapper -->
        <div class="container-fluid d-flex align-items-center animate__animated animate__slideInRight">
            <!-- Navbar brand -->
            <button class="navbar-toggler me-2" :class="night ? 'text-white-50' : ''" type="button" @click.prevent="openSidebar">
                <i class="fas fa-bars"></i>
            </button>
            <a class="navbar-brand" @click.prevent="homeView">
                <img :src="this.$masterRoute + '/image/logo_pintucity.png'" height="40" alt="logo" loading="lazy" />
                <strong class="d-none d-sm-block ms-1" :class="night ? 'text-white-50' : 'text-black-50'">WebApp</strong>
            </a>
            <!-- Left links -->
            <div class="collapse navbar-collapse me-auto">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0 d-flex w3-medium">
                    <li v-if="userPermisos.devices.status" class="nav-item me-3 me-lg-0">
                        <a class="nav-link" @click.prevent="devicesView" style="cursor: pointer;" :class="view === 'devices' ? 'disabled' : ''">
                            <i class="fas fa-desktop"></i>
                            <span>Dispositivos</span>
                        </a>
                    </li>
                    <li v-if="userPermisos.org.status" class="nav-item me-3 me-lg-0">
                        <a class="nav-link" @click.prevent="organizacionesView" style="cursor: pointer;" :class="view === 'orgs' ? 'disabled' : ''">
                            <i class="fas fa-city"></i>
                            <span>Organizaciones</span>
                        </a>
                    </li>
                    <li v-if="userPermisos.users.status" class="nav-item me-3 me-lg-0">
                        <a class="nav-link" @click.prevent="UsersView" style="cursor: pointer;" :class="view === 'users' ? 'disabled' : ''">
                            <i class="fas fa-users"></i>
                            <span>Usuarios</span>
                        </a>
                    </li>
                    <li v-if="userPermisos.activos.status" class="nav-item me-3 me-lg-0">
                        <a class="nav-link" @click.prevent="ActivosView" style="cursor: pointer;" :class="view === 'activos' ? 'disabled' : ''">
                            <i class="fas fa-cubes"></i>
                            <span>Activos</span>
                        </a>
                    </li>
                    <li v-if="userPermisos.reportes.status" class="nav-item me-3 me-lg-0">
                        <a class="nav-link" @click.prevent="ReportesView" style="cursor: pointer;" :class="view === 'reports' ? 'disabled' : ''">
                            <i class="fas fa-clipboard-list"></i>
                            <span>Reportes</span>
                        </a>
                    </li>
                    <li v-if="userPermisos.compras.status" class="nav-item me-3 me-lg-0">
                        <a class="nav-link" @click.prevent="comprasView" style="cursor: pointer;" :class="view === 'buys' ? 'disabled' : ''">
                            <i class="fas fa-shopping-cart"></i>
                            <span>Compras</span>
                        </a>
                    </li>
                    <li v-if="userPermisos.providers.status" class="nav-item me-3 me-lg-0">
                        <a class="nav-link" @click.prevent="proveedoresView" style="cursor: pointer;" :class="view === 'providers' ? 'disabled' : ''">
                            <i class="fas fa-people-carry"></i>
                            <span>Proveedores</span>
                        </a>
                    </li>
                    <li v-if="userPermisos.analytics.status" class="nav-item me-3 me-lg-0">
                        <a class="nav-link" @click.prevent="analyticsView" style="cursor: pointer;" :class="view === 'analytics' ? 'disabled' : ''">
                            <i class="fas fa-chart-bar"></i>
                            <span>Análisis datos</span>
                        </a>
                    </li>
                </ul>
            </div>
            <!--rigth items-->
            <ul class="navbar-nav flex-row ms-auto">
                <li class="nav-item d-none d-lg-block me-3 me-lg-1">
                    <a class="nav-link" href="" @click.prevent="homeView">
                        <img :src="authData.imagen" class="rounded-circle" height="30" alt="" loading="lazy" />
                        <strong class="ms-1" :class="night ? 'text-white-50' : ''">{{ authData.user }}</strong>
                    </a>
                </li>
                <!--<li class="nav-item dropdown me-3 me-lg-1">
                    <a class="nav-link dropdown-toggle hidden-arrow" href="#" id="navbarDropdownMenuLink" role="button" data-mdb-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-exclamation-triangle fa-lg"></i>
                        <span class="badge rounded-pill badge-notification bg-danger">6</span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                        <li><a class="dropdown-item" href="#">Some news</a></li>
                        <li><a class="dropdown-item" href="#">Another news</a></li>
                        <li>
                            <a class="dropdown-item" href="#">Something else here</a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item dropdown me-3 me-lg-1">
                    <a class="nav-link dropdown-toggle hidden-arrow" href="#" id="navbarDropdownMenuLink" role="button" data-mdb-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-bell fa-lg"></i>
                        <span class="badge rounded-pill badge-notification bg-danger">6</span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                        <li><a class="dropdown-item" href="#">Some news</a></li>
                        <li><a class="dropdown-item" href="#">Another news</a></li>
                        <li>
                            <a class="dropdown-item" href="#">Something else here</a>
                        </li>
                    </ul>
                </li>-->
                <li class="nav-item dropdown me-3 me-lg-1">
                    <a class="nav-link dropdown-toggle hidden-arrow" :class="night ? 'text-white-50' : ''" id="navbarDropdownMenuLink" role="button" data-mdb-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-chevron-circle-down fa-lg"></i>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end" :class="night ? 'dropdown-menu-dark' : ''" aria-labelledby="navbarDropdownMenuLink">
                        <li>
                            <a class="dropdown-item" style="cursor: pointer;" @click="ayudaSoporteView">
                                <i class="fas fa-question-circle fa-lg me-1"></i>
                                <span>Ayuda</span>
                            </a>
                        </li>
                        <li>
                            <a v-if="!night" class="dropdown-item" style="cursor: pointer;" @click.prevent="changeMode(true)">
                                <i class="fas fa-moon fa-lg me-1"></i>
                                <span>Modo nocturno</span>
                            </a>
                            <a v-else class="dropdown-item" style="cursor: pointer;" @click.prevent="changeMode(false)">
                                <i class="far fa-sun fa-lg me-1"></i>
                                <span>Modo diurno</span>
                            </a>
                        </li>
                        <li>
                            <a class="dropdown-item" style="cursor: pointer;" @click.prevent="closeSession">
                                <i class="fas fa-power-off fa-lg me-1"></i>
                                <span>Cerrar Sesión</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <!--rigth items-->
        </div>
        <!-- Container wrapper -->
    </nav>
    <!-- Navbar -->
</div>
</template>

<script>
import $axios from 'axios'
export default {
    created() {
        this.nightMode();
        this.authData = JSON.parse(sessionStorage.authUser);
        this.userPermisos = JSON.parse(this.authData.permisos);
        this.getOrg();
    },
    data() {
        return {
            night: null,
            authData: {},
            userPermisos: {},
            org: [],
            view: '',
            idObjectOrg: [],
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.nightMode();
        });
    },
    methods: {
        nightMode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        getOrg() {
            $axios.get(this.$masterRoute + '/api/organizaciones', {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.org = this.generateTree(response.data);
                this.org.forEach(node => {
                    this.searchOrgId(node);
                });
                if (localStorage.firstView) {
                    this.switchView(localStorage.firstView);
                } else {
                    this.homeView();
                }
            }).catch(error => {
                console.log(error);
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
            });
        },
        changeMode(status) {
            localStorage.nightMode = status;
            $axios.get(this.$masterRoute + '/api/darkmodeevent').then(response => {
                console.info(true);
            });
        },
        openSidebar() {
            $('#sidebarPrincipal').show();
            $('#overlayPrincipal').show();
        },
        closeSidebar() {
            $('#sidebarPrincipal').hide();
            $('#overlayPrincipal').hide();
        },
        switchView(item) {
            switch (item) {
                case 'users':
                    this.UsersView();
                    break;

                case 'activos':
                    this.ActivosView();
                    break;

                case 'reports':
                    this.ReportesView();
                    break;

                case 'orgs':
                    this.organizacionesView();
                    break;

                case 'devices':
                    this.devicesView();
                    break;

                case 'buys':
                    this.comprasView();
                    break;

                case 'providers':
                    this.proveedoresView()
                    break;

                case 'help':
                    this.ayudaSoporteView();
                    break;

                case 'analytics':
                    this.analyticsView();
                    break;

                default:
                    this.homeView();
                    break;
            }
        },
        homeView() {
            this.$router.push({
                name: 'home',
            });
            this.view = 'home';
            localStorage.firstView = '';
        },
        UsersView() {
            this.$router.push({
                name: 'adminPanelUsers',
                params: {
                    $permisos: this.userPermisos.users.children,
                }
            });
            this.view = 'users';
            localStorage.firstView = 'users';
        },
        ActivosView() {
            this.$router.push({
                name: 'adminPanelActivos',
                params: {
                    $permisos: this.userPermisos.activos.children,
                },
            });
            this.view = 'activos';
            localStorage.firstView = 'activos';
        },
        ReportesView() {
            this.$router.push({
                name: 'adminPanelReportes',
                params: {
                    $permisos: this.userPermisos.reportes.children,
                },
            });
            this.view = 'reports';
            localStorage.firstView = 'reports';
        },
        organizacionesView() {
            this.$router.push({
                name: 'adminPanelOrganizaciones',
                params: {
                    $permisos: this.userPermisos.org.permisos,
                },
            });
            this.view = 'orgs';
            localStorage.firstView = 'orgs';
        },
        devicesView() {
            this.$router.push({
                name: 'adminPanelDevices',
                params: {
                    $permisos: this.userPermisos.devices.permisos,
                }
            });
            this.view = 'devices';
            localStorage.firstView = 'devices';
        },
        comprasView() {
            this.$router.push({
                name: 'adminPanelCompras',
                params: {
                    $permisos: this.userPermisos.compras.children,
                }
            });
            this.view = 'buys';
            localStorage.firstView = 'buys';
        },
        proveedoresView() {
            this.$router.push({
                name: 'adminPanelProveedores',
                params: {
                    $permisos: this.userPermisos.providers.children,
                }
            });
            this.view = 'providers';
            localStorage.firstView = 'providers';
        },
        analyticsView() {
            this.$router.push({
                name: 'adminPanelAnalytics',
                params: {
                    $permisos: this.userPermisos.analytics.children,
                }
            });
            this.view = 'analytics';
            localStorage.firstView = 'analytics';
        },
        ayudaSoporteView() {
            this.$router.push({
                name: 'adminPanelAyudaSoporte',
            });
            this.view = 'help';
            localStorage.firstView = 'help';
        },
        closeSession() {
            sessionStorage.removeItem('authToken');
            sessionStorage.removeItem('authUser');
            location.reload();
        },
        generateTree(data) {
            let map = {},
                node,
                roots = [],
                i;
            for (i = 0; i < data.length; i++) {
                map[data[i].id] = i;
                data[i].children = [];
            }
            for (i = 0; i < data.length; i++) {
                node = data[i];
                if (node.parentId) {
                    if (node.parentId) {
                        data[map[node.parentId]].children.push(node);
                    }
                } else {
                    roots.push(node);
                }
            }
            this.load = false;
            return roots;
        },
        searchOrgId(node) {
            if (node.id === this.authData.orgId) {
                this.generateIdDataOrg(node);
            } else {
                node.children.forEach(element => {
                    this.searchOrgId(element);
                });
            }
        },
        generateIdDataOrg(item) {
            this.idObjectOrg.push(item.id);
            if (item.children.length) {
                item.children.forEach(node => {
                    this.generateIdDataOrg(node);
                });
            }
            sessionStorage.organizaciones = JSON.stringify(this.idObjectOrg);
        },
    },
    computed: {
        optionsDatalist() {
            let arr = [];
            Object.keys(this.userPermisos).forEach(item => {
                Object.values(this.userPermisos[item].children).forEach(elem => {
                    if (elem.status) {
                        arr.push(this.userPermisos[item].name + '/' + elem.name);
                    }
                });
            });
            return arr;
        },
    }
}
</script>
