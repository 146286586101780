<template>
<div class="row">
    <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 mb-1 align-self-center">
        <button class="btn btn-danger float-end mb-3" @click.prevent="$delQuestion($index)">
            <i class="fas fa-backspace fa-lg"></i>
        </button>
        <div class="mb-3">
            <div class="input-group">
                <span class="input-group-text w3-light-gray">
                    <i class="fas fa-question-circle fa-lg me-2"></i>
                    <span>Pregunta {{ $index + 1 }}:</span>
                </span>
                <input type="text" v-model="question" class="form-control" :class="isValidQuestion?'is-valid':'is-invalid'" maxlength="100">
                <div class="invalid-feedback ms-2">{{ errors }}</div>
            </div>
            <p class="float-end pe-2">{{ question.length }}/100</p>
        </div>

    </div>
    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 mb-3">
        <ul class="list-group mb-3">
            <li v-for="(resp, index) in answers" :key="index" class="list-group-item d-flex align-items-center">
                <span>{{ resp }}</span>
                <button type="button" class="btn btn-danger btn-floating btn-sm ms-auto" @click.prevent="$delResp($index, index)">
                    <i class="fas fa-times"></i>
                </button>
            </li>
        </ul>
        <div class="input-group mb-3">
            <input type="text" v-model="answer" class="form-control" :class="this.answers.length < 2 ? 'is-invalid' : 'is-valid'" :id="'ans' + $index" placeholder="Agrega nueva respuesta...">
            <button class="btn btn-primary px-3" :disabled="!this.answer" @click.prevent="putResp">
                <i class="fas fa-plus fa-lg"></i>
            </button>
            <div class="invalid-feedback ms-2">Necesitas por lo menos 2 respuestas</div>
        </div>
    </div>
    <hr>
</div>
</template>

<script>
export default {
    props: {
        $index: Number,
        $item: Object,
        $putResp: Function,
        $delResp: Function,
        $putQuest: Function,
        $delQuestion: Function,
    },
    created() {
        this.question = this.$item.question;
        this.answers = this.$item.answers;
    },
    watch: {
        $item() {
            this.question = this.$item.question;
            this.answers = this.$item.answers;
        },
        question() {
            this.$putQuest(this.question, this.$index);
        }
    },
    data() {
        return {
            question: '',
            answers: [],
            answer: '',
            errors: [],
        }
    },
    methods: {
        putResp() {
            this.$putResp(this.answer, this.$index);
            this.answer = "";
            $('#ans' + this.$index).focus();
        }
    },
    computed: {
        isValidQuestion() {
            if (!this.question) {
                this.errors = 'Este campo es obligatorio';
                return false;
            }
            if (this.question.indexOf('¿') === -1 || this.question.indexOf('?') === -1) {
                this.errors = 'Las preguntas deben contener los signos de interrogación (¿?)';
                return false;
            }
            if (this.question.indexOf('¿') != 0) {
                this.errors = '(¿) Este signo debe colocarse al inicio';
                return false;
            }
            if (this.question.indexOf('?') != this.question.length - 1) {
                this.errors = '(?) Este signo debe colocarse al final';
                return false;
            }
            return true;
        },
    },

}
</script>
