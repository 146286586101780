<template>
<div>
    <div class="w3-light-gray animate__animated animate__fadeInDownBig">
        <div class="container-fluid w3-light-gray px-2 pt-2 w3-card-4 position-sticky left-0 right-0" style="top: 60px;z-index:100;">
            <!--Titulo para dispositivos xl lg md-->
            <div class="d-lg-flex d-none align-items-center">
                <div class="w3-medium me-auto ms-auto">
                    <i class="fas fa-cubes fa-lg me-2"></i>
                    <strong>ACTIVOS</strong>
                </div>
            </div>
            <!--Titulo para dispositivos sm xs-->
            <div class="d-lg-none d-flex align-items-center">
                <div class="w3-small me-auto ms-auto">
                    <i class="fas fa-cubes fa-lg me-2"></i>
                    <strong>ACTIVOS</strong>
                </div>
            </div>
        </div>
        <!--Contenido-->
        <div class="container-fluid pt-2">
            <div class="row">
                <div class="col mb-3 px-0">
                    <div class="card border">
                        <!-- encabezado caja de herramientas para dispositivos xl lg md-->
                        <header class="card-header">
                            <div class="d-flex align-items-center">
                                <button v-if="permisos.create.status" type="button" class="btn btn-secondary btn-floating btn-lg me-2" @click.prevent="putMasterElement">
                                    <i class="fas fa-folder-plus fa-lg"></i>
                                </button>
                                <div class="w3-medium ms-2">
                                    <i class="fas fa-briefcase fa-lg"></i>
                                    <strong>Portafolio de activos</strong>
                                </div>
                            </div>
                        </header>
                        <div class="card-body">
                            <div class="me-auto align-items-center d-xl-flex d-lg-flex d-md-flex d-none">
                                <button v-if="permisos.create.status" class="btn btn-primary btn-rounded btn-sm me-2" :class="[isCreated ? '' : 'disabled']" @click.prevent="addElement">
                                    <i class="fas fa-file-medical fa-lg"></i>
                                    <span>Crear</span>
                                </button>
                                <button v-if="permisos.move.status" class="btn btn-info btn-rounded btn-sm me-2" :class="[selectElement.id ? '' : 'disabled']" @click.prevent="moveElement">
                                    <i class="fas fa-arrows-alt fa-lg"></i>
                                    <span>Mover</span>
                                </button>
                                <button type="button" class="btn btn-success btn-rounded btn-sm me-2" :class="[selectElement.id ? '' : 'disabled']" @click.prevent="assignElement">
                                    <i class="fas fa-hand-pointer fa-lg"></i>
                                    <span>Asignar</span>
                                </button>
                                <button v-if="permisos.edit.status" class="btn btn-warning btn-rounded btn-sm me-2" :class="[selectElement.id ? '' : 'disabled']" @click.prevent="editElement">
                                    <i class="fas fa-pen fa-lg"></i>
                                    <span>Editar</span>
                                </button>
                                <button v-if="permisos.delete.status" class="btn btn-danger btn-rounded btn-sm me-2" :class="[isDelete ? '' : 'disabled']" @click.prevent="deleteElement">
                                    <i class="fas fa-trash-alt fa-lg"></i>
                                    <span>Eliminar</span>
                                </button>
                            </div>
                            <div class="px-auto d-xl-none d-lg-none d-md-none d-flex">
                                <button v-if="permisos.create.status" class="btn btn-primary btn-floating me-2" :class="[isCreated ? '' : 'disabled']" @click.prevent="addElement">
                                    <i class="fas fa-plus fa-lg"></i>
                                </button>
                                <button v-if="permisos.move.status" class="btn btn-info btn-floating me-2" :class="[selectElement.id ? '' : 'disabled']" @click.prevent="moveElement">
                                    <i class="fas fa-arrows-alt fa-lg"></i>
                                </button>
                                <button type="button" class="btn btn-success btn-floating me-2" :class="[selectElement.id ? '' : 'disabled']" @click.prevent="assignElement">
                                    <i class="fas fa-hand-pointer fa-lg"></i>
                                </button>
                                <button v-if="permisos.edit.status" class="btn btn-warning btn-floating me-2" :class="[selectElement.id ? '' : 'disabled']" @click.prevent="editElement">
                                    <i class="fas fa-pen fa-lg"></i>
                                </button>
                                <button v-if="permisos.delete.status" class="btn btn-danger btn-floating me-2" :class="[isDelete ? '' : 'disabled']" @click.prevent="deleteElement">
                                    <i class="fas fa-trash-alt fa-lg"></i>
                                </button>
                            </div>
                            <div class="mt-3">
                                <structure-tree v-for="(node, index) in treeData" :key="index" :$node="node" :$putElem="putParentId" :$elemSelect="selectElement"></structure-tree>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-8 animate__animated animate__slideInRight" v-if="isActivo">
                    <div class="card">
                        <header class="card-header d-xl-flex d-lg-flex d-md-flex align-items-center">
                            <h5 class="card-title me-auto">
                                <i class="fas fa-cubes fa-lg me-2"></i>
                                <strong>{{ selectElement.clave }} - {{ selectElement.name }}</strong>
                            </h5>
                            <button v-if="formatData" type="button" class="btn btn-secondary btn-rounded btn-sm" @click.prevent="formatData = false">
                                <i class="fas fa-cog fa-lg"></i>
                                <span>Configurar Información de activos</span>
                            </button>
                            <button v-else type="button" class="btn btn-info btn-rounded btn-sm" @click.prevent="formatData = true">
                                <i class="fas fa-cog fa-lg"></i>
                                <span>Configurar formato de datos</span>
                            </button>
                        </header>
                        <div class="card-body">
                            <formatData-table :class="!formatData ? 'd-none' : ''" :$activo="selectElement" :$getElem="this.getElements"></formatData-table>
                            <activos-tableData :class="formatData ? 'd-none' : ''" :$activo="selectElement" :$getElem="this.getElements"></activos-tableData>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Vistas para enrutamiento VueRoute-->
    <router-view></router-view>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        $permisos: Object,
    },
    created() {
        this.permisos = this.$permisos.permisos;
        this.getElements();
    },
    data() {
        return {
            permisos: {},
            structures: [],
            treeData: [],
            parentId: 0,
            selectElement: {},
            load: false,
            structure: {},
            formatData: false,
        };
    },
    methods: {
        getElements() {
            $axios.get(this.$masterRoute + '/api/structures', {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.treeData = this.generateTree(response.data);
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Clave de seguridad expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
            });
        },
        addElement() {
            this.$router.push({
                name: 'structureCreate',
                params: {
                    _parentId: this.parentId,
                    _getElem: this.getElements,
                },
            });
        },
        moveElement() {
            this.$router.push({
                name: 'structureMove',
                params: {
                    _elem: this.selectElement,
                    _getElem: this.getElements,
                    _treeData: this.treeData,
                },
            });
        },
        assignElement() {
            this.$router.push({
                name: 'structureAssign',
                params: {
                    _elem: this.selectElement,
                },
            });
        },
        editElement() {
            this.$router.push({
                name: 'structureEdit',
                params: {
                    _element: this.selectElement,
                    _getElem: this.getElements,
                    _putData: this.putParentId,
                },
            });
        },
        deleteElement() {
            $swal.fire({
                title: '¿Estás seguro?',
                text: "Estas a punto de eliminar un elemento de la estructura!!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#03cc9a',
                cancelButtonColor: '#ED1848',
                confirmButtonText: 'Si, eliminarlo!'
            }).then((result) => {
                if (result.isConfirmed) {
                    $axios.delete(this.$masterRoute + '/api/structures/' + this.selectElement.id, {
                            headers: {
                                Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                            }
                        })
                        .then(response => {
                            if (response.data.status) {
                                $swal.fire(
                                    'Acción exitosa!',
                                    response.data.menssage,
                                    'success'
                                )
                                this.getElements();
                                this.selectElement = {};
                            } else {
                                $swal.fire({
                                    icon: 'error',
                                    title: 'Error!!',
                                    text: response.data.menssage,
                                });
                            }
                        })
                        .catch(error => {
                            if (error.response.status === 401) {
                                $swal.fire({
                                    icon: 'error',
                                    title: 'Clave de seguridad expirada',
                                    text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                                }).then((result) => {
                                    this.$router.push({
                                        name: 'login'
                                    });
                                });
                            } else {
                                $swal.fire({
                                    icon: 'error',
                                    title: 'Error Inesperado!!',
                                    text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                                });
                            }
                            console.log(error.response);
                        });
                }
            })

        },
        putParentId(element) {
            this.parentId = element.id;
            this.selectElement = element;
        },
        putMasterElement() {
            this.parentId = 0;
            this.addElement();
        },
        generateTree(data) {
            let map = {},
                node,
                roots = [],
                i;
            for (i = 0; i < data.length; i++) {
                map[data[i].id] = i;
                data[i].children = [];
            }
            for (i = 0; i < data.length; i++) {
                node = data[i];
                if (node.parentId) {
                    if (node.parentId) {
                        data[map[node.parentId]].children.push(node);
                    }
                } else {
                    roots.push(node);
                }
            }
            return roots;
        },
    },
    computed: {
        isActivo() {
            return this.selectElement.children && !this.selectElement.children.length;
        },
        isDelete() {
            if (this.selectElement.id) {
                if (this.selectElement.children.length === 0 && this.selectElement.counter === 0) {
                    return true;
                }
            }
            return false;
        },
        isCreated() {
            if (this.selectElement.id) {
                if (this.selectElement.counter === 0) {
                    return true;
                }
            }
            return false;
        },
    }
}
</script>
