<template>
<div>
    <navbar></navbar>
    <div style="margin-top: 60px;">
        <router-view></router-view>
    </div>
    <div v-if="modalActive" class="w3-modal d-block pb-4">
        <div class="w3-modal-content w3-animate-zoom card border mt-3" :class="night ? 'bg-dark-gray' : 'w3-white'">
            <div class="card-body text-center">
                <i class="fas fa-exclamation-triangle fa-6x me-2 text-warning pb-3"></i>
                <h3 :class="night ? 'text-white-50' : ''">Su sesión está a punto de caducar.</h3>
                <h3 :class="night ? 'text-white-50' : ''">Se cerrará en:</h3>
                <strong class="w3-xxlarge" :class="night ? 'text-white-50' : ''">{{ counterTime }}</strong>
                <h3 :class="night ? 'text-white-50' : ''">Segundos</h3>
                <button type="button" class="btn btn-success btn-lg float-end" @click.prevent="closeTimer">Continuar en la sesión</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    created() {
        this.darkmode();
    },
    data() {
        return {
            night: null,
            events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
            warningTimer: null,
            logout: null,
            modalActive: false,
            counterTime: 30,
        }
    },
    mounted() {
        this.events.forEach(function (event) {
            window.addEventListener(event, this.resetTimer);
        }, this);
        this.setTimer();
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
    },
    destroyed() {
        this.events.forEach(function (event) {
            window.removeEventListener(event, this.resetTimer);
        }, this);
        this.resetTimer();
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        setTimer() {
            this.warningTimer = setTimeout(this.warningMessage, 4 * 60 * 1000);
        },
        warningMessage() {
            this.modalActive = true;
            this.logout = setInterval(this.timeout, 1000);
        },
        timeout() {
            if (this.counterTime > 0) {
                this.counterTime = this.counterTime - 1;
            } else {
                sessionStorage.removeItem('authToken');
                sessionStorage.removeItem('authUser');
                location.reload();
            }
        },
        logOutUser() {
            this.logoutMessage = true;
        },
        resetTimer() {
            clearTimeout(this.warningTimer);
            this.setTimer();
        },
        closeTimer() {
            clearTimeout(this.logout);
            this.counterTime = 30;
            this.modalActive = false;
        },
    },
}
</script>
