<template>
<div class="w3-modal d-block">
    <div class="w3-modal-content w3-animate-zoom card border" :class="night ? 'bg-dark-gray text-white-50' : ''">
        <header class="card-header" :class="night ? 'w3-dark-gray' : ''">
            <button @click.prevent="cancel" class="btn btn-danger btn-sm px-2 float-end">
                <i class="fas fa-times"></i>
            </button>
            <div class="d-xl-flex d-lg-flex d-md-flex d-none align-items-center w3-large">
                <i class="fas fa-plus-square fa-2x text-primary me-auto"></i>
                <strong class="me-auto">Creando nuevo rol</strong>
            </div>
            <div class="d-xl-none d-lg-none d-md-none d-flex align-items-center w3-medium">
                <i class="fas fa-plus-square fa-2x text-primary me-2"></i>
                <span>Creando nuevo rol</span>
            </div>
        </header>
        <div class="card-body">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-12 mb-3">
                    <div class="input-group">
                        <span class="input-group-text w3-light-gray">Nombre:</span>
                        <input type="text" v-model="formData.name" class="form-control form-control-sm" maxlength="100" :class="formData.name ? 'is-valid' : 'is-invalid'">
                        <div class="invalid-feedback ms-2">Este campo es obligatorio</div>
                    </div>
                    <small class="float-end me-2 w3-small">{{ formData.name.length }}/100</small>
                </div>
                <div class="col-xl-6 col-lg-6 col-12 mb-3">
                    <div class="input-group">
                        <span class="input-group-text w3-light-gray">Descripción:</span>
                        <textarea class="form-control form-control-sm" maxlength="500" v-model="formData.descripcion" :class="formData.descripcion ? 'is-valid' : 'is-invalid'"></textarea>
                        <div class="invalid-feedback ms-2">Este campo es obligatorio</div>
                    </div>
                    <small class="float-end me-2 w3-small">{{ formData.descripcion.length }}/500</small>
                </div>
            </div>
        </div>
        <footer class="card-footer" :class="night ? 'w3-dark-gray' : ''">
            <div v-if="load">
                <button class="btn btn-secondary btn-rounded btn-block" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Cargando...
                </button>
            </div>
            <div v-else class="row">
                <div class="col">
                    <button class="btn btn-danger btn-sm btn-rounded btn-block" @click.prevent="cancel">
                        <i class="fas fa-ban"></i>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="col">
                    <button class="btn btn-success btn-sm btn-rounded btn-block" :disabled="isDisable" @click.prevent="saveRol">
                        <i class="fas fa-save"></i>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </footer>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        _loadData: Function,
        _jsonData: Object,
    },
    created() {
        this.nightMode();
        this.formData.jsonData = JSON.stringify(this._jsonData);
    },
    data() {
        return {
            night: null,
            load: false,
            formData: {
                name: '',
                descripcion: '',
                jsonData: '',
            }
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.nightMode();
        });
    },
    methods: {
        nightMode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        saveRol() {
            this.load = true;
            $axios.post(this.$masterRoute + '/api/roles/permisos', this.formData, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                if (response.data.status) {
                    $swal.fire(
                        'Acción exitosa!',
                        response.data.menssage,
                        'success'
                    );
                    this.cancel();
                    this._loadData();
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error!!',
                        text: response.data.menssage,
                    });
                }
                this.load = false;
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });
        },
        cancel() {
            this.$router.go(-1);
        },
    },
    computed: {
        isDisable() {
            if (!this.formData.name || !this.formData.descripcion) {
                return true;
            }
            return false;
        },
    },
}
</script>
