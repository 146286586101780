<template>
<div class="card mb-3">
    <header class="card-header" :class="$night ? 'w3-dark-gray' : ''">
        <div class="form-check form-switch">
            <input class="form-check-input" v-model="objectItem" @change.prevent="changeStatus" type="checkbox" />
            <label class="form-check-label" for="flexSwitchCheckDefault">
                <strong>{{ $datos.name }}</strong>
            </label>
        </div>
    </header>
    <div v-if="$datos.children" class="card-body" :class="[objectItem ? '' : 'd-none', $night ? 'bg-dark-gray' : 'w3-light-gray']">
        <users-cards-permisos v-for="(item, index) in Object.keys($datos.children)" :key="index" :$datos="$datos.children[item]" :$clave="pushItemArrayChildren(item)" :$changeStatus="$changeStatus" :$night="$night"></users-cards-permisos>
    </div>
    <div v-else-if="$datos.permisos" class="card-body" :class="[objectItem ? '' : 'd-none', $night ? 'w3-dark-gray' : '']">
        <div class="row">
            <check-permisos v-for="(item, index) in Object.keys($datos.permisos)" :key="index" :$datos="$datos.permisos[item]" :$changeStatus="$changeStatus" :$clave="pushItemArrayPermiso(item)"></check-permisos>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        $datos: Object,
        $changeStatus: Function,
        $clave: Array,
        $night: Boolean,
    },
    created() {
        this.objectItem = this.$datos.status;
    },
    data() {
        return {
            objectItem: '',
        }
    },
    methods: {
        changeStatus() {
            this.$changeStatus(this.$clave, this.objectItem);
        },
        pushItemArrayChildren(item) {
            let arr = [];
            this.$clave.forEach(itemfor => {
                arr.push(itemfor);
            });
            arr.push('children');
            arr.push(item);
            //console.log(arr);
            return arr;
        },
        pushItemArrayPermiso(item) {
            let arr = [];
            this.$clave.forEach(itemfor => {
                arr.push(itemfor);
            });
            arr.push('permisos');
            arr.push(item);
            //console.log(arr);
            return arr;
        },
    },
}
</script>
