<template>
<div>
    <div class="card" :class="night ? 'bg-dark-gray' : ''">
        <div class="card-body" :style="'height:' + screenheightsize + 'px;'">
            <h4 class="mb-3">
                <i class="fas fa-clipboard-list me-2"></i>
                <span>Módulo Reportes</span>
            </h4>
            <div>
                <label class="p-2" :class="night ? 'w3-gray' : 'bg-secondary text-white'">Filtros primarios</label>
                <hr class="mt-0 mb-3" style="height: 4px;" :class="night ? 'w3-gray': 'bg-secondary'">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <div class="input-group input-group-sm mb-3">
                            <span class="input-group-text w-25 fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Desde fecha:
                            </span>
                            <input v-model="fromDate" type="date" class="form-control">
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="input-group input-group-sm mb-3">
                            <span class="input-group-text w-25 fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Hasta fecha:
                            </span>
                            <input v-model="toDate" :min="fromDate" type="date" class="form-control">
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="input-group input-group-sm mb-3">
                            <label class="input-group-text w-25 fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Tipo fecha: </label>
                            <select v-model="typeDate" class="form-select">
                                <option value="1">Creación</option>
                                <option value="2">Iniciación</option>
                                <option value="3">Terminación</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <label class="p-2" :class="night ? 'w3-gray' : 'bg-secondary text-white'">Filtros Secundarios</label>
                <hr class="mt-0 mb-3" style="height: 4px;" :class="night ? 'w3-gray': 'bg-secondary'">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <div class="input-group input-group-sm mb-3">
                            <label class="input-group-text w-25 fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Remitente: </label>
                            <dropdown-checkboxes :items="sendersItems" v-model="senders" class="w-75"></dropdown-checkboxes>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="input-group input-group-sm mb-3">
                            <label class="input-group-text w-25 fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Destinatario: </label>
                            <dropdown-checkboxes :items="addresseesItems" :selectedItems="addressees" class="w-75"></dropdown-checkboxes>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="input-group input-group-sm mb-3">
                            <label class="input-group-text w-25 fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Urgencia: </label>
                            <select v-model="urgency" class="form-select">
                                <option value="" selected>Todas</option>
                                <option value="Alta">Alta</option>
                                <option value="Media">Media</option>
                                <option value="Baja">Baja</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <label class="p-2" :class="night ? 'w3-gray' : 'bg-secondary text-white'">Ver resultados</label>
                <hr class="mt-0 mb-3" style="height: 4px;" :class="night ? 'w3-gray': 'bg-secondary'">
                <div class="row justify-content-center">
                    <div v-if="permisos.html.status" class="col-12 col-lg-3">
                        <button v-if="load" class="btn btn-primary btn-sm mb-3" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                        <button v-else class="btn btn-primary btn-sm mb-3" @click.prevent="searchDataReport">
                            <span>Formato HTML</span>
                            <i class="fas fa-file-code fa-lg"></i>
                        </button>
                    </div>
                    <div v-if="permisos.pdf.status" class="col-12 col-lg-3">
                        <button v-if="load" class="btn btn-primary btn-sm mb-3" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                        <button v-else class="btn btn-primary btn-sm mb-3" @click.prevent="resultsPDF">
                            <span>Formato PDF</span>
                            <i class="fas fa-file-pdf fa-lg"></i>
                        </button>
                    </div>
                    <div v-if="permisos.excel.status" class="col-12 col-lg-3">
                        <button v-if="load" class="btn btn-primary btn-sm mb-3" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                        <button v-else class="btn btn-primary btn-sm mb-3" @click.prevent="resultsXLSX">
                            <span>Formato Excel</span>
                            <i class="fas fa-file-excel fa-lg"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import $axios from 'axios';
import moment from 'moment';
import $swal from 'sweetalert2'
import dropdownCheckboxes from '../../../../components/dropdownCheckboxes.vue';
import * as XLSX from 'xlsx';
import {
    saveAs
} from 'file-saver';

export default {
    props: {
        $permisos: Object,
    },
    components: {
        dropdownCheckboxes
    },
    created() {
        this.darkmode();
        this.getSendersAndAddressee();
        this.permisos = this.$permisos;
    },
    data() {
        return {
            load: false,
            night: null,
            permisos: {},
            screenwidthsize: 0,
            screenheightsize: 0,
            //fromDate: moment("2021-11-01").format("YYYY-MM-DD"),
            fromDate: moment(new Date()).format("YYYY-MM-DD"),
            toDate: moment(new Date()).format("YYYY-MM-DD"),
            typeDate: 1,
            senders: [],
            addressees: [],
            urgency: "",
            data: [],
            sendersItems: [],
            addresseesItems: [],
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
        this.changeSize();
        window.addEventListener("resize", this.changeSize);
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        searchDataReport() {
            let text = this.validate();
            if (!text) {
                this.load = true;
                $axios.post(this.$masterRoute + '/api/analytics/reports', {
                    fromDate: this.fromDate,
                    toDate: this.toDate,
                    typeDate: this.typeDate,
                    sender: this.senders.length > 0 ? JSON.stringify(this.senders) : "",
                    addressee: this.addressees.length > 0 ? JSON.stringify(this.addressees) : "",
                    urgency: this.urgency
                }, {
                    headers: {
                        Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                    }
                }).then(resp => {
                    this.data = resp.data;
                    this.$router.push({
                        name: 'htmlformatAnalytics',
                        params: {
                            dataItems: this.data,
                        }
                    });
                    this.load = false;
                }).catch(error => {
                    if (error.response.status === 401) {
                        $swal.fire({
                            icon: 'error',
                            title: 'Sesión expirada',
                            text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                        }).then((result) => {
                            this.$router.push({
                                name: 'login'
                            });
                        });
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: 'Error Inesperado!!',
                            text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                        });
                    }
                    console.log(error.response);
                    this.load = false;
                });
            } else {
                $swal.fire({
                    icon: 'warning',
                    title: 'filtros primarios invalidos!',
                    text: text,
                });
            }
        },
        resultsPDF() {
            let text = this.validate();
            if (!text) {
                let url = new URL('/analytics/reports/pdf', window.location.origin);
                url.searchParams.set('fromDate', this.fromDate);
                url.searchParams.set('toDate', this.toDate);
                url.searchParams.set('typeDate', this.typeDate);
                url.searchParams.set('sender', this.senders.length > 0 ? JSON.stringify(this.senders) : "");
                url.searchParams.set('addressee', this.addressees.length > 0 ? JSON.stringify(this.addressees) : "");
                url.searchParams.set('urgency', this.urgency);
                window.open(url, "PDF document", "width=" + window.innerWidth / 2 + ", height=" + window.innerHeight / 2);
            } else {
                $swal.fire({
                    icon: 'warning',
                    title: 'filtros primarios invalidos!',
                    text: text,
                });
            }

        },
        resultsXLSX() {
            let text = this.validate();
            if (!text) {
                this.load = true;
                $axios.post(this.$masterRoute + '/api/analytics/reports', {
                    fromDate: this.fromDate,
                    toDate: this.toDate,
                    typeDate: this.typeDate,
                    sender: this.senders.length > 0 ? JSON.stringify(this.senders) : "",
                    addressee: this.addressees.length > 0 ? JSON.stringify(this.addressees) : "",
                    urgency: this.urgency
                }, {
                    headers: {
                        Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                    }
                }).then(resp => {
                    this.data = resp.data;
                    let dataInfo = resp.data;
                    if (Array.isArray(dataInfo)) {
                        if (dataInfo.length > 0) {
                            let ws_data = [
                                ['No. reporte', 'Remitente', 'Destinatario', 'Prioridad', 'Información', 'Creado', 'Iniciado', 'Terminado'],
                                []
                            ];
                            dataInfo.forEach(item => {
                                ws_data.push([item.no_report, item.remitente.name, item.destinatario.name, item.urgencia, item.rptname + ": " + item.descripcion, this.getMomentDate(item.created_at), this.getMomentDate(item.started_in), this.getMomentDate(item.finished_in)]);
                            });
                            var wb = XLSX.utils.book_new();
                            wb.Props = {
                                Title: "Pintucity SheetExcel",
                                Subject: "Análisis dimensional módulo reportes",
                                Author: "Pintucity WebApp AdminSystem",
                                CreatedDate: new Date()
                            };
                            var sheetName = "Reportes(" + this.fromDate + "_" + this.toDate + ")";
                            wb.SheetNames.push(sheetName);
                            var ws = XLSX.utils.aoa_to_sheet(ws_data);
                            wb.Sheets[sheetName] = ws;
                            var wbout = XLSX.write(wb, {
                                bookType: 'xlsx',
                                type: 'binary'
                            });
                            saveAs(new Blob([this.s2ab(wbout)], {
                                type: "application/octet-stream"
                            }), 'Pintucity_Analisis_Reportes.xlsx');
                        }
                    }
                    this.load = false;
                }).catch(error => {
                    if (error.response.status === 401) {
                        $swal.fire({
                            icon: 'error',
                            title: 'Sesión expirada',
                            text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                        }).then((result) => {
                            this.$router.push({
                                name: 'login'
                            });
                        });
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: 'Error Inesperado!!',
                            text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                        });
                    }
                    console.log(error.response);
                    this.load = false;
                });
            } else {
                $swal.fire({
                    icon: 'warning',
                    title: 'filtros primarios invalidos!',
                    text: text,
                });
            }
        },
        s2ab(s) {
            var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
            var view = new Uint8Array(buf); //create uint8array as viewer
            for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
            return buf;
        },
        getMomentDate(date) {
            if (date != null && date != "") {
                return moment(date).format('DD/MM/YYYY HH:mm A');
            }
            return "";
        },
        getSendersAndAddressee() {
            $axios.get(this.$masterRoute + '/api/analytics/sendersaddressees', {
                params: {
                    orgs: sessionStorage.organizaciones,
                },
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(resp => {
                this.sendersItems = resp.data.remitentes;
                this.addresseesItems = resp.data.destinatarios;
            }).catch(error => {
                console.log(error);
            });
        },
        changeSize() {
            if (screen.width < 1336) {
                this.screenwidthsize = 1336;
            } else {
                this.screenwidthsize = screen.width - 30;
            }
            this.screenheightsize = window.innerHeight - 150;
        },
        validate() {
            if (!this.fromDate) {
                return "'Desde fecha' es un campo obligatorio";
            }
            if (!this.toDate) {
                return "'Hasta fecha' es un campo obligatorio"
            }
            return "";
        }
    },
}
</script>
