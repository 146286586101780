<template>
<div class="vue-dropdown">
    <div class="input-group input-group-sm" data-dropdown="true" data-dropdowninput="true" :data-dropdownid="dropdownKey">
        <input type="text" class="form-control border-end-0" :placeholder="placeholder" data-dropdown="true" data-dropdowninput="true" :data-dropdownid="dropdownKey" style="background-color: #fff;" disabled>
        <span class="input-group-text border-start-0" style="background-color: #fff;" data-dropdown="true" data-dropdowninput="true" :data-dropdownid="dropdownKey">
            <i class="bi bi-chevron-down" data-dropdown="true" data-dropdowninput="true" :data-dropdownid="dropdownKey"></i>
        </span>
    </div>
    <select :id="selectKey" class="hidden" v-model="selectedItems" multiple>
        <option v-for="(item, index) in items" :key="'option-' + index" :value="item.value">{{ item.text }}</option>
    </select>
    <div :id="dropdownKey" class="card shadow rounded dropdown-content mt-1 border border-dark" style="display: none;" data-dropdown="true">
        <div class="card-header p-2" data-dropdown="true">
            <div class="input-group input-group-sm" data-dropdown="true">
                <span class="input-group-text" data-dropdown="true">
                    <i class="bi bi-search" data-dropdown="true"></i>
                </span>
                <input type="search" v-model="search" class="form-control border-left-0" placeholder="Buscar..." data-dropdown="true">
            </div>
        </div>
        <div class="card-body p-3" style="overflow: auto; max-height: 230px;" data-dropdown="true">
            <div v-show="!search" class="form-check mb-3 text-black-50" data-dropdown="true">
                <input class="form-check-input" type="checkbox" value="" data-dropdown="true" @change="allChecked($event)">
                <label class="form-check-label" data-dropdown="true">Seleccionar todos</label>
            </div>
            <div v-for="(item, index) in items" :key="'fromcheck-' + index" class="form-check mb-1 optioncheck" data-dropdown="true">
                <input class="form-check-input" type="checkbox" :value="item.value" data-dropdown="true" @change="checkedCheckbox($event)">
                <label class="form-check-label" data-dropdown="true">{{ item.text }}</label>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    model: {
        prop: 'selectedItems',
        event: 'change',
    },
    props: {
        selectedItems: {
            type: Array,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        }
    },
    created() {
        let self = this;
        self.dropdownKey = 'dropdown-' + self.NewKey();
        self.selectKey = 'select-' + self.NewKey();
        window.addEventListener("click", function (event) {
            if (event.target.dataset.dropdown) {
                if (event.target.dataset.dropdowninput) {
                    let dropID = event.target.dataset.dropdownid;
                    $("#" + self.dropdownKey).hide();
                    $("#" + dropID).show();
                }
            } else {
                $("#" + self.dropdownKey).hide();
                self.search = "";
            }
        });
    },
    watch: {
        search() {
            this.filterFunction();
        },
        check() {
            alert(this.check);
        },
        selectedItems() {
            this.$emit('change', this.selectedItems);
        }
    },
    data() {
        return {
            check: false,
            search: '',
            dropdownKey: 'dropdown-',
            selectKey: 'select-',
        }
    },
    methods: {
        myFunction() {
            $("#myDropdown").show();
        },
        filterFunction() {
            var filter, a, i, div;
            filter = this.search.toUpperCase();
            div = document.getElementById(this.dropdownKey);
            a = div.getElementsByClassName("optioncheck");
            for (i = 0; i < a.length; i++) {
                let txtValue = a[i].getElementsByTagName('label')[0].textContent || a[i].getElementsByTagName('label')[0].innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    a[i].style.display = "";
                } else {
                    a[i].style.display = "none";
                }

            }
        },
        allChecked(event) {
            let selection = document.getElementById(this.selectKey);
            let dropContent = document.getElementById(this.dropdownKey);
            let checkboxes = dropContent.getElementsByClassName("optioncheck");
            let array = selection.options;

            if (event.target.checked) {
                if (this.selectedItems > 0) {
                    this.selectedItems.splice(0, this.selectedItems.length);
                }
                for (var i = 0; i < array.length; i++) {
                    this.selectedItems.push(array[i].value);
                };
                for (var i = 0; i < checkboxes.length; i++) {
                    checkboxes[i].getElementsByTagName('input')[0].checked = true;
                }
            } else {
                this.selectedItems.splice(0, this.selectedItems.length);
                for (var i = 0; i < checkboxes.length; i++) {
                    checkboxes[i].getElementsByTagName('input')[0].checked = false;
                }
            }
        },
        checkedCheckbox(event) {
            let selection = document.getElementById(this.selectKey);
            if (event.target.checked) {
                this.selectedItems.push(event.target.value);
            } else {
                let index = this.selectedItems.findIndex((elem) => {
                    return elem == event.target.value;
                });
                this.selectedItems.splice(index, 1);
            }
        },
        NewKey() {
            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for (var i = 0; i < 5; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));

            return text;
        },
    },
    computed: {
        placeholder() {
            if (this.selectedItems.length > 0) {
                if (this.selectedItems.length == 1) {
                    return this.items.find(item => item.value == this.selectedItems[0]).text + " seleccionado";
                } else {
                    return this.selectedItems.length + " elementos seleccionados";
                }
            } else {
                return "Sin elementos seleccionados";
            }
        }
    },
}
</script>

<style scoped>
.vue-dropdown {
    position: relative;
    display: inline-block;
}

.hidden {
    display: none !important;
}

.dropdown-content {
    color: black;
    display: none;
    position: absolute;
    min-width: 230px;
    z-index: 1;
}
</style>
