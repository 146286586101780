<template>
<div @click="getEvent">
    <div class="animate__animated animate__fadeInDownBig">
        <div class="container-fluid px-2 pt-2 w3-card-4 position-sticky left-0 right-0" :class="night ? 'w3-dark-gray text-white' : 'w3-light-gray'" style="top: 60;z-index:100;">
            <!--Titulo de la vista para dispositivos xl lg md-->
            <div class="d-lg-flex d-none align-items-center">
                <div class="w3-medium me-auto ms-auto">
                    <i class="fas fa-shopping-cart fa-lg me-2"></i>
                    <strong>COMPRAS</strong>
                </div>
            </div>
            <!--Titulo de la vista para dispositivos sm xs-->
            <div class="d-lg-none d-flex align-items-center">
                <div class="w3-small me-auto ms-auto">
                    <i class="fas fa-shopping-cart fa-lg me-2"></i>
                    <strong>COMPRAS</strong>
                </div>
            </div>
        </div>
        <div class="container-fluid pt-1">
            <ul class="nav nav-pills nav-fill mb-2 d-md-flex d-none" :class="night ? 'bg-dark-gray text-white' : 'w3-white'">
                <li v-if="vistas.status && vistas.permisos.ordcom.status" class="nav-item">
                    <a class="nav-link text-capitalize fw-bold px-2" :class="activeView == 'ordCompras' ? 'active disabled' : ''" href="#" @click.prevent="ordComprasView">
                        <i class="fas fa-file-invoice-dollar fa-lg"></i>
                        <span>Ord. Compras</span>
                    </a>
                </li>
                <li v-if="vistas.status && vistas.permisos.auth.status" class="nav-item">
                    <a class="nav-link text-capitalize fw-bold px-2" :class="activeView == 'myauthorized' ? 'active disabled' : ''" href="#" @click.prevent="myAuthorizedView">
                        <i class="fas fa-file-signature fa-lg"></i>
                        <span>Mis autorizaciones</span>
                    </a>
                </li>
                <li v-if="admin.status" class="nav-item">
                    <a class="nav-link text-capitalize fw-bold px-2" :class="activeView == 'compras' ? 'active disabled' : ''" href="#" @click.prevent="comprasView">
                        <i class="fas fa-shopping-bag fa-lg"></i>
                        <span>Compras</span>
                    </a>
                </li>
                <li v-if="config.status" class="nav-item ">
                    <a class="nav-link text-capitalize fw-bold px-2" :class="activeView == 'config' ? 'active disabled' : ''" href="#" @click.prevent="configView">
                        <i class="fas fa-cogs fa-lg"></i>
                        <span>Configuraciones</span>
                    </a>
                </li>
            </ul>
            <div class="container py-2 mb-2 d-md-none d-block" :class="night ? 'bg-dark-gray text-white' : 'w3-white'">
                <div class="dropdown">
                    <button class="btn btn-primary btn-block dropdown-toggle" type="button" id="dropdownMenuButton" data-mdb-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-lg me-2" :class="smallMenu.class"></i>
                        <span>{{ smallMenu.text }}</span>
                    </button>
                    <ul class="dropdown-menu w-100 shadow-1-strong" aria-labelledby="dropdownMenuButton">
                        <li>
                            <a class="dropdown-item" href="#" @click.prevent="ordComprasView">
                                <i class="fas fa-file-invoice-dollar fa-lg me-2"></i>
                                <span>Ord. Compras</span>
                            </a>
                        </li>
                        <li>
                            <a class="dropdown-item" href="#" @click.prevent="myAuthorizedView">
                                <i class="fas fa-file-signature fa-lg me-2"></i>
                                <span>Mis autorizaciones</span>
                            </a>
                        </li>
                        <li>
                            <a class="dropdown-item" href="#" @click.prevent="comprasView">
                                <i class="fas fa-shopping-bag fa-lg me-2"></i>
                                <span>Compras</span>
                            </a>
                        </li>
                        <li>
                            <a class="dropdown-item" href="#" @click.prevent="configView">
                                <i class="fas fa-cogs fa-lg me-2"></i>
                                <span>Configuraciones</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <router-view></router-view>
        </div>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
export default {
    props: {
        $permisos: Object,
    },
    created() {
        this.vistas = this.$permisos.vistas;
        this.admin = this.$permisos.admin;
        this.config = this.$permisos.config;
        this.darkmode();
        if (localStorage.secondView) {
            this.switchView(localStorage.secondView);
        } else {
            if (this.vistas.status && this.vistas.permisos.ordcom.status) {
                this.ordComprasView();
            }
        }
    },
    data() {
        return {
            night: null,
            activeView: '',
            vistas: {},
            admin: {},
            config: {},
            smallMenu: {
                class: '',
                text: '',
            },
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        getEvent(e) {
            if (e.target.tagName == 'INPUT' && e.target.dataset.isdropdown) {
                $('.inputdropdown').hide();
                $('#' + e.target.dataset.dropdown).show();
            } else {
                $('.inputdropdown').hide();
            }
        },
        switchView(item) {
            switch (item) {
                case 'ordcom':
                    this.ordComprasView();
                    break;

                case 'authorized':
                    this.myAuthorizedView();
                    break;

                case 'buys':
                    this.comprasView();
                    break;
                case 'config':
                    this.configView();
                    break;

                default:
                    break;
            }
        },
        ordComprasView() {
            this.activeView = 'ordCompras';
            this.smallMenu = {
                class: 'fa-file-invoice-dollar',
                text: 'Ord. Compras',
            };
            this.$router.push({
                name: 'indexOrdCompras',
            });
            localStorage.secondView = 'ordcom';
        },
        myAuthorizedView() {
            this.activeView = 'myauthorized';
            this.smallMenu = {
                class: 'fa-file-signature',
                text: 'Mis Autorizaciones',
            };
            this.$router.push({
                name: 'myauthorizedIndex',
            })
            localStorage.secondView = 'authorized';
        },
        comprasView() {
            this.activeView = 'compras';
            this.smallMenu = {
                class: 'fa-shopping-bag',
                text: 'Compras',
            };
            this.$router.push({
                name: 'comprasIndex',
                params: {
                    p_permisos: this.admin.permisos,
                }
            })
            localStorage.secondView = 'buys';
        },
        configView() {
            this.activeView = 'config';
            this.smallMenu = {
                class: 'fa-cogs',
                text: 'Configuraciones',
            };
            this.$router.push({
                name: 'configurationsIndex',
                params: {
                    p_permisos: this.config.children,
                }
            });
            localStorage.secondView = 'config';
        }
    },
}
</script>
