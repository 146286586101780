<template>
<tr>
    <td class="p-1 text-center w3-light-gray border-1 w3-card-4">
        <div class="d-flex">
            <button @click.prevent="editElement()" class="btn btn-warning btn-floating btn-sm ms-auto me-2">
                <i class="fas fa-pen fa-lg"></i>
            </button>
            <button @click.prevent="deleteElement()" class="btn btn-danger btn-floating btn-sm ms-1 me-auto">
                <i class="fas fa-trash-alt fa-lg"></i>
            </button>
        </div>
    </td>
    <td scope="row">{{ $index + 1 }}</td>
    <td>
        <span>{{ $elem.name }}</span>
    </td>
    <td>
        <span>{{ $elem.type }}</span>
    </td>
    <td>
        <span>{{ $elem.size }}</span>
    </td>
    <td>
        <div v-if="loadinput" class="spinner-border spinner-border-sm text-info" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div v-else-if="inputDefault" class="input-group input-group-sm">
            <input type="text" v-model="inputData" class="form-control">
            <button class="btn btn-danger" type="button" @click.prevent="inputDefault = false">
                <i class="fas fa-ban fa-lg"></i>
            </button>
            <button class="btn btn-success" type="button" @click.prevent="changeDefaultValue">
                <i class="fas fa-save fa-lg"></i>
            </button>
        </div>
        <button v-else-if="!$elem.defaultValue && !$elem.isRequire" type="button" class="btn btn-outline-info btn-sm" @click.prevent="inputDefault = true">
            Agregar valor
        </button>
        <div v-else>
            <span>
                {{ $elem.defaultValue }}
            </span>
            <button v-if="!$elem.isRequire && $elem.type != 'Selector'" type="button" class="btn btn-outline-info btn-sm ms-2" @click.prevent="inputDefault = true">
                <i class="fas fa-exchange-alt fa-lg"></i>
            </button>
        </div>

    </td>
    <td class="text-center">
        <i v-if="$elem.type == 'Selector'" class="fas fa-lock fa-lg text-danger"></i>
        <i v-else-if="$elem.isRequire" class="fas fa-lock fa-lg text-danger" @click.prevent="changeBlocked(0)" style="cursor: pointer;"></i>
        <i v-else class="fas fa-lock-open fa-lg text-warning" @click.prevent="changeBlocked(1)" style="cursor: pointer;"></i>
        <div v-if="loadblock" class="spinner-border spinner-border-sm text-info" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </td>
    <td class="text-center">
        <button v-if="$elem.isVisible" type="button" @click.prevent="changeVisivility(0)" class="btn btn-success btn-floating btn-sm">
            <i class="fas fa-eye fa-lg"></i>
        </button>
        <button v-else type="button" @click.prevent="changeVisivility(1)" class="btn btn-danger btn-floating btn-sm">
            <i class="fas fa-eye-slash fa-lg"></i>
        </button>
        <div v-if="loadview" class="spinner-border spinner-border-sm text-info" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </td>
</tr>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        $elem: Object,
        $index: Number,
        $reload: Function,
    },
    data() {
        return {
            loadblock: false,
            loadview: false,
            loadinput: false,
            inputDefault: false,
            inputData: '',
        }
    },
    methods: {
        editElement() {
            this.modalActive = true;
            this.$router.push({
                name: 'editFormatData',
                params: {
                    _element: this.$elem,
                    _reload: this.$reload,
                },
            });
        },
        deleteElement() {
            $swal.fire({
                title: '¿Estás seguro?',
                text: "Estas a punto de eliminar el datos seleccionado!!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#03cc9a',
                cancelButtonColor: '#ED1848',
                confirmButtonText: 'Si, eliminarlo!'
            }).then((result) => {
                if (result.isConfirmed) {
                    $axios.delete(this.$masterRoute + '/api/formatData/' + this.$elem.id, {
                            headers: {
                                Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                            }
                        })
                        .then(response => {
                            if (response.data.status) {
                                $swal.fire(
                                    'Acción exitosa!',
                                    response.data.menssage,
                                    'success'
                                )
                                this.$reload();
                            } else {
                                $swal.fire({
                                    icon: 'error',
                                    title: 'Error!!',
                                    text: response.data.menssage,
                                });
                            }
                        })
                        .catch(error => {
                            if (error.response.status === 401) {
                                $swal.fire({
                                    icon: 'error',
                                    title: 'Clave de seguridad expirada',
                                    text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                                }).then((result) => {
                                    this.$router.push({
                                        name: 'login'
                                    });
                                });
                            } else {
                                $swal.fire({
                                    icon: 'error',
                                    title: 'Error Inesperado!!',
                                    text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                                });
                            }
                            console.log(error.response);
                        });
                }
            });
        },
        changeVisivility(status) {
            this.loadview = true;
            $axios.get(this.$masterRoute + '/api/formatData/' + this.$elem.id + '/edit?status=' + status, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                if (response.status) {
                    this.$reload();
                    this.loadview = false;
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error!!',
                        text: response.data.menssage,
                    });
                    this.loadview = false;
                }
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Clave de seguridad expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.loadview = false;
            });
        },
        changeBlocked(status) {
            this.loadblock = true;
            $axios.get(this.$masterRoute + '/api/formatData/' + this.$elem.id + '?status=' + status, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                if (response.status) {
                    this.$reload();
                    this.loadblock = false;
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error!!',
                        text: response.data.menssage,
                    });
                    this.loadblock = false;
                }
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Clave de seguridad expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.loadblock = false;
            });
        },
        changeDefaultValue() {
            this.loadinput = true;
            $axios.get(this.$masterRoute + '/api/formatData/create?id=' + this.$elem.id + '&value=' + JSON.stringify(this.inputData), {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                if (response.status) {
                    this.$reload();
                    this.loadinput = false;
                    this.inputDefault = false;
                    this.inputData = '';
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error!!',
                        text: response.data.menssage,
                    });
                    this.loadinput = false;
                }
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Clave de seguridad expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.loadinput = false;
            });
        }
    }
}
</script>
