<template>
<div>
    <div class="animate__animated animate__fadeInDownBig">
        <div class="container-fluid px-2 pt-2 w3-card-4 position-sticky left-0 right-0" :class="night ? 'w3-dark-gray text-white' : 'w3-light-gray'" style="top: 60px;z-index:100;">
            <!--Titulo de la vista para dispositivos xl lg md-->
            <div class="d-lg-flex d-none align-items-center">
                <div class="w3-medium me-auto ms-auto">
                    <i class="fas fa-desktop fa-lg me-2"></i>
                    <strong>DISPOSITIVOS</strong>
                </div>
            </div>
            <!--Titulo de la vista para dispositivos sm xs-->
            <div class="d-lg-none d-flex align-items-center">
                <div class="w3-small me-auto ms-auto">
                    <i class="fas fa-desktop fa-lg me-2"></i>
                    <strong>DISPOSITIVOS</strong>
                </div>
            </div>
        </div>
        <!-- Contenido -->
        <div class="container-fluid pt-3">
            <div class="card" :class="night ? 'bg-dark-gray' : 'w3-white'">
                <div class="card-body">
                    <div class="table-responsive d-none d-md-block" style="overflow:auto;" :style="'height:' + screenheightsize + 'px;'">
                        <table class="table table-hover table-sm align-middle" :style="'width:' + screenwidthsize + 'px;'">
                            <thead class="w3-medium text-center" style="position: sticky;top: 0;z-index: 10;">
                                <tr style="position: sticky;top: 0;z-index: 10;">
                                    <th scope="col" :class="night ? 'w3-dark-gray text-white' : 'w3-light-gray'" style="width: 10px;position: sticky;top: 0;z-index: 10;">
                                        <strong>#</strong>
                                    </th>
                                    <th scope="col" :class="night ? 'w3-dark-gray text-white' : 'w3-light-gray'" style="width: 175px;position: sticky;top: 0;z-index: 10;">
                                        <strong>Dispositivo</strong>
                                    </th>
                                    <th scope="col" :class="night ? 'w3-dark-gray text-white' : 'w3-light-gray'" style="position: sticky;top: 0;z-index: 10;">
                                        <strong>Información</strong>
                                    </th>
                                    <th v-if="$permisos.seepass.status" scope="col" :class="night ? 'w3-dark-gray text-white' : 'w3-light-gray'" style="width: 75px;position: sticky;top: 0;z-index: 10;">
                                        <strong>Clave</strong>
                                    </th>
                                    <th scope="col" :class="night ? 'w3-dark-gray text-white' : 'w3-light-gray'" style="width: 10px;position: sticky;top: 0;z-index: 10;">
                                        <strong>Estado</strong>
                                    </th>
                                    <th scope="col" :class="night ? 'w3-dark-gray text-white' : 'w3-light-gray'" style="width: 175px;position: sticky;top: 0;z-index: 10;">
                                        <strong>Última conexión</strong>
                                    </th>
                                    <th scope="col" :class="night ? 'w3-dark-gray text-white' : 'w3-light-gray'" style="width: 175px;position: sticky;top: 0;z-index: 10;">
                                        <strong>Creación</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <devices-rows v-for="(item, index) in devices" :key="index" :$item="item" :$index="index" :$night="night" :$permisos="$permisos.seepass.status"></devices-rows>
                            </tbody>
                        </table>
                    </div>
                    <div class="d-md-none d-block">
                        <ul class="list-group">
                            <devices-item v-for="(item, index) in devices" :key="index" :$item="item" :$index="index" :$permisos="$permisos.seepass.status"></devices-item>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <router-view></router-view>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
import moment from 'moment'
export default {
    props: {
        $permisos: Object,
    },
    created() {
        this.darkmode();
        this.getDevices();
        moment.locale('es-mx');
    },
    data() {
        return {
            night: null,
            devices: [],
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        getDevices() {
            $axios.get(this.$masterRoute + '/api/devices', {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.devices = response.data;
            }).catch(error => {
                console.log(error);
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
            });
        },
    },
    computed: {
        screenwidthsize() {
            if (screen.width < 1336) {
                return 1336;
            }
            return screen.width - 55;
        },
        screenheightsize() {
            let num = 150;
            if (screen.width > 1500) {
                num = 250;
            }
            return screen.height - num;
        },
    }
}
</script>
