<template>
<div class="animate__animated animate__fadeIn">
    <div class="container-fluid">
        <div class="d-flex align-items-center mb-3">
            <button type="button" class="btn btn-primary btn-floating me-auto" @click.prevent="addElement">
                <i class="fas fa-plus fa-lg"></i>
            </button>
            <strong class="w3-large me-auto">
                <i class="fas fa-table fa-lg me-2"></i>
                <span>Formato de datos de los activos</span>
            </strong>
        </div>
        <div v-if="load" class="d-flex justify-content-center">
            <div class="spinner-border text-secondary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-else-if="formatData.length">
            <div class="table-responsive">
                <table class="table table-sm table-hover align-middle d-xl-block d-lg-none d-md-none d-sm-none d-none ">
                    <thead>
                        <tr class="w3-light-gray">
                            <th></th>
                            <th scope="col">
                                <strong>#</strong>
                            </th>
                            <th scope="col">
                                <strong>Nombre</strong>
                            </th>
                            <th scope="col">
                                <strong>Tipo</strong>
                            </th>
                            <th scope="col">
                                <strong>Tamaño</strong>
                            </th>
                            <th scope="col">
                                <strong>Valor por defecto</strong>
                            </th>
                            <th scope="col">
                                <strong>Obligatorio</strong>
                            </th>
                            <th scope="col">
                                <strong>Visibilidad</strong>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <formatData-cols v-for="(elem, index) in formatData" :key="index" :$index="index" :$elem="elem" :$reload="getFormatData"></formatData-cols>
                    </tbody>
                </table>
            </div>
            <ul class="list-group d-xl-none d-lg-block d-md-block d-sm-block d-block">
                <li v-for="(elem, index) in formatData" :key="index" class="list-group-item list-group-item-action">
                    <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1">
                            <strong>#</strong> {{ index + 1 }}
                        </h6>
                        <small v-if="elem.isVisible" class="text-muted pt-2"><i class="fas fa-check-circle fa-lg"></i></small>
                        <small v-else class="text-muted pt-2"><i class="fas fa-times-circle fa-lg"></i></small>
                    </div>
                    <div class="row">
                        <p class="mb-1 col-lg-6 col-md-12 col-sm-6 col-12 w3-small">
                            <strong>Nombre:</strong> {{ elem.name }}
                        </p>
                        <p class="mb-1 col-lg-3 col-md-6 col-sm-3 col-12 w3-small">
                            <strong>Tipo:</strong> {{ elem.type }}
                        </p>
                        <p class="mb-1 col-lg-3 col-md-6 col-sm-3 col-12 w3-small">
                            <strong>Tamaño:</strong> {{ elem.size }}
                        </p>
                        <p class="mb-1 col-lg-3 col-md-6 col-sm-3 col-12 w3-small">
                            <strong>Obligatorio:</strong>
                            <small v-if="elem.isRequire" class="text-muted"><i class="fas fa-lock fa-2x text-danger"></i></small>
                            <small v-else class="text-muted"><i class="fas fa-lock-open fa-2x text-warning"></i></small>
                        </p>
                        <p class="mb-1 col-lg-9 col-md-6 col-sm-3 col-12 w3-small">
                            <strong>Valor por defecto:</strong> {{ elem.defaultValue }}
                        </p>
                    </div>
                    <small class="text-muted">
                        <div class="d-flex justify-content-end w-100">
                            <div class="ms-auto">
                                <button class="btn btn-warning btn-rounded btn-sm w3-tiny" @click.prevent="editElement(elem)">
                                    <i class="fas fa-pen"></i>
                                    <span>Editar</span>
                                </button>
                                <button class="btn btn-danger btn-rounded btn-sm w3-tiny" @click.prevent="deleteElement(elem.id)">
                                    <i class="fas fa-trash-alt"></i>
                                    <span>Eliminar</span>
                                </button>
                            </div>
                        </div>
                    </small>
                </li>
            </ul>
        </div>
        <h5 v-else class="text-center"><i class="fas fa-ban"></i> No se encontraron datos</h5>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        $activo: Object,
        $getElem: Function,
    },
    created() {
        this.load = true;
        this.getFormatData();
    },
    watch: {
        $activo() {
            this.load = true;
            this.getFormatData();
        }
    },
    data() {
        return {
            load: false,
            formatData: {}
        }
    },
    methods: {
        getFormatData() {
            $axios.get(this.$masterRoute + '/api/formatData?id=' + this.$activo.id, {
                    headers: {
                        Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        this.formatData = response.data.datos;
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: 'Error!!',
                            text: response.data.menssage,
                        });
                    }
                    this.load = false;
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        $swal.fire({
                            icon: 'error',
                            title: 'Calve de seguridad expirada',
                            text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                        }).then((result) => {
                            this.$router.push({
                                name: 'login'
                            });
                        });
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: 'Error Inesperado!!',
                            text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                        });
                    }
                    console.log(error.response);
                    this.load = false;
                });
        },
        addElement() {
            this.$router.push({
                name: 'createFormatData',
                params: {
                    _element: this.$activo,
                    _reload: this.getFormatData,
                },
            });
        },
    }
}
</script>
