<template>
<div>
    <div class="row mb-3">
        <div class="col">
            <button v-if="permisos.create.status" class="btn btn-primary btn-sm btn-rounded d-md-block d-none" @click="create">
                <i class="fas fa-plus fa-lg me-2"></i>
                Nueva categoria
            </button>
            <button v-if="permisos.create.status" class="btn btn-primary btn-floating d-md-none d-block" @click="create">
                <i class="fas fa-plus fa-lg me-2"></i>
            </button>
        </div>
        <div class="col-7 col-md-4">
            <div class="input-group input-group-sm rounded ms-auto">
                <input type="search" v-model="search" class="form-control rounded" placeholder="Buscar" aria-label="Search" aria-describedby="search-addon" />
                <span class="input-group-text border-0" id="search-addon">
                    <i class="fas fa-search" :class="night ? 'text-white' : ''"></i>
                </span>
            </div>
        </div>
    </div>
    <div class="table-responsive overflow-auto d-none d-md-block" :style="'height:' + screenheightsize + 'px;'">
        <table class="table table-hover table-sm align-middle" :class="night ? 'table-dark' : 'table-light'" :style="'width:' + screenwidthsize + 'px;'">
            <thead style="position: sticky;top: 0;" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">
                <tr class="text-center" :class="night ? 'border-dark' : 'border'" style="position: sticky;top: 0;">
                    <th class="fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'" style="position: sticky;left: 0;width: 10px;z-index: 1;">Opciones</th>
                    <th class="fw-bold" style="position: sticky;top: 0;">Nombre</th>
                    <th class="fw-bold" style="position: sticky;top: 0;">Creación</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="load">
                    <td colspan="4" class="text-center">
                        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </td>
                </tr>
                <tr v-else class="text-center" v-for="(item, index) in categories" :key="index">
                    <td :class="night ? 'w3-dark-gray' : 'w3-light-gray'" style="position:sticky;left:0px;">
                        <div class="btn-group btn-group-sm" role="group">
                            <button v-if="permisos.edit.status" type="button" class="btn btn-warning" @click="edit(item)">
                                <i class="fas fa-pencil-alt fa-lg"></i>
                            </button>
                            <button v-if="permisos.delete.status" type="button" class="btn btn-danger" @click="destroy(item)">
                                <i class="fas fa-trash-alt fa-lg"></i>
                            </button>
                        </div>
                    </td>
                    <td>{{ item.name }}</td>
                    <td>{{ getMomentDate(item.created_at) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <ul class="list-group overflow-auto d-block d-md-none border-top border-5" :style="'height:' + screenheightsize + 'px;'">
        <li v-if="load" class="list-group-item text-center">
            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </li>
        <li v-else v-for="(item, index) in categories" :key="index" class="list-group-item">
            <div class="row mb-3">
                <div class="col-6">
                    <strong>#</strong>
                    <small>{{ index + 1 }}</small>
                </div>
                <div class="col-6 text-end">
                    <div class="btn-group btn-group-sm" role="group">
                        <button v-if="permisos.edit.status" type="button" class="btn btn-warning" @click="edit(item)">
                            <i class="fas fa-pencil-alt fa-lg"></i>
                        </button>
                        <button v-if="permisos.delete.status" type="button" class="btn btn-danger" @click="destroy(item)">
                            <i class="fas fa-trash-alt fa-lg"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12 text-center">
                    <strong>Nombre</strong>
                    <br>
                    <small>{{ item.name }}</small>
                </div>
                <div class="col-12 text-center">
                    <strong>Creacion</strong>
                    <br>
                    <small>{{ getMomentDate(item.created_at) }}</small>
                </div>
            </div>
        </li>
    </ul>
    <router-view></router-view>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
import moment from 'moment'
export default {
    props: {
        p_permisos: Object,
    },
    created() {
        this.darkmode();
        this.permisos = this.p_permisos;
        this.changeSize();
        this.getCategories();
        moment.locale('es-mx');
        this.debouncedGetCategories = _.debounce(this.getCategories, 500);
    },
    watch: {
        search() {
            this.debouncedGetCategories();
        }
    },
    data() {
        return {
            night: null,
            categories: [],
            pagination: {},
            permisos: {},
            search: '',
            load: false,
            screenwidthsize: null,
            screenheightsize: null,
        }
    },
    mounted() {
        window.addEventListener("resize", this.changeSize);
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        getCategories() {
            this.load = true;
            $axios.get(this.$masterRoute + '/api/catcompras?search=' + this.search, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.categories = response.data.categories.data;
                this.pagination = response.data.pagination;
                this.load = false;
            }).catch(error => {
                console.error(error);
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                this.load = false;
            });
        },
        create() {
            this.$router.push({
                name: 'createCategoryCom',
                params: {
                    p_reload: this.getCategories,
                }
            });
        },
        edit(item) {
            this.$router.push({
                name: 'editCategoryCom',
                params: {
                    p_reload: this.getCategories,
                    p_item: item,
                }
            });
        },
        destroy(item) {
            $swal.fire({
                title: '¿Estás seguro?',
                text: "Estás a punto de eliminar una categoría de compra!!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#03cc9a',
                cancelButtonColor: '#ED1848',
                confirmButtonText: 'Si, eliminarlo!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.load = true;
                    $axios.delete(this.$masterRoute + '/api/catcompras/' + item.id, {
                        headers: {
                            Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                        }
                    }).then(response => {
                        if (response.data.status) {
                            $swal.fire({
                                icon: 'success',
                                title: response.data.title,
                                text: response.data.menssage,
                            });
                            this.getCategories();
                        } else {
                            $swal.fire({
                                icon: 'error',
                                title: response.data.title,
                                text: response.data.menssage,
                            });
                        }
                        this.load = false;
                    }).catch(error => {
                        console.error(error);
                        if (error.response.status === 401) {
                            $swal.fire({
                                icon: 'error',
                                title: 'Sesión expirada',
                                text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                            }).then((result) => {
                                this.$router.push({
                                    name: 'login'
                                });
                            });
                        } else {
                            $swal.fire({
                                icon: 'error',
                                title: 'Error Inesperado!!',
                                text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                            });
                        }
                        this.load = false;
                    });
                }
            });
        },
        getMomentDate(date) {
            return moment(date).format('LLLL');
        },
        changeSize() {
            if (window.innerWidth < 975) {
                this.screenwidthsize = 975;
            } else {
                this.screenwidthsize = window.innerWidth - 60;
            }
            this.screenheightsize = window.innerHeight - 300;
        },
    }
}
</script>
