<template>
<div>
    <div v-if="load" class="d-flex align-items-center justify-content-center mb-3">
        <div class="spinner-border text-secondary" style="width: 3rem; height: 3rem;" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <ul v-else class="list-group">
        <li v-if="!reports.length" :class="$night ? 'w3-dark-gray' : ''" class="list-group-item d-flex align-items-center justify-content-center w3-medium">
            <i class="fas fa-ban fa-lg me-2"></i>
            <strong>Sin resultados</strong>
        </li>
        <li v-else v-for="(item, index) in reports" :key="index" class="list-group-item d-flex align-items-center">
            <img :src="item.image" class="shadow-2-strong me-5" style="width: 40px;" alt="imagen" />
            <strong class="me-2">{{ item.clave }}</strong>
            <small class="me-auto">{{ item.name }}</small>
            <small>{{ getMomentDate(item.created_at) }}</small>
        </li>
    </ul>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
import moment from 'moment'
export default {
    props: {
        $night: Boolean,
        $org: Object,
    },
    created() {
        this.getReportsByOrg()
    },
    watch: {
        $org() {
            this.load = true;
            this.getReportsByOrg();
        }
    },
    data() {
        return {
            reports: [],
            load: true,
        }
    },
    methods: {
        getReportsByOrg() {
            $axios.get(this.$masterRoute + '/api/organizaciones/reports/' + this.$org.id, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.reports = response.data;
                this.load = false;
            }).catch(error => {
                console.error(error);
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                this.load = false;
            });
        },
        getMomentDate(date) {
            return moment(date).format('MMMM Do YYYY, h:mm a');
        },
    }
}
</script>
