<template>
<div>
    <div v-if="editPermisos" class="mb-3">
        <button class="btn btn-danger btn-rounded btn-sm me-2" @click.prevent="editPermisos = false">
            <i class="fas fa-ban fa-lg"></i>
            <span class="d-xl-inline d-lg-inline d-md-inline d-none">Cancelar</span>
        </button>
        <button class="btn btn-success btn-rounded btn-sm me-2" :disabled="!btnSave" @click.prevent="addOrChangePermisos">
            <i class="fas fa-save fa-lg"></i>
            <span class="d-xl-inline d-lg-inline d-md-inline d-none">Guardar cambios</span>
        </button>
    </div>
    <div v-else-if="$permissions" class="mb-3">
        <button class="btn btn-info btn-rounded btn-sm" @click.prevent="editPermisos = true">
            <i class="fas fa-exchange-alt"></i>
            <span class="d-xl-inline d-lg-inline d-md-inline d-none">Cambiar permisos</span>
        </button>
    </div>
    <div v-if="editPermisos">
        <users-cards-permisos v-for="(item, index) in Object.keys(permisosObject)" :key="index" :$datos="permisosObject[item]" :$changeStatus="changeStatusPermiso" :$clave="pushItemArray(item)" :$night="$night"></users-cards-permisos>
    </div>
    <div v-else>
        <view-permisos v-for="(item, index) in Object.keys(permisosObject)" :key="index" :$datos="permisosObject[item]" :$night="$night"></view-permisos>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        $permissions: Boolean,
        $role: Object,
        $rolReload: Function,
        $night: Boolean,
    },
    created() {
        this.permisosObject = JSON.parse(this.$role.permisos);
        this.existsObjectValues();
    },
    watch: {
        $role() {
            this.permisosObject = JSON.parse(this.$role.permisos);
            this.existsObjectValues();
            this.editPermisos = false;
            this.btnSave = false;
        }
    },
    data() {
        return {
            editPermisos: false,
            permisosObject: {},
            btnSave: false,
        }
    },
    methods: {
        addOrChangePermisos() {
            this.load = true;
            $axios.post(this.$masterRoute + '/api/roles/permisos/addOrChange', {
                id: this.$role.id,
                jsonData: JSON.stringify(this.permisosObject),
            }, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                if (response.data.status) {
                    $swal.fire(
                        'Acción exitosa!',
                        response.data.menssage,
                        'success'
                    );
                    this.editPermisos = false;
                    this.$rolReload();
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error!!',
                        text: response.data.menssage,
                    });
                }
                this.load = false;
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Clave de seguridad expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });
        },
        changeStatusPermiso(clave, status) {
            this.btnSave = true;
            switch (clave.length) {
                case 1:
                    this.permisosObject[clave[0]].status = status;
                    break;
                case 3:
                    this.permisosObject[clave[0]][clave[1]][clave[2]].status = status;
                    break;
                case 5:
                    this.permisosObject[clave[0]][clave[1]][clave[2]][clave[3]][clave[4]].status = status;
                    break;
                case 7:
                    this.permisosObject[clave[0]][clave[1]][clave[2]][clave[3]][clave[4]][clave[5]][clave[6]].status = status;
                    break;
                default:
                    break;
            }
        },
        pushItemArray(item) {
            let ar = [];
            ar.push(item);
            return ar;
        },
        existsObjectValues() {
            let permisos = {};
            //Parent devices
            if (!this.permisosObject.devices) {
                this.permisosObject.devices = {
                    status: false,
                    name: 'Dispositivos',
                    permisos: {},
                };
                permisos.devices = {
                    status: false,
                    name: 'Dispositivos',
                    permisos: {},
                };
            } else {
                permisos.devices = {
                    status: this.permisosObject.devices.status,
                    name: 'Dispositivos',
                    permisos: {},
                }
            }
            //Devices children
            if (!this.permisosObject.devices.permisos) {
                this.permisosObject.devices.permisos = {};
                permisos.devices.permisos = {};
            }
            if (!this.permisosObject.devices.permisos.seepass) {
                this.permisosObject.devices.permisos.seepass = {
                    status: false,
                    name: 'Ver contraseñas',
                };
                permisos.devices.permisos.seepass = {
                    status: false,
                    name: 'Ver contraseñas',
                }
            } else {
                permisos.devices.permisos.seepass = {
                    status: this.permisosObject.devices.permisos.seepass.status,
                    name: 'Ver contraseñas',
                }
            }
            /*----------------------------------------------------------------*/
            //Parent organizaciones
            if (!this.permisosObject.org) {
                this.permisosObject.org = {
                    status: false,
                    name: 'Organizaciones',
                    permisos: {},
                };
                permisos.org = {
                    status: false,
                    name: 'Organizaciones',
                    permisos: {},
                };
            } else {
                permisos.org = {
                    status: this.permisosObject.org.status,
                    name: 'Organizaciones',
                    permisos: {},
                };
            }
            //Organizaciones permisos
            if (!this.permisosObject.org.permisos) {
                this.permisosObject.org.permisos = {};
                permisos.org.permisos = {};
            }
            if (!this.permisosObject.org.permisos.createraiz) {
                this.permisosObject.org.permisos.createraiz = {
                    status: false,
                    name: 'Crear elemento raiz',
                };
                permisos.org.permisos.createraiz = {
                    status: false,
                    name: 'Crear elemento raiz',
                }
            } else {
                permisos.org.permisos.createraiz = {
                    status: this.permisosObject.org.permisos.createraiz.status,
                    name: 'Crear elemento raiz'
                }
            }
            //---------------------------------------------
            if (!this.permisosObject.org.permisos.create) {
                this.permisosObject.org.permisos.create = {
                    status: false,
                    name: 'Crear elementos',
                };
                permisos.org.permisos.create = {
                    status: false,
                    name: 'Crear elementos',
                }
            } else {
                permisos.org.permisos.create = {
                    status: this.permisosObject.org.permisos.create.status,
                    name: 'Crear elementos'
                }
            }
            //----------------------------------------
            if (!this.permisosObject.org.permisos.edit) {
                this.permisosObject.org.permisos.edit = {
                    status: false,
                    name: 'Editar elementos',
                };
                permisos.org.permisos.edit = {
                    status: false,
                    name: 'Editar elementos',
                };
            } else {
                permisos.org.permisos.edit = {
                    status: this.permisosObject.org.permisos.edit.status,
                    name: 'Editar elementos'
                };
            }
            //------------------------------------
            if (!this.permisosObject.org.permisos.move) {
                this.permisosObject.org.permisos.move = {
                    status: false,
                    name: 'Mover elementos',
                };
                permisos.org.permisos.move = {
                    status: false,
                    name: 'Mover elementos',
                };
            } else {
                permisos.org.permisos.move = {
                    status: this.permisosObject.org.permisos.move.status,
                    name: 'Mover elementos'
                };
            }
            //-------------------------------------
            if (!this.permisosObject.org.permisos.delete) {
                this.permisosObject.org.permisos.delete = {
                    status: false,
                    name: 'Eliminar elementos'
                };
                permisos.org.permisos.delete = {
                    status: false,
                    name: 'Eliminar elementos'
                };
            } else {
                permisos.org.permisos.delete = {
                    status: this.permisosObject.org.permisos.delete.status,
                    name: 'Eliminar elementos'
                };
            }
            /*----------------------------------------------------------------*/
            //Parent usuarios
            if (!this.permisosObject.users) {
                this.permisosObject.users = {
                    status: false,
                    name: 'Usuarios',
                    children: {},
                };
                permisos.users = {
                    status: false,
                    name: 'Usuarios',
                    children: {},
                };
            } else {
                permisos.users = {
                    status: this.permisosObject.users.status,
                    name: 'Usuarios',
                    children: {},
                };
            }
            //users children
            if (!this.permisosObject.users.children) {
                this.permisosObject.users.children = {};
                permisos.users.children = {};
            }
            if (!this.permisosObject.users.children.rol) {
                this.permisosObject.users.children.rol = {
                    status: false,
                    name: 'Roles',
                    permisos: {},
                };
                permisos.users.children.rol = {
                    status: false,
                    name: 'Roles',
                    permisos: {},
                };
            } else {
                permisos.users.children.rol = {
                    status: this.permisosObject.users.children.rol.status,
                    name: 'Roles',
                    permisos: {},
                };
            }
            //--------------------------------
            if (!this.permisosObject.users.children.permissions) {
                this.permisosObject.users.children.permissions = {
                    status: false,
                    name: 'Permisos',
                    permisos: {},
                };
                permisos.users.children.permissions = {
                    status: false,
                    name: 'Permisos',
                    permisos: {},
                };
            } else {
                permisos.users.children.permissions = {
                    status: this.permisosObject.users.children.permissions.status,
                    name: 'Permisos',
                    permisos: {},
                };
            }
            //-----------------------------------
            if (!this.permisosObject.users.children.panel) {
                this.permisosObject.users.children.panel = {
                    status: false,
                    name: 'Panel de usuarios',
                    permisos: {},
                };
                permisos.users.children.panel = {
                    status: false,
                    name: 'Panel de usuarios',
                    permisos: {},
                };
            } else {
                permisos.users.children.panel = {
                    status: this.permisosObject.users.children.panel.status,
                    name: 'Panel de usuarios',
                    permisos: {},
                };
            }
            //Users roles permisos
            if (!this.permisosObject.users.children.rol.permisos.create) {
                this.permisosObject.users.children.rol.permisos.create = {
                    status: false,
                    name: 'Crear',
                };
                permisos.users.children.rol.permisos.create = {
                    status: false,
                    name: 'Crear',
                };
            } else {
                permisos.users.children.rol.permisos.create = {
                    status: this.permisosObject.users.children.rol.permisos.create.status,
                    name: 'Crear',
                };
            }
            //-------------------------------
            if (!this.permisosObject.users.children.rol.permisos.edit) {
                this.permisosObject.users.children.rol.permisos.edit = {
                    status: false,
                    name: 'Editar',
                };
                permisos.users.children.rol.permisos.edit = {
                    status: false,
                    name: 'Editar',
                };
            } else {
                permisos.users.children.rol.permisos.edit = {
                    status: this.permisosObject.users.children.rol.permisos.edit.status,
                    name: 'Editar',
                };
            }
            //------------------------------------
            if (!this.permisosObject.users.children.rol.permisos.delete) {
                this.permisosObject.users.children.rol.permisos.delete = {
                    status: false,
                    name: 'Eliminar',
                };
                permisos.users.children.rol.permisos.delete = {
                    status: false,
                    name: 'Eliminar',
                };
            } else {
                permisos.users.children.rol.permisos.delete = {
                    status: this.permisosObject.users.children.rol.permisos.delete.status,
                    name: 'Eliminar',
                };
            }

            //users permissions permisos
            if (!this.permisosObject.users.children.permissions.permisos.change) {
                this.permisosObject.users.children.permissions.permisos.change = {
                    status: false,
                    name: 'Cambiar permisos',
                };
                permisos.users.children.permissions.permisos.change = {
                    status: false,
                    name: 'Cambiar permisos',
                };
            } else {
                permisos.users.children.permissions.permisos.change = {
                    status: this.permisosObject.users.children.permissions.permisos.change.status,
                    name: 'Cambiar permisos',
                };
            }

            //Users panel permisos
            if (!this.permisosObject.users.children.panel.permisos.create) {
                this.permisosObject.users.children.panel.permisos.create = {
                    status: false,
                    name: 'Crear'
                };
                permisos.users.children.panel.permisos.create = {
                    status: false,
                    name: 'Crear'
                }
            } else {
                permisos.users.children.panel.permisos.create = {
                    status: this.permisosObject.users.children.panel.permisos.create.status,
                    name: 'Crear'
                }
            }
            //-------------------------------
            if (!this.permisosObject.users.children.panel.permisos.edit) {
                this.permisosObject.users.children.panel.permisos.edit = {
                    status: false,
                    name: 'Editar'
                };
                permisos.users.children.panel.permisos.edit = {
                    status: false,
                    name: 'Editar'
                };
            } else {
                permisos.users.children.panel.permisos.edit = {
                    status: this.permisosObject.users.children.panel.permisos.edit.status,
                    name: 'Editar'
                };
            }
            //----------------------------------
            if (!this.permisosObject.users.children.panel.permisos.delete) {
                this.permisosObject.users.children.panel.permisos.delete = {
                    status: false,
                    name: 'Eliminar'
                };
                permisos.users.children.panel.permisos.delete = {
                    status: false,
                    name: 'Eliminar'
                };
            } else {
                permisos.users.children.panel.permisos.delete = {
                    status: this.permisosObject.users.children.panel.permisos.delete.status,
                    name: 'Eliminar'
                };
            }
            //--------------------------------
            if (!this.permisosObject.users.children.panel.permisos.changePass) {
                this.permisosObject.users.children.panel.permisos.changePass = {
                    status: false,
                    name: 'Cambiar contraseña'
                };
                permisos.users.children.panel.permisos.changePass = {
                    status: false,
                    name: 'Cambiar contraseña'
                };
            } else {
                permisos.users.children.panel.permisos.changePass = {
                    status: this.permisosObject.users.children.panel.permisos.changePass.status,
                    name: 'Cambiar contraseña'
                };
            }
            /*----------------------------------------------------------------*/
            //Parent activos
            if (!this.permisosObject.activos) {
                this.permisosObject.activos = {
                    status: false,
                    name: 'Activos',
                    children: {},
                };
                permisos.activos = {
                    status: false,
                    name: 'Activos',
                    children: {},
                };
            } else {
                permisos.activos = {
                    status: this.permisosObject.activos.status,
                    name: 'Activos',
                    children: {},
                }
            }
            //activos children
            if (!this.permisosObject.activos.children.panelAdmin) {
                this.permisosObject.activos.children.panelAdmin = {
                    status: false,
                    name: 'Panel de administración',
                    permisos: {},
                }
            }
            if (!this.permisosObject.activos.children.structure) {
                this.permisosObject.activos.children.structure = {
                    status: false,
                    name: 'Estructuras',
                    permisos: {},
                }
            }
            if (!this.permisosObject.activos.children.formatData) {
                this.permisosObject.activos.children.formatData = {
                    status: false,
                    name: 'Formatos y datos',
                    permisos: {},
                };
            }
            if (!this.permisosObject.activos.children.metricas) {
                this.permisosObject.activos.children.metricas = {
                    status: false,
                    name: 'Metricas',
                    permisos: {},
                };
            }
            //activos panelAdmin permisos
            if (!this.permisosObject.activos.children.panelAdmin.permisos.createActivo) {
                this.permisosObject.activos.children.panelAdmin.permisos.createActivo = {
                    status: false,
                    name: 'Crear activo'
                };
            }
            if (!this.permisosObject.activos.children.panelAdmin.permisos.editActivo) {
                this.permisosObject.activos.children.panelAdmin.permisos.editActivo = {
                    status: false,
                    name: 'Editar activo'
                };
            }
            if (!this.permisosObject.activos.children.panelAdmin.permisos.deleteActivo) {
                this.permisosObject.activos.children.panelAdmin.permisos.deleteActivo = {
                    status: false,
                    name: 'Eliminar activo'
                };
            }
            //activos Estructuras permisos
            if (!this.permisosObject.activos.children.structure.permisos.create) {
                this.permisosObject.activos.children.structure.permisos.create = {
                    status: false,
                    name: 'Crear'
                };
            }
            if (!this.permisosObject.activos.children.structure.permisos.move) {
                this.permisosObject.activos.children.structure.permisos.move = {
                    status: false,
                    name: 'Mover'
                };
            }
            if (!this.permisosObject.activos.children.structure.permisos.edit) {
                this.permisosObject.activos.children.structure.permisos.edit = {
                    status: false,
                    name: 'Editar'
                };
            }
            if (!this.permisosObject.activos.children.structure.permisos.delete) {
                this.permisosObject.activos.children.structure.permisos.delete = {
                    status: false,
                    name: 'Eliminar'
                };
            }

            //activos Formatos y datos permisos
            if (!this.permisosObject.activos.children.formatData.permisos.create) {
                this.permisosObject.activos.children.formatData.permisos.create = {
                    status: false,
                    name: 'Crear'
                };
            }
            if (!this.permisosObject.activos.children.formatData.permisos.edit) {
                this.permisosObject.activos.children.formatData.permisos.edit = {
                    status: false,
                    name: 'Editar'
                };
            }
            if (!this.permisosObject.activos.children.formatData.permisos.delete) {
                this.permisosObject.activos.children.formatData.permisos.delete = {
                    status: false,
                    name: 'Eliminar'
                };
            }
            if (!this.permisosObject.activos.children.formatData.permisos.changeVisible) {
                this.permisosObject.activos.children.formatData.permisos.changeVisible = {
                    status: false,
                    name: 'Cambiar Visibilidad'
                };
            }
            /*----------------------------------------------------------------*/
            //Parent Reportes
            if (!this.permisosObject.reportes) {
                this.permisosObject.reportes = {
                    status: false,
                    name: 'Reportes',
                    children: {},
                };
                permisos.reportes = {
                    status: false,
                    name: 'Reportes',
                    children: {},
                };
            } else {
                permisos.reportes = {
                    status: this.permisosObject.reportes.status,
                    name: 'Reportes',
                    children: {},
                }
            }
            //Reportes children
            if (!this.permisosObject.reportes.children.principal) {
                this.permisosObject.reportes.children.principal = {
                    status: false,
                    name: 'Panel principal',
                    permisos: {},
                };
                permisos.reportes.children.principal = {
                    status: false,
                    name: 'Panel principal',
                    permisos: {},
                };
            } else {
                permisos.reportes.children.principal = {
                    status: this.permisosObject.reportes.children.principal.status,
                    name: 'Panel principal',
                    permisos: {},
                };
            }
            //-----------------------------------
            if (!this.permisosObject.reportes.children.config) {
                this.permisosObject.reportes.children.config = {
                    status: false,
                    name: 'Panel de configuraciones',
                    permisos: {},
                };
                permisos.reportes.children.config = {
                    status: false,
                    name: 'Panel de configuraciones',
                    permisos: {},
                };
            } else {
                permisos.reportes.children.config = {
                    status: this.permisosObject.reportes.children.config.status,
                    name: 'Panel de configuraciones',
                    permisos: {},
                };
            }

            //Reportes principal permisos
            if (!this.permisosObject.reportes.children.principal.permisos.create) {
                this.permisosObject.reportes.children.principal.permisos.create = {
                    status: false,
                    name: 'Crear reporte'
                };
                permisos.reportes.children.principal.permisos.create = {
                    status: false,
                    name: 'Crear reporte'
                };
            } else {
                permisos.reportes.children.principal.permisos.create = {
                    status: this.permisosObject.reportes.children.principal.permisos.create.status,
                    name: 'Crear reporte'
                };
            }
            //-------------------------------
            if (!this.permisosObject.reportes.children.principal.permisos.filter) {
                this.permisosObject.reportes.children.principal.permisos.filter = {
                    status: false,
                    name: 'Filtrar información'
                };
                permisos.reportes.children.principal.permisos.filter = {
                    status: false,
                    name: 'Filtrar información'
                };
            } else {
                permisos.reportes.children.principal.permisos.filter = {
                    status: this.permisosObject.reportes.children.principal.permisos.filter.status,
                    name: 'Filtrar información'
                };
            }
            //-----------------------------------------
            if (!this.permisosObject.reportes.children.principal.permisos.viewrem) {
                this.permisosObject.reportes.children.principal.permisos.viewrem = {
                    status: false,
                    name: 'Ver remitente'
                };
                permisos.reportes.children.principal.permisos.viewrem = {
                    status: false,
                    name: 'Ver remitente'
                };
            } else {
                permisos.reportes.children.principal.permisos.viewrem = {
                    status: this.permisosObject.reportes.children.principal.permisos.viewrem.status,
                    name: 'Ver remitente'
                };
            }
            //---------------------------------------
            if (!this.permisosObject.reportes.children.principal.permisos.viewdest) {
                this.permisosObject.reportes.children.principal.permisos.viewdest = {
                    status: false,
                    name: 'Ver destinatario'
                };
                permisos.reportes.children.principal.permisos.viewdest = {
                    status: false,
                    name: 'Ver destinatario'
                };
            } else {
                permisos.reportes.children.principal.permisos.viewdest = {
                    status: this.permisosObject.reportes.children.principal.permisos.viewdest.status,
                    name: 'Ver destinatario'
                };
            }
            //---------------------------------------
            if (!this.permisosObject.reportes.children.principal.permisos.viewprio) {
                this.permisosObject.reportes.children.principal.permisos.viewprio = {
                    status: false,
                    name: 'Ver prioridad'
                };
                permisos.reportes.children.principal.permisos.viewprio = {
                    status: false,
                    name: 'Ver prioridad'
                };
            } else {
                permisos.reportes.children.principal.permisos.viewprio = {
                    status: this.permisosObject.reportes.children.principal.permisos.viewprio.status,
                    name: 'Ver prioridad'
                };
            }
            //---------------------------------------
            if (!this.permisosObject.reportes.children.principal.permisos.startprocess) {
                this.permisosObject.reportes.children.principal.permisos.startprocess = {
                    status: false,
                    name: 'Iniciar proceso'
                };
                permisos.reportes.children.principal.permisos.startprocess = {
                    status: false,
                    name: 'Iniciar proceso'
                };
            } else {
                permisos.reportes.children.principal.permisos.startprocess = {
                    status: this.permisosObject.reportes.children.principal.permisos.startprocess.status,
                    name: 'Iniciar proceso'
                };
            }
            //----------------------------------
            if (!this.permisosObject.reportes.children.principal.permisos.checkprocess) {
                this.permisosObject.reportes.children.principal.permisos.checkprocess = {
                    status: false,
                    name: 'Terminar proceso'
                };
                permisos.reportes.children.principal.permisos.checkprocess = {
                    status: false,
                    name: 'Terminar proceso'
                };
            } else {
                permisos.reportes.children.principal.permisos.checkprocess = {
                    status: this.permisosObject.reportes.children.principal.permisos.checkprocess,
                    name: 'Terminar proceso'
                };
            }
            //-------------------------------------
            if (!this.permisosObject.reportes.children.principal.permisos.confirmprocess) {
                this.permisosObject.reportes.children.principal.permisos.confirmprocess = {
                    status: false,
                    name: 'Confirmar proceso'
                };
                permisos.reportes.children.principal.permisos.confirmprocess = {
                    status: false,
                    name: 'Confirmar proceso'
                };
            } else {
                permisos.reportes.children.principal.permisos.confirmprocess = {
                    status: this.permisosObject.reportes.children.principal.permisos.confirmprocess.status,
                    name: 'Confirmar proceso'
                };
            }
            //Reportes configuraciones permisos
            if (!this.permisosObject.reportes.children.config.permisos.create) {
                this.permisosObject.reportes.children.config.permisos.create = {
                    status: false,
                    name: 'Crear'
                };
                permisos.reportes.children.config.permisos.create = {
                    status: false,
                    name: 'Crear'
                };
            } else {
                permisos.reportes.children.config.permisos.create = {
                    status: this.permisosObject.reportes.children.config.permisos.create.status,
                    name: 'Crear'
                };
            }
            //--------------------------------
            if (!this.permisosObject.reportes.children.config.permisos.edit) {
                this.permisosObject.reportes.children.config.permisos.edit = {
                    status: false,
                    name: 'Editar'
                };
                permisos.reportes.children.config.permisos.edit = {
                    status: false,
                    name: 'Editar'
                };
            } else {
                permisos.reportes.children.config.permisos.edit = {
                    status: this.permisosObject.reportes.children.config.permisos.edit.status,
                    name: 'Editar'
                };
            }
            //---------------------------------------
            if (!this.permisosObject.reportes.children.config.permisos.delete) {
                this.permisosObject.reportes.children.config.permisos.delete = {
                    status: false,
                    name: 'Eliminar'
                };
                permisos.reportes.children.config.permisos.delete = {
                    status: false,
                    name: 'Eliminar'
                };
            } else {
                permisos.reportes.children.config.permisos.delete = {
                    status: this.permisosObject.reportes.children.config.permisos.delete.status,
                    name: 'Eliminar'
                };
            }
            //----------------------------------------
            if (!this.permisosObject.reportes.children.config.permisos.changeimg) {
                this.permisosObject.reportes.children.config.permisos.changeimg = {
                    status: false,
                    name: 'Cambiar imagen'
                };
                permisos.reportes.children.config.permisos.changeimg = {
                    status: false,
                    name: 'Cambiar imagen'
                };
            } else {
                permisos.reportes.children.config.permisos.changeimg = {
                    status: this.permisosObject.reportes.children.config.permisos.changeimg.status,
                    name: 'Cambiar imagen'
                };
            }
            /*----------------------------------------------------------------*/

            //Parent Compras
            if (!this.permisosObject.compras) {
                this.permisosObject.compras = {
                    status: false,
                    name: 'Compras',
                    children: {},
                };
                permisos.compras = {
                    status: false,
                    name: 'Compras',
                    children: {},
                };
            } else {
                permisos.compras = {
                    status: this.permisosObject.compras.status,
                    name: 'Compras',
                    children: {},
                }
            }

            //Compras children
            if (!this.permisosObject.compras.children.vistas) {
                this.permisosObject.compras.children.vistas = {
                    status: false,
                    name: 'Vistas principales',
                    permisos: {},
                };
                permisos.compras.children.vistas = {
                    status: false,
                    name: 'Vistas principales',
                    permisos: {},
                };
            } else {
                permisos.compras.children.vistas = {
                    status: this.permisosObject.compras.children.vistas.status,
                    name: 'Vistas principales',
                    permisos: {},
                };
            }
            //----------------------------------------
            if (!this.permisosObject.compras.children.admin) {
                this.permisosObject.compras.children.admin = {
                    status: false,
                    name: 'Administración de compras',
                    permisos: {},
                };
                permisos.compras.children.admin = {
                    status: false,
                    name: 'Administración de compras',
                    permisos: {},
                };
            } else {
                permisos.compras.children.admin = {
                    status: this.permisosObject.compras.children.admin.status,
                    name: 'Administración de compras',
                    permisos: {},
                };
            }
            //----------------------------------------
            if (!this.permisosObject.compras.children.config) {
                this.permisosObject.compras.children.config = {
                    status: false,
                    name: 'Configuraciones',
                    children: {},
                };
                permisos.compras.children.config = {
                    status: false,
                    name: 'Configuraciones',
                    children: {},
                };
            } else {
                permisos.compras.children.config = {
                    status: this.permisosObject.compras.children.config.status,
                    name: 'Configuraciones',
                    children: {},
                };
            }

            //Compras vistas permisos
            if (!this.permisosObject.compras.children.vistas.permisos.ordcom) {
                this.permisosObject.compras.children.vistas.permisos.ordcom = {
                    status: false,
                    name: 'Ordenes de compra'
                };
                permisos.compras.children.vistas.permisos.ordcom = {
                    status: false,
                    name: 'Ordenes de compra'
                };
            } else {
                permisos.compras.children.vistas.permisos.ordcom = {
                    status: this.permisosObject.compras.children.vistas.permisos.ordcom.status,
                    name: 'Ordenes de compra'
                };
            }
            //----------------------------------------
            if (!this.permisosObject.compras.children.vistas.permisos.auth) {
                this.permisosObject.compras.children.vistas.permisos.auth = {
                    status: false,
                    name: 'Autorizaciones'
                };
                permisos.compras.children.vistas.permisos.auth = {
                    status: false,
                    name: 'Autorizaciones'
                };
            } else {
                permisos.compras.children.vistas.permisos.auth = {
                    status: this.permisosObject.compras.children.vistas.permisos.auth.status,
                    name: 'Autorizaciones'
                };
            }

            //Compras Admin permisos
            if (!this.permisosObject.compras.children.admin.permisos) {
                this.permisosObject.compras.children.admin.permisos = {};
                permisos.compras.children.admin.permisos = {};
            }
            if (!this.permisosObject.compras.children.admin.permisos.pay) {
                this.permisosObject.compras.children.admin.permisos.pay = {
                    status: false,
                    name: 'Pagar compras'
                };
                permisos.compras.children.admin.permisos.pay = {
                    status: false,
                    name: 'Pagar compras'
                };
            } else {
                permisos.compras.children.admin.permisos.pay = {
                    status: this.permisosObject.compras.children.admin.permisos.pay.status,
                    name: 'Pagar compras'
                };
            }
            //----------------------------------------
            if (!this.permisosObject.compras.children.admin.permisos.request) {
                this.permisosObject.compras.children.admin.permisos.request = {
                    status: false,
                    name: 'Solicitar compras'
                };
                permisos.compras.children.admin.permisos.request = {
                    status: false,
                    name: 'Solicitar compras'
                };
            } else {
                permisos.compras.children.admin.permisos.request = {
                    status: this.permisosObject.compras.children.admin.permisos.request.status,
                    name: 'Solicitar compras'
                };
            }
            //----------------------------------------
            if (!this.permisosObject.compras.children.admin.permisos.receive) {
                this.permisosObject.compras.children.admin.permisos.receive = {
                    status: false,
                    name: 'Recibir compras'
                };
                permisos.compras.children.admin.permisos.receive = {
                    status: false,
                    name: 'Recibir compras'
                };
            } else {
                permisos.compras.children.admin.permisos.receive = {
                    status: this.permisosObject.compras.children.admin.permisos.receive.status,
                    name: 'Recibir compras'
                };
            }
            //----------------------------------------
            if (!this.permisosObject.compras.children.admin.permisos.cancel) {
                this.permisosObject.compras.children.admin.permisos.cancel = {
                    status: false,
                    name: 'Cancelar compras'
                };
                permisos.compras.children.admin.permisos.cancel = {
                    status: false,
                    name: 'Cancelar compras'
                };
            } else {
                permisos.compras.children.admin.permisos.cancel = {
                    status: this.permisosObject.compras.children.admin.permisos.cancel.status,
                    name: 'Cancelar compras'
                };
            }
            //----------------------------------------
            if (!this.permisosObject.compras.children.admin.permisos.addimg) {
                this.permisosObject.compras.children.admin.permisos.addimg = {
                    status: false,
                    name: 'Adjuntar imágenes'
                };
                permisos.compras.children.admin.permisos.addimg = {
                    status: false,
                    name: 'Adjuntar imágenes'
                };
            } else {
                permisos.compras.children.admin.permisos.addimg = {
                    status: this.permisosObject.compras.children.admin.permisos.addimg.status,
                    name: 'Adjuntar imágenes'
                };
            }
            //----------------------------------------
            if (!this.permisosObject.compras.children.admin.permisos.delimg) {
                this.permisosObject.compras.children.admin.permisos.delimg = {
                    status: false,
                    name: 'Eliminar imágenes'
                };
                permisos.compras.children.admin.permisos.delimg = {
                    status: false,
                    name: 'Eliminar imágenes'
                };
            } else {
                permisos.compras.children.admin.permisos.delimg = {
                    status: this.permisosObject.compras.children.admin.permisos.delimg.status,
                    name: 'Eliminar imágenes'
                };
            }
            //----------------------------------------
            if (!this.permisosObject.compras.children.admin.permisos.print) {
                this.permisosObject.compras.children.admin.permisos.print = {
                    status: false,
                    name: 'Imprimir'
                };
                permisos.compras.children.admin.permisos.print = {
                    status: false,
                    name: 'Imprimir'
                };
            } else {
                permisos.compras.children.admin.permisos.print = {
                    status: this.permisosObject.compras.children.admin.permisos.print.status,
                    name: 'Imprimir'
                };
            }

            //Compras config children
            if (!this.permisosObject.compras.children.config.children) {
                this.permisosObject.compras.children.config.children = {};
                permisos.compras.children.config.children = {};
            }
            if (!this.permisosObject.compras.children.config.children.lim) {
                this.permisosObject.compras.children.config.children.lim = {
                    status: false,
                    name: 'Limitantes de compra',
                    permisos: {},
                };
                permisos.compras.children.config.children.lim = {
                    status: false,
                    name: 'Limitantes de compra',
                    permisos: {},
                };
            } else {
                permisos.compras.children.config.children.lim = {
                    status: this.permisosObject.compras.children.config.children.lim.status,
                    name: 'Limitantes de compra',
                    permisos: {},
                };
            }
            //----------------------------------------

            if (!this.permisosObject.compras.children.config.children.cat) {
                this.permisosObject.compras.children.config.children.cat = {
                    status: false,
                    name: 'Categorias de compra',
                    permisos: {},
                };
                permisos.compras.children.config.children.cat = {
                    status: false,
                    name: 'Categorias de compra',
                    permisos: {},
                };
            } else {
                permisos.compras.children.config.children.cat = {
                    status: this.permisosObject.compras.children.config.children.cat.status,
                    name: 'Categorias de compra',
                    permisos: {},
                };
            }
            //----------------------------------------

            if (!this.permisosObject.compras.children.config.children.admin) {
                this.permisosObject.compras.children.config.children.admin = {
                    status: false,
                    name: 'Administradores de compra',
                    permisos: {},
                };
                permisos.compras.children.config.children.admin = {
                    status: false,
                    name: 'Administradores de compra',
                    permisos: {},
                };
            } else {
                permisos.compras.children.config.children.admin = {
                    status: this.permisosObject.compras.children.config.children.admin.status,
                    name: 'Administradores de compra',
                    permisos: {},
                };
            }

            //Compras Config Limitantes Permisos
            if (!this.permisosObject.compras.children.config.children.lim.permisos.create) {
                this.permisosObject.compras.children.config.children.lim.permisos.create = {
                    status: false,
                    name: 'Crear',
                };
                permisos.compras.children.config.children.lim.permisos.create = {
                    status: false,
                    name: 'Crear',
                };
            } else {
                permisos.compras.children.config.children.lim.permisos.create = {
                    status: this.permisosObject.compras.children.config.children.lim.permisos.create.status,
                    name: 'Crear'
                };
            }
            //----------------------------------------
            if (!this.permisosObject.compras.children.config.children.lim.permisos.edit) {
                this.permisosObject.compras.children.config.children.lim.permisos.edit = {
                    status: false,
                    name: 'Editar',
                };
                permisos.compras.children.config.children.lim.permisos.edit = {
                    status: false,
                    name: 'Editar',
                };
            } else {
                permisos.compras.children.config.children.lim.permisos.edit = {
                    status: this.permisosObject.compras.children.config.children.lim.permisos.edit.status,
                    name: 'Editar'
                };
            }
            //----------------------------------------
            if (!this.permisosObject.compras.children.config.children.lim.permisos.delete) {
                this.permisosObject.compras.children.config.children.lim.permisos.delete = {
                    status: false,
                    name: 'Eliminar',
                };
                permisos.compras.children.config.children.lim.permisos.delete = {
                    status: false,
                    name: 'Eliminar',
                };
            } else {
                permisos.compras.children.config.children.lim.permisos.delete = {
                    status: this.permisosObject.compras.children.config.children.lim.permisos.delete.status,
                    name: 'Eliminar'
                };
            }
            //----------------------------------------

            //Compras Config category permisos
            if (!this.permisosObject.compras.children.config.children.cat.permisos.create) {
                this.permisosObject.compras.children.config.children.cat.permisos.create = {
                    status: false,
                    name: 'Crear',
                };
                permisos.compras.children.config.children.cat.permisos.create = {
                    status: false,
                    name: 'Crear',
                };
            } else {
                permisos.compras.children.config.children.cat.permisos.create = {
                    status: this.permisosObject.compras.children.config.children.cat.permisos.create.status,
                    name: 'Crear'
                };
            }
            //----------------------------------------
            if (!this.permisosObject.compras.children.config.children.cat.permisos.edit) {
                this.permisosObject.compras.children.config.children.cat.permisos.edit = {
                    status: false,
                    name: 'Editar',
                };
                permisos.compras.children.config.children.cat.permisos.edit = {
                    status: false,
                    name: 'Editar',
                };
            } else {
                permisos.compras.children.config.children.cat.permisos.edit = {
                    status: this.permisosObject.compras.children.config.children.cat.permisos.edit.status,
                    name: 'Editar'
                };
            }
            //----------------------------------------
            if (!this.permisosObject.compras.children.config.children.cat.permisos.delete) {
                this.permisosObject.compras.children.config.children.cat.permisos.delete = {
                    status: false,
                    name: 'Eliminar',
                };
                permisos.compras.children.config.children.cat.permisos.delete = {
                    status: false,
                    name: 'Eliminar',
                };
            } else {
                permisos.compras.children.config.children.cat.permisos.delete = {
                    status: this.permisosObject.compras.children.config.children.cat.permisos.delete.status,
                    name: 'Eliminar'
                };
            }
            //----------------------------------------

            //Compras Config admin permisos
            if (!this.permisosObject.compras.children.config.children.admin.permisos.create) {
                this.permisosObject.compras.children.config.children.admin.permisos.create = {
                    status: false,
                    name: 'Crear',
                };
                permisos.compras.children.config.children.admin.permisos.create = {
                    status: false,
                    name: 'Crear',
                };
            } else {
                permisos.compras.children.config.children.admin.permisos.create = {
                    status: this.permisosObject.compras.children.config.children.admin.permisos.create.status,
                    name: 'Crear'
                };
            }
            //----------------------------------------
            if (!this.permisosObject.compras.children.config.children.admin.permisos.delete) {
                this.permisosObject.compras.children.config.children.admin.permisos.delete = {
                    status: false,
                    name: 'Eliminar',
                };
                permisos.compras.children.config.children.admin.permisos.delete = {
                    status: false,
                    name: 'Eliminar',
                };
            } else {
                permisos.compras.children.config.children.admin.permisos.delete = {
                    status: this.permisosObject.compras.children.config.children.admin.permisos.delete.status,
                    name: 'Eliminar'
                };
            }
            /*----------------------------------------------------------------*/

            //Parent Providers
            if (!this.permisosObject.providers) {
                this.permisosObject.providers = {
                    status: false,
                    name: 'Proveedores',
                    children: {},
                };
                permisos.providers = {
                    status: false,
                    name: 'Proveedores',
                    children: {},
                };
            } else {
                permisos.providers = {
                    status: this.permisosObject.providers.status,
                    name: 'Proveedores',
                    children: {},
                }
            }

            //Providers children
            if (!this.permisosObject.providers.children) {
                this.permisosObject.providers.children = {};
                permisos.providers.children = {};
            }
            if (!this.permisosObject.providers.children.admin) {
                this.permisosObject.providers.children.admin = {
                    status: false,
                    name: 'Panel de administración',
                    permisos: {},
                };
                permisos.providers.children.admin = {
                    status: false,
                    name: 'Panel de administración',
                    permisos: {},
                };
            } else {
                permisos.providers.children.admin = {
                    status: this.permisosObject.providers.children.admin.status,
                    name: 'Panel de administración',
                    permisos: {},
                };
            }
            //----------------------------------------
            if (!this.permisosObject.providers.children.products) {
                this.permisosObject.providers.children.products = {
                    status: false,
                    name: 'Productos',
                    permisos: {},
                };
                permisos.providers.children.products = {
                    status: false,
                    name: 'Productos',
                    permisos: {},
                };
            } else {
                permisos.providers.children.products = {
                    status: this.permisosObject.providers.children.products.status,
                    name: 'Productos',
                    permisos: {},
                };
            }

            //Providers Admin Permissions
            if (!this.permisosObject.providers.children.admin.permisos) {
                this.permisosObject.providers.children.admin.permisos = {};
                permisos.providers.children.admin.permisos = {};
            }
            if (!this.permisosObject.providers.children.admin.permisos.create) {
                this.permisosObject.providers.children.admin.permisos.create = {
                    status: false,
                    name: 'Crear',
                };
                permisos.providers.children.admin.permisos.create = {
                    status: false,
                    name: 'Crear',
                };
            } else {
                permisos.providers.children.admin.permisos.create = {
                    status: this.permisosObject.providers.children.admin.permisos.create.status,
                    name: 'Crear',
                };
            }
            //----------------------------------------
            if (!this.permisosObject.providers.children.admin.permisos.edit) {
                this.permisosObject.providers.children.admin.permisos.edit = {
                    status: false,
                    name: 'Editar',
                };
                permisos.providers.children.admin.permisos.edit = {
                    status: false,
                    name: 'Editar',
                };
            } else {
                permisos.providers.children.admin.permisos.edit = {
                    status: this.permisosObject.providers.children.admin.permisos.edit.status,
                    name: 'Editar',
                };
            }

            //----------------------------------------
            if (!this.permisosObject.providers.children.admin.permisos.delete) {
                this.permisosObject.providers.children.admin.permisos.delete = {
                    status: false,
                    name: 'Eliminar',
                };
                permisos.providers.children.admin.permisos.delete = {
                    status: false,
                    name: 'Eliminar',
                };
            } else {
                permisos.providers.children.admin.permisos.delete = {
                    status: this.permisosObject.providers.children.admin.permisos.delete.status,
                    name: 'Eliminar',
                };
            }

            //Providers Products Permissions
            if (!this.permisosObject.providers.children.products.permisos) {
                this.permisosObject.providers.children.products.permisos = {};
                permisos.providers.children.products.permisos = {};
            }
            if (!this.permisosObject.providers.children.products.permisos.create) {
                this.permisosObject.providers.children.products.permisos.create = {
                    status: false,
                    name: 'Crear',
                };
                permisos.providers.children.products.permisos.create = {
                    status: false,
                    name: 'Crear',
                };
            } else {
                permisos.providers.children.products.permisos.create = {
                    status: this.permisosObject.providers.children.products.permisos.create.status,
                    name: 'Crear',
                };
            }
            //----------------------------------------
            if (!this.permisosObject.providers.children.products.permisos.edit) {
                this.permisosObject.providers.children.products.permisos.edit = {
                    status: false,
                    name: 'Editar',
                };
                permisos.providers.children.products.permisos.edit = {
                    status: false,
                    name: 'Editar',
                };
            } else {
                permisos.providers.children.products.permisos.edit = {
                    status: this.permisosObject.providers.children.products.permisos.edit.status,
                    name: 'Editar',
                };
            }

            //----------------------------------------
            if (!this.permisosObject.providers.children.products.permisos.delete) {
                this.permisosObject.providers.children.products.permisos.delete = {
                    status: false,
                    name: 'Eliminar',
                };
                permisos.providers.children.products.permisos.delete = {
                    status: false,
                    name: 'Eliminar',
                };
            } else {
                permisos.providers.children.products.permisos.delete = {
                    status: this.permisosObject.providers.children.products.permisos.delete.status,
                    name: 'Eliminar',
                };
            }
            /*----------------------------------------------------------------*/

            //Parent Analytics
            if (!this.permisosObject.analytics) {
                this.permisosObject.analytics = {
                    status: false,
                    name: 'Análisis Datos',
                    children: {},
                };
                permisos.analytics = {
                    status: false,
                    name: 'Análisis Datos',
                    children: {},
                };
            } else {
                permisos.analytics = {
                    status: this.permisosObject.analytics.status,
                    name: 'Análisis Datos',
                    children: {},
                }
            }

            //Analytics children
            if (!this.permisosObject.analytics.children) {
                this.permisosObject.analytics.children = {};
                permisos.analytics.children = {};
            }
            if (!this.permisosObject.analytics.children.reports) {
                this.permisosObject.analytics.children.reports = {
                    status: false,
                    name: 'Módulo Reportes',
                    permisos: {},
                };
                permisos.analytics.children.reports = {
                    status: false,
                    name: 'Módulo Reportes',
                    permisos: {},
                };
            } else {
                permisos.analytics.children.reports = {
                    status: this.permisosObject.analytics.children.reports.status,
                    name: 'Módulo Reportes',
                    permisos: {},
                };
            }
            //----------------------------------------

            //Analytics Reports Permissions
            if (!this.permisosObject.analytics.children.reports.permisos) {
                this.permisosObject.analytics.children.reports.permisos = {};
                permisos.analytics.children.reports.permisos = {};
            }
            if (!this.permisosObject.analytics.children.reports.permisos.html) {
                this.permisosObject.analytics.children.reports.permisos.html = {
                    status: false,
                    name: 'Formato HTML',
                };
                permisos.analytics.children.reports.permisos.html = {
                    status: false,
                    name: 'Formato HTML',
                };
            } else {
                permisos.analytics.children.reports.permisos.html = {
                    status: this.permisosObject.analytics.children.reports.permisos.html.status,
                    name: 'Formato HTML',
                };
            }
            //----------------------------------------
            if (!this.permisosObject.analytics.children.reports.permisos.pdf) {
                this.permisosObject.analytics.children.reports.permisos.pdf = {
                    status: false,
                    name: 'Formato PDF',
                };
                permisos.analytics.children.reports.permisos.pdf = {
                    status: false,
                    name: 'Formato PDF',
                };
            } else {
                permisos.analytics.children.reports.permisos.pdf = {
                    status: this.permisosObject.analytics.children.reports.permisos.pdf.status,
                    name: 'Formato PDF',
                };
            }
            //----------------------------------------
            if (!this.permisosObject.analytics.children.reports.permisos.excel) {
                this.permisosObject.analytics.children.reports.permisos.excel = {
                    status: false,
                    name: 'Formato Excel',
                };
                permisos.analytics.children.reports.permisos.excel = {
                    status: false,
                    name: 'Formato Excel',
                };
            } else {
                permisos.analytics.children.reports.permisos.excel = {
                    status: this.permisosObject.analytics.children.reports.permisos.excel.status,
                    name: 'Formato Excel',
                };
            }
            /*----------------------------------------------------------------*/

            this.permisosObject = permisos;
        },
    }
}
</script>
