<template>
<div class="container">
    <div class="row mb-5">
        <div class="col-12">
            <div class="dropdown">
                <div class="input-group d-none d-md-inline-flex">
                    <button v-if="proveedorId != ''" class="btn btn-danger" @click.prevent="delProveedor">
                        <i class="fas fa-trash-alt fa-lg"></i>
                    </button>
                    <span class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Proveedor</span>
                    <input v-if="!proveedorId" v-model="proveedor" type="text" data-isdropdown="true" :data-dropdown="'provDropdown' + $index" class="form-control" @focus="focusEvent('provDropdown' + $index)">
                    <input v-else v-model="proveedor" type="text" class="form-control" readonly>
                </div>
                <div class="d-block d-md-none">
                    <label class="input-group-text w3-light-gray fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Proveedor</label>
                    <input v-if="!proveedorId" v-model="proveedor" type="text" data-isdropdown="true" :data-dropdown="'provDropdown' + $index" class="form-control" @focus="focusEvent('provDropdown' + $index)">
                    <input v-else v-model="proveedor" type="text" class="form-control" readonly>
                    <button v-if="proveedorId != ''" class="btn btn-danger btn-block mt-2" @click.prevent="delProveedor">
                        <i class="fas fa-trash-alt fa-lg"></i>
                    </button>
                </div>
                <div :id="'provDropdown' + $index" class="dropdown-menu w-100 inputdropdown" :class="night ? 'dropdown-menu-dark' : 'border'">
                    <a v-if="load" class="dropdown-item text-center" href="#">
                        <div class="spinner-border text-secondary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </a>
                    <a v-else v-for="(item, index) in proveedores" :key="index" class="dropdown-item" href="#" @click="putProveedor(item)">{{ provName(item) }}</a>
                    <a v-if="proveedores.length < 11 && !load" class="dropdown-item btn btn-primary" @click.prevent="createProveedor">
                        <i class="fas fa-plus fa-lg"></i>
                        Crear nuevo proveedor
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <compras-presupuesto-producto v-for="(item, index) in productos" :key="index" :$proveedorId="proveedorId" :$putJsonProducto="putJsonProducto" :$deleteJsonProducto="deleteJsonProducto" :$index="index" :$name="'presupuesto' + $index" :$ids="productosId" :$item="item" :$itemId="item.producto"></compras-presupuesto-producto>
    </div>
    <div class="d-flex my-3">
        <button v-if="proveedorId" type="button" class="btn btn-outline-primary btn-rounded mx-auto" @click="putProducto">
            <i class="fas fa-plus fa-lg"></i>
            Agregar producto a la compra
        </button>
    </div>
    <div v-if="proveedorId" class="row">
        <hr>
        <div class="col align-self-center">
            <div class="input-group mb-3 d-none d-md-inline-flex">
                <span class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">*Tiempo de entrega:</span>
                <textarea v-model="time" class="form-control" rows="3" :class="!time ? 'is-invalid' : 'is-valid'"></textarea>
            </div>
            <div class="input-group mb-3 d-none d-md-inline-flex">
                <span class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Notas:</span>
                <textarea v-model="notes" class="form-control" rows="3"></textarea>
            </div>
            <div class="mb-3 d-block d-md-none">
                <label class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">*Tiempo de entrega:</label>
                <textarea v-model="time" class="form-control" rows="3" :class="!time ? 'is-invalid' : 'is-valid'"></textarea>
            </div>
            <div class="mb-3 d-block d-md-none">
                <label class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Notas:</label>
                <textarea v-model="notes" class="form-control" rows="3"></textarea>
            </div>
        </div>
        <div v-if="factura === 1" class="col-lg-3 col-md-4 col-12">
            <div class="input-group mb-3">
                <span class="input-group-text w-50" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Subtotal:</span>
                <input type="text" :value="'$ ' + subtotal" class="form-control text-end" readonly>
            </div>
            <div class="input-group mb-3">
                <span class="input-group-text w-50" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">IVA 16%:</span>
                <input type="text" :value="'$ ' + iva" class="form-control text-end" readonly>
            </div>
            <div class="input-group mb-3">
                <span class="input-group-text fw-bold w-50" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Total:</span>
                <input type="text" :value="'$ ' + total" class="form-control text-end" readonly>
            </div>
        </div>
        <div v-else class="col-lg-3 col-md-4 col-12">
            <div class="input-group mb-3">
                <span class="input-group-text w-50" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Total:</span>
                <input type="text" :value="'$ ' + subtotal" class="form-control text-end" readonly>
            </div>
        </div>
        <div class="container-fluid border border-2 py-2 mb-3">
            <input type="file" class="d-none" id="imgfile" accept="image/*" @change="putImage">
            <input type="file" class="d-none" id="pdffile" accept="application/pdf" @change="putPdf">
            <div class="d-flex align-items-center">
                <h6>Imagenes y/o archivos adjuntos</h6>
                <button class="btn btn-rounded btn-outline-primary btn-sm ms-auto" @click="addImg">
                    <i class="fas fa-plus fa-lg"></i>
                    <i class="far fa-image fa-lg"></i>
                </button>
                <button class="btn btn-rounded btn-outline-primary btn-sm ms-2" @click="addPdf">
                    <i class="fas fa-plus fa-lg"></i>
                    <i class="far fa-file-pdf fa-lg text-danger"></i>
                </button>
            </div>
            <div class="row">
                <div v-for="(item, index) in files" :key="index" class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-4 mb-2">
                    <div class="bg-image">
                        <img v-if="item.type == 'img'" :src="item.url" class="img-fluid" alt="Sample" />
                        <img v-if="item.type == 'pdf'" src="/image/pdf.png" class="img-fluid" alt="Sample">
                        <div class="mask">
                            <div class="d-flex justify-content-end align-items-start h-100 pt-2 pe-2">
                                <button type="button" class="btn btn-danger btn-floating btn-sm float-end" @click.prevent="delFile(index)">
                                    <i class="fas fa-times fa-lg"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <small>{{ item.name }}</small>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        $index: Number,
        $putJsonProveedores: Function,
        $putJsonNotas: Function,
        $putJsonTiempo: Function,
        $putJsonProductos: Function,
        $putJsonTotal: Function,
        $putJsonFiles: Function,
        $ids: Array,
    },
    created() {
        this.darkmode();
        this.debounceProveedores = _.debounce(this.getProveedores, 500);
    },
    watch: {
        proveedor() {
            this.load = true;
            this.loadData = false;
            this.debounceProveedores();
        },
        time() {
            this.$putJsonTiempo(this.time, this.$index);
        },
        notes() {
            this.$putJsonNotas(this.notes, this.$index);
        },
        total() {
            this.$putJsonTotal(this.total, this.$index);
        },
    },
    data() {
        return {
            night: null,
            proveedores: [],
            proveedor: '',
            proveedorId: '',
            factura: null,
            productos: [],
            notes: '',
            time: '',
            files: [],
            subtotal: 0,
            iva: 0,
            total: 0,
            loadData: false,
            load: true,
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        getProveedores() {
            console.log(this.$ids);
            $axios.get(this.$masterRoute + '/api/proveedores/create?search=' + this.proveedor + '&ids=' + JSON.stringify(this.$ids), {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.proveedores = response.data.data;
                this.loadData = true;
                this.load = false;
            }).catch(error => {
                console.error(error);
            });
        },
        createProveedor() {
            this.$router.push({
                name: 'createProveedorOrdCompras',
                params: {
                    p_reload: this.getProveedores,
                }
            })
        },
        addImg() {
            $('#imgfile').click();
        },
        addPdf() {
            $('#pdffile').click();
        },
        putProveedor(item) {
            if (item.comercialname == 'N/A') {
                this.proveedor = item.razonsocial;
            } else {
                this.proveedor = item.comercialname;
            }
            this.proveedorId = item.id;
            this.factura = item.factura;
            this.$putJsonProveedores(item.id, this.$index);
            this.putProducto();
        },
        putProducto() {
            if (this.productos.length == 0) {
                this.productos.push({
                    producto: 0,
                    cantidad: 1,
                    precio: 0,
                    iva: 0,
                });
            } else {
                if (this.validateProducto()) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Formulario invalido!',
                        text: 'Existe un producto sin seleccionar',
                    })
                } else {
                    this.productos.push({
                        producto: 0,
                        cantidad: 1,
                        precio: 0,
                        iva: 0,
                    });
                }
            }
        },
        putImage(e) {
            let fl = e.target.files[0];
            this.files.push({
                file: fl,
                name: fl.name,
                type: 'img',
                url: URL.createObjectURL(fl),
            });
            this.$putJsonFiles(this.files, this.$index);
        },
        putPdf(e) {
            let fl = e.target.files[0];
            this.files.push({
                file: fl,
                name: fl.name,
                type: 'pdf',
                url: URL.createObjectURL(fl),
            });
            this.$putJsonFiles(this.files, this.$index);
        },
        delProveedor() {
            this.proveedorId = '';
            this.productos = [];
            this.proveedor = '';
            this.notes = '';
            this.time = '';
            this.subtotal = 0;
            this.iva = 0;
            this.total = 0;
            this.$putJsonProveedores(0, this.$index);
            this.$putJsonProductos([], this.$index);
        },
        delFile(index) {
            this.files.splice(index, 1);
            this.$putJsonFiles(this.files, this.$index);
        },
        focusEvent(id) {
            $('.inputdropdown').hide();
            $('#' + id).show();
            this.getProveedores();
        },
        validateProducto() {
            let error = false;
            this.productos.forEach(element => {
                if (element.producto == 0 || !element.producto) {
                    error = true;
                }
            });
            return error;
        },
        putJsonProducto(id, cantidad, precio, iva, index) {
            this.productos[index].producto = id;
            this.productos[index].cantidad = cantidad;
            this.productos[index].precio = precio;
            this.productos[index].iva = iva;
            this.$putJsonProductos(this.productos, this.$index);
            this.sumatorias();
        },
        deleteJsonProducto(index) {
            this.productos.splice(index, 1);
            this.$putJsonProductos(this.productos, this.$index);
            this.sumatorias();
        },
        sumatorias() {
            let sub = 0;
            this.productos.forEach(element => {
                if (element.iva == 1) {
                    sub = sub + ((element.precio / 1.16) * element.cantidad);
                } else {
                    sub = sub + (element.precio * element.cantidad);
                }
            });
            this.subtotal = Math.round(sub * 100) / 100;
            this.iva = Math.round(sub * 0.16 * 100) / 100;
            this.total = Math.round(sub * 1.16 * 100) / 100;
        },
        provName(item) {
            if (item.comercialname == 'N/A') {
                return item.razonsocial;
            } else {
                return item.comercialname;
            }
        },
    },
    computed: {
        productosId() {
            let datos = [];
            this.productos.forEach(element => {
                datos.push(element.producto);
            });
            return datos;
        }
    },
}
</script>
