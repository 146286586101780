<template>
<div class="w3-modal d-block">
    <div class="w3-modal-content w3-animate-zoom card border mt-3">
        <header class="card-header">
            <button @click.prevent="cancel" class="btn btn-danger btn-sm px-2 float-end">
                <i class="fas fa-times"></i>
            </button>
            <div class="d-xl-flex d-lg-flex d-md-flex d-none align-items-center w3-large">
                <i class="fas fa-hand-pointer fa-lg text-white bg-success p-2 me-auto card"></i>
                <strong class="me-auto">Asignando estructura a organización</strong>
            </div>
            <div class="d-xl-none d-lg-none d-md-none d-flex align-items-center w3-medium">
                <i class="fas fa-hand-pointer fa-lg text-white bg-success p-2 me-auto card"></i>
                <strong class="me-auto">Asignando estructura a organización</strong>
            </div>
            <div class="d-flex align-items-center mt-3">
                <strong>({{ _elem.clave }}):{{ _elem.name }}</strong>
                <i class="fas fa-arrow-circle-right mx-2"></i>
                <span v-if="selectOrg.name">{{ selectOrg.name }}</span>
            </div>
        </header>
        <div class="card-body text-center">
            <h5 class="text-center">Selecciona la jerarquia de organización</h5>
            <div v-if="orgload" class="spinner-border text-secondary" style="width: 3rem; height: 3rem;" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <org-treeData v-else class="w3-light-gray pt-2" v-for="(item, index) in organizacionesTree" :key="index" :$node="item" :$elemSelect="selectOrg" :$putElem="putOrg"></org-treeData>
        </div>
        <footer class="card-footer">
            <div v-if="load">
                <button class="btn btn-secondary btn-rounded btn-block" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Cargando...
                </button>
            </div>
            <div v-else class="row px-0">
                <div class="col-6">
                    <button class="btn btn-danger btn-rounded btn-sm btn-block" @click.prevent="cancel">
                        <i class="fas fa-ban fa-lg"></i>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="col-6">
                    <button class="btn btn-success btn-rounded btn-sm btn-block" :disabled="!selectOrg.name || !selectOrg.parentId" @click.prevent="saveData">
                        <i class="fas fa-save fa-lg"></i>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </footer>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        _elem: Object,
    },
    created() {
        this.getorganizaciones();
        this.generateIdDataActivos(this._elem);
    },
    watch: {
        selectOrg() {
            this.generateIdDataOrg(this.selectOrg);
        }
    },
    data() {
        return {
            selectOrg: {},
            organizacionesTree: {},
            orgId: '',
            idObjectActivos: [],
            idObjectOrg: [],
            load: false,
            orgload: true,
        }
    },
    methods: {
        saveData() {
            this.load = true;
            $axios.post(this.$masterRoute + '/api/organizaciones/addActivos', {
                'activosIds': JSON.stringify(this.idObjectActivos),
                'orgIds': JSON.stringify(this.idObjectOrg),
            }, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                if (response.data.status) {
                    $swal.fire(
                        'Acción exitosa!',
                        response.data.menssage,
                        'success'
                    )
                    this.cancel();
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: response.data.title,
                        text: response.data.menssage,
                    });
                    this.load = false;
                }
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });
        },
        getorganizaciones() {
            $axios.get(this.$masterRoute + '/api/organizaciones', {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.organizacionesTree = this.generateTree(response.data);
                this.orgload = false;
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
            });
        },
        cancel() {
            this.$router.go(-1);
        },
        putOrg(elem) {
            this.selectOrg = elem;
            this.orgId = elem.id;
            if (!elem.parentId) {
                new Noty({
                    type: 'warning',
                    text: 'La organización seleccionada es de alto mando, no se permite la asignacion de activos',
                    timeout: 3500,
                    progressBar: true,
                }).show();
            }
        },
        generateTree(data) {
            let map = {},
                node,
                roots = [],
                i;
            for (i = 0; i < data.length; i++) {
                map[data[i].id] = i;
                data[i].children = [];
            }
            for (i = 0; i < data.length; i++) {
                node = data[i];
                if (node.parentId) {
                    if (node.parentId) {
                        data[map[node.parentId]].children.push(node);
                    }
                } else {
                    roots.push(node);
                }
            }
            this.load = false;
            return roots;
        },
        generateIdDataActivos(item) {
            this.idObjectActivos.push(item.id);
            if (item.children.length) {
                item.children.forEach(node => {
                    this.generateIdDataActivos(node);
                });
            }
        },
        generateIdDataOrg(item) {
            this.idObjectOrg.push(item.id);
            if (item.children.length) {
                item.children.forEach(node => {
                    this.generateIdDataOrg(node);
                });
            }
        },
    }
}
</script>
