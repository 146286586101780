<template>
<div class="w3-modal d-block">
    <div v-if="startload" class="w3-modal-content w3-animate-zoom card border mb-3 mt-lg-auto mt-3">
        <header class="card-header" :class="night ? 'w3-dark-gray' : ''">
            <h5 class="text-center">Acceso de nuevo dispositivo</h5>
        </header>
        <div class="card-body" :class="night ? 'bg-dark-gray' : ''">
            <div class="alert alert-danger" role="alert">
                <i class="fas fa-exclamation-circle fa-lg me-2"></i>
                <strong>Este dispositivo no esta autorizado para ingresar al sistema</strong>
            </div>
            <div class="text-center mb-2">
                <div v-if="load" class="spinner-border text-secondary" style="width: 3rem; height: 3rem;" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <h4 v-else>{{ device }}</h4>
            </div>
            <div class="mb-3">
                <strong class="ms-2">Ingresa clave de acceso</strong>
                <input type="password" v-model="password" class="form-control form-control-lg" placeholder="clave de acceso">
            </div>
        </div>
        <footer class="card-footer d-flex align-items-center justify-content-center" :class="night ? 'w3-dark-gray' : ''">
            <button v-if="loadbtn" class="btn btn-secondary btn-rounded w-50" type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Cargando...
            </button>
            <button v-else type="button" class="btn btn-success btn-rounded w-50" :disabled="password.length < 8" @click.prevent="sendPass">
                <i class="fas fa-paper-plane fa-lg"></i>
                <span>Enviar</span>
            </button>
        </footer>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    created() {
        if (!localStorage.nightMode) {
            localStorage.nightMode = false;
        } else {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        }
        if (localStorage.deviceToken) {
            this.authDevice();
        } else if (localStorage.deviceName) {
            this.device = localStorage.deviceName;
            this.startload = true;
            this.load = false;
        } else {
            this.startload = true;
            this.notKeyDevice();
        }
    },
    data() {
        return {
            night: null,
            device: '',
            password: '',
            load: true,
            loadbtn: false,
            startload: false,
        }
    },
    methods: {
        authDevice() {
            $axios.post(this.$masterRoute + '/api/device/auth', {
                token: localStorage.deviceToken,
            }).then(response => {
                if (response.data.status) {
                    console.log(response.data);
                    this.$router.push({
                        name: 'login'
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error!!',
                        text: response.data.menssage,
                    });
                    this.startload = true;
                    this.notKeyDevice();
                }
            }).catch(error => {
                console.error(error.response);
                $swal.fire({
                    icon: 'error',
                    title: 'Error!!',
                    text: 'System Error!!',
                });
            });
        },
        sendPass() {
            this.loadbtn = true;
            $axios.post(this.$masterRoute + '/api/device/update', {
                pass: this.password,
                device: this.device,
            }).then(response => {
                if (response.data.status) {
                    localStorage.removeItem('deviceName');
                    localStorage.deviceToken = response.data.token;
                    this.$router.push({
                        name: 'login'
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error!!',
                        text: response.data.menssage,
                    });
                    this.loadbtn = false;
                }
            }).catch(error => {
                console.error(error.response);
                $swal.fire({
                    icon: 'error',
                    title: 'Error!!',
                    text: 'System Error!!',
                });
                this.loadbtn = false;
            });
        },
        notKeyDevice() {
            let formData = new FormData();
            formData.append('useragent', navigator.userAgent);
            $axios.post(this.$masterRoute + '/api/device', formData).then(response => {
                this.device = response.data.device;
                localStorage.deviceName = this.device;
                this.load = false;
            }).catch(error => {
                console.error(error.response);
                $swal.fire({
                    icon: 'error',
                    title: 'Error!!',
                    text: 'System Error!!',
                });
                this.load = false;
            });
        },
    },
}
</script>
