<template>
<div>
    <div class="card" :class="night ? 'bg-dark-gray' : ''">
        <header class="card-header d-flex align-items-center" :class="night ? 'w3-dark-gray' : ''">
            <button class="btn btn-danger btn-sm" @click="returnBack">
                <i class="fas fa-arrow-left fa-lg"></i>
                <span class="d-none d-md-inline">Regresar</span>
            </button>
            <div class="mx-auto">
                <h4 class="card-title text-center d-none d-md-block">Productos</h4>
                <h5 class="card-title text-center d-block d-md-none">Productos</h5>
                <h5 v-if="proveedor.comercialname == 'N/A'" class="card-title text-center d-none d-md-block">({{ proveedor.razonsocial }})</h5>
                <h5 v-else class="card-title text-center d-none d-md-block">({{ proveedor.comercialname }})</h5>
                <h6 v-if="proveedor.comercialname == 'N/A'" class="card-title text-center d-block d-md-none">({{ proveedor.razonsocial }})</h6>
                <h6 v-else class="card-title text-center d-block d-md-none">({{ proveedor.comercialname }})</h6>
            </div>
        </header>
        <div class="card-body">
            <div class="row mb-3">
                <div class="col">
                    <button v-if="permisos.create.status" class="btn btn-primary btn-rounded btn-sm d-none d-md-block" @click="create">
                        <i class="fas fa-plus fa-lg me-2"></i>
                        <span class="d-md-inline d-none">Nuevo producto</span>
                    </button>
                    <button v-if="permisos.create.status" class="btn btn-primary btn-floating d-block d-md-none" @click="create">
                        <i class="fas fa-plus fa-lg"></i>
                    </button>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-8 d-inline d-md-none">
                    <div class="input-group input-group-sm rounded ms-auto">
                        <input type="search" v-model="search" class="form-control rounded" placeholder="Buscar" aria-label="Search" aria-describedby="search-addon" />
                        <span class="input-group-text border-0" id="search-addon">
                            <i class="fas fa-search" :class="night ? 'text-white' : ''"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="d-xl-flex d-lg-flex d-md-flex align-items-center mb-3">
                <div class="input-group input-group-sm rounded me-2 d-none d-md-inline-flex" style="width: 175px;">
                    <span class="input-group-text border-0" :class="night ? 'text-white' : ''">Filas por página: </span>
                    <select v-model="itemperpage" class="form-select form-select-sm rounded">
                        <option value="10" selected>10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </select>
                </div>
                <div class="me-auto ms-2 mb-xl-0 mb-lg-0 mb-2 d-none d-md-inline">
                    <small>Mostrando {{ pagination.from }}-{{ pagination.to }} de {{ pagination.total }}</small>
                </div>
                <div class="ms-auto mb-xl-0 mb-lg-0 mb-2">
                    <nav class="d-flex">
                        <ul class="pagination pagination-circle pagination-sm mb-0 ms-auto me-auto">
                            <li class="page-item" v-if="pagination.current_page > 1" @click.prevent="changePageActivosData(pagination.current_page - 1)">
                                <a class="page-link" :class="night ? 'w3-text-white w3-hover-white' : ''" href="#" tabindex="-1" aria-disabled="true">Anterior</a>
                            </li>
                            <li class="page-item" style="z-index: 1;" v-for="(page, index) in pagesNumberActivosData" :key="index" :class="[page == isActivedActivosData ? 'active' : '']" @click.prevent="changePageActivosData(page)">
                                <a class="page-link" :class="night && page != isActivedActivosData ? 'w3-text-white w3-hover-white' : ''" href="#">{{ page }}</a>
                            </li>
                            <li class="page-item" v-if="pagination.current_page < pagination.last_page" @click.prevent="changePageActivosData(pagination.current_page + 1)">
                                <a class="page-link" :class="night ? 'w3-text-white w3-hover-white' : ''" href="#">Siguiente</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div class="table-responsive overflow-auto d-none d-md-block" :style="'height:' + heightsize + 'px;'">
                <table class="table table-hover table-sm align-middle" :class="night ? 'table-dark' : 'table-light'" :style="'width:' + widthsize + 'px;'">
                    <thead style="position: sticky;top: 0;" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">
                        <tr class="text-center" :class="night ? 'border-dark' : 'border'" style="position: sticky;top: 0;">
                            <th class="fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'" style="position: sticky;left: 0;width: 10px;z-index: 1;">
                                <label class="mb-1">Opciones</label>
                                <button v-if="filters" class="btn btn-outline-black btn-rounded btn-sm" :class="night ? 'w3-white' : ''" @click="cleanFilters">
                                    <i class="fas fa-eraser fa-lg"></i>
                                </button>
                            </th>
                            <th class="fw-bold" style="position: sticky;top: 0;">
                                <label class="mb-1">Nombre</label>
                                <input v-model="name" type="search" class="form-control form-control-sm" placeholder="Buscar">
                            </th>
                            <th class="fw-bold" style="position: sticky;top: 0;">
                                <label class="mb-1">Precio</label>
                                <input v-model="precio" type="search" class="form-control form-control-sm" placeholder="Buscar">
                            </th>
                            <th class="fw-bold" style="position: sticky;top: 0;">
                                <label class="mb-1">IVA</label>
                                <select v-model="iva" class="form-select form-select-sm">
                                    <option value="">Todos</option>
                                    <option value="1">Incluido</option>
                                    <option value="0">No incluido</option>
                                </select>
                            </th>
                            <th class="fw-bold" style="position: sticky;top: 0;">
                                <label class="mb-1">Unidad de medida</label>
                                <select v-model="unidad" class="form-select form-select-sm">
                                    <option value="">Todas</option>
                                    <option value="Metros">Metros</option>
                                    <option value="Kilogramos">Kilogramos</option>
                                    <option value="Litros">Litros</option>
                                    <option value="Galones">Galones</option>
                                    <option value="Pares">Pares</option>
                                    <option value="Piezas">Piezas</option>
                                </select>
                            </th>
                            <th class="fw-bold" style="position: sticky;top: 0;">
                                <label class="mb-1">Descripción</label>
                                <input v-model="descripcion" type="search" class="form-control form-control-sm" placeholder="Buscar">
                            </th>
                            <th class="fw-bold" style="position: sticky;top: 0;">
                                <label class="mb-1">Creación</label>
                                <input v-model="creacion" type="Date" class="form-control form-control-sm" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" placeholder="Buscar">
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="load">
                            <td colspan="8" class="text-center">
                                <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </td>
                        </tr>
                        <tr v-else v-for="(item, index) in products" :key="index" class="text-center">
                            <td :class="night ? 'w3-dark-gray' : 'w3-light-gray'" style="position:sticky;left:0px;z-index: 2;">
                                <div class="btn-group btn-group-sm" role="group">
                                    <button v-if="permisos.edit.status" type="button" class="btn btn-warning" @click="edit(item)">
                                        <i class="fas fa-pencil-alt fa-lg"></i>
                                    </button>
                                    <button v-if="permisos.delete.status" type="button" class="btn btn-danger" @click="destroy(item)">
                                        <i class="fas fa-trash-alt fa-lg"></i>
                                    </button>
                                </div>
                            </td>
                            <td>{{ item.name }}</td>
                            <td>
                                <div class="d-flex justify-content-center align-items-center">
                                    <span class="me-2">$ {{ (item.precio * 1).toFixed(2) }}</span>
                                    <div class="dropdown">
                                        <button class="btn btn-info btn-floating btn-sm me-2" data-mdb-toggle="dropdown">
                                            <i class="fas fa-history fa-lg"></i>
                                        </button>
                                        <ul class="dropdown-menu" :class="night ? 'dropdown-menu-dark' : 'border'" aria-labelledby="dropdownMenuButton">
                                            <li v-for="(elem, index) in item.history" :key="index">
                                                <a class="dropdown-item" href="#">{{ '$ ' + (elem.precio * 1).toFixed(2) + ' > ' + getMomentDate(elem.created_at) }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                            <td>{{ isInclude(item.IVA) }}</td>
                            <td>{{ item.unidadmedida }}</td>
                            <td>{{ item.descripcion }}</td>
                            <td>{{ getMomentDate(item.created_at) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ul class="list-group overflow-auto d-block d-md-none border-top border-5" :style="'height:' + heightsize + 'px;'">
                <li v-if="load" class="list-group-item text-center" :class="night ? 'w3-dark-gray' : 'w3-white'">
                    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </li>
                <li v-else v-for="(item, index) in products" :key="'sm' + index" class="list-group-item" :class="night ? 'w3-dark-gray' : 'w3-white'">
                    <div class="row mb-3">
                        <div class="col-6">
                            <strong>#</strong>
                            <small>{{ index + 1 }}</small>
                        </div>
                        <div class="col-6 text-end">
                            <div class="btn-group btn-group-sm" role="group">
                                <button type="button" class="btn btn-warning" @click="edit(item)">
                                    <i class="fas fa-pencil-alt fa-lg"></i>
                                </button>
                                <button type="button" class="btn btn-danger" @click="destroy(item)">
                                    <i class="fas fa-trash-alt fa-lg"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <small class="fw-bold me-2">Nombre:</small>
                        <small>{{ item.name }}</small>
                    </div>
                    <div class="d-flex align-items-center">
                        <small class="fw-bold me-2">Precio:</small>
                        <small>$ {{ (item.precio * 1).toFixed(2) }}</small>
                        <div class="dropdown ms-auto">
                            <button class="btn btn-info btn-floating btn-sm me-2" data-mdb-toggle="dropdown">
                                <i class="fas fa-history fa-lg"></i>
                            </button>
                            <ul class="dropdown-menu" :class="night ? 'dropdown-menu-dark' : 'border'" aria-labelledby="dropdownMenuButton">
                                <li v-for="(elem, index) in item.history" :key="index">
                                    <a class="dropdown-item" href="#">
                                        <span>{{ '$ ' + (elem.precio * 1).toFixed(2) }}</span>
                                        <br>
                                        <span>{{ getMomentDate(elem.created_at) }}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <small class="fw-bold me-2">IVA:</small>
                        <small>{{ isInclude(item.IVA) }}</small>
                    </div>
                    <div class="d-flex align-items-center">
                        <small class="fw-bold me-2">Unidad medida:</small>
                        <small>{{ item.unidadmedida }}</small>
                    </div>
                    <div class="d-flex align-items-center">
                        <small class="fw-bold me-2">Descripción:</small>
                        <small>{{ item.descripcion }}</small>
                    </div>
                    <div class="d-flex align-items-center">
                        <small class="fw-bold me-2">Creación:</small>
                        <small>{{ getMomentDate(item.created_at) }}</small>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <router-view></router-view>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
import moment from 'moment'
export default {
    props: {
        p_prov: Object,
        p_permisos: Object,
    },
    created() {
        this.darkmode();
        this.proveedor = this.p_prov;
        this.permisos = this.p_permisos;
        this.changeSize();
        this.getProducts();
        moment.locale('es-mx');
        this.debounceGetProducts = _.debounce(this.getProducts, 500);
    },
    watch: {
        search() {
            this.debounceGetProducts();
        },
        name() {
            this.debounceGetProducts();
        },
        precio() {
            this.debounceGetProducts();
        },
        iva() {
            this.getProducts();
        },
        unidad() {
            this.getProducts();
        },
        descripcion() {
            this.debounceGetProducts();
        },
        creacion() {
            this.debounceGetProducts();
        },
        itemperpage() {
            this.getProducts();
        }
    },
    data() {
        return {
            night: null,
            proveedor: {},
            products: [],
            pagination: {},
            permisos: {},
            search: '',
            name: '',
            precio: '',
            iva: '',
            unidad: '',
            descripcion: '',
            creacion: '',
            itemperpage: 10,
            offset: 6,
            widthsize: null,
            heightsize: null,
            load: false,
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
        window.addEventListener("resize", this.changeSize);
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        getProducts(page) {
            this.load = true;
            let searchData = {
                search: this.search,
                name: this.name,
                precio: this.precio,
                iva: this.iva,
                unidad: this.unidad,
                descripcion: this.descripcion,
                creacion: this.creacion,
                itemperpage: this.itemperpage,
            };
            $axios.get(this.$masterRoute + '/api/productos?page=' + page + '&provId=' + this.proveedor.id + '&searchdata=' + JSON.stringify(searchData), {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.products = response.data.products.data;
                this.pagination = response.data.pagination;
                this.load = false;
            }).catch(error => {
                console.error(error);
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                this.load = false;
            });
        },
        create() {
            this.$router.push({
                name: 'createProducto',
                params: {
                    p_proveedorId: this.proveedor.id,
                    p_reload: this.getProducts,
                }
            });
        },
        edit(item) {
            this.$router.push({
                name: 'editProducto',
                params: {
                    p_proveedorId: this.proveedor.id,
                    p_item: item,
                    p_reload: this.getProducts,
                }
            })
        },
        destroy(item) {
            $swal.fire({
                title: '¿Estás seguro?',
                text: "Estás a punto de eliminar un producto!!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#03cc9a',
                cancelButtonColor: '#ED1848',
                confirmButtonText: 'Si, eliminarlo!',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.load = true;
                    $axios.delete(this.$masterRoute + '/api/productos/' + item.id, {
                        headers: {
                            Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                        }
                    }).then(response => {
                        if (response.data.status) {
                            $swal.fire({
                                icon: 'success',
                                title: response.data.title,
                                text: response.data.menssage,
                            });
                            this.getProducts();
                        } else {
                            $swal.fire({
                                icon: 'error',
                                title: response.data.title,
                                text: response.data.menssage,
                            });
                        }
                        this.load = false;
                    }).catch(error => {
                        console.error(error);
                        if (error.response.status === 401) {
                            $swal.fire({
                                icon: 'error',
                                title: 'Sesión expirada',
                                text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                            }).then((result) => {
                                this.$router.push({
                                    name: 'login'
                                });
                            });
                        } else {
                            $swal.fire({
                                icon: 'error',
                                title: 'Error Inesperado!!',
                                text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                            });
                        }
                        this.load = false;
                    });
                }
            });
        },
        cleanFilters() {
            this.name = '';
            this.precio = '';
            this.iva = '';
            this.unidad = '';
            this.descripcion = '';
            this.creacion = '';
        },
        isInclude(item) {
            if (item === 1) {
                return 'Incluido';
            } else {
                return 'No incluido';
            }
        },
        getMomentDate(date) {
            return moment(date).format('LLLL');
        },
        changeSize() {
            if (window.innerWidth > 1600) {
                this.widthsize = window.innerWidth;
            } else {
                this.widthsize = 1600;
            }
            if (window.innerWidth < 576) {
                this.heightsize = window.innerHeight - 290;
            } else {
                this.heightsize = window.innerHeight - 310;
            }
        },
        returnBack() {
            this.$router.go(-1);
        },
        changePageActivosData(page) {
            this.load = true;
            this.pagination.current_page = page;
            this.getProducts(page);
        },
    },
    computed: {
        isActivedActivosData: function () {
            return this.pagination.current_page;
        },
        pagesNumberActivosData: function () {
            if (!this.pagination.to) {
                return [];
            }

            var from = this.pagination.current_page - (this.offset / 2);
            if (from < 1) {
                from = 1;
            }

            var to = from + (this.offset);
            if (to >= this.pagination.last_page) {
                to = this.pagination.last_page;
            }

            var pagesArray = [];
            while (from <= to) {
                pagesArray.push(from);
                from++;
            }
            return pagesArray;
        },
        filters() {
            if (!this.name && !this.precio && !this.iva && !this.unidad && !this.descripcion && !this.creacion) {
                return false;
            }
            return true;
        },
    }
}
</script>
