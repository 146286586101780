<template>
<div class="w3-modal d-block">
    <div class="w3-modal-content w3-animate-zoom card border mt-3">
        <header class="card-header">
            <button @click.prevent="cancel" class="btn btn-danger btn-sm px-2 float-end">
                <i class="fas fa-times"></i>
            </button>
            <div class="d-xl-flex d-lg-flex d-md-flex d-none align-items-center w3-large">
                <i class="fas fa-user-circle fa-2x text-success me-2"></i>
                <strong class="me-2">Perfil usuario</strong>
            </div>
            <div class="d-xl-none d-lg-none d-md-none d-flex align-items-center w3-medium">
                <i class="fas fa-user-circle fa-2x text-success me-2"></i>
                <strong class="me-2">Perfil usuario</strong>
            </div>
        </header>
        <div class="card-body">
            <div class="row g-0">
                <div class="col-xl-3 col-lg-3 col-md-3 col-8 offset-2 offset-md-0 offset-lg-0 offset-xl-0">
                    <img src="https://exelord.com/ember-initials/images/default-d5f51047d8bd6327ec4a74361a7aae7f.jpg" alt="img perfil" class="rounded-circle img-fluid" />
                </div>
                <div class="col-md-9">
                    <div class="card-body">
                        <h4 class="card-title">{{ _user.user }}</h4>
                        <p class="card-text mb-1">
                            <strong>Nombre: </strong>
                            <span>{{ _user.name }}</span>
                        </p>
                        <p class="card-text mb-1">
                            <strong>Rol: </strong>
                            <span>{{ _user.role }}</span>
                        </p>
                        <p class="card-text mb-1">
                            <strong>Descripcion: </strong>
                            <span>{{ _user.descripcion }}</span>
                        </p>
                        <p v-for="(item, index) in userExtradata" :key="index" class="card-text mb-1">
                            <strong>{{ item.titulo }}: </strong>
                            <span>{{ item.texto }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <footer class="card-footer">

        </footer>
    </div>
</div>
</template>

<script>
export default {
    props: {
        _user: Object,
    },
    created() {
        this.userExtradata = JSON.parse(this._user.extradata);
    },
    data() {
        return {
            userExtradata: [],
        }
    },
    methods: {
        cancel() {
            this.$router.go(-1);
        }
    },
}
</script>
