<template>
<div>
    <div class="card" :class="night ? 'bg-dark-gray' : ''">
        <div class="card-body">
            <div class="row mb-3">
                <div class="col">
                    <button class="btn btn-primary btn-rounded btn-sm d-none d-md-block" @click="create">
                        <i class="fas fa-plus fa-lg me-2"></i>
                        <span class="d-md-inline d-none">Nueva orden de compra</span>
                    </button>
                    <button class="btn btn-primary btn-floating d-block d-md-none" @click="create">
                        <i class="fas fa-plus fa-lg"></i>
                    </button>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-8 d-inline d-md-none">
                    <div class="input-group input-group-sm rounded ms-auto">
                        <input type="search" v-model="search" class="form-control rounded" placeholder="Buscar" aria-label="Search" aria-describedby="search-addon" />
                        <span class="input-group-text border-0" id="search-addon">
                            <i class="fas fa-search" :class="night ? 'text-white' : ''"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="d-xl-flex d-lg-flex d-md-flex align-items-center mb-3">
                <div class="input-group input-group-sm rounded me-2 d-none d-md-inline-flex" style="width: 175px;">
                    <span class="input-group-text border-0" :class="night ? 'text-white' : ''">Filas por página: </span>
                    <select v-model="itemperpage" class="form-select form-select-sm rounded">
                        <option value="10" selected>10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </select>
                </div>
                <div class="ms-auto mb-xl-0 mb-lg-0 mb-2 d-none d-md-inline-flex">
                    <div class="btn-group dropstart" data-bs-toggle="tooltip" data-bs-placement="top" title="Configuraciones tabla de contenido">
                        <button type="button" class="btn btn-info btn-sm dropdown-toggle" data-mdb-toggle="dropdown" aria-expanded="false">
                            <i class="fas fa-table fa-lg"></i>
                            <i class="fas fa-cogs fa-lg"></i>
                        </button>
                        <ul class="dropdown-menu" :class="{ 'dropdown-menu-dark' : night }">
                            <li>
                                <div class="dropdown-item">
                                    <div class="form-check">
                                        <input v-model="tableFormat.folio" @change="putStorage" class="form-check-input" type="checkbox" />
                                        <label class="form-check-label">
                                            Folio
                                        </label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="dropdown-item">
                                    <div class="form-check">
                                        <input v-model="tableFormat.estado" @change="putStorage" class="form-check-input" type="checkbox" />
                                        <label class="form-check-label">
                                            Estado
                                        </label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="dropdown-item">
                                    <div class="form-check">
                                        <input v-model="tableFormat.maxMonto" @change="putStorage" class="form-check-input" type="checkbox" />
                                        <label class="form-check-label">
                                            Monto mayor
                                        </label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="dropdown-item">
                                    <div class="form-check">
                                        <input v-model="tableFormat.motivo" @change="putStorage" class="form-check-input" type="checkbox" />
                                        <label class="form-check-label">
                                            Motivo
                                        </label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="dropdown-item">
                                    <div class="form-check">
                                        <input v-model="tableFormat.remitente" @change="putStorage" class="form-check-input" type="checkbox" />
                                        <label class="form-check-label">
                                            Remitente
                                        </label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="dropdown-item">
                                    <div class="form-check">
                                        <input v-model="tableFormat.categoria" @change="putStorage" class="form-check-input" type="checkbox" />
                                        <label class="form-check-label">
                                            Categoría
                                        </label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="dropdown-item">
                                    <div class="form-check">
                                        <input v-model="tableFormat.creacion" @change="putStorage" class="form-check-input" type="checkbox" />
                                        <label class="form-check-label">
                                            Creación
                                        </label>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="table-responsive overflow-auto d-none d-md-block" :style="'height:' + screenheightsize + 'px;'">
                <table class="table table-hover table-sm align-middle" :class="night ? 'table-dark' : 'table-light'">
                    <thead style="position: sticky;top: 0;z-index: 1;" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">
                        <tr class="text-center" style="position: sticky;top: 0;" :class="night ? 'border-dark' : 'border'">
                            <th class="fw-bold px-1" style="position: sticky;top: 0;width: 10px;z-index: 2px;">
                                <button class="btn btn-outline-black btn-rounded btn-sm" :class="[night ? 'w3-white' : '', filters ? 'visible' : 'invisible']" @click="cleanFilters">
                                    <i class="fas fa-eraser fa-lg"></i>
                                </button>
                            </th>
                            <th v-if="tableFormat.folio" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Folio</label>
                                <input v-model="folio" type="search" class="form-control form-control-sm" placeholder="Buscar">
                            </th>
                            <th v-if="tableFormat.estado" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Estado</label>
                                <select v-model="estado" class="form-select form-select-sm">
                                    <option value="" selected>Todas</option>
                                    <option value="Esperando Autorización">Esperando Autorización</option>
                                    <option value="En Revisión">En Revisión</option>
                                    <option value="Aceptada">Aceptadas</option>
                                    <option value="Rechazada">Rechazadas</option>
                                </select>
                            </th>
                            <th v-if="tableFormat.maxMonto" class="fw-bold px-1 position-sticky top-0">
                                <label class="mb-1">Monto mayor</label>
                                <input type="search" class="form-control form-control-sm invisible" placeholder="Buscar">
                            </th>
                            <th v-if="tableFormat.motivo" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Motivo</label>
                                <input v-model="motivo" type="search" class="form-control form-control-sm" placeholder="Buscar">
                            </th>
                            <th v-if="tableFormat.remitente" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Remitente</label>
                                <select v-model="org" class="form-select form-select-sm">
                                    <option value="" selected>Todos</option>
                                    <option v-for="(item, index) in organizaciones" :key="index" :value="item.id">{{ item.name }}</option>
                                </select>
                            </th>
                            <th v-if="tableFormat.categoria" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Categoría</label>
                                <input v-model="category" type="search" class="form-control form-control-sm" placeholder="Buscar">
                            </th>
                            <th v-if="tableFormat.creacion" class="fw-bold px-1" style="position: sticky;top: 0;">
                                <label class="mb-1">Creación</label>
                                <input v-model="creacion" type="Date" class="form-control form-control-sm" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" placeholder="Buscar">
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="load">
                            <td :colspan="Object.keys(tableFormat).length + 1" class="text-center">
                                <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </td>
                        </tr>
                        <tr v-else v-for="(item, index) in ordCompras" :key="index" @click="showPresupuesto(item)" class="text-center">
                            <td width="10"></td>
                            <td v-if="tableFormat.folio" width="75">{{ item.folio }}</td>
                            <td v-if="tableFormat.estado" width="125">
                                <span class="badge rounded-pill w-100" :class="{
                                'bg-success': item.status == 'Aceptada', 
                                'bg-danger' : item.status == 'Rechazada',
                                'bg-warning' : item.status == 'En Revisión',
                                'bg-secondary' : item.status == 'Esperando Autorización',
                                }">
                                    {{ item.status }}
                                </span>
                            </td>
                            <td v-if="tableFormat.maxMonto">$ {{ item.maxtotal.toFixed(2) }}</td>
                            <td v-if="tableFormat.motivo">{{ item.motivo }}</td>
                            <td v-if="tableFormat.remitente">{{ item.orgId.name }}</td>
                            <td v-if="tableFormat.categoria">{{ item.category }}</td>
                            <td v-if="tableFormat.creacion">{{ getMomentDate(item.created_at) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ul class="list-group overflow-auto d-block d-md-none border-top border-bottom border-5" :style="'height:' + screenheightsize + 'px;'">
                <li v-if="load" class="list-group-item text-center" :class="night ? 'w3-dark-gray' : 'w3-white'">
                    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </li>
                <li v-for="(item, index) in ordCompras" :key="'sm' + index" class="list-group-item" @click="showPresupuesto(item)" :class="night ? 'w3-dark-gray' : 'w3-white'">
                    <div class="d-flex align-items-center mb-2">
                        <strong class="me-auto">{{ item.folio }}</strong>
                        <small>{{ getMomentDate(item.created_at) }}</small>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <small class="me-auto badge" :class="{
                            'bg-success' : item.status === 'Aceptada', 
                            'bg-danger' : item.status === 'Rechazada', 
                            'bg-warning' : item.status == 'En Revisión',
                            'bg-secondary' : item.status == 'Esperando Autorización', 
                            }">
                            {{ item.status }}
                        </small>
                        <small class="fw-bold me-1">Monto mayor:</small>
                        <small>$ {{ item.maxtotal.toFixed(2) }}</small>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <small class="fw-bold me-1">Remitente:</small>
                        <small>{{ item.orgId.name }}</small>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <small class="fw-bold me-1">Motivo:</small>
                        <small>{{ item.motivo }}</small>
                    </div>
                </li>
            </ul>
            <div class="d-xl-flex d-lg-flex d-md-flex align-items-center mt-2">
                <div class="me-auto ms-2 mb-xl-0 mb-lg-0 d-none d-md-inline">
                    <small>Mostrando {{ pagination.from }}-{{ pagination.to }} de {{ pagination.total }}</small>
                </div>
                <div class="ms-auto mb-xl-0 mb-lg-0 mb-2">
                    <nav class="d-flex">
                        <ul class="pagination pagination-circle pagination-sm mb-0 ms-auto me-auto">
                            <li class="page-item" v-if="pagination.current_page > 1" @click.prevent="changePageActivosData(pagination.current_page - 1)">
                                <a class="page-link" :class="night ? 'w3-text-white w3-hover-white' : ''" href="#" tabindex="-1" aria-disabled="true">Anterior</a>
                            </li>
                            <li class="page-item" style="z-index: 1;" v-for="(page, index) in pagesNumberActivosData" :key="index" :class="[page == isActivedActivosData ? 'active' : '']" @click.prevent="changePageActivosData(page)">
                                <a class="page-link" :class="night && page != isActivedActivosData ? 'w3-text-white w3-hover-white' : ''" href="#">{{ page }}</a>
                            </li>
                            <li class="page-item" v-if="pagination.current_page < pagination.last_page" @click.prevent="changePageActivosData(pagination.current_page + 1)">
                                <a class="page-link" :class="night ? 'w3-text-white w3-hover-white' : ''" href="#">Siguiente</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <router-view></router-view>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
import moment from 'moment'
export default {
    created() {
        this.darkmode();
        this.changeSize();
        this.getOrdCompras();
        moment.locale('es-mx');
        this.debounceOrdCompras = _.debounce(this.getOrdCompras, 500);
        if (localStorage.ordBuysRows) {
            let obr = JSON.parse(localStorage.ordBuysRows);
            Object.keys(obr).forEach(element => {
                this.tableFormat[element] = obr[element];
            });
        } else {
            localStorage.ordBuysRows = JSON.stringify(this.tableFormat);
        }
    },
    watch: {
        folio() {
            this.debounceOrdCompras();
        },
        motivo() {
            this.debounceOrdCompras();
        },
        org() {
            this.getOrdCompras();
        },
        category() {
            this.debounceOrdCompras();
        },
        estado() {
            this.getOrdCompras();
        },
        creacion() {
            this.getOrdCompras();
        },
        itemperpage() {
            this.getOrdCompras();
        },
        search() {
            this.debounceOrdCompras();
        }
    },
    data() {
        return {
            night: null,
            ordCompras: [],
            organizaciones: [],
            pagination: {},
            folio: '',
            motivo: '',
            org: '',
            category: '',
            estado: '',
            creacion: '',
            search: '',
            itemperpage: 10,
            load: false,
            offset: 6,
            screenwidthsize: null,
            screenheightsize: null,
            tableFormat: {
                folio: true,
                estado: true,
                maxMonto: true,
                motivo: true,
                remitente: true,
                categoria: true,
                creacion: true,
            }
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
        window.Echo.channel('channel-buys').listen('BuysStatusChangeEvent', (e) => {
            this.getOrdCompras();
        });
        window.addEventListener("resize", this.changeSize);
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        putStorage() {
            localStorage.ordBuysRows = JSON.stringify(this.tableFormat);
        },
        getOrdCompras(page) {
            this.load = true;
            let searchData = {
                folio: this.folio,
                motivo: this.motivo,
                org: this.org,
                category: this.category,
                estado: this.estado,
                creacion: this.creacion,
                itemperpage: this.itemperpage,
                search: this.search,
            }
            $axios.get(this.$masterRoute + '/api/ordcompras?page=' + page + '&organizaciones=' + sessionStorage.organizaciones +
                '&searchData=' + JSON.stringify(searchData), {
                    headers: {
                        Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                    }
                }).then(response => {
                this.ordCompras = response.data.ordCompras.data;
                this.pagination = response.data.pagination;
                this.organizaciones = response.data.organizaciones;
                this.load = false;
            }).catch(error => {
                console.log(error);
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                this.load = false;
            });
        },
        create() {
            this.$router.push({
                name: 'createOrdCompras',
            });
        },
        showPresupuesto(item) {
            this.$router.push({
                name: 'showPresupuestoOrdCompras',
                params: {
                    p_item: item,
                    p_authorized: item.authorized,
                },
            });
        },
        cleanFilters() {
            this.folio = '';
            this.motivo = '';
            this.org = '';
            this.category = '';
            this.estado = '';
            this.creacion = '';
        },
        getMomentDate(date) {
            return moment(date).format('LLLL');
        },
        changeSize() {
            if (window.innerWidth < 1225) {
                this.screenwidthsize = 1225;
            } else {
                this.screenwidthsize = window.innerWidth - 60;
            }
            if (window.innerWidth < 768) {
                this.screenheightsize = window.innerHeight - 280;
            } else {
                this.screenheightsize = window.innerHeight - 320;
            }
        },
        changePageActivosData(page) {
            this.load = true;
            this.pagination.current_page = page;
            this.getOrdCompras(page);
        },
    },
    computed: {
        isActivedActivosData: function () {
            return this.pagination.current_page;
        },
        pagesNumberActivosData: function () {
            if (!this.pagination.to) {
                return [];
            }

            var from = this.pagination.current_page - (this.offset / 2);
            if (from < 1) {
                from = 1;
            }

            var to = from + (this.offset);
            if (to >= this.pagination.last_page) {
                to = this.pagination.last_page;
            }

            var pagesArray = [];
            while (from <= to) {
                pagesArray.push(from);
                from++;
            }
            return pagesArray;
        },
        filters() {
            if (!this.folio && !this.motivo && !this.org && !this.category && !this.estado && !this.creacion) {
                return false;
            }
            return true;
        }
    }
}
</script>
