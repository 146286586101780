<template>
<div class="w3-modal d-block">
    <div class="w3-modal-content w3-animate-zoom card border my-3" :class="night ? 'bg-dark-gray' : ''">
        <header class="card-header" :class="night ? 'w3-dark-gray' : ''">
            <button @click.prevent="cancel" class="btn btn-danger btn-sm px-2 float-end">
                <i class="fas fa-times"></i>
            </button>
            <h5 class="text-center card-title">Visor Orden de compra</h5>
        </header>
        <div class="card-body">
            <div class="row mb-3">
                <div class="col-12 col-lg-7 align-self-center">
                    <table class="table table-sm table-borderless" :class="night ? 'text-white' : ''">
                        <tbody>
                            <tr>
                                <td class="fw-bold">Motivo:</td>
                                <td>{{ p_item.motivo }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Categoría:</td>
                                <td>{{ p_item.category }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12 col-lg-5">
                    <table class="table table-sm table-borderless" :class="night ? 'text-white' : ''">
                        <tbody>
                            <tr>
                                <td class="fw-bold">Remitente:</td>
                                <td>{{ p_item.orgId.name }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Fecha:</td>
                                <td>{{ getDate(p_item.created_at) }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Folio:</td>
                                <td>{{ p_item.folio }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <compras-presupuestos-view v-for="(item, index) in presupuesto" :key="index" :$item="item" :$ordcomId="p_item.id" :$index="index" :$isAuth="true" :$reload="p_reload"></compras-presupuestos-view>
            </div>
        </div>
        <footer class="card-footer text-center" :class="night ? 'w3-dark-gray' : ''">
            <button v-if="load" class="btn btn-secondary btn-rounded btn-block" type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Cargando...
            </button>
            <button v-else class="btn btn-danger btn-rounded" @click.prevent="cancelOrdCompra">
                <i class="far fa-window-close fa-lg me-2"></i>
                Rechazar orden de compra
            </button>
        </footer>
    </div>
</div>
</template>

<script>
import moment from 'moment'
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        p_item: Object,
        p_reload: Function,
    },
    created() {
        this.darkmode();
        moment.locale('es-mx');
        this.presupuesto = this.p_item.presupuestos;
    },
    data() {
        return {
            night: null,
            presupuesto: {},
            load: false,
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        getDate(date) {
            return moment(date).format('LL');
        },
        cancelOrdCompra() {
            $swal.fire({
                title: 'Escribe el motivo de cancelación de la orden de compra',
                input: 'text',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Enviar',
                confirmButtonColor: '#03cc9a',
                cancelButtonColor: '#ED1848',
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'Necesitas agregar la información que se solicita'
                    } else {
                        this.load = true;
                        $axios.delete(this.$masterRoute + '/api/ordcompras/' + this.p_item.id + '?motivo=' + value, {
                            headers: {
                                Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                            }
                        }).then(response => {
                            if (response.data.status) {
                                $swal.fire({
                                    icon: 'success',
                                    title: response.data.title,
                                    text: response.data.menssage,
                                });
                                this.p_reload();
                                this.cancel();
                            } else {
                                $swal.fire({
                                    icon: 'error',
                                    title: response.data.title,
                                    text: response.data.menssage,
                                });
                            }
                            this.load = false;
                        }).catch(error => {
                            console.error(error);
                            if (error.response.status === 401) {
                                $swal.fire({
                                    icon: 'error',
                                    title: 'Sesión expirada',
                                    text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                                }).then((result) => {
                                    this.$router.push({
                                        name: 'login'
                                    });
                                });
                            } else {
                                $swal.fire({
                                    icon: 'error',
                                    title: 'Error Inesperado!!',
                                    text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                                });
                            }
                            this.load = false;
                        });
                    }
                }
            });
        },
        cancel() {
            this.$router.go(-1);
        }
    }
}
</script>
