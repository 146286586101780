<template>
<div>
    <div class="card" :class="night ? 'bg-dark-gray' : ''">
        <header class="card-header py-0 px-1" :class="night ? 'w3-dark-gray' : ''">
            <ul class="nav nav-pills nav-fill">
                <li v-if="permisos.lim.status" class="nav-item">
                    <a class="nav-link text-capitalize fw-bold px-2" :class="activeView == 'authlim' ? 'active disabled' : ''" href="#" @click="authlimView">
                        <i class="fas fa-tasks fa-lg"></i>
                        <span class="d-none d-md-inline">Autorizaciones y limitantes de compra</span>
                    </a>
                </li>
                <li v-if="permisos.cat.status" class="nav-item">
                    <a class="nav-link text-capitalize fw-bold px-2" :class="activeView == 'catcom' ? 'active disabled' : ''" href="#" @click="catcomView">
                        <i class="fas fa-layer-group fa-lg"></i>
                        <span class="d-none d-md-inline">Categorias de compras</span>
                    </a>
                </li>
                <li v-if="permisos.admin.status" class="nav-item">
                    <a class="nav-link text-capitalize fw-bold px-2" :class="activeView == 'admincom' ? 'active disabled' : ''" href="#" @click="admincomView">
                        <i class="fas fa-cash-register fa-lg"></i>
                        <span class="d-none d-md-inline">Administradores de compras</span>
                    </a>
                </li>
            </ul>
        </header>
        <div class="card-body">
            <router-view></router-view>
        </div>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        p_permisos: Object,
    },
    created() {
        this.darkmode();
        this.permisos = this.p_permisos;
        this.authlimView();
    },
    data() {
        return {
            night: null,
            activeView: 'authlim',
            permisos: {},
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        authlimView() {
            this.activeView = 'authlim';
            this.$router.push({
                name: 'authlimConfigCompras',
                params: {
                    p_permisos: this.permisos.lim.permisos,
                }
            });
        },
        catcomView() {
            this.activeView = 'catcom';
            this.$router.push({
                name: 'catcomConfigCompras',
                params: {
                    p_permisos: this.permisos.cat.permisos,
                }
            });
        },
        admincomView() {
            this.activeView = 'admincom';
            this.$router.push({
                name: 'admincomConfigCompras',
                params: {
                    p_permisos: this.permisos.admin.permisos,
                }
            });
        }
    },
    computed: {

    }
}
</script>
