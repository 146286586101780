<template>
<div>
    <div class="animate__animated animate__fadeInDownBig">
        <div class="container-fluid px-2 pt-2 w3-card-4 position-sticky left-0 right-0" :class="night ? 'w3-dark-gray text-white' : 'w3-light-gray'" style="top: 60;z-index:100;">
            <!--Titulo de la vista para dispositivos xl lg md-->
            <div class="d-lg-flex d-none align-items-center">
                <div class="w3-medium me-auto ms-auto">
                    <i class="fas fa-clipboard-list fa-lg me-2"></i>
                    <strong>REPORTES</strong>
                </div>
            </div>
            <!--Titulo de la vista para dispositivos sm xs-->
            <div class="d-lg-none d-flex align-items-center">
                <div class="w3-small me-auto ms-auto">
                    <i class="fas fa-clipboard-list fa-lg me-2"></i>
                    <strong>REPORTES</strong>
                </div>
            </div>
        </div>
        <!-- Contenido-->
        <div v-if="!config" class="container-fluid px-0" :class="night ? 'bg-dark-gray' : 'w3-white'">
            <!-- Tabs navs -->
            <div class="border-bottom border-4" :class="night ? 'bg-dark-gray' : 'w3-white'" style="position: sticky;top:90;right: 0;left: 0;z-index: 99;height:150px;">
                <div class="container-fluid d-flex align-items-center pt-2 pb-1 pb-lg-2">
                    <button v-if="principalPermisos.create.status" type="button" class="btn btn-primary btn-rounded d-lg-flex d-none align-items-center me-auto btn-sm" @click.prevent="newReport">
                        <i class="fas fa-plus fa-2x me-2"></i>
                        <span class="w3-small">Crear reporte</span>
                    </button>
                    <button v-if="principalPermisos.create.status" type="button" class="btn btn-primary btn-rounded d-lg-none d-flex align-items-center me-auto btn-sm" @click.prevent="newReport">
                        <i class="fas fa-plus fa-lg me-2"></i>
                        <span>Crear reporte</span>
                    </button>
                    <button v-if="permisos.config.status" class="btn btn-info btn-rounded btn-sm d-lg-block d-none" @click.prevent="config = true">
                        <i class="fas fa-cog fa-lg"></i>
                        <span>Configuraciones</span>
                    </button>
                    <button v-if="permisos.config.status" class="btn btn-info btn-floating d-lg-none d-block" @click.prevent="config = true">
                        <i class="fas fa-cog fa-lg"></i>
                    </button>
                </div>
                <ul class="nav nav-tabs nav-justified" :class="night ? 'bg-dark-gray' : 'w3-white'" id="ex1" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="ex2-tab-1" :class="night ? 'bg-dark-gray' : ''" data-mdb-toggle="tab" href="#ex2-tabs-1" role="tab" aria-controls="ex2-tabs-1" aria-selected="true">
                            <i class="far fa-list-alt fa-2x"></i>
                            <span class="badge rounded-pill badge-notification bg-info ms-1 w3-small">{{ reportCounter.pendientes }}</span>
                            <h6 class="d-md-block d-none">Pendientes</h6>
                        </a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" :class="night ? 'bg-dark-gray' : ''" id="ex2-tab-2" data-mdb-toggle="tab" href="#ex2-tabs-2" role="tab" aria-controls="ex2-tabs-2" aria-selected="false">
                            <i class="fas fa-sync fa-2x"></i>
                            <span class="badge rounded-pill badge-notification bg-info ms-1 w3-small">{{ reportCounter.proceso }}</span>
                            <h6 class="d-md-block d-none">En proceso</h6>
                        </a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" :class="night ? 'bg-dark-gray' : ''" id="ex2-tab-3" data-mdb-toggle="tab" href="#ex2-tabs-3" role="tab" aria-controls="ex2-tabs-3" aria-selected="false">
                            <i class="fas fa-check-double fa-2x"></i>
                            <h6 class="d-md-block d-none">Terminados</h6>
                        </a>
                    </li>
                </ul>
                <div class="d-flex align-items-center d-lg-none mt-1">
                    <div class="input-group input-group-sm rounded w-50 float-end ms-auto me-2">
                        <input type="search" v-model="search" class="form-control form-control-sm rounded" placeholder="Buscar" aria-label="Search" aria-describedby="search-addon" />
                        <span class="input-group-text border-0" id="search-addon">
                            <i class="fas fa-search" :class="night ? 'text-white-50' : ''"></i>
                        </span>
                    </div>
                </div>
            </div>
            <!-- Tabs navs -->
            <!-- Tabs content -->
            <div class="container-fluid py-2">
                <div class="tab-content" id="ex2-content">
                    <div class="tab-pane fade show active" id="ex2-tabs-1" role="tabpanel" aria-labelledby="ex2-tab-1">
                        <reports-pendientes :$counter="reportCounter.pendientes" :$getCounter="getCountReport" :$search="search" :$permisos="principalPermisos" :$night="night"></reports-pendientes>
                    </div>
                    <div class="tab-pane fade" id="ex2-tabs-2" role="tabpanel" aria-labelledby="ex2-tab-2">
                        <reports-proceso :$counter="reportCounter.proceso" :$getCounter="getCountReport" :$search="search" :$permisos="principalPermisos" :$night="night"></reports-proceso>
                    </div>
                    <div class="tab-pane fade" id="ex2-tabs-3" role="tabpanel" aria-labelledby="ex2-tab-3">
                        <reports-terminados :$counter="reportCounter.terminados" :$getCounter="getCountReport" :$search="search" :$permisos="principalPermisos" :$night="night"></reports-terminados>
                    </div>
                </div>
                <!-- Tabs content -->
            </div>
        </div>
        <div v-else class="container-fluid pt-2">
            <div class="card" :class="night ? 'bg-dark-gray' : 'w3-white'">
                <div class="card-header">
                    <div class="d-flex align-items-center mb-2">
                        <button v-if="configPermisos.create.status" type="button" class="btn btn-primary btn-rounded btn-sm ms-2 d-md-block d-none" @click.prevent="createTypeReport">
                            <i class="fas fa-plus fa-lg me-2"></i>
                            <span>Crear tipo de reporte</span>
                        </button>
                        <button v-if="configPermisos.create.status" type="button" class="btn btn-primary btn-floating ms-2 d-md-none d-block" @click.prevent="createTypeReport">
                            <i class="fas fa-plus fa-lg me-2"></i>
                        </button>
                        <button class="btn btn-secondary btn-rounded btn-sm ms-auto me-2 d-md-block d-none" @click.prevent="config = false">
                            <i class="fas fa-undo-alt fa-lg me-2"></i>
                            <span>Regresar a vista reportes</span>
                        </button>
                        <button class="btn btn-secondary btn-rounded btn-floating ms-auto me-2 d-md-none d-block" @click.prevent="config = false">
                            <i class="fas fa-undo-alt fa-lg me-2"></i>
                        </button>
                    </div>
                    <div class="text-center">
                        <strong class="w3-large me-auto d-md-block d-none">
                            <i class="fas fa-cogs fa-lg me-2"></i>
                            <span>Configuración para tipos de reportes</span>
                        </strong>
                        <strong class="w3-medium me-auto d-md-none d-block">
                            <i class="fas fa-cogs fa-lg me-2"></i>
                            <span>Configuración para tipos de reportes</span>
                        </strong>
                    </div>
                </div>
                <div class="card-body" :class="night ? 'w3-dark-gray' : 'w3-light-gray'" style="overflow:auto;" :style="'height:' + screenheightsize + 'px;'">
                    <div class="row">
                        <reports-type v-for="(item, index) in reportsType" :key="index" :$item="item" :$permisos="configPermisos" :$reload="getTypeReport" :$night="night"></reports-type>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Vistas para enrutamiento VueRoute-->
    <router-view></router-view>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        $permisos: Object,
    },
    created() {
        this.darkmode();
        this.principalPermisos = this.$permisos.principal.permisos;
        this.configPermisos = this.$permisos.config.permisos;
        this.permisos = this.$permisos;
        this.getTypeReport();
        this.getCountReport();
    },
    data() {
        return {
            night: null,
            config: false,
            reportsType: [],
            reportCounter: [],
            search: '',
            principalPermisos: {},
            configPermisos: {},
            permisos: {},
            timer: null,
        }
    },
    mounted() {
        window.Echo.channel('channel-reports').listen('OrderStatusChangeEvent', (e) => {
            this.getCountReport();
        });
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        getTypeReport() {
            $axios.get(this.$masterRoute + '/api/reportstype', {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.reportsType = response.data;
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });
        },
        getCountReport() {
            let orgids = sessionStorage.organizaciones;
            $axios.get(this.$masterRoute + '/api/reports/counters?orgids=' + orgids, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.reportCounter = response.data;
                //this.setTimer();
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });
        },
        newReport() {
            this.$router.push({
                name: 'createReport',
                params: {
                    _reload: this.getCountReport,
                }
            });
        },
        createTypeReport() {
            this.$router.push({
                name: 'createTypeReport',
                params: {
                    _reload: this.getTypeReport,
                }
            });
        },
        /*setTimer() {
            this.timer = setTimeout(this.getCountReport, 30 * 1000);
        },*/
    },
    computed: {
        screenheightsize() {
            return window.innerHeight - 180;
        },
    }
}
</script>
