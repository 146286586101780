<template>
<div class="w3-modal d-block">
    <div class="w3-modal-content w3-animate-zoom card border mt-3">
        <header class="card-header">
            <button @click.prevent="cancel" class="btn btn-danger btn-sm px-2 float-end">
                <i class="fas fa-times"></i>
            </button>
            <div class="d-xl-flex d-lg-flex d-md-flex d-none align-items-center w3-large">
                <i class="fas fa-arrows-alt fa-lg text-white bg-info p-2 me-auto card"></i>
                <strong class="me-auto">Moviendo organizaciones</strong>
            </div>
            <div class="d-xl-none d-lg-none d-md-none d-flex align-items-center w3-medium">
                <i class="fas fa-arrows-alt fa-lg text-white bg-info p-2 me-auto card"></i>
                <strong class="me-auto">Moviendo organizaciones</strong>
            </div>
            <div class="d-flex align-items-center mt-3">
                <strong>({{ _org.clave }}):{{ _org.name }}</strong>
                <i class="fas fa-arrow-circle-right mx-2"></i>
                <span v-if="selectOrg.name">({{ selectOrg.clave }}):{{ selectOrg.name }}</span>
            </div>
        </header>
        <div class="card-body">
            <h5 class="text-center mb-2">
                <strong>
                    Selecciona donde deseas mover la organización
                </strong>
            </h5>
            <div class="container w3-light-gray py-2">
                <div @click.prevent="putMasterId" class="w3-left-align w3-medium px-2" style="cursor:pointer;" :class="selectOrg.id === 0 ? 'bg-secondary text-white' : ''">
                    <i class="fas fa-home"></i>
                    <span>Raiz o Inicio</span>
                </div>
                <org-moveTreeData v-for="(node, index) in _treeData" :key="index" :$node="node" :$selectElem="_org" :$getParent="putParentId" :$childSelect="selectOrg"></org-moveTreeData>
            </div>
        </div>
        <footer class="card-footer">
            <div v-if="load">
                <button class="btn btn-secondary btn-rounded btn-block" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Cargando...
                </button>
            </div>
            <div v-else class="row px-0">
                <div class="col-6">
                    <button class="btn btn-danger btn-rounded btn-sm btn-block" @click.prevent="cancel">
                        <i class="fas fa-ban fa-lg"></i>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="col-6">
                    <button class="btn btn-success btn-rounded btn-sm btn-block" :class="[selectOrg.name ? '' : 'disabled']" @click.prevent="moveOrg">
                        <i class="fas fa-save fa-lg"></i>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </footer>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        _org: Object,
        _treeData: Array,
        _reload: Function,
    },
    data() {
        return {
            selectOrg: {},
            load: false,
        }
    },
    methods: {
        moveOrg: function () {
            this.load = true;
            $axios.get(this.$masterRoute + '/api/organizaciones/' + this._org.id + '/edit?parentId=' + this.selectOrg.id, {
                    headers: {
                        Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        $swal.fire(
                            'Acción exitosa!',
                            response.data.menssage,
                            'success'
                        )
                        this._reload();
                        this.cancel();
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: 'Error!!',
                            text: response.data.menssage,
                        });
                        this.load = false;
                    }
                }).catch(error => {
                    if (error.response.status === 401) {
                        $swal.fire({
                            icon: 'error',
                            title: 'Clave de seguridad expirada',
                            text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                        }).then((result) => {
                            this.$router.push({
                                name: 'login'
                            });
                        });
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: 'Error Inesperado!!',
                            text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                        });
                    }
                    console.log(error.response);
                    this.load = false;
                });
        },
        putMasterId() {
            this.selectOrg = {};
            if (this._org.parentId != null) {
                this.selectElement = {
                    id: 0,
                    name: 'Inicio',
                    clave: 'Raiz',
                };
            } else {
                new Noty({
                    type: 'warning',
                    text: 'El elmento ya se encuentra en la raiz',
                    timeout: 2500,
                    progressBar: true,
                }).show();
            }
        },
        putParentId(item) {
            this.selectOrg = item;
        },
        cancel() {
            this.$router.go(-1);
        }
    },
}
</script>
