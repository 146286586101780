<template>
<div class="text-center">
    <div v-if="load" class="d-flex align-items-center justify-content-center mb-3">
        <div class="spinner-border text-secondary" style="width: 3rem; height: 3rem;" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div v-else>
        <ul class="list-group mb-3">
            <li v-if="!users.length" :class="$night ? 'w3-dark-gray' : ''" class="list-group-item d-flex align-items-center justify-content-center w3-medium">
                <i class="fas fa-ban fa-lg me-2"></i>
                <strong>Sin resultados</strong>
            </li>
            <li v-else v-for="(user, index) in users" :key="index" :class="$night ? 'w3-dark-gray' : ''" class="list-group-item d-flex align-items-center">
                <img src="https://exelord.com/ember-initials/images/default-d5f51047d8bd6327ec4a74361a7aae7f.jpg" class="rounded-circle img-fluid me-3" style="width: 30px;" />
                <strong>{{ user.user }}</strong>
                <span class="ms-3">{{ user.name }}</span>
                <span class="ms-auto">{{ getMomentDate(user.created_at) }}</span>
            </li>
        </ul>
        <button v-if="pagination.last_page > 1 && pagination.current_page < pagination.last_page" class="btn btn-info btn-rounded" @click.prevent="getUsers(pagination.current_page + 1)">
            <i class="fas fa-sync-alt fa-lg me-2"></i>
            <span>Cargar más</span>
        </button>
    </div>
</div>
</template>

<script>
import moment from 'moment'
import $swal from 'sweetalert2'
import $axios from 'axios'
export default {
    props: {
        $org: Object,
        $night: Boolean,
    },
    created() {
        this.getUsers();
        moment.locale('es-mx');
    },
    watch: {
        $org() {
            this.load = true;
            this.users = [],
                this.getUsers();
        }

    },
    data() {
        return {
            users: [],
            pagination: [],
            load: true,
        }
    },
    methods: {
        getUsers(page) {
            $axios.get(this.$masterRoute + '/api/organizaciones/users/' + this.$org.id + '?page=' + page, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.pagination = response.data.pagination;
                response.data.users.data.forEach(element => {
                    this.users.push(element);
                });
                this.load = false;
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });
        },
        getMomentDate(date) {
            return moment(date).format('MMMM Do YYYY, h:mm a');
        },
    }
}
</script>
