<template>
<li class="list-group-item">
    <div class="d-flex align-items-center mb-2">
        <strong class="me-auto">{{ $index + 1 + '. ' + $item.device }}</strong>
        <i v-if="$item.status == 1" class="fas fa-check-circle fa-lg text-success"></i>
        <i v-else class="fas fa-times-circle fa-lg text-danger"></i>
    </div>
    <div class="text-justify mb-2">{{ $item.data }}</div>
    <div v-if="$permisos" class="d-flex align-items-center justify-content-center mb-2">
        <strong v-if="pass" style="cursor: pointer;" @click.prevent="pass = false">{{ $item.password }}</strong>
        <button v-else type="button" class="btn btn-outline-black btn-floating" @click.prevent="pass = true">
            <i class="fas fa-eye"></i>
        </button>
    </div>
    <div class="row mb-2">
        <div class="col align-self-center text-center">
            <strong>Última conexión</strong>
            <small>{{ getMomentDate($item.updated_at) }}</small>
        </div>
        <div class="col align-self-center text-center">
            <strong>Última conexión</strong>
            <small>{{ getMomentDate($item.created_at) }}</small>
        </div>
    </div>
</li>
</template>

<script>
export default {
    props: {
        $permisos: Boolean,
        $index: Number,
        $item: Object,
    },
    data() {
        return {
            pass: false,
        }
    },
    methods: {
        getMomentDate(date) {
            return moment(date).calendar();
        },
    },
}
</script>
