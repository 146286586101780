<template>
<div class="animate__animated animate__fadeIn">
    <div v-if="load" class="text-center">
        <div class="spinner-border text-secondary" style="width: 3rem; height: 3rem;" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div v-else-if="!headers.length && headersload" class="d-flex align-items-center justify-content-center">
        <i class="fas fa-table fa-2x me-2"></i>
        <h4>No se ha configuarado el formato de datos para los activos</h4>
    </div>
    <div v-else>
        <!-- Header -->
        <div class="d-flex align-items-center mb-3">
            <button type="button" class="btn btn-primary btn-floating me-auto" @click.prevent="createActivo">
                <i class="fas fa-plus fa-lg"></i>
            </button>
            <strong class="w3-large me-auto">
                <i class="fas fa-info-circle fa-lg me-2"></i>
                <span>Información de los activos</span>
            </strong>
        </div>
        <div class="d-xl-flex d-lg-flex d-md-flex mb-3">
            <div class="me-auto mb-2">
                <div class="input-group input-group-sm rounded">
                    <span class="input-group-text border-0">Filas por página: </span>
                    <select class="form-select form-select-sm rounded" v-model="itemsPerPage">
                        <option value="10" selected>10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </select>
                </div>
            </div>
            <div class="ms-auto mb-2">
                <div class="input-group input-group-sm rounded">
                    <input type="search" class="form-control rounded" placeholder="Buscar" v-model="searchItem" />
                    <span class="input-group-text border-0" id="search-addon">
                        <i class="fas fa-search"></i>
                    </span>
                </div>
            </div>
        </div>
        <!-- Body -->
        <div class="table-responsive">
            <table class="table table-bordered table-hover align-middle" :style="'width:' + (headers.length * 175) + 'px;'">
                <thead>
                    <tr class="w3-light-gray">
                        <th scope="col" class="p-1 text-center text-white border-1 w3-card-4" style="position:sticky;left:0px; width: 50px;"></th>
                        <th scope="col" class="p-1 text-center" style="width: 10px;"><strong>#</strong></th>
                        <activos-headers-tables v-for="(head, index) in headers" :key="index" :$head="head" v-show="head.isVisible"></activos-headers-tables>
                    </tr>
                </thead>
                <tbody v-if="activos.length">
                    <activos-cols-tables v-show="activos.length" v-for="(activo, index) in activos" :key="index" :$cols="activo" :$id="index + 1" :$headers="headers" :$reloadData="getActivosData" :$activo="$activo"></activos-cols-tables>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="6" class="text-center">
                            <span>
                                <i class="fas fa-ban"></i> Sin resultados
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Footer -->
        <div class="d-xl-flex d-lg-flex d-md-flex align-items-center mt-3">
            <div class="me-auto ms-2 mb-xl-0 mb-lg-0 mb-2">
                <small>Mostrando {{ pagination.from }}-{{ pagination.to }} de {{ pagination.total }}</small>
            </div>
            <div class="ms-auto mb-xl-0 mb-lg-0 mb-2">
                <nav class="d-flex">
                    <ul class="pagination pagination-circle pagination-sm mb-0 ms-auto me-auto">
                        <li class="page-item" v-if="pagination.current_page > 1" @click.prevent="changePageActivosData(pagination.current_page - 1)">
                            <a class="page-link text-secondary" href="#" tabindex="-1" aria-disabled="true">Anterior</a>
                        </li>
                        <li class="page-item" style="z-index: 1;" v-for="(page, index) in pagesNumberActivosData" :key="index" :class="[page == isActivedActivosData ? 'active' : '']" @click.prevent="changePageActivosData(page)">
                            <a class="page-link" href="#">{{ page }}</a>
                        </li>
                        <li class="page-item" v-if="pagination.current_page < pagination.last_page" @click.prevent="changePageActivosData(pagination.current_page + 1)">
                            <a class="page-link text-secondary" href="#">Siguiente</a>
                        </li>
                    </ul>
                </nav>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        $activo: Object,
        $getElem: Function,
    },
    created() {
        this.getHeaders();
        this.debounceGetActivosData = _.debounce(this.getActivosData, 500);
    },
    watch: {
        $activo() {
            this.load = true;
            this.getHeaders();
        },
        searchItem() {
            this.debounceGetActivosData();
        },
        itemsPerPage() {
            this.getActivosData();
        }
    },
    data() {
        return {
            activos: [],
            headers: [],
            pagination: [],
            itemsPerPage: 10,
            searchItem: '',
            sortAction: {
                name: '',
                type: '',
            },
            load: true,
            headersload: false,
            offset: 4,
        }
    },
    methods: {
        getHeaders() {
            $axios.get(this.$masterRoute + '/api/panel/admin/getActivo' + this.$activo.id, {
                    headers: {
                        Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                    }
                })
                .then(response => {
                    this.headers = response.data.formatData;
                    this.getActivosData();
                    this.headersload = true;
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        $swal.fire({
                            icon: 'error',
                            title: 'Clave de seguridad expirada',
                            text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                        }).then((result) => {
                            this.$router.push({
                                name: 'login'
                            });
                        });
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: 'Error Inesperado!!',
                            text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                        });
                    }
                    console.log(error.response);
                    this.load = false;
                })
        },
        getActivosData(page) {
            $axios.get(this.$masterRoute + '/api/panel/admin/getDataActivos' + this.$activo.id + '?page=' + page + '&itemsperpage=' + this.itemsPerPage + '&searchitem=' + this.searchItem + '&sortname=' + this.sortAction.name + '&sorttype=' + this.sortAction.type, {
                    headers: {
                        Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                    }
                })
                .then(response => {
                    this.activos = response.data.activos.data;
                    this.pagination = response.data.pagination;
                    this.$getElem();
                    this.load = false;
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        $swal.fire({
                            icon: 'error',
                            title: 'Clave de seguridad expirada',
                            text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                        }).then((result) => {
                            this.$router.push({
                                name: 'login'
                            });
                        });
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: 'Error Inesperado!!',
                            text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                        });
                    }
                    console.log(error.response);
                    this.load = false;
                })
        },
        createActivo() {
            this.$router.push({
                name: 'createActivo',
                params: {
                    _activo: this.$activo,
                    _datosForm: this.headers,
                    _reloadData: this.getActivosData,
                },
            });
        },
        changePageActivosData(page) {
            this.load = true;
            this.pagination.current_page = page;
            this.getActivosData(page);
        },
    },
    computed: {
        isActivedActivosData: function () {
            return this.pagination.current_page;
        },
        pagesNumberActivosData: function () {
            if (!this.pagination.to) {
                return [];
            }

            var from = this.pagination.current_page - (this.offset / 2);
            if (from < 1) {
                from = 1;
            }

            var to = from + (this.offset);
            if (to >= this.pagination.last_page) {
                to = this.pagination.last_page;
            }

            var pagesArray = [];
            while (from <= to) {
                pagesArray.push(from);
                from++;
            }
            return pagesArray;
        },
    },
}
</script>
