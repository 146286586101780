<template>
<div class="row">
    <div class="col-xl-5 col-lg-5 col-12 mb-3">
        <div class="mb-3">
            <div class="input-group">
                <span class="input-group-text" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Titulo:</span>
                <input type="text" class="form-control form-control-sm" v-model="titulo" maxlength="100" :class="titulo.length ? 'is-valid': 'is-invalid'" />
                <div class="invalid-feedback ms-2">Este campo es obligatorio!!</div>
            </div>
            <small class="float-end me-2">{{ titulo.length }}/100</small>
        </div>
    </div>
    <div class="col-xl-5 col-lg-5 col-12 mb-3">
        <div class="mb-3">
            <div class="input-group">
                <span class="input-group-text" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Texto:</span>
                <input type="text" class="form-control form-control-sm" v-model="texto" maxlength="100" :class="texto.length ? 'is-valid': 'is-invalid'" />
                <div class="invalid-feedback ms-2">Este campo es obligatorio!!</div>
            </div>
            <small class="float-end me-2">{{ texto.length }}/100</small>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-12 mb-3 text-center">
        <button type="button" class="btn btn-danger px-2" @click.prevent="$delInfo($index)">
            <i class="fas fa-backspace fa-lg me-2"></i>
            <span>Quitar</span>
        </button>
    </div>
</div>
</template>

<script>
export default {
    props: {
        $index: Number,
        $item: Object,
        $id: String,
        $delInfo: Function,
        $create: Function,
    },
    created() {
        this.darkmode();
        this.titulo = this.$item.title;
        this.texto = this.$item.text;
        this.debounceCreate = _.debounce(this.createRegister, 500);
    },
    watch: {
        $id() {
            this.titulo = this.$item.title;
            this.texto = this.$item.text;
        },
        titulo() {
            this.debounceCreate();
        },
        texto() {
            this.debounceCreate();
        }
    },
    data() {
        return {
            night: null,
            titulo: '',
            texto: '',
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        createRegister() {
            this.$create(this.$index, this.titulo, this.texto);
        }
    }
}
</script>
