<template>
<div>
    <div v-if="isChildren && $node.id != $selectElem.id" :id="'moveOpen' + $node.id" class="w3-left-align ps-2 mt-2 d-flex w3-medium">
        <a @click.prevent="openSeccion($node, isOpen)" class="text-decoration-none me-2 d-flex align-items-center">
            <i :class="[isOpen ? 'fas fa-caret-down' : 'fas fa-caret-right']" class="me-1"></i>
            <i class="fas fa-building fa-lg"></i>
        </a>
        <strong v-if="selectIsVisible" @click.prevent="$getParent($node)" style="cursor:pointer;" class="ps-2 w-100 disabled" :class="$node === $childSelect ? 'bg-secondary text-white' : ''">{{ $node.name }}</strong>
        <strong v-else class="ps-2 w-100 disabled">{{ $node.name }}</strong>
    </div>
    <div :id="'moveClose' + $node.id" v-if="!isChildren && $node.counter === 0 && $node.id != $selectElem.id" @click.prevent="$getParent($node)" class="w3-left-align w3-medium px-2" style="cursor:pointer;" :class="$node === $childSelect ? 'bg-secondary text-white' : ''">
        <i class="fas fa-store-alt"></i>
        <span>{{ $node.name }}</span>
    </div>

    <div :id="'moveSeccion' + $node.id" class="w3-container ms-3" style="display: none" v-if="isChildren">
        <org-moveTreeData v-for="(node, index) in $node.children" :key="index" :$node="node" :$selectElem="$selectElem" :$getParent="$getParent" :$childSelect="$childSelect"></org-moveTreeData>
    </div>
</div>
</template>

<script>
export default {
    props: {
        $node: Object,
        $selectElem: Object,
        $getParent: Function,
        $childSelect: Object,
    },
    data() {
        return {
            isOpen: false,
        };
    },
    methods: {
        openSeccion: function (object, open) {
            if (open) {
                $("#moveSeccion" + object.id).hide();
                this.isOpen = false;
            } else {
                $("#moveSeccion" + object.id).show();
                this.isOpen = true;
            }
        },
    },
    computed: {
        isChildren: function () {
            return this.$node.children && this.$node.children.length;
        },
        selectIsVisible() {
            if (this.$selectElem.children && this.$selectElem.children.length) {
                return true;
            } else {
                if (this.$node.id != this.$selectElem.parentId) {
                    return true;
                }
                return false;
            }
        }
    },
};
</script>
