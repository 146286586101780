<template>
<div>
    <!--Carpeta de activos-->
    <div v-if="isChildren" :id="'btnOpen' + $node.id" class="w3-left-align ps-2 d-flex w3-medium">
        <div @click.prevent="openSeccion($node, isOpen)" class="text-decoration-none me-2 d-flex align-items-center text-black-50">
            <i :class="[isOpen ? 'fas fa-caret-down' : 'fas fa-caret-right', $night ? 'text-white-50' : '']" class="me-1"></i>
            <i class="fas fa-building fa-lg" :class="$night ? 'text-white-50' : ''"></i>
        </div>
        <div class="w-100" :class="$node.id === $elemSelect.id ? 'bg-secondary text-white' : ''" @click.prevent="$putElem($node)" style="cursor:pointer;">
            <strong class="ps-2">{{ $node.name }}</strong>
        </div>

    </div>
    <!--Grupo de activos-->
    <div :id="'btnClose' + $node.id" v-if="!isChildren" @click.prevent="$putElem($node)" class="w3-left-align w3-medium px-2" style="cursor:pointer;" :class="$node.id === $elemSelect.id ? 'bg-secondary text-white' : ''">
        <i class="fas fa-store-alt me-2" :class="[$night ? 'text-white-50' : '', $node.id === $elemSelect.id ? 'text-white' : 'text-black-50']"></i>
        <strong>{{ $node.name }}</strong>
    </div>

    <div :id="'seccionorg' + $node.id" class="w3-container ms-3 mb-3" v-if="isChildren">
        <org-treeData v-for="(child, index) in $node.children" :key="index" :$node="child" :$elemSelect="$elemSelect" :$putElem="$putElem" :$night="$night"></org-treeData>
    </div>
</div>
</template>

<script>
export default {
    props: {
        $night: Boolean,
        $node: Object,
        $putElem: Function,
        $elemSelect: Object,
    },
    data() {
        return {
            isOpen: true,
        }
    },
    methods: {
        openSeccion: function (object, open) {
            if (open) {
                $("#seccionorg" + object.id).hide();
                this.isOpen = false;
            } else {
                $("#seccionorg" + object.id).show();
                this.isOpen = true;
            }
        },
    },
    computed: {
        isChildren: function () {
            return this.$node.children && this.$node.children.length;
        },
    }
}
</script>
