<template>
<div class="col-12">
    <div class="d-lg-block d-none">
        <div class="input-group mb-3">
            <span class="input-group-text w3-light-gray w-75">{{ $quest.question }}</span>
            <select class="form-select" v-model="answer" :class="!answer ? 'is-invalid' : 'is-valid'">
                <option selected></option>
                <option v-for="(ans, index) in $quest.answers" :key="index" :value="ans">{{ ans }}</option>
            </select>
        </div>
    </div>
    <div class="mb-3 d-lg-none d-block">
        <label class="form-label">{{ $quest.question }}</label>
        <select class="form-select" v-model="answer" :class="!answer ? 'is-invalid' : 'is-valid'">
            <option selected></option>
            <option v-for="(ans, index) in $quest.answers" :key="index">{{ ans }}</option>
        </select>
    </div>
</div>
</template>

<script>
export default {
    props: {
        $quest: Object,
        $index: Number,
        $putAnwers: Function,
    },
    created() {
        this.$putAnwers({
            question: this.$quest.question,
            answer: this.answer,
        }, this.$index);
    },
    watch: {
        answer() {
            this.$putAnwers({
                question: this.$quest.question,
                answer: this.answer,
            }, this.$index);
        }
    },
    data() {
        return {
            answer: '',
        }
    },
}
</script>
