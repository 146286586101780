<template>
<div>
    <div class="d-flex align-items-center justify-content-center" style="height: 92vh;">
        <div class="card p-3 animate__animated animate__fadeInDownBig" :class="night ? 'bg-dark-gray' : ''">
            <div class="card-body text-center d-xl-block d-lg-block d-md-block d-none">
                <h1 class="w3-jumbo" :class="night ? 'text-white-50' : 'text-black-50'">Bienvenido!!</h1>
                <img :src="$masterRoute + '/image/logo_pintucity.png'" class="w-100" alt="" loading="eager" />
            </div>
            <div class="card-body text-center d-xl-none d-lg-none d-md-none d-block bg-image">
                <h1 class="w3-xxlarge" :class="night ? 'text-white-50' : 'text-black-50'">Bienvenido!!</h1>
                <img :src="$masterRoute + '/image/logo_pintucity.png'" class="w-100" alt="" loading="eager" />
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    created() {
        this.darkmode();
        this.authData = JSON.parse(sessionStorage.authUser);
    },
    data() {
        return {
            night: null,
            authData: '',
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        }
    },
}
</script>
