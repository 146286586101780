<template>
<div class="container">
    <div class="card" :class="night ? 'bg-dark-gray' : ''">
        <header class="card-header" :class="night ? 'w3-dark-gray' : ''">
            <h5 class="card-title text-center">Nueva orden de compra</h5>
        </header>
        <div class="card-body overflow-auto" :style="'height: ' + heightSize + 'px;'">
            <div class="input-group mb-3 d-none d-md-inline-flex">
                <span class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">*Motivo de compra:</span>
                <input v-model="motivo" type="text" class="form-control" :class="!motivo ? 'is-invalid' : 'is-valid'">
            </div>
            <div class="mb-3 d-block d-md-none">
                <label class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">*Motivo de compra:</label>
                <input v-model="motivo" type="text" class="form-control" :class="!motivo ? 'is-invalid' : 'is-valid'">
            </div>
            <div class="input-group mb-3 d-none d-md-inline-flex">
                <span class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">*Categoría de orden de compra:</span>
                <select class="form-select" v-model="catCompra">
                    <option v-for="(item, index) in categoriesCompras" :key="index" :value="item.name">{{ item.name }}</option>
                </select>
            </div>
            <div class="mb-3 d-block d-md-none">
                <label class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">*Categoría de orden de compra:</label>
                <select v-model="catCompra" class="form-select">
                    <option v-for="(item, index) in categoriesCompras" :key="index" :value="item.name">{{ item.name }}</option>
                </select>
            </div>
            <div class="container border border-3 mb-2">
                <h5 class="mt-2 mb-3">Presupuesto 1</h5>
                <compras-presupuesto :$index="0" :$putJsonProveedores="putJsonProveedores" :$putJsonNotas="putJsonNotas" :$putJsonTiempo="putJsonTiempo" :$putJsonProductos="putJsonProductos" :$putJsonTotal="putJsonTotal" :$putJsonFiles="putJsonFiles" :$ids="proveedoresId"></compras-presupuesto>
            </div>
            <div class="container border border-3 mb-2">
                <h5 class="mt-2 mb-3">Presupuesto 2</h5>
                <compras-presupuesto :$index="1" :$putJsonProveedores="putJsonProveedores" :$putJsonNotas="putJsonNotas" :$putJsonTiempo="putJsonTiempo" :$putJsonProductos="putJsonProductos" :$putJsonTotal="putJsonTotal" :$putJsonFiles="putJsonFiles" :$ids="proveedoresId"></compras-presupuesto>
            </div>
            <div class="container border border-3 mb-2">
                <h5 class="mt-2 mb-3">Presupuesto 3</h5>
                <compras-presupuesto :$index="2" :$putJsonProveedores="putJsonProveedores" :$putJsonNotas="putJsonNotas" :$putJsonTiempo="putJsonTiempo" :$putJsonProductos="putJsonProductos" :$putJsonTotal="putJsonTotal" :$putJsonFiles="putJsonFiles" :$ids="proveedoresId"></compras-presupuesto>
            </div>
            <router-view></router-view>
        </div>
        <footer class="card-footer" :class="night ? 'w3-dark-gray' : ''">
            <div v-if="load">
                <button class="btn btn-secondary btn-rounded btn-block" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Cargando...
                </button>
            </div>
            <div v-else class="row">
                <div class="col">
                    <button class="btn btn-danger btn-sm btn-rounded btn-block" @click.prevent="cancel">
                        <i class="fas fa-ban fa-lg"></i>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="col">
                    <button class="btn btn-success btn-sm btn-rounded btn-block" @click.prevent="save">
                        <i class="fas fa-save fa-lg"></i>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </footer>
    </div>
</div>
</template>

<script>
import $swal from 'sweetalert2'
import $axios from 'axios'
export default {
    created() {
        this.darkmode();
        this.changeSize();
        this.getAdminCompras();
    },
    data() {
        return {
            night: null,
            motivo: '',
            catCompra: '',
            categoriesCompras: [],
            load: false,
            heightSize: null,
            presupuestos: [{
                proveedor: 0,
                productos: [],
                tiempo: '',
                notas: '',
                total: 0,
                fileIds: [],
                files: [],
            }, {
                proveedor: 0,
                productos: [],
                tiempo: '',
                notas: '',
                total: 0,
                fileIds: [],
                files: [],
            }, {
                proveedor: 0,
                productos: [],
                tiempo: '',
                notas: '',
                total: 0,
                fileIds: [],
                files: [],
            }],
            filesCount: 0,
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
        window.addEventListener("resize", this.changeSize);
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        save() {
            let text = this.validate();
            if (!text) {
                this.load = true;
                $axios.post(this.$masterRoute + '/api/ordcompras', {
                    motivo: this.motivo,
                    catCompra: this.catCompra,
                    presupuestos: JSON.stringify(this.presupuestos),
                }, {
                    headers: {
                        Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                    }
                }).then(response => {
                    if (response.data.status) {
                        if (this.countFiles() > 0) {
                            this.filesCount = this.countFiles();
                            this.saveFiles(response.data.ordCompraId);
                        } else {
                            $swal.fire({
                                icon: 'success',
                                title: response.data.title,
                                text: response.data.menssage,
                            })
                            this.cancel();
                            this.load = false;
                        }
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: response.data.title,
                            text: response.data.menssage,
                        });
                        this.load = false;
                    }
                }).catch(error => {
                    console.error(error);
                    if (error.response.status === 401) {
                        $swal.fire({
                            icon: 'error',
                            title: 'Sesión expirada',
                            text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                        }).then((result) => {
                            this.$router.push({
                                name: 'login'
                            });
                        });
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: 'Error Inesperado!!',
                            text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                        });
                    }
                    this.load = false;
                });
            } else {
                $swal.fire({
                    icon: 'warning',
                    title: 'Orden de compra invalida!',
                    text: text,
                })
            }
        },
        saveFiles(id) {
            this.presupuestos.forEach((element, index) => {
                element.files.forEach(item => {
                    let form = new FormData();
                    form.append('id', id);
                    form.append('file', item.file);
                    form.append('type', item.type);
                    $axios.post('/api/filesordcompras', form, {
                        headers: {
                            Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                        }
                    }).then(response => {
                        if (response.data.status) {
                            this.filesCount--;
                            if (this.filesCount == 0) {
                                this.presupuestos[index].fileIds.push(response.data.id);
                                this.presupuestos.forEach(element => {
                                    delete element.files;
                                });
                                this.updatePresupuestos(id);
                            } else {
                                this.presupuestos[index].fileIds.push(response.data.id);
                            }
                        } else {
                            $swal.fire({
                                icon: 'error',
                                title: response.data.title,
                                text: response.data.menssage,
                            });
                            this.load = false;
                        }
                    }).catch(error => {
                        console.log(error);
                        if (error.response.status === 401) {
                            $swal.fire({
                                icon: 'error',
                                title: 'Sesión expirada',
                                text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                            }).then((result) => {
                                this.$router.push({
                                    name: 'login'
                                });
                            });
                        } else {
                            $swal.fire({
                                icon: 'error',
                                title: 'Error Inesperado!!',
                                text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                            });
                        }
                        this.load = false;
                    });
                });
            });
        },
        updatePresupuestos(id) {
            $axios.put('/api/ordcompras/' + id, {
                presupuestos: JSON.stringify(this.presupuestos),
            }, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                console.log(response.data);
                if (response.data.status) {
                    $swal.fire({
                        icon: 'success',
                        title: response.data.title,
                        text: response.data.menssage,
                    })
                    this.cancel();
                    this.load = false;
                } else {
                    alert('is this');
                    $swal.fire({
                        icon: 'error',
                        title: response.data.title,
                        text: response.data.menssage,
                    });
                    this.load = false;
                }
            }).catch(error => {
                console.log(error);
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                this.load = false;
            });
        },
        getAdminCompras() {
            $axios.get(this.$masterRoute + '/api/catcompras/create', {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.categoriesCompras = response.data;
                this.catCompra = this.categoriesCompras[0].name;
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });
        },
        validate() {
            if (!this.motivo) {
                return 'Es necesario ingresar el motivo de compra';
            }
            let prov = false;
            let time = {
                status: false,
                index: null,
            };
            let prod = {
                status: false,
                index: null,
            };
            this.presupuestos.forEach((element, index) => {
                if (element.proveedor != 0) {
                    prov = true;
                    if (element.productos.length == 0) {
                        prod.status = true;
                        prod.index = index + 1;
                    }
                    if (!element.tiempo) {
                        time.status = true;
                        time.index = index + 1;
                    }
                }
            });
            if (!prov) {
                return 'No has seleccionado ningún proveedor';
            }
            if (prod.status) {
                return 'No has agregado ningún producto al presupuesto ' + prod.index;
            }
            if (time.status) {
                return 'Necesitas agregar el tiempo de entrega en el presupuesto ' + time.index;
            }
            if (!this.catCompra) {
                return 'No has seleccionado una categoría de compra';
            }
            return '';
        },
        putJsonProveedores(id, index) {
            this.presupuestos[index].proveedor = id;
        },
        putJsonNotas(text, index) {
            this.presupuestos[index].notas = text;
        },
        putJsonTiempo(text, index) {
            this.presupuestos[index].tiempo = text;
        },
        putJsonProductos(array, index) {
            this.presupuestos[index].productos = array;
        },
        putJsonTotal(number, index) {
            this.presupuestos[index].total = number;
        },
        putJsonFiles(array, index) {
            this.presupuestos[index].files = array;
        },
        countFiles() {
            let num = 0;
            this.presupuestos.forEach(element => {
                num = num + element.files.length;
            });
            return num;
        },
        changeSize() {
            if (window.innerWidth < 576) {
                this.heightSize = window.innerHeight - 260;
            } else {
                this.heightSize = window.innerHeight - 270;
            }
        },
        cancel() {
            this.$router.go(-1);
        }
    },
    computed: {
        proveedoresId() {
            let datos = [];
            this.presupuestos.forEach(element => {
                datos.push(element.proveedor);
            });
            return datos;
        },
    }
}
</script>
