<template>
<div class="col-lg-4 col-md-6 col-12 mb-3">
    <div class="card mb-3 h-100" :class="$night ? 'bg-dark-gray' : 'w3-white'">
        <div class="row g-0 h-100">
            <div :class="loadImg ? 'w3-light-gray' : 'bg-image w3-white'" class="col-md-4">
                <div :class="loadImg ? 'd-flex' : 'd-none'" class="align-items-center justify-content-center h-100 w3-opacity">
                    <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <img :class="!loadImg ? 'd-block' : 'd-none'" :src="$item.image" alt="..." class="img-fluid" />
            </div>
            <div class="col-md-8">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <button v-if="$permisos.edit.status" type="button" class="btn btn-warning btn-floating btn-sm ms-auto" @click.prevent="editTypeReport">
                            <i class="fas fa-pen fa-lg"></i>
                        </button>
                        <button v-if="$permisos.delete.status" type="button" class="btn btn-danger btn-floating btn-sm ms-2" @click.prevent="delTypeReport">
                            <i class="fas fa-trash-alt fa-lg"></i>
                        </button>
                    </div>
                    <h5 class="card-title">{{ $item.clave }} - {{ $item.name }}</h5>
                    <div v-for="(item, index) in questions" :key="index">
                        <strong class="card-text me-2">
                            P{{ index + 1 }}.- {{ item.question }}
                        </strong> <br>
                        <small v-for="asw in item.answers" :key="asw">({{ asw }}) </small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        $night: Boolean,
        $item: Object,
        $permisos: Object,
        $reload: Function,
    },
    created() {
        this.questions = JSON.parse(this.$item.questions);
        setTimeout(this.stopLoad, 1500);
    },
    watch: {
        $item() {
            this.loadImg = true;
            setTimeout(this.stopLoad, 1500);
        }
    },
    data() {
        return {
            questions: [],
            loadImg: true,
        }
    },
    methods: {
        delTypeReport() {
            $swal.fire({
                title: '¿Estás seguro?',
                text: "Estás a punto de eliminar un tipo de reporte!!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#03cc9a',
                cancelButtonColor: '#ED1848',
                confirmButtonText: 'Si, eliminarlo!',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    $axios.delete(this.$masterRoute + '/api/reportstype/' + this.$item.id, {
                        headers: {
                            Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                        }
                    }).then(response => {
                        if (response.data.status) {
                            $swal.fire(
                                'Acción exitosa!',
                                response.data.menssage,
                                'success'
                            );
                            this.$reload();
                        } else {
                            $swal.fire({
                                icon: 'error',
                                title: response.data.title,
                                text: response.data.menssage,
                            });
                        }
                    }).catch(error => {
                        console.log(error);
                        if (error.response.status === 401) {
                            $swal.fire({
                                icon: 'error',
                                title: 'Sesión expirada',
                                text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                            }).then((result) => {
                                this.$router.push({
                                    name: 'login'
                                });
                            });
                        } else {
                            $swal.fire({
                                icon: 'error',
                                title: 'Error Inesperado!!',
                                text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                            });
                        }
                        console.log(error.response);
                    });
                }
            });
        },
        stopLoad() {
            this.loadImg = false;
        },
        editTypeReport() {
            this.$router.push({
                name: 'editTypeReport',
                params: {
                    _typeReport: this.$item,
                    _reload: this.$reload,
                    _night: this.$night,
                }
            });
        },
    },
}
</script>
