<template>
<div>
    <router-view></router-view>
</div>
</template>

<script>
export default {
    created() {
        this.darkmode();
        this.$router.push({
            name: 'keyDevice'
        });
    },
    data() {
        return {
            night: null,
            heightZise: null,
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                $("body").addClass("w3-black");
                $("body").removeClass("w3-light-gray");
            }
            if (localStorage.nightMode == 'false') {
                $("body").removeClass("w3-black");
                $("body").addClass("w3-light-gray");
            }
        },
    },
}
</script>
