<template>
<div class="w3-modal d-block">
    <div class="w3-modal-content w3-animate-zoom card border mt-3" :class="night ? 'bg-dark-gray text-white-50' : ''">
        <header class="card-header" :class="night ? 'w3-dark-gray' : ''">
            <button @click.prevent="cancel" class="btn btn-danger btn-sm px-2 float-end">
                <i class="fas fa-times"></i>
            </button>
            <div class="d-xl-flex d-lg-flex d-md-flex d-none align-items-center w3-large">
                <i class="fas fa-key fa-2x text-info me-2"></i>
                <strong class="mx-auto">Cambio de contraseña usuario: {{ _user.user }}</strong>
            </div>
            <div class="d-xl-none d-lg-none d-md-none d-flex align-items-center">
                <i class="fas fa-key fa-lg text-info me-2"></i>
                <strong class="me-2">Cambio de contraseña usuario: {{ _user.user }}</strong>
            </div>
        </header>
        <div class="card-body">
            <div class="row">
                <div class="col-12 mb-3">
                    <div class="input-group mb-3">
                        <span class="input-group-text w3-light-gray">Contraseña:</span>
                        <input :type="isPassShow" class="form-control form-control-sm" v-model="pass" :class="isCorrectPass ? 'is-valid': 'is-invalid'" />
                        <button class="btn btn-secondary px-2" @click.prevent="showHidePass">
                            <i class="fas fa-eye fa-lg" :class="showPass ? 'fa-eye-slash' : 'fa-eye'"></i>
                        </button>
                        <div class="invalid-feedback ms-2">{{ errorPass }}</div>
                    </div>
                </div>
                <div v-show="pass" class="col-12 mb-3">
                    <div class="input-group mb-3">
                        <span class="input-group-text w3-light-gray">Repite contraseña:</span>
                        <input type="password" class="form-control form-control-sm" v-model="repitePass" :class="isSamePass ? 'is-valid': 'is-invalid'" />
                        <div class="invalid-feedback ms-2">{{ errorRepPass }}</div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="card-footer" :class="night ? 'w3-dark-gray' : ''">
            <div v-if="load">
                <button class="btn btn-secondary btn-rounded btn-block" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Cargando...
                </button>
            </div>
            <div v-else class="row">
                <div class="col">
                    <button class="btn btn-danger btn-sm btn-rounded btn-block" @click.prevent="cancel">
                        <i class="fas fa-ban"></i>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="col">
                    <button class="btn btn-success btn-sm btn-rounded btn-block" :disabled="isDisabledSave" @click.prevent="savePass">
                        <i class="fas fa-save"></i>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </footer>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        _user: Object,
    },
    created() {
        this.nightMode();
    },
    data() {
        return {
            night: null,
            load: false,
            pass: '',
            repitePass: '',
            errorPass: '',
            errorRepPass: '',
            showPass: false,
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.nightMode();
        });
    },
    methods: {
        nightMode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        savePass() {
            this.load = true;
            $axios.put(this.$masterRoute + '/api/users/changePass/' + this._user.id, {
                pass: this.pass,
            }, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                if (response.data.status) {
                    $swal.fire(
                        'Acción exitosa!',
                        response.data.menssage,
                        'success'
                    );
                    this.cancel();
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error!!',
                        text: response.data.menssage,
                    });
                }
                this.load = false;
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false
            });
        },
        showHidePass() {
            if (this.showPass) {
                this.showPass = false;
            } else {
                this.showPass = true;
            }
        },
        cancel() {
            this.$router.go(-1);
        },
    },
    computed: {
        isDisabledSave() {
            if (!this.isCorrectPass || !this.isSamePass) {
                return true;
            }
            return false;
        },
        isPassShow() {
            if (this.showPass) {
                return 'text';
            }
            return 'password';
        },
        isCorrectPass() {
            if (!this.pass) {
                this.errorPass = 'Este campo es obligatorio!!';
                return false;
            }
            if (this.pass.length < 6) {
                this.errorPass = 'La contraseña debe tener 6 caracteres mínimo';
                return false;
            }
            return true;
        },
        isSamePass() {
            if (!this.repitePass) {
                this.errorRepPass = 'Este campo es obligatorio!!';
                return false;
            }
            if (this.pass != this.repitePass) {
                this.errorRepPass = 'La contraseña no coincide!!';
                return false;
            }
            return true;
        }
    },
}
</script>
