<template>
<div class="w3-modal d-block">
    <div class="w3-modal-content w3-animate-zoom card border mt-3">
        <header class="card-header">
            <button @click.prevent="cancel" class="btn btn-danger btn-sm px-2 float-end">
                <i class="fas fa-times"></i>
            </button>
            <div class="d-xl-flex d-lg-flex d-md-flex d-none align-items-center w3-large">
                <i class="fas fa-pen-square fa-2x text-warning me-auto"></i>
                <strong class="me-auto">Editando organización</strong>
            </div>
            <div class="d-xl-none d-lg-none d-md-none d-flex align-items-center w3-medium">
                <i class="fas fa-pen-square fa-2x text-warning me-auto"></i>
                <strong class="me-auto">Editando organización</strong>
            </div>
        </header>
        <div class="card-body">
            <div class="mb-3">
                <div class="input-group mb-3">
                    <span class="input-group-text w3-light-gray">Nombre:</span>
                    <input type="text" class="form-control" v-model="orgName" :class="!orgName || orgName.length < 5?'is-invalid':'is-valid'" maxlength="100" />
                    <div v-if="!orgName" class="invalid-feedback ms-2">El campo es obligatorio</div>
                    <div v-else-if="orgName.length < 5" class="invalid-feedback ms-2">El campo debe ser mayor a 5 caracteres</div>
                </div>
                <div class="float-end me-2">{{ orgName.length }}/100</div>
            </div>
        </div>
        <footer class="card-footer">
            <div v-if="load">
                <button class="btn btn-secondary btn-rounded btn-block" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Cargando...
                </button>
            </div>
            <div v-else class="row">
                <div class="col">
                    <button class="btn btn-danger btn-sm btn-rounded btn-block" @click.prevent="cancel">
                        <i class="fas fa-ban"></i>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="col">
                    <button class="btn btn-success btn-sm btn-rounded btn-block" :disabled="isDisable" @click.prevent="UpdateOrganizacion">
                        <i class="fas fa-save"></i>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </footer>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        _org: Object,
        _reload: Function,
    },
    created() {
        this.orgName = this._org.name;
    },
    data() {
        return {
            load: false,
            orgName: '',
        }
    },
    methods: {
        UpdateOrganizacion() {
            this.load = true;
            $axios.put(this.$masterRoute + '/api/organizaciones/' + this._org.id, {
                name: this.orgName
            }, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                if (response.data.status) {
                    $swal.fire(
                        'Acción exitosa!',
                        response.data.menssage,
                        'success'
                    );
                    this._reload();
                    this.cancel();
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: response.data.title,
                        text: response.data.menssage,
                    });
                }
                this.load = false;
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });
        },
        cancel() {
            this.$router.go(-1);
        },
    },
    computed: {
        isDisable() {
            if (!this.orgName || this.orgName.length < 5 || this._org.name === this.orgName) {
                return true;
            }
            return false;
        },
    }
}
</script>
