<template>
<div>
    <div class="animate__animated animate__fadeInDownBig">
        <div class="container-fluid w3-light-gray px-2 pt-2 w3-card-4 position-sticky left-0 right-0" :class="night ? 'w3-dark-gray text-white' : 'w3-light-gray'" style="top: 60px;z-index:100;">
            <!--Titulo de la vista para dispositivos xl lg md-->
            <div class="d-lg-flex d-none align-items-center">
                <div class="w3-medium me-auto ms-auto">
                    <i class="fas fa-city fa-lg me-2"></i>
                    <strong>ORGANIZACIONES</strong>
                </div>
            </div>
            <!--Titulo de la vista para dispositivos sm xs-->
            <div class="d-lg-none d-flex align-items-center">
                <div class="w3-small me-auto ms-auto">
                    <i class="fas fa-city fa-lg me-auto"></i>
                    <strong>ORGANIZACIONES</strong>
                </div>
            </div>
        </div>
        <!-- Contenido -->
        <div class="container-fluid pt-3">
            <div class="row">
                <div class="col">
                    <div class="card border" :class="night ? 'bg-dark-gray' : 'w3-white'">
                        <header class="card-header">
                            <div class="d-flex align-items-center">
                                <!--Boton para crear nueva organizacion en la raiz-->
                                <button v-if="permisos.createraiz.status" type="button" class="btn btn-secondary btn-floating me-2" @click.prevent="createOrg(0)">
                                    <i class="fas fa-building fa-lg"></i>
                                </button>
                                <h5 class="card-title ms-2">
                                    <i class="fas fa-city fa-lg me-2"></i>
                                    <span>Organizaciones</span>
                                </h5>
                            </div>
                        </header>
                        <div v-if="load" class="card-body text-center">
                            <div class="spinner-border text-secondary" style="width: 3rem; height: 3rem;" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        <div v-else class="card-body">
                            <div class="me-auto align-items-center d-xl-flex d-none">
                                <button v-if="permisos.create.status" class="btn btn-primary btn-rounded btn-sm me-2" :class="[selectElement.id ? '' : 'disabled']" @click.prevent="createOrg(selectElement.id)">
                                    <i class="fas fa-plus fa-lg"></i>
                                    <span>Crear</span>
                                </button>
                                <button v-if="permisos.move.status" class="btn btn-info btn-rounded btn-sm me-2" :class="[selectElement.id ? '' : 'disabled']" @click.prevent="moveOrg">
                                    <i class="fas fa-arrows-alt fa-lg"></i>
                                    <span>Mover</span>
                                </button>
                                <button v-if="permisos.edit.status" class="btn btn-warning btn-rounded btn-sm me-2" :class="[selectElement.id ? '' : 'disabled']" @click.prevent="editOrg">
                                    <i class="fas fa-pen fa-lg"></i>
                                    <span>Editar</span>
                                </button>
                                <button v-if="permisos.delete.status" class="btn btn-danger btn-rounded btn-sm me-2" :class="[isDelete ? '' : 'disabled']" @click.prevent="deleteOrg">
                                    <i class="fas fa-trash-alt fa-lg"></i>
                                    <span>Eliminar</span>
                                </button>
                            </div>
                            <div class="px-auto d-xl-none d-flex">
                                <button v-if="permisos.create.status" class="btn btn-primary btn-floating me-2" :class="[selectElement.id ? '' : 'disabled']" @click.prevent="createOrg(selectElement.id)">
                                    <i class="fas fa-plus fa-lg"></i>
                                </button>
                                <button v-if="permisos.move.status" class="btn btn-info btn-floating me-2" :class="[selectElement.id ? '' : 'disabled']" @click.prevent="moveOrg">
                                    <i class="fas fa-arrows-alt fa-lg"></i>
                                </button>
                                <button v-if="permisos.edit.status" class="btn btn-warning btn-floating me-2" :class="[selectElement.id ? '' : 'disabled']" @click.prevent="editOrg">
                                    <i class="fas fa-pen fa-lg"></i>
                                </button>
                                <button v-if="permisos.delete.status" class="btn btn-danger btn-floating me-2" :class="[isDelete ? '' : 'disabled']" @click.prevent="deleteOrg">
                                    <i class="fas fa-trash-alt fa-lg"></i>
                                </button>
                            </div>
                            <div class="mt-3">
                                <org-treeData v-for="(item, index) in organizacionesTree" :key="index" :$node="item" :$elemSelect="selectElement" :$putElem="putParent" :$night="night"></org-treeData>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-8 animate__animated animate__slideInRight" v-if="selectElement.id">
                    <div class="card" :class="night ? 'bg-dark-gray' : ''">
                        <header class="card-header d-flex align-items-center">
                            <h5 class="card-title me-auto">
                                <i v-if="isChildren" class="fas fa-store-alt fa-lg me-2"></i>
                                <i v-else class="fas fa-building fa-lg me-2"></i>
                                <strong>{{ selectElement.name }}</strong>
                            </h5>
                            <!--<button v-if="asignActivos" type="button" class="btn btn-secondary btn-rounded btn-sm" @click.prevent="asignActivos = false">
                                <i class="fas fa-cog fa-lg"></i>
                                <span>Configurar Información de usuarios</span>
                            </button>
                            <button v-else type="button" class="btn btn-info btn-rounded btn-sm" @click.prevent="asignActivos = true">
                                <i class="fas fa-cog fa-lg"></i>
                                <span>Configurar asignación de activos</span>
                            </button>-->
                        </header>
                        <div class="card-body">
                            <!-- Tabs navs -->
                            <ul class="nav nav-tabs nav-justified mb-3" id="ex1" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" id="ex3-tab-1" data-mdb-toggle="tab" :class="night ? 'bg-dark-gray text-white' : ''" href="#ex3-tabs-1" role="tab" aria-controls="ex3-tabs-1" aria-selected="true">
                                        <i class="fas fa-users fa-2x"></i>
                                        <h5 class="d-lg-block d-none">Usuarios</h5>
                                    </a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" id="ex3-tab-2" data-mdb-toggle="tab" :class="night ? 'bg-dark-gray text-white' : ''" href="#ex3-tabs-2" role="tab" aria-controls="ex3-tabs-2" aria-selected="false">
                                        <i class="fas fa-cubes fa-2x"></i>
                                        <h5 class="d-lg-block d-none">Activos</h5>
                                    </a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" id="ex3-tab-3" data-mdb-toggle="tab" :class="night ? 'bg-dark-gray text-white' : ''" href="#ex3-tabs-3" role="tab" aria-controls="ex3-tabs-3" aria-selected="false">
                                        <i class="fas fa-clipboard-list fa-2x"></i>
                                        <h5 class="d-lg-block d-none">Reportes</h5>
                                    </a>
                                </li>
                            </ul>
                            <!-- Tabs navs -->

                            <!-- Tabs content -->
                            <div class="tab-content" id="ex2-content">
                                <div class="tab-pane fade show active" id="ex3-tabs-1" role="tabpanel" aria-labelledby="ex3-tab-1">
                                    <org-usersinfo :$org="selectElement" :$night="night"></org-usersinfo>
                                </div>
                                <div class="tab-pane fade" id="ex3-tabs-2" role="tabpanel" aria-labelledby="ex3-tab-2">
                                    <org-activosinfo :$org="selectElement"></org-activosinfo>
                                </div>
                                <div class="tab-pane fade" id="ex3-tabs-3" role="tabpanel" aria-labelledby="ex3-tab-3">
                                    <org-reportesinfo :$org="selectElement" :$night="night"></org-reportesinfo>
                                </div>
                            </div>
                            <!-- Tabs content -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Vistas para enrutamiento VueRoute-->
    <router-view></router-view>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        $permisos: Object,
    },
    created() {
        this.permisos = this.$permisos;
        console.log(this.permisos);
        this.darkmode();
        this.getorganizaciones();
    },
    data() {
        return {
            night: null,
            selectElement: {},
            permisos: {},
            offset: 6,
            load: true,
            organizacionesTree: {},
            orgUsersData: {},
            asignActivos: false,
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        getorganizaciones() {
            $axios.get(this.$masterRoute + '/api/organizaciones', {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.organizacionesTree = this.generateTree(response.data);
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });
        },
        deleteOrg() {
            this.load = true;
            $swal.fire({
                title: '¿Estás seguro?',
                text: "Estás a punto de eliminar una organización!!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#03cc9a',
                cancelButtonColor: '#ED1848',
                confirmButtonText: 'Si, eliminarlo!'
            }).then((result) => {
                if (result.isConfirmed) {
                    $axios.delete(this.$masterRoute + '/api/organizaciones/' + this.selectElement.id, {
                        headers: {
                            Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                        }
                    }).then(response => {
                        if (response.data.status) {
                            $swal.fire(
                                'Acción exitosa!!',
                                response.data.menssage,
                                'success'
                            );
                            this.getorganizaciones();
                            this.selectElement = {};
                        } else {
                            $swal.fire({
                                icon: 'error',
                                title: response.data.title,
                                text: response.data.menssage,
                            });
                        }
                        this.load = false;
                    }).catch(error => {
                        if (error.response.status === 401) {
                            $swal.fire({
                                icon: 'error',
                                title: 'Sesión expirada',
                                text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                            }).then((result) => {
                                this.$router.push({
                                    name: 'login'
                                });
                            });
                        } else {
                            $swal.fire({
                                icon: 'error',
                                title: 'Error Inesperado!!',
                                text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                            });
                        }
                        console.log(error.response);
                        this.load = false;
                    });
                } else {
                    this.load = false;
                }
            });
        },
        putParent(elem) {
            this.selectElement = elem;
        },
        createOrg(id) {
            this.$router.push({
                name: 'createOrg',
                params: {
                    _parentId: id,
                    _reload: this.getorganizaciones,
                },
            });
        },
        editOrg() {
            this.$router.push({
                name: 'editOrg',
                params: {
                    _org: this.selectElement,
                    _reload: this.getorganizaciones,
                },
            });
        },
        moveOrg() {
            this.$router.push({
                name: 'moveOrg',
                params: {
                    _org: this.selectElement,
                    _treeData: this.organizacionesTree,
                    _reload: this.getorganizaciones,
                },
            })
        },
        generateTree(data) {
            let map = {},
                node,
                roots = [],
                i;
            for (i = 0; i < data.length; i++) {
                map[data[i].id] = i;
                data[i].children = [];
            }
            for (i = 0; i < data.length; i++) {
                node = data[i];
                if (node.parentId) {
                    if (node.parentId) {
                        data[map[node.parentId]].children.push(node);
                    }
                } else {
                    roots.push(node);
                }
            }
            this.load = false;
            return roots;
        },
    },
    computed: {
        isChildren() {
            return this.selectElement.children && !this.selectElement.children.length;
        },
        isDelete() {
            if (this.selectElement.id) {
                if (this.selectElement.children.length === 0) {
                    return true;
                }
            }
            return false;
        },
    }
}
</script>
