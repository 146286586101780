<template>
<div class="w3-modal d-block">
    <div class="w3-modal-content w3-animate-zoom card border my-3" :class="night ? 'bg-dark-gray' : ''">
        <header class="card-header" :class="night ? 'w3-dark-gray' : ''">
            <button @click.prevent="cancel" class="btn btn-danger btn-sm px-2 float-end">
                <i class="fas fa-times"></i>
            </button>
            <h5 class="card-title text-center">Visor de Compra</h5>
        </header>
        <div class="card-body">
            <h1 v-if="itemData.status == 'Cancelado'" class="text-danger fw-bold text-center mb-3">
                <i class="far fa-window-close"></i>
                Cancelada
            </h1>
            <h1 v-if="itemData.status == 'Adquirido'" class="text-success fw-bold text-center mb-3">
                <i class="far fa-check-square"></i>
                Adquirida
            </h1>
            <h3 v-if="itemData.pay == 1" class="text-success fw-bold text-center mb-3">(Pagado)</h3>
            <div class="row mb-3">
                <div class="col-lg-5 col-12">
                    <table class="table table-sm table-borderless" :class="night ? 'text-white' : ''">
                        <tbody>
                            <tr>
                                <td class="fw-bold">Fecha:</td>
                                <td>{{ getDate(itemData.created_at) }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Remitente:</td>
                                <td>{{ itemData.remitente.name }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Folio:</td>
                                <td>{{ itemData.folio }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Modalidad:</td>
                                <td>{{ itemData.mode }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Autorizado:</td>
                                <td>{{ itemData.userId.name }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-lg-7 col-12 border border-4">
                    <table class="table table-sm table-borderless" :class="night ? 'text-white' : ''">
                        <thead>
                            <tr>
                                <th colspan="2">
                                    <h5 class="border-bottom border-2 fw-bold">Proveedor</h5>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="fw-bold">Nombre comercial:</td>
                                <td>{{ itemData.proveedor.comercialname }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Razón social:</td>
                                <td>{{ itemData.proveedor.razonsocial }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Correo electrónico:</td>
                                <td>{{ itemData.proveedor.email }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Domicilio:</td>
                                <td>{{ itemData.proveedor.address }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Teléfono:</td>
                                <td>{{ itemData.proveedor.phone }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Facturación:</td>
                                <td v-if="itemData.proveedor.factura == 1">Si realiza facturas</td>
                                <td v-else>No realiza facturas</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Tipo de pago:</td>
                                <td v-if="itemData.proveedor.payType === 1">Trasferencia o cheque</td>
                                <td v-else>Tarjeta o efectivo</td>
                            </tr>
                            <tr v-if="itemData.proveedor.payType === 1">
                                <td class="fw-bold">No. de cuenta:</td>
                                <td>{{ payData.noCuenta }}</td>
                            </tr>
                            <tr v-if="itemData.proveedor.payType === 1">
                                <td class="fw-bold">Banco:</td>
                                <td>{{ payData.bank }}</td>
                            </tr>
                            <tr v-if="itemData.proveedor.payType === 1">
                                <td class="fw-bold">Beneficiario:</td>
                                <td>{{ payData.beneficiario }}</td>
                            </tr>
                            <tr v-if="itemData.proveedor.payType === 1">
                                <td class="fw-bold">CLABE:</td>
                                <td>{{ payData.CLABE }}</td>
                            </tr>
                            <tr v-for="(item, index) in extradata" :key="index">
                                <td class="fw-bold">{{ item.title }}</td>
                                <td>{{ item.text }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-bordered table-sm" :class="night ? 'text-white w3-border-white' : 'w3-border-black'">
                    <thead :class="night ? 'w3-dark-gray' : 'w3-light-gray'">
                        <tr>
                            <th class="text-center fw-bold" colspan="5">Productos</th>
                        </tr>
                        <tr class="text-center">
                            <th>#</th>
                            <th>Nombre</th>
                            <th>Cantidad</th>
                            <th>Precio</th>
                            <th>Importe</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(elem, index) in itemData.products" :key="index">
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-center">{{ elem.producto.name }}</td>
                            <td class="text-center">{{ elem.cantidad }}</td>
                            <td v-if="elem.iva === 1" class="text-end">{{ (elem.precio / 1.16).toFixed(2) }}</td>
                            <td v-else>{{ elem.precio }}</td>
                            <td v-if="elem.iva === 1" class="text-end">{{ (elem.cantidad * (elem.precio / 1.16)).toFixed(2) }}</td>
                            <td v-else class="text-end">{{ elem.cantidad * elem.precio }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <div class="offset-lg-8 col-lg-4 offset-md-6 col-md-6 offset-0 col-12">
                    <table class="table table-sm table-bordered" :class="night ? 'text-white w3-border-white' : 'w3-border-black'">
                        <tbody>
                            <tr v-if="itemData.proveedor.factura == 1">
                                <td class="fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Subtotal:</td>
                                <td>{{ '$ ' + subtotal }}</td>
                            </tr>
                            <tr v-if="itemData.proveedor.factura == 1">
                                <td class="fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">IVA 16%:</td>
                                <td>{{ '$ ' + iva }}</td>
                            </tr>
                            <tr v-if="itemData.proveedor.factura == 1">
                                <td class="fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Total:</td>
                                <td>{{ '$ ' + total }}</td>
                            </tr>
                            <tr v-if="itemData.proveedor.factura == 0">
                                <td class="fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Total:</td>
                                <td>{{ '$ ' + subtotal }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12 align-self-center">
                    <table class="table table-sm table-bordered" :class="night ? 'text-white w3-border-white' : 'w3-border-black'">
                        <tbody>
                            <tr>
                                <td class="fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Tiempo de entrega:</td>
                                <td>{{ itemData.times }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Notas:</td>
                                <td>{{ itemData.notes }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="container-fluid border border-3 py-2">
                <div class="d-flex align-items-center">
                    <h5 class="me-auto">Imagenes adjuntas</h5>
                    <button v-if="load" class="btn btn-secondary btn-rounded" type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Cargando...
                    </button>
                    <div v-else-if="permisos.addimg">
                        <button class="btn btn-primary btn-rounded btn-sm d-none d-md-block" @click="addImg">
                            <i class="fas fa-plus fa-lg me-2"></i>
                            <span class="d-md-inline d-none">Agregar imagen</span>
                        </button>
                        <button class="btn btn-primary btn-rounded d-block d-md-none" @click="addImg">
                            <i class="fas fa-plus fa-lg"></i>
                        </button>
                    </div>
                    <input type="file" class="d-none" id="imgfile" accept="image/*" @change="putImage">
                </div>
                <div class="row">
                    <div v-for="(img, index) in itemData.images" :key="index" class="col-12 col-md-6 col-lg-4">
                        <div class="bg-image">
                            <img :src="img.url" class="img-fluid" alt="img">
                            <div class="mask">
                                <div v-if="permisos.delimg" class="d-flex justify-content-end align-items-start h-100 pt-2 pe-2">
                                    <button type="button" class="btn btn-danger btn-floating btn-sm float-end" @click.prevent="delImg(img.id)">
                                        <i class="fas fa-times fa-lg"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <footer v-if="isVisible" class="card-footer text-center" :class="night ? 'w3-dark-gray' : ''">
            <button v-if="load" class="btn btn-secondary btn-rounded btn-block" type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Cargando...
            </button>
            <button v-else class="btn btn-danger btn-rounded" @click.prevent="cancelCompra">
                <i class="far fa-window-close fa-lg me-2"></i>
                Cancelar compra
            </button>
        </footer>
    </div>
</div>
</template>

<script>
import moment from 'moment'
import $swal from 'sweetalert2'
import $axios from 'axios'
export default {
    props: {
        p_reload: Function,
        p_item: Object,
        p_permisos: Object,
    },
    created() {
        this.darkmode();
        this.permisos = this.p_permisos;
        this.itemData = this.p_item;
        moment.locale('es-mx');
        this.getSubtotal();
        this.payData = JSON.parse(this.itemData.proveedor.bankData);
        this.extradata = JSON.parse(this.itemData.proveedor.extradata);
    },
    data() {
        return {
            night: null,
            permisos: {},
            itemData: {},
            subtotal: 0,
            iva: 0,
            total: 0,
            payData: {},
            extradata: [],
            load: false,
            sweetBackColor: '',
            sweetTextColor: '',
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
                this.sweetBackColor = '#5c5c5c';
                this.sweetTextColor = 'text-white';
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
                this.sweetBackColor = '#fff';
                this.sweetTextColor = '';
            }
        },
        getSubtotal() {
            let sub = 0;
            this.itemData.products.forEach(element => {
                if (element.iva == 1) {
                    sub = sub + ((element.cantidad * element.precio) / 1.16);
                } else {
                    sub = sub + (element.cantidad * element.precio);
                }
            });
            this.subtotal = sub.toFixed(2);
            this.iva = (sub * 0.16).toFixed(2);
            this.total = (sub * 1.16).toFixed(2);
        },
        getDate(date) {
            return moment(date).format('LL');
        },
        addImg() {
            $('#imgfile').click();
        },
        delImg(id) {
            $swal.fire({
                title: '¿Estás seguro de eliminar la imagen del registro?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#03cc9a',
                cancelButtonColor: '#ED1848',
                confirmButtonText: 'Si, eliminarlo!',
                cancelButtonText: 'Cancelar',
                background: this.sweetBackColor,
                customClass: {
                    title: this.sweetTextColor,
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    this.load = true;
                    $axios.delete(this.$masterRoute + '/api/imgcompras/' + id, {
                        headers: {
                            Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                        }
                    }).then(response => {
                        if (response.data.status) {
                            $swal.fire({
                                icon: 'success',
                                title: response.data.title,
                                text: response.data.menssage,
                            });
                            this.itemData = response.data.compra;
                            this.p_reload();
                        } else {
                            $swal.fire({
                                icon: 'error',
                                title: response.data.title,
                                text: response.data.menssage,
                            });
                        }
                        this.load = false;
                    }).catch(error => {
                        console.error(error);
                        if (error.response.status === 401) {
                            $swal.fire({
                                icon: 'error',
                                title: 'Sesión expirada',
                                text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                            }).then((result) => {
                                this.$router.push({
                                    name: 'login'
                                });
                            });
                        } else {
                            $swal.fire({
                                icon: 'error',
                                title: 'Error Inesperado!!',
                                text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                            });
                        }
                        this.load = false;
                    });
                }
            });
        },
        putImage(e) {
            this.load = true;
            let formData = new FormData();
            formData.append('comId', this.itemData.id);
            formData.append('image', e.target.files[0]);
            $axios.post(this.$masterRoute + '/api/imgcompras', formData, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                if (response.data.status) {
                    this.itemData = response.data.compra;
                    this.p_reload();
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: response.data.title,
                        text: response.data.menssage,
                    })
                }
                this.load = false;
            }).catch(error => {
                console.error(error);
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                this.load = false;
            });
        },
        cancelCompra() {
            $swal.fire({
                title: 'Escribe el motivo de cancelación de la compra',
                input: 'text',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Enviar',
                confirmButtonColor: '#03cc9a',
                cancelButtonColor: '#ED1848',
                background: this.sweetBackColor,
                customClass: {
                    title: this.sweetTextColor,
                    input: this.sweetTextColor,
                },
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'Necesitas agregar la información que se solicita'
                    } else {
                        this.load = true;
                        $axios.delete(this.$masterRoute + '/api/compras/' + this.itemData.id + '?motivo=Motivo de cancelación: ' + value, {
                            headers: {
                                Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                            }
                        }).then(response => {
                            if (response.data.status) {
                                $swal.fire({
                                    icon: 'success',
                                    title: response.data.title,
                                    text: response.data.menssage,
                                });
                                this.p_reload();
                                this.cancel();
                            } else {
                                $swal.fire({
                                    icon: 'error',
                                    title: response.data.title,
                                    text: response.data.menssage,
                                });
                            }
                            this.load = false;
                        }).catch(error => {
                            console.error(error);
                            if (error.response.status === 401) {
                                $swal.fire({
                                    icon: 'error',
                                    title: 'Sesión expirada',
                                    text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                                }).then((result) => {
                                    this.$router.push({
                                        name: 'login'
                                    });
                                });
                            } else {
                                $swal.fire({
                                    icon: 'error',
                                    title: 'Error Inesperado!!',
                                    text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                                });
                            }
                            this.load = false;
                        });
                    }
                }
            });
        },
        cancel() {
            this.$router.go(-1);
        }
    },
    computed: {
        isVisible() {
            if (this.permisos.cancelbuy) {
                if (this.itemData.status == 'Adquirido' || this.itemData.status == 'Cancelado') {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }
    }
}
</script>
