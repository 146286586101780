<template>
<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-2">
    <div class="form-check form-check-inline">
        <input class="form-check-input" v-model="objectItem" @change.prevent="changeStatus" type="checkbox" />
        <label class="form-check-label">{{ $datos.name }}</label>
    </div>
</div>
</template>

<script>
export default {
    props: {
        $datos: Object,
        $changeStatus: Function,
        $clave: Array,
    },
    created() {
        this.objectItem = this.$datos.status;
    },
    data() {
        return {
            objectItem: '',
        }
    },
    methods: {
        changeStatus() {
            this.$changeStatus(this.$clave, this.objectItem);
        }
    },
}
</script>
