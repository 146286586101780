<template>
<div class="col-xl-6 col-lg-6 col-12">
    <div v-if="$inputForm.type === 'Selector'" class="mb-3">
        <div class="input-group">
            <span class="input-group-text w3-light-gray">
                <strong>{{ $inputForm.name }}:</strong>
            </span>
            <select class="form-select is-valid" v-model="valorInput">
                <option v-for="(item, index) in JSON.parse($inputForm.defaultValue)" :key="index" :value="item">{{ item }}</option>
            </select>
        </div>
    </div>
    <div v-else class="mb-3">
        <div class="input-group">
            <span class="input-group-text w3-light-gray w3-small">
                <i class="fas fa-keyboard me-2"></i>
                <strong>{{ $inputForm.name }}:</strong>
            </span>
            <input :type="inputData" v-model="valorInput" :maxlength="inputSize" class="form-control form-control-sm" :class="isValid ? 'is-valid' : 'is-invalid'">
            <div v-if="$inputForm.isRequire" class="invalid-feedback ms-2">Este campo es obligatorio</div>
        </div>
        <small v-if="inputSize" class="float-end me-2 w3-tiny">{{ valorInput.length }}/{{ $inputForm.size }}</small>
    </div>
</div>
</template>

<script>
export default {
    props: {
        $inputForm: Object,
        $valid: Function,
        $inputValue: String,
        $changeData: Function,
    },
    created() {
        let jsondata = JSON.parse(this.$inputValue);
        if (jsondata[this.$inputForm.name]) {
            this.valorInput = jsondata[this.$inputForm.name];
        }
        this.debouncedChangeObjectData = _.debounce(this.changeObjectdata, 500);
    },
    watch: {
        valorInput() {
            this.debouncedChangeObjectData();
        }
    },
    data() {
        return {
            valorInput: '',
        }
    },
    methods: {
        changeObjectdata() {
            this.$changeData(this.$inputForm.name, this.valorInput);
        },
    },
    computed: {
        inputData() {
            if (this.$inputForm.type === 'Texto') {
                return 'text';
            } else if (this.$inputForm.type === 'Numero') {
                return 'number';
            } else if (this.$inputForm.type === 'Fecha') {
                return 'date';
            } else if (this.$inputForm.type === 'Tiempo') {
                return 'time';
            } else if (this.$inputForm.type === 'Fecha/Tiempo') {
                return 'datetime-local';
            }
        },
        inputSize() {
            if (this.$inputForm.size != 0) {
                return this.$inputForm.size;
            }
            return false;
        },
        isValid() {
            if (this.$inputForm.isRequire) {
                if (!this.valorInput) {
                    this.$valid(this.$inputForm.id, false);
                    return false;
                } else {
                    this.$valid(this.$inputForm.id, true);
                    return true;
                }
            }
            this.$valid(this.$inputForm.id, true);
            return true;
        },
    },
}
</script>
