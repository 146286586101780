<template>
<div class="row mb-2">
    <div class="col-lg-5 col-12 mb-2">
        <div class="dropdown">
            <div class="input-group d-none d-md-inline-flex">
                <button class="btn btn-danger" @click="$deleteJsonProducto($index)">
                    <i class="fas fa-trash-alt fa-lg"></i>
                </button>
                <span class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Producto</span>
                <input v-if="!productoId" v-model="producto" type="text" data-isdropdown="true" :data-dropdown="$name + 'prodDropdown' + $index" class="form-control" @focus="focusEvent($name + 'prodDropdown' + $index)">
                <input v-else v-model="producto" type="text" class="form-control" readonly>
            </div>
            <div class="d-md-none d-block">
                <label class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Producto</label>
                <input v-if="!productoId" v-model="producto" type="text" data-isdropdown="true" :data-dropdown="$name + 'prodDropdown' + $index" class="form-control" @focus="focusEvent($name + 'prodDropdown' + $index)">
                <input v-else v-model="producto" type="text" class="form-control" readonly>
            </div>
            <div :id="$name + 'prodDropdown' + $index" class="dropdown-menu w-100 inputdropdown" :class="night ? 'dropdown-menu-dark' : 'border'">
                <a v-if="load" class="dropdown-item text-center" href="#">
                    <div class="spinner-border text-secondary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </a>
                <a v-else v-for="(item, index) in productos" :key="index" class="dropdown-item" href="#" @click="putProduct(item)">{{ item.name }}</a>
                <a v-if="productos.length < 11 && !load" class="dropdown-item btn btn-primary" @click="createProducto">
                    <i class="fas fa-plus fa-lg"></i>
                    <span>Agregar nuevo producto</span>
                </a>
            </div>
        </div>
        <button class="btn btn-danger btn-block mt-2 d-md-none d-block" @click="$deleteJsonProducto($index)">
            <i class="fas fa-trash-alt fa-lg"></i>
        </button>
    </div>
    <div v-if="productoId" class="col">
        <div class="row">
            <div class="col-md-4 col-12 mb-2">
                <div class="input-group">
                    <span class="input-group-text" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Cantidad</span>
                    <input v-if="!formPrice" v-model="cantidad" type="number" min="1" class="form-control" @blur="isNull">
                    <input v-else v-model="cantidad" type="number" class="form-control" readonly>
                </div>
            </div>
            <div class="col-md-4 col-12 mb-2">
                <div v-if="!formPrice" class="input-group">
                    <span class="input-group-text" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Precio</span>
                    <input type="number" class="form-control text-end" :value="getPrice" readonly>
                </div>
                <div v-else class="input-group">
                    <span class="input-group-text" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Nuevo precio</span>
                    <input v-if="load" v-model="newPrice" type="number" class="form-control" readonly>
                    <input v-else v-model="newPrice" type="number" class="form-control">
                </div>
            </div>
            <div class="col-md-4 col-12 mb-2">
                <div v-if="!formPrice" class="d-flex align-items-center">
                    <div class="dropdown">
                        <button class="btn btn-info btn-floating btn-sm me-2" data-mdb-toggle="dropdown">
                            <i class="fas fa-history fa-lg"></i>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li v-for="(item, index) in historyPrice" :key="index">
                                <a class="dropdown-item" href="#">{{ '$ ' + item.precio + ' > ' + getMomentDate(item.created_at) }}</a>
                            </li>
                        </ul>
                    </div>
                    <button class="btn btn-outline-primary btn-rounded btn-sm" @click="formPrice = true">
                        <i class="far fa-money-bill-alt fa-lg"></i>
                        Nuevo precio
                    </button>
                </div>
                <div v-else class="d-flex align-items-center">
                    <div class="form-check form-switch me-2">
                        <input class="form-check-input" v-model="newIva" type="checkbox" id="flexSwitchCheckDefault" />
                        <label class="form-check-label" for="flexSwitchCheckDefault">IVA incluido</label>
                    </div>
                    <button v-if="load" class="btn btn-secondary btn-rounded btn-block" type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Cargando...
                    </button>
                    <div v-else class="d-flex align-items-center">
                        <button class="btn btn-danger btn-floating me-2" @click.prevent="formPrice = false">
                            <i class="fas fa-ban fa-lg"></i>
                        </button>
                        <button class="btn btn-success btn-floating" @click.prevent="createNewPrice">
                            <i class="fas fa-save fa-lg"></i>
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <hr>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
import moment from 'moment'
export default {
    props: {
        $proveedorId: Number,
        $putJsonProducto: Function,
        $deleteJsonProducto: Function,
        $index: Number,
        $ids: Array,
        $name: String,
        $item: Object,
        $itemId: Number,
    },
    created() {
        this.darkmode();
        moment.locale('es-mx');
        this.debounceGetProducts = _.debounce(this.getProductos, 500);
    },
    watch: {
        producto() {
            this.load = true;
            this.loadData = false;
            this.debounceGetProducts();
        },
        cantidad() {
            this.$putJsonProducto(this.productoId, this.cantidad, this.price, this.iva, this.$index);
        },
        $itemId() {
            if (this.$itemId != 0) {
                this.productoId = this.$itemId;
                this.cantidad = this.$item.cantidad;
                this.getProducto();
            }
        }
    },
    data() {
        return {
            night: null,
            productos: [],
            producto: '',
            productoId: '',
            cantidad: 1,
            price: 0,
            iva: 0,
            historyPrice: [],
            loadData: false,
            load: true,
            newPrice: '',
            newIva: 0,
            formPrice: false,
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        getProductos() {
            $axios.get(this.$masterRoute + '/api/productos/create?proveedorId=' + this.$proveedorId + '&search=' + this.producto + '&ids=' + JSON.stringify(this.$ids), {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.productos = response.data.data;
                this.loadData = true;
                this.load = false;
            }).catch(error => {
                console.error(error);
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
            });
        },
        getProducto() {
            $axios.get(this.$masterRoute + '/api/productos/' + this.productoId, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.putProduct(response.data);
            }).catch(error => {
                console.error(error);
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
            });
        },
        putProduct(item) {
            this.producto = item.name;
            this.productoId = item.id;
            this.price = item.precio;
            this.iva = item.IVA;
            this.historyPrice = item.historyPrice;
            this.$putJsonProducto(this.productoId, this.cantidad, this.price, this.iva, this.$index);
        },
        createProducto() {
            this.$router.push({
                name: 'createProductoOrdCompras',
                params: {
                    p_proveedorId: this.$proveedorId,
                    p_reload: this.getProductos,
                }
            })
        },
        focusEvent(id) {
            $('.inputdropdown').hide();
            $('#' + id).show();
            this.getProductos();
        },
        createNewPrice() {
            if (!this.newPrice) {
                $swal.fire({
                    icon: 'warning',
                    title: 'alerta!!',
                    text: 'No has ingresado el precio para guardar',
                });
            } else {
                this.load = true;
                $axios.get(this.$masterRoute + '/api/productos/' + this.productoId + '/edit?data=' + JSON.stringify({
                    price: this.newPrice,
                    iva: this.newIva,
                }), {
                    headers: {
                        Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                    }
                }).then(response => {
                    if (response.data.status) {
                        $swal.fire({
                            icon: 'success',
                            title: response.data.title,
                            text: response.data.menssage,
                        });
                        this.getProducto();
                        this.newPrice = '';
                        this.newIva = 0;
                        this.formPrice = false;
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: response.data.title,
                            text: response.data.menssage,
                        });
                    }
                    this.load = false;
                }).catch(error => {
                    console.error(error);
                    if (error.response.status === 401) {
                        $swal.fire({
                            icon: 'error',
                            title: 'Sesión expirada',
                            text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                        }).then((result) => {
                            this.$router.push({
                                name: 'login'
                            });
                        });
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: 'Error Inesperado!!',
                            text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                        });
                    }
                    this.load = false;
                });
            }
        },
        getMomentDate(date) {
            return moment(date).calendar();
        },
        isNull() {
            if (!this.cantidad || this.cantidad < 1) {
                this.cantidad = 1;
            }
        },

    },
    computed: {
        getPrice() {
            if (this.iva === 1) {
                return (this.price / 1.16).toFixed(2);
            } else {
                return (this.price * 1).toFixed(2);
            }
        }
    }
}
</script>
