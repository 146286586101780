<template>
<div>
    <div class="animate__animated animate__fadeInDownBig">
        <div class="container-fluid px-2 pt-2 w3-card-4 position-sticky left-0 right-0" :class="night ? 'w3-dark-gray text-white' : 'w3-light-gray'" style="top: 60px;z-index:100;">
            <!--Titulo de la vista para dispositivos xl lg md-->
            <div class="d-lg-flex d-none justify-content-center">
                <div class="w3-medium me-auto ms-auto">
                    <i class="fas fa-question-circle fa-lg me-2"></i>
                    <strong>AYUDA Y SOPORTE</strong>
                </div>
            </div>
            <!--Titulo de la vista para dispositivos sm xs-->
            <div class="d-lg-none d-flex justify-content-center">
                <div class="w3-small me-auto ms-auto">
                    <i class="fas fa-question-circle fa-lg me-2"></i>
                    <strong>AYUDA Y SOPORTE</strong>
                </div>
            </div>
        </div>
        <div class="container-fluid pt-3">
            <!--<button class="btn btn-rounded btn-info mb-3">
                <i class="fas fa-info fa-lg me-2"></i>
                <span>Reportar un error o mal funcionamiento del sistema</span>
            </button>-->
            <h2 class="text-center fw-bold mb-3">Manual de uso de la aplicación</h2>
            <div class="row" >
                <div class="col-md-4 col-12 mb-3">
                    <div class="card p-3 overflow-auto" :style="'height:' + screenheightsize + 'px;'" :class="night ? 'bg-dark-gray' : ''">
                        <div class="accordion accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item mt-2 w3-card-4" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">
                                <h2 class="accordion-header" id="flush-headingOne">
                                    <button class="accordion-button collapsed fw-bold" :class="night ? 'text-white' : ''" type="button" data-mdb-toggle="collapse" data-mdb-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        Dispositivos
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-mdb-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <nav class="nav flex-column w3-medium mt-2">
                                            <a class="nav-link" @click.prevent="routerPush('prox')" style="cursor: pointer;">
                                                <i class="fas fa-book-open fa-lg me-1"></i>
                                                <span>Generales</span>
                                            </a>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item mt-2 w3-card-4" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">
                                <h2 class="accordion-header" id="flush-headingTwo">
                                    <button class="accordion-button collapsed fw-bold" :class="night ? 'text-white' : ''" type="button" data-mdb-toggle="collapse" data-mdb-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        Organizaciones
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-mdb-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <nav class="nav flex-column w3-medium mt-2">
                                            <a class="nav-link" @click.prevent="routerPush('prox')" style="cursor: pointer;">
                                                <i class="fas fa-book-open fa-lg me-1"></i>
                                                <span>Generales</span>
                                            </a>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item mt-2 w3-card-4" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">
                                <h2 class="accordion-header" id="flush-headingThree">
                                    <button class="accordion-button collapsed fw-bold" :class="night ? 'text-white' : ''" type="button" data-mdb-toggle="collapse" data-mdb-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                        Usuarios
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-mdb-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <nav class="nav flex-column w3-medium mt-2">
                                            <a class="nav-link" @click.prevent="routerPush('prox')" style="cursor: pointer;">
                                                <i class="fas fa-book-open fa-lg me-1"></i>
                                                <span>Generales</span>
                                            </a>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item mt-2 w3-card-4" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">
                                <h2 class="accordion-header" id="flush-headingFour">
                                    <button class="accordion-button collapsed fw-bold" :class="night ? 'text-white' : ''" type="button" data-mdb-toggle="collapse" data-mdb-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                        Módulo reportes
                                    </button>
                                </h2>
                                <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-mdb-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <nav class="nav flex-column w3-medium mt-2">
                                            <a class="nav-link" @click.prevent="routerPush('gralReports')" style="cursor: pointer;">
                                                <i class="fas fa-book-open fa-lg me-1"></i>
                                                <span>Generales</span>
                                            </a>
                                            <a class="nav-link" @click.prevent="routerPush('newTypeReport')" style="cursor: pointer;">
                                                <i class="fas fa-plus-square fa-lg me-1"></i>
                                                <span>Crear nuevo tipo de reportes</span>
                                            </a>
                                            <a class="nav-link" @click.prevent="routerPush('editTypeReportManual')" style="cursor: pointer;">
                                                <i class="fas fa-pen-square fa-lg me-1"></i>
                                                <span>Editar tipo de reportes</span>
                                            </a>
                                            <a class="nav-link" @click.prevent="routerPush('newReport')" style="cursor: pointer;">
                                                <i class="fas fa-plus-circle fa-lg me-1"></i>
                                                <span>Crear nuevos reportes</span>
                                            </a>
                                            <a class="nav-link" @click.prevent="routerPush('viewReport')" style="cursor: pointer;">
                                                <i class="fas fa-eye fa-lg me-1"></i>
                                                <span>Ver detalles de un reporte</span>
                                            </a>
                                            <a class="nav-link" @click.prevent="routerPush('startProcess')" style="cursor: pointer;">
                                                <i class="far fa-play-circle fa-lg me-1"></i>
                                                <span>Iniciar proceso de un reporte</span>
                                            </a>
                                            <a class="nav-link" @click.prevent="routerPush('finishProcess')" style="cursor: pointer;">
                                                <i class="fas fa-clipboard-check fa-lg me-1"></i>
                                                <span>Terminar proceso de un reporte</span>
                                            </a>
                                            <a class="nav-link" @click.prevent="routerPush('confirmProcess')" style="cursor: pointer;">
                                                <i class="fas fa-user-check fa-lg me-1"></i>
                                                <span>Confirmar terminación del reporte</span>
                                            </a>
                                            <a class="nav-link" @click.prevent="routerPush('commentsReports')" style="cursor: pointer;">
                                                <i class="fas fa-comment-alt fa-lg me-1"></i>
                                                <span>Agregar comentarios al reporte</span>
                                            </a>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md col-12 mb-3">
                    <div class="card p-3 overflow-auto" :style="'height:' + screenheightsize + 'px;'" :class="night ? 'bg-dark-gray' : ''">
                        <router-view></router-view>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    created() {
        this.nightMode();
        this.routerPush('presentacion');
    },
    data() {
        return {
            night: null,
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.nightMode();
        });
    },
    methods: {
        nightMode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        routerPush(name) {
            if (this.$router.history.current.name != name) {
                this.$router.push({
                    name: name,
                });
            }
        },
    },
    computed: {
        screenheightsize() {
            let num = 175;
            if (screen.width > 1500) {
                num = 275;
            }
            return screen.height - num;
        },
    }
}
</script>
