<template>
<div>
    <button v-if="permisos.create.status" class="btn btn-primary btn-sm btn-rounded d-md-block d-none mb-3" @click="create">
        <i class="fas fa-plus fa-lg me-2"></i>
        Nuevo administrador
    </button>
    <button v-if="permisos.create.status" class="btn btn-primary btn-floating d-md-none d-block mb-3" @click="create">
        <i class="fas fa-plus fa-lg me-2"></i>
    </button>
    <div class="table-responsive overflow-auto d-none d-md-block" :style="'height:' + screenheightsize + 'px;'">
        <table class="table table-hover table-sm align-middle" :class="night ? 'table-dark' : 'table-light'" :style="'width:' + screenwidthsize + 'px;'">
            <thead style="position: sticky;top: 0;" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">
                <tr class="text-center" :class="night ? 'border-dark' : 'border'" style="position: sticky;top: 0;">
                    <th class="fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'" style="position: sticky;left: 0;width: 10px;z-index: 1;">Opciones</th>
                    <th class="fw-bold" style="position: sticky;top: 0;">Organización</th>
                    <th class="fw-bold" style="position: sticky;top: 0;">Creación</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="load">
                    <td colspan="4" class="text-center">
                        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </td>
                </tr>
                <tr v-else-if="adminCom.length == 0">
                    <td colspan="4" class="text-center">
                        <i class="fas fa-ban fa-lg me-2"></i>
                        sin resultados
                    </td>
                </tr>
                <tr v-else class="text-center" v-for="(item, index) in adminCom" :key="index">
                    <td :class="night ? 'w3-dark-gray' : 'w3-light-gray'" style="position:sticky;left:0px;">
                        <button v-if="permisos.delete.status" class="btn btn-danger btn-sm" @click="destroy(item)">
                            <i class="fas fa-trash-alt fa-lg"></i>
                        </button>
                    </td>
                    <td>{{ item.orgId.name }}</td>
                    <td>{{ getMomentDate(item.created_at) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <ul class="list-group overflow-auto d-block d-md-none border-top border-5" :style="'height:' + screenheightsize + 'px;'">
        <li v-for="(item, index) in adminCom" :key="index" class="list-group-item">
            <div class="row mb-3">
                <div class="col-6">
                    <strong>#</strong>
                    <small>{{ index + 1 }}</small>
                </div>
                <div class="col-6 text-end">
                    <button v-if="permisos.delete.status" type="button" class="btn btn-danger" @click="destroy(item)">
                        <i class="fas fa-trash-alt fa-lg"></i>
                    </button>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12 text-center">
                    <strong>Nombre</strong>
                    <br>
                    <small>{{ item.orgId.name }}</small>
                </div>
                <div class="col-12 text-center">
                    <strong>Creación</strong>
                    <br>
                    <small>{{ getMomentDate(item.created_at) }}</small>
                </div>
            </div>
        </li>
    </ul>
    <router-view></router-view>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
import moment from 'moment'
export default {
    props: {
        p_permisos: Object,
    },
    created() {
        this.darkmode();
        this.permisos = this.p_permisos;
        moment.locale('es-mx');
        this.changeSize();
        this.getAdminCompras();
    },
    data() {
        return {
            night: null,
            adminCom: [],
            permisos: {},
            load: false,
            screenwidthsize: null,
            screenheightsize: null,
        }
    },
    mounted() {
        window.addEventListener("resize", this.changeSize);
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        getAdminCompras() {
            this.load = true;
            $axios.get(this.$masterRoute + '/api/admincompras', {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.adminCom = response.data;
                this.load = false;
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });
        },
        create() {
            this.$router.push({
                name: 'createAdminCompras',
                params: {
                    p_reload: this.getAdminCompras,
                }
            });
        },
        destroy(item) {
            $swal.fire({
                title: '¿Estás seguro?',
                text: "Estás a punto de eliminar un administrador de compra!!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#03cc9a',
                cancelButtonColor: '#ED1848',
                confirmButtonText: 'Si, eliminarlo!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.load = true;
                    $axios.delete(this.$masterRoute + '/api/admincompras/' + item.id, {
                        headers: {
                            Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                        }
                    }).then(response => {
                        if (response.data.status) {
                            $swal.fire({
                                icon: 'success',
                                title: response.data.title,
                                text: response.data.menssage,
                            });
                            this.getAdminCompras();
                        } else {
                            $swal.fire({
                                icon: 'error',
                                title: response.data.title,
                                text: response.data.menssage,
                            });
                        }
                        this.load = false;
                    }).catch(error => {
                        console.error(error);
                        if (error.response.status === 401) {
                            $swal.fire({
                                icon: 'error',
                                title: 'Sesión expirada',
                                text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                            }).then((result) => {
                                this.$router.push({
                                    name: 'login'
                                });
                            });
                        } else {
                            $swal.fire({
                                icon: 'error',
                                title: 'Error Inesperado!!',
                                text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                            });
                        }
                        this.load = false;
                    });
                }
            });
        },
        getMomentDate(date) {
            return moment(date).format('LLLL');
        },
        changeSize() {
            if (window.innerWidth < 975) {
                this.screenwidthsize = 975;
            } else {
                this.screenwidthsize = window.innerWidth - 60;
            }
            this.screenheightsize = window.innerHeight - 300;
        },
    }
}
</script>
