<template>
<div class="w3-modal d-block">
    <div class="w3-modal-content w3-animate-zoom card border my-3" :class="night ? 'bg-dark-gray' : ''">
        <header class="card-header" :class="night ? 'w3-dark-gray' : ''">
            <button @click.prevent="cancel" class="btn btn-danger btn-sm px-2 float-end">
                <i class="fas fa-times"></i>
            </button>
            <div class="d-xl-flex d-lg-flex d-md-flex d-none align-items-center w3-large">
                <i class="fas fa-pen-square fa-2x text-warning me-auto"></i>
                <strong class="card-title me-auto">Editando proveedor</strong>
            </div>
            <div class="d-xl-none d-lg-none d-md-none d-flex align-items-center w3-medium">
                <i class="fas fa-pen-square fa-2x text-warning me-auto"></i>
                <strong class="card-title me-auto">Editando proveedor</strong>
            </div>
        </header>
        <div class="card-body">
            <div class="input-group mb-3 d-none d-lg-inline-flex">
                <span class="input-group-text w-25 fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Nombre Comercial:</span>
                <input v-model="comercialname" type="text" class="form-control" :class="!comercialname && !razonsocial ? 'is-invalid' : ''">
            </div>
            <div class="mb-3 d-block d-lg-none">
                <label class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Nombre Comercial:</label>
                <input v-model="comercialname" type="text" class="form-control" :class="!comercialname && !razonsocial ? 'is-invalid' : ''">
            </div>
            <div class="input-group mb-3 d-none d-lg-inline-flex">
                <span class="input-group-text w-25 fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Razón social:</span>
                <input v-model="razonsocial" type="text" class="form-control" :class="!comercialname && !razonsocial ? 'is-invalid' : ''">
            </div>
            <div class="mb-3 d-block d-lg-none">
                <label class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Razón social:</label>
                <input v-model="razonsocial" type="text" class="form-control" :class="!comercialname && !razonsocial ? 'is-invalid' : ''">
            </div>
            <div class="input-group mb-3 d-none d-lg-inline-flex">
                <span class="input-group-text w-25 fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Correo electrónico:</span>
                <input v-model="email" type="text" class="form-control">
            </div>
            <div class="mb-3 d-block d-lg-none">
                <label class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Correo electrónico:</label>
                <input v-model="email" type="text" class="form-control">
            </div>
            <div class="input-group mb-3 d-none d-lg-inline-flex">
                <span class="input-group-text w-25 fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Dirección:</span>
                <input v-model="address" type="text" class="form-control">
            </div>
            <div class="mb-3 d-block d-lg-none">
                <label class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Dirección:</label>
                <input v-model="address" type="text" class="form-control">
            </div>
            <div class="input-group mb-3 d-none d-lg-inline-flex">
                <span class="input-group-text w-25 fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Teléfono:</span>
                <input v-model="phone" type="text" class="form-control" maxlength="10" :class="!phone || phone.length != 10 ? 'is-invalid' : 'is-valid'">
            </div>
            <div class="mb-3 d-block d-lg-none">
                <label class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Teléfono:</label>
                <input v-model="phone" type="text" class="form-control" maxlength="10" :class="!phone || phone.length != 10 ? 'is-invalid' : 'is-valid'">
            </div>
            <div class="input-group mb-3 d-flex justify-content-center">
                <div class="form-check">
                    <input class="form-check-input" v-model="factura" type="checkbox" id="flexCheckDefault" />
                    <label class="form-check-label fw-bold" for="flexCheckDefault">
                        Realiza facturas?
                    </label>
                </div>
            </div>
            <div class="input-group mb-3 d-none d-lg-inline-flex">
                <span class="input-group-text w-25 fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Forma de pago:</span>
                <select v-model="paytype" class="form-select">
                    <option :value="false">Tarjeta o efectivo</option>
                    <option :value="true">Trasferencia o cheque</option>
                </select>
            </div>
            <div class="mb-3 d-block d-lg-none">
                <label class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Forma de pago:</label>
                <select v-model="paytype" class="form-select">
                    <option :value="false">Tarjeta o efectivo</option>
                    <option :value="true">Trasferencia o cheque</option>
                </select>
            </div>
            <div v-if="paytype">
                <div class="input-group mb-3 d-none d-lg-inline-flex">
                    <span class="input-group-text w-25 fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">No. cuenta:</span>
                    <input v-model="bankData.noCuenta" type="number" class="form-control" maxlength="10" :class="!bankData.noCuenta ? 'is-invalid' : 'is-valid'">
                </div>
                <div class="mb-3 d-block d-lg-none">
                    <label class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">No. cuenta:</label>
                    <input v-model="bankData.noCuenta" type="number" class="form-control" maxlength="10" :class="!bankData.noCuenta ? 'is-invalid' : 'is-valid'">
                </div>
                <div class="input-group mb-3 d-none d-lg-inline-flex">
                    <span class="input-group-text w-25 fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Banco:</span>
                    <input v-model="bankData.bank" type="text" class="form-control" :class="!bankData.bank ? 'is-invalid' : 'is-valid'">
                </div>
                <div class="mb-3 d-block d-lg-none">
                    <label class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Banco:</label>
                    <input v-model="bankData.bank" type="text" class="form-control" :class="!bankData.bank ? 'is-invalid' : 'is-valid'">
                </div>
                <div class="input-group mb-3 d-none d-lg-inline-flex">
                    <span class="input-group-text w-25 fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Beneficiario:</span>
                    <input v-model="bankData.beneficiario" type="text" class="form-control" :class="!bankData.beneficiario ? 'is-invalid' : 'is-valid'">
                </div>
                <div class="mb-3 d-block d-lg-none">
                    <label class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Beneficiario:</label>
                    <input v-model="bankData.beneficiario" type="text" class="form-control" :class="!bankData.beneficiario ? 'is-invalid' : 'is-valid'">
                </div>
                <div class="input-group mb-3 d-none d-lg-inline-flex">
                    <span class="input-group-text w-25 fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">CLABE interbancaria:</span>
                    <input v-model="bankData.CLABE" type="number" class="form-control" maxlength="18" :class="!bankData.CLABE ? 'is-invalid' : 'is-valid'">
                </div>
                <div class="mb-3 d-block d-lg-none">
                    <label class="input-group-text fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">CLABE interbancaria:</label>
                    <input v-model="bankData.CLABE" type="number" class="form-control" maxlength="18" :class="!bankData.CLABE ? 'is-invalid' : 'is-valid'">
                </div>
            </div>
            <div class="d-flex mb-3">
                <button class="btn btn-info btn-sm btn-rounded btn-block w-50 mx-auto" @click.prevent="addInfo">
                    <i class="fas fa-info-circle fa-lg me-2"></i>
                    <span>Agregar más información</span>
                </button>
            </div>
            <compras-proveedor-extradata v-for="(item, index) in extradata" :key="index" :$index="index" :$item="item" :$id="item.id" :$delInfo="delInfo" :$create="createRegister"></compras-proveedor-extradata>
        </div>
        <footer class="card-footer" :class="night ? 'w3-dark-gray' : ''">
            <div v-if="load">
                <button class="btn btn-secondary btn-rounded btn-block" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Cargando...
                </button>
            </div>
            <div v-else class="row">
                <div class="col">
                    <button class="btn btn-danger btn-sm btn-rounded btn-block" @click.prevent="cancel">
                        <i class="fas fa-ban"></i>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="col">
                    <button class="btn btn-success btn-sm btn-rounded btn-block" :disabled="false" @click.prevent="save">
                        <i class="fas fa-save"></i>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </footer>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        p_item: Object,
        p_reload: Function,
    },
    created() {
        this.darkmode();
        this.comercialname = this.p_item.comercialname;
        this.razonsocial = this.p_item.razonsocial;
        this.email = this.p_item.email;
        this.address = this.p_item.address;
        this.phone = this.p_item.phone;
        if (this.p_item.factura === 1) {
            this.factura = true;
        } else {
            this.factura = false;
        }
        if (this.p_item.payType === 1) {
            this.paytype = true;
        } else {
            this.paytype = false;
        }
        this.bankData = JSON.parse(this.p_item.bankData);
        this.extradata = JSON.parse(this.p_item.extradata);
    },
    watch: {
        paytype() {
            if (!this.paytype) {
                this.bankData = {
                    noCuenta: '',
                    bank: '',
                    beneficiario: '',
                    CLABE: '',
                };
            }
        },
    },
    data() {
        return {
            night: null,
            load: false,
            comercialname: '',
            razonsocial: '',
            email: '',
            address: '',
            phone: '',
            factura: false,
            paytype: false,
            bankData: {
                noCuenta: '',
                bank: '',
                beneficiario: '',
                CLABE: '',
            },
            extradata: [],
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        save() {
            let error = this.validate();
            if (!error) {
                this.load = true;
                $axios.put(this.$masterRoute + '/api/proveedores/' + this.p_item.id, {
                    comercialname: this.comercialname,
                    razonsocial: this.razonsocial,
                    email: this.email,
                    address: this.address,
                    phone: this.phone,
                    factura: this.factura,
                    paytype: this.paytype,
                    bankdata: JSON.stringify(this.bankData),
                    extradata: JSON.stringify(this.extradata),
                }, {
                    headers: {
                        Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                    }
                }).then(response => {
                    if (response.data.status) {
                        $swal.fire({
                            icon: 'success',
                            title: response.data.title,
                            text: response.data.menssage,
                        });
                        this.p_reload();
                        this.cancel();
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: response.data.title,
                            text: response.data.menssage,
                        });
                    }
                    this.load = false;
                }).catch(error => {
                    console.error(error);
                    if (error.response.status === 401) {
                        $swal.fire({
                            icon: 'error',
                            title: 'Sesión expirada',
                            text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                        }).then((result) => {
                            this.$router.push({
                                name: 'login'
                            });
                        });
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: 'Error Inesperado!!',
                            text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                        });
                    }
                });
            } else {
                $swal.fire({
                    icon: 'error',
                    title: 'Formulario invalido!',
                    text: error,
                })
                this.load = false;
            }
        },
        validate() {
            if (!this.comercialname && !this.razonsocial) {
                return 'Es necesario ingresar el nombre comercial o la razón social, no pueden quedar vacios';
            }
            if (!this.phone) {
                return 'El teléfono es obligatorio';
            }
            if (this.phone.length < 10) {
                return 'El número de teléfono no es valido debe tener por lo menos 10 digitos';
            }
            if (this.extradata.length > 0) {
                if (this.checkExtradata()) {
                    return 'Tienes valores vacios en la información adicional';
                }
            }
            if (this.paytype) {
                if (!this.bankData.noCuenta) {
                    return 'El No. de cuenta es obligatorio para el tipo de pago seleccionado';
                }
                if (!this.bankData.bank) {
                    return 'El Nombre del banco es obligatorio para el tipo de pago seleccionado';
                }
                if (!this.bankData.beneficiario) {
                    return 'El Nombre del beneficiario es obligatorio para el tipo de pago seleccionado';
                }
                if (!this.bankData.CLABE) {
                    return 'La CLABE es obligatorio para el tipo de pago seleccionado';
                }
            }
            return '';
        },
        checkExtradata() {
            let isTrue = false;
            this.extradata.forEach(item => {
                Object.values(item).forEach(val => {
                    if (!val) {
                        isTrue = true;
                    }
                });
            });
            return isTrue;
        },
        addInfo() {
            this.extradata.push({
                id: Math.random().toString(36).slice(-8),
                title: '',
                text: '',
            });
        },
        delInfo(index) {
            this.extradata.splice(index, 1);
        },
        createRegister(index, title, text) {
            this.extradata[index].title = title;
            this.extradata[index].text = text;
        },
        cancel() {
            this.$router.go(-1);
        }
    }
}
</script>
