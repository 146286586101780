<template>
<div>
    <div class="row mb-3">
        <div class="col">
            <button v-if="permisos.create.status" class="btn btn-primary btn-sm btn-rounded d-none d-md-block" @click="create">
                <i class="fas fa-plus fa-lg me-2"></i>
                Nuevo limitante
            </button>
            <button v-if="permisos.create.status" class="btn btn-primary btn-floating d-md-none d-block" @click="create">
                <i class="fas fa-plus fa-lg me-2"></i>
            </button>
        </div>
        <div class="col-7 col-md-4">
            <div class="input-group input-group-sm rounded ms-auto">
                <input type="search" v-model="search" class="form-control rounded" placeholder="Buscar" aria-label="Search" aria-describedby="search-addon" />
                <span class="input-group-text border-0" id="search-addon">
                    <i class="fas fa-search" :class="night ? 'text-white' : ''"></i>
                </span>
            </div>
        </div>
    </div>
    <div class="d-xl-flex d-lg-flex d-md-flex align-items-center mb-3">
        <div class="input-group input-group-sm rounded me-2 d-none d-md-inline-flex" style="width: 175px;">
            <span class="input-group-text border-0" :class="night ? 'text-white' : ''">Filas por página: </span>
            <select v-model="itemperpage" class="form-select form-select-sm rounded">
                <option value="10" selected>10</option>
                <option value="25">25</option>
                <option value="50">50</option>
            </select>
        </div>
        <div class="me-auto ms-2 mb-xl-0 mb-lg-0 mb-2 d-none d-md-inline-flex">
            <small>Mostrando {{ pagination.from }}-{{ pagination.to }} de {{ pagination.total }}</small>
        </div>
        <div class="ms-auto mb-xl-0 mb-lg-0 mb-2">
            <nav class="d-flex">
                <ul class="pagination pagination-circle pagination-sm mb-0 ms-auto me-auto">
                    <li class="page-item" v-if="pagination.current_page > 1" @click.prevent="changePageActivosData(pagination.current_page - 1)">
                        <a class="page-link" href="#" :class="night ? 'w3-text-white w3-hover-white' : ''" tabindex="-1" aria-disabled="true">Anterior</a>
                    </li>
                    <li class="page-item" style="z-index: 1;" v-for="(page, index) in pagesNumberActivosData" :key="index" :class="[page == isActivedActivosData ? 'active' : '']" @click.prevent="changePageActivosData(page)">
                        <a class="page-link" :class="night && page != isActivedActivosData ? 'w3-text-white w3-hover-white' : ''" href="#">{{ page }}</a>
                    </li>
                    <li class="page-item" v-if="pagination.current_page < pagination.last_page" @click.prevent="changePageActivosData(pagination.current_page + 1)">
                        <a class="page-link" :class="night ? 'w3-text-white w3-hover-white' : ''" href="#">Siguiente</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <div class="table-responsive overflow-auto d-none d-md-block" :style="'height:' + screenheightsize + 'px;'">
        <table class="table table-hover table-sm align-middle" :class="night ? 'table-dark' : 'table-light'" :style="'width:' + screenwidthsize + 'px;'">
            <thead style="position: sticky;top: 0;" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">
                <tr class="text-center" :class="night ? 'border-dark' : 'border'" style="position: sticky;top: 0;">
                    <th class="fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'" style="position: sticky;left: 0;width: 10px;z-index: 1;">
                        <label class="mb-1">Opciones</label>
                        <button v-if="filters" class="btn btn-outline-black btn-rounded btn-sm" :class="night ? 'w3-white' : ''" @click="cleanFilters">
                            <i class="fas fa-eraser fa-lg"></i>
                        </button>
                    </th>
                    <th class="fw-bold" style="position: sticky;top: 0;">
                        <label class="mb-1">Departamento</label>
                        <input v-model="depto" type="search" class="form-control form-control-sm" placeholder="Buscar">
                    </th>
                    <th class="fw-bold" style="position: sticky;top: 0;">
                        <label class="mb-1">Autoriza</label>
                        <input v-model="auth" type="search" class="form-control form-control-sm" placeholder="Buscar">
                    </th>
                    <th class="fw-bold" style="position: sticky;top: 0;">
                        <label class="mb-1">Mínimo</label>
                        <input v-model="min" type="search" class="form-control form-control-sm" placeholder="Buscar">
                    </th>
                    <th class="fw-bold" style="position: sticky;top: 0;">
                        <label class="mb-1">Máximo</label>
                        <input v-model="max" type="search" class="form-control form-control-sm" placeholder="Buscar">
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="load">
                    <td colspan="6" class="text-center">
                        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </td>
                </tr>
                <tr v-else class="text-center" v-for="(item, index) in limitantes" :key="index">
                    <td :class="night ? 'w3-dark-gray' : 'w3-light-gray'" style="position:sticky;left:0px;">
                        <div class="btn-group btn-group-sm" role="group">
                            <button v-if="permisos.edit.status" type="button" class="btn btn-warning" @click="edit(item)">
                                <i class="fas fa-pencil-alt fa-lg"></i>
                            </button>
                            <button v-if="permisos.delete.status" type="button" class="btn btn-danger" @click="destroy(item)">
                                <i class="fas fa-trash-alt fa-lg"></i>
                            </button>
                        </div>
                    </td>
                    <td>{{ putOrgs(item.orgs) }}</td>
                    <td>{{ item.userAuth.name }}</td>
                    <td>{{ item.cantMin }}</td>
                    <td>{{ item.cantMax }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <ul class="list-group overflow-auto d-block d-md-none border-top border-5" :style="'height:' + screenheightsize + 'px;'">
        <li v-if="load" class="list-group-item text-center" :class="night ? 'w3-dark-gray' : 'w3-white'">
            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </li>
        <li v-else v-for="(item, index) in limitantes" :key="index" class="list-group-item" :class="night ? 'w3-dark-gray' : 'w3-white'">
            <div class="row mb-3">
                <div class="col-6">
                    <strong>#</strong>
                    <small>{{ index + 1 }}</small>
                </div>
                <div class="col-6 text-end">
                    <div class="btn-group btn-group-sm" role="group">
                        <button v-if="permisos.edit.status" type="button" class="btn btn-warning" @click="edit(item)">
                            <i class="fas fa-pencil-alt fa-lg"></i>
                        </button>
                        <button v-if="permisos.delete.status" type="button" class="btn btn-danger" @click="destroy(item)">
                            <i class="fas fa-trash-alt fa-lg"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-6 text-center">
                    <strong>Departamento</strong>
                    <br>
                    <small></small>
                </div>
                <div class="col-6 text-center">
                    <strong>Autoriza</strong>
                    <br>
                    <small>{{ item.userAuth.name }}</small>
                </div>
            </div>
            <div class="row">
                <div class="col-6 text-center">
                    <strong>Mínimo</strong>
                    <br>
                    <small>{{ item.cantMin }}</small>
                </div>
                <div class="col-6 text-center">
                    <strong>Máximo</strong>
                    <br>
                    <small>{{ item.cantMax }}</small>
                </div>
            </div>
        </li>
    </ul>
    <router-view></router-view>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        p_permisos: Object,
    },
    created() {
        this.darkmode();
        this.permisos = this.p_permisos;
        this.changeSize();
        this.getLimitantes();
        this.debounceGetLimitantes = _.debounce(this.getLimitantes, 500);
    },
    watch: {
        depto() {
            this.debounceGetLimitantes();
        },
        auth() {
            this.debounceGetLimitantes();
        },
        min() {
            this.debounceGetLimitantes();
        },
        max() {
            this.debounceGetLimitantes();
        },
        search() {
            this.debounceGetLimitantes();
        }
    },
    data() {
        return {
            night: null,
            limitantes: [],
            pagination: {},
            permisos: {},
            search: '',
            depto: '',
            auth: '',
            min: '',
            max: '',
            itemperpage: 10,
            load: false,
            offset: 6,
            screenwidthsize: null,
            screenheightsize: null,
        }
    },
    mounted() {
        window.addEventListener("resize", this.changeSize);
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        getLimitantes() {
            this.load = true;
            let searchData = {
                depto: this.depto,
                auth: this.auth,
                min: this.min,
                max: this.max,
                itemperpage: this.itemperpage,
                search: this.search,
            }
            $axios.get(this.$masterRoute + '/api/limitantecompras?searchData=' + JSON.stringify(searchData), {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.limitantes = response.data.lim.data;
                this.pagination = response.data.pagination;
                this.load = false;
            }).catch(error => {
                console.error(error);
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                this.load = false;
            });
        },
        create() {
            this.$router.push({
                name: 'createLimitanteCompras',
                params: {
                    p_reload: this.getLimitantes,
                }
            });
        },
        edit(item) {
            this.$router.push({
                name: 'editLimitanteCompra',
                params: {
                    p_item: item,
                    p_reload: this.getLimitantes,
                }
            });
        },
        destroy(item) {
            $swal.fire({
                title: '¿Estás seguro?',
                text: "Estás a punto de eliminar una limitante de compra!!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#03cc9a',
                cancelButtonColor: '#ED1848',
                confirmButtonText: 'Si, eliminarlo!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.load = true;
                    $axios.delete(this.$masterRoute + '/api/limitantecompras/' + item.id, {
                        headers: {
                            Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                        }
                    }).then(response => {
                        if (response.data.status) {
                            $swal.fire({
                                icon: 'success',
                                title: response.data.title,
                                text: response.data.menssage,
                            });
                            this.getLimitantes();
                        } else {
                            $swal.fire({
                                icon: 'error',
                                title: response.data.title,
                                text: response.data.menssage,
                            });
                        }
                        this.load = false;
                    }).catch(error => {
                        console.error(error);
                        if (error.response.status === 401) {
                            $swal.fire({
                                icon: 'error',
                                title: 'Sesión expirada',
                                text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                            }).then((result) => {
                                this.$router.push({
                                    name: 'login'
                                });
                            });
                        } else {
                            $swal.fire({
                                icon: 'error',
                                title: 'Error Inesperado!!',
                                text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                            });
                        }
                        this.load = false;
                    });
                }
            });
        },
        putOrgs(items) {
            let orgs = '';
            items.forEach(element => {
                orgs = orgs + element.name + ', ';
            });
            return orgs;
        },
        cleanFilters() {
            this.depto = '';
            this.auth = '';
            this.min = '';
            this.max = '';
        },
        changeSize() {
            if (window.innerWidth < 1336) {
                this.screenwidthsize = 1336;
            } else {
                this.screenwidthsize = window.innerWidth - 60;
            }
            this.screenheightsize = window.innerHeight - 345;
        },
        changePageActivosData(page) {
            this.load = true;
            this.pagination.current_page = page;
            this.getLimitantes(page);
        },
    },
    computed: {
        isActivedActivosData: function () {
            return this.pagination.current_page;
        },
        pagesNumberActivosData: function () {
            if (!this.pagination.to) {
                return [];
            }

            var from = this.pagination.current_page - (this.offset / 2);
            if (from < 1) {
                from = 1;
            }

            var to = from + (this.offset);
            if (to >= this.pagination.last_page) {
                to = this.pagination.last_page;
            }

            var pagesArray = [];
            while (from <= to) {
                pagesArray.push(from);
                from++;
            }
            return pagesArray;
        },
        filters() {
            if (!this.depto && !this.auth && !this.min && !this.max) {
                return false;
            }
            return true;
        }
    }
}
</script>
