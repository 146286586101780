<template>
<div class="w3-modal d-block">
    <div class="w3-modal-content w3-animate-zoom card border mt-3">
        <header class="card-header">
            <button @click.prevent="cancel" class="btn btn-danger btn-sm px-2 float-end">
                <i class="fas fa-times"></i>
            </button>
            <div class="d-xl-flex d-lg-flex d-md-flex d-none align-items-center w3-large">
                <i class="fas fa-plus-square fa-2x text-primary me-2"></i>
                <span class="me-2">Creando nuevo elemento en la estructura</span>
            </div>
            <div class="d-xl-none d-lg-none d-md-none d-flex align-items-center w3-medium">
                <i class="fas fa-plus-square fa-2x text-primary me-2"></i>
                <span class="me-2">Creando nuevo elemento en la estructura</span>
            </div>
        </header>
        <div class="card-body">
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 mb-1">
                    <div class="mb-3">
                        <div class="input-group">
                            <span class="input-group-text w3-light-gray">
                                <i class="fas fa-key fa-lg"></i>
                            </span>
                            <input type="text" v-model="structure.clave" class="form-control" placeholder="Clave" maxlength="3" :disabled="load" :class="!errors.clave ? 'is-valid': 'is-invalid'">
                            <div class="invalid-feedback ms-2">{{ errors.clave }}</div>
                        </div>
                        <p class="float-end pe-2">{{ structure.clave.length }}/3</p>
                    </div>

                </div>
                <div class="col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12 mb-1">
                    <div class="mb-3">
                        <div class="input-group">
                            <span class="input-group-text w3-light-gray">
                                <i class="fas fa-edit fa-lg"></i>
                            </span>
                            <input type="text" v-model="structure.name" class="form-control" placeholder="Nombre" maxlength="100" :disabled="load" :class="!errors.name ? 'is-valid': 'is-invalid'">
                            <div class="invalid-feedback ms-2">{{ errors.name }}</div>
                        </div>
                        <p class="float-end pe-2">{{ structure.name.length }}/100</p>
                    </div>
                </div>
            </div>

        </div>
        <footer class="card-footer">
            <div v-if="load">
                <button class="btn btn-secondary btn-rounded btn-block" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Cargando...
                </button>
            </div>
            <div v-else class="row px-0">
                <div class="col-6">
                    <button class="btn btn-danger btn-rounded btn-sm btn-block" @click.prevent="cancel">
                        <i class="fas fa-ban fa-lg"></i>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="col-6">
                    <button class="btn btn-success btn-rounded btn-sm btn-block" :disabled="isDisableSave" @click.prevent="saveElement">
                        <i class="fas fa-save fa-lg"></i>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </footer>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        _parentId: Number,
        _getElem: Function,
    },
    data() {
        return {
            structure: {
                clave: "",
                name: "",
            },
            errors: {
                clave: '',
                name: '',
            },
            load: false,
        }
    },
    methods: {
        saveElement: function () {
            this.load = true;
            $axios.post($('#masterRoute').val() + '/api/structures', {
                'clave': this.structure.clave,
                'name': this.structure.name,
                'parentId': this._parentId,
            }, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                if (response.data.status) {
                    $swal.fire(
                        'Acción exitosa!',
                        response.data.menssage,
                        'success'
                    )
                    this.cancel();
                    this._getElem();
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error!!',
                        text: response.data.menssage,
                    });
                    this.load = false;
                }
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Clave de seguridad expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });
        },
        cancel() {
            this.$router.go(-1);
        }
    },
    computed: {
        isDisableSave() {
            this.errors = {
                clave: '',
                name: '',
            };
            if (this.structure.clave.length < 3) {
                this.errors.clave = 'El campo clave debe tener 3 caracteres';
            }
            if (this.structure.name.length < 5) {
                this.errors.name = 'El campo nombre debe tener por lo menos 5 letras';
            }
            if (!this.structure.clave) {
                this.errors.clave = 'El campo clave se encuentra vacio';
            }
            if (!this.structure.name) {
                this.errors.name = 'El campo nombre se encuentra vacio';
            }
            if (!this.errors.name && !this.errors.clave) {
                return false;
            }
            return true
        }
    },
}
</script>
