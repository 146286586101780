<template>
<div class="card mb-3">
    <header class="card-header" :class="$night ? 'w3-dark-gray' : ''">
        <div class="d-flex align-items-center">
            <i class="fas fa-lg me-2" :class="$datos.status ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'"></i>
            <strong>{{ $datos.name }}</strong>
        </div>
    </header>
    <div v-if="$datos.children" class="card-body" :class="[$datos.status ? '' : 'd-none', $night ? 'bg-dark-gray' : 'w3-light-gray']">
        <view-permisos v-for="(item, index) in Object.keys($datos.children)" :key="index" :$datos="$datos.children[item]" :$night="$night"></view-permisos>
    </div>
    <div v-else-if="$datos.permisos" class="card-body" :class="[$datos.status ? '' : 'd-none', $night ? 'w3-dark-gray' : '']">
        <div class="row">
            <div v-for="(item, index) in Object.keys($datos.permisos)" :key="index" class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-2">
                <div class="d-flex align-items-center">
                    <i class="fas fa-lg me-2" :class="$datos.permisos[item].status ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'"></i>
                    <small>{{ $datos.permisos[item].name }}</small>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        $datos: Object,
        $night: Boolean,
    }
}
</script>
