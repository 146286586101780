<template>
<th scope="col" class="p-1 w3-light-gray">
    <div class="d-flex">
        <strong class="me-auto">{{ $head.name }}</strong>
        <i v-show="sortItems.sort" class="fas fa-sort mt-auto" @click.prevent="sortFind($head.name, 1)"></i>
        <i v-show="sortItems.sortDown" class="fas fa-sort-down mt-auto" @click.prevent="sortFind($head.name, 2)"></i>
        <i v-show="sortItems.sortUp" class="fas fa-sort-up mt-auto" @click.prevent="sortFind($head.name, 0)"></i>
    </div>
</th>
</template>

<script>
export default {
    props: {
        $head: Object,
        //$filter: Function,
        //$cleanSort: String,
    },
    watch: {
        /*$cleanSort() {
            if (this.$head.name != this.$cleanSort) {
                this.sortItems = {
                    sortUp: false,
                    sortDown: false,
                    sort: true,
                };
            }
        }*/
    },
    data() {
        return {
            sortItems: {
                sortUp: false,
                sortDown: false,
                sort: true,
            }
        }
    },
    methods: {
        sortFind(name, type) {
            if (type == 0) {
               // this.$filter('', '');
                this.sortItems = {
                    sortUp: false,
                    sortDown: false,
                    sort: true,
                };
            } else if (type == 1) {
               // this.$filter(name, 'DESC');
                this.sortItems = {
                    sortUp: false,
                    sortDown: true,
                    sort: false,
                };
            } else if (type == 2) {
              //  this.$filter(name, 'ASC');
                this.sortItems = {
                    sortUp: true,
                    sortDown: false,
                    sort: false,
                };
            }
        }
    },
}
</script>
