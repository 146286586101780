<template>
<div>
    <div class="animate__animated animate__fadeInDownBig">
        <div class="container-fluid px-2 pt-2 w3-card-4 position-sticky left-0 right-0" :class="night ? 'w3-dark-gray text-white' : 'w3-light-gray'" style="top: 60px;z-index:100;">
            <!--Titulo de la vista para dispositivos xl lg md-->
            <div class="d-lg-flex d-none justify-content-center">
                <div class="w3-medium me-auto ms-auto">
                    <i class="fas fa-users fa-lg me-2"></i>
                    <strong>USUARIOS</strong>
                </div>
            </div>
            <!--Titulo de la vista para dispositivos sm xs-->
            <div class="d-lg-none d-flex justify-content-center">
                <div class="w3-small me-auto ms-auto">
                    <i class="fas fa-users fa-lg me-2"></i>
                    <strong>USUARIOS</strong>
                </div>
            </div>
        </div>
        <!-- Contenido -->
        <div class="container-fluid pt-3">
            <div class="row" :style="'height:' + screenheightsize + 'px;'">
                <!--Vista para roles-->
                <div v-if="permisos.rol.status" class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
                    <div class="card h-100" :class="night ? 'bg-dark-gray text-white-50' : ''">
                        <div class="card-header" :class="night ? 'w3-dark-gray' : ''">
                            <div class="row">
                                <!--Modulo titulo rol-->
                                <div class="col-xl-5 col-lg-5 col-12 mb-3">
                                    <button v-if="permisos.rol.permisos.create.status" type="button" class="btn btn-primary btn-floating me-2" @click.prevent="createRol">
                                        <i class="fas fa-plus fa-lg"></i>
                                    </button>
                                    <span class="w3-medium">
                                        <i class="fas fa-male fa-lg"></i> Roles
                                    </span>
                                    <span class="w3-tiny d-xl-none d-lg-none d-md-none d-none">
                                        <i class="fas fa-male fa-lg"></i> Roles
                                    </span>
                                </div>
                                <!--Modulo buscar role-->
                                <div class="col-xl-7 col-lg-7 col-12 mb-3">
                                    <div class="input-group rounded">
                                        <input type="search" v-model="searchRol" class="form-control form-control-sm rounded" placeholder="Buscar" />
                                        <span class="input-group-text border-0">
                                            <i class="fas fa-search" :class="night ? 'text-white-50' : ''"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body" style="overflow:auto;">
                            <div v-if="load" class="text-center">
                                <div class="spinner-border text-secondary" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            <div v-else>
                                <ul v-if="rolesData.length" class="list-group">
                                    <li v-for="(rol, index) in rolesData" :key="index" type="button" @click.prevent="selectedRol(rol)" class="list-group-item" :class="[rol === selected ? 'bg-secondary text-white' : '', night ? 'w3-dark-gray' : '']">
                                        <div class="d-flex mb-2">
                                            <div class="me-auto">
                                                <strong class="me-2">{{ rol.name }}</strong>
                                                <span class="badge bg-info">{{ rol.counter }} usuarios</span>
                                            </div>
                                            <div class="ms-auto">
                                                <button v-if="permisos.rol.permisos.edit.status" type="button" @click.prevent="editRol(rol)" class="btn btn-warning btn-sm btn-floating me-2">
                                                    <i class="fas fa-pen"></i>
                                                </button>
                                                <button v-if="permisos.rol.permisos.delete.status" type="button" @click.prevent="deleteRol(rol)" class="btn btn-danger btn-sm btn-floating">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <small class="text-justify">{{ rol.descripcion }}</small>
                                    </li>
                                </ul>
                                <div v-else class="text-center">
                                    <i class="fas fa-ban"></i>
                                    <span>Sin resultados</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Vista para permisos-->
                <div class="col-xl-8 col-lg-8 col-md-8 col-12 mb-3">
                    <div class="card h-100" :class="night ? 'bg-dark-gray text-white-50' : ''">
                        <!--Titulo de la vista permisos-->
                        <div class="card-header d-flex align-items-center" :class="night ? 'w3-dark-gray' : ''">
                            <strong class="me-auto w3-large" v-if="selected.name">
                                <i class="fas fa-male fa-lg"></i>
                                <span>{{ selected.name }}</span>
                            </strong>
                            <strong v-else class="me-auto w3-large">
                                <i class="fas fa-users fa-lg"></i>
                                <span>Todos los usuarios</span>
                            </strong>
                            <div v-if="selected.name">
                                <button v-if="configPermisos" type="button" class="btn btn-secondary btn-rounded btn-sm" @click.prevent="configPermisos = false">
                                    <i class="fas fa-cog fa-lg"></i>
                                    <span>Usuarios</span>
                                </button>
                                <button v-else-if="permisos.permissions.status" type="button" class="btn btn-info btn-rounded btn-sm" @click.prevent="configPermisos = true">
                                    <i class="fas fa-cog fa-lg"></i>
                                    <span>Permisos</span>
                                </button>
                            </div>
                        </div>
                        <!--Contenido vista permisos-->
                        <div class="card-body" style="overflow:auto;">
                            <config-permisos v-if="configPermisos" :$role="selected" :$rolReload="getRoles" :$permissions="permisos.permissions.permisos.change.status" :$night="night"></config-permisos>
                            <users-tableData v-else :class="night ? 'bg-dark-gray' : ''" :$rol="selected" :$rolReload="getRoles" :$permissions="permisos.panel.permisos" :$night="night"></users-tableData>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <router-view></router-view>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        $permisos: Object,
    },
    created() {
        this.nightMode();
        this.permisos = this.$permisos;
        this.getRoles();
        this.debouncedSearchRol = _.debounce(this.getRoles, 500);
    },
    watch: {
        searchRol() {
            this.load = true;
            this.debouncedSearchRol();
        },
        selected() {
            this.editPermisos = false;
        },
    },
    data() {
        return {
            night: null,
            selected: {},
            permisos: {},
            modalActive: false,
            load: false,
            rolesData: {},
            permisosObject: {},
            btnSave: false,
            searchRol: '',
            configPermisos: false,
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.nightMode();
        });
    },
    methods: {
        nightMode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        getRoles() {
            this.load = true;
            $axios.get(this.$masterRoute + '/api/roles/permisos?item=' + this.searchRol, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.rolesData = response.data;
                //this.selected = {};
                this.load = false;
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Clave de seguridad expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });
        },
        createRol() {
            this.$router.push({
                name: 'createRol',
                params: {
                    _loadData: this.getRoles,
                    _jsonData: this.permisosObject,
                    _closeModal: this.closeModal,
                },
            });
            this.modalActive = true;
        },
        editRol(item) {
            this.$router.push({
                name: 'editRol',
                params: {
                    _loadData: this.getRoles,
                    _datos: item,
                    _closeModal: this.closeModal,
                }
            });
            this.modalActive = true;
        },
        deleteRol(item) {
            this.load = true;
            $swal.fire({
                title: '¿Estás seguro?',
                text: "Estas a punto de eliminar un rol de usuario!!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminarlo!'
            }).then((result) => {
                if (result.isConfirmed) {
                    $axios.delete(this.$masterRoute + '/api/roles/permisos/' + item.id, {
                            headers: {
                                Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                            }
                        })
                        .then(response => {
                            if (response.data.status) {
                                $swal.fire(
                                    'Acción exitosa!',
                                    response.data.menssage,
                                    'success'
                                )
                                this.getRoles();
                            } else {
                                $swal.fire({
                                    icon: 'error',
                                    title: 'Error!!',
                                    text: response.data.menssage,
                                });
                            }
                            this.load = false;
                        })
                        .catch(error => {
                            if (error.response.status === 401) {
                                $swal.fire({
                                    icon: 'error',
                                    title: 'Clave de seguridad expirada',
                                    text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                                }).then((result) => {
                                    this.$router.push({
                                        name: 'login'
                                    });
                                });
                            } else {
                                $swal.fire({
                                    icon: 'error',
                                    title: 'Error Inesperado!!',
                                    text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                                });
                            }
                            console.log(error.response);
                            this.load = false;
                        });
                } else {
                    this.load = false;
                }
            })
        },
        closeModal() {
            this.$router.go(-1);
            this.modalActive = false;
        },
        selectedRol(item) {
            this.selected = item;
        },
    },
    computed: {
        screenheightsize() {
            let num = 120;
            if (screen.width > 1500) {
                num = 220;
            }
            return screen.height - num;
        },
    },
}
</script>
