<template>
<div class="w3-modal d-block">
    <div class="w3-modal-content w3-animate-zoom card border my-3">
        <header class="card-header">
            <button @click.prevent="cancel" class="btn btn-danger btn-sm px-2 float-end">
                <i class="fas fa-times"></i>
            </button>
            <div class="d-xl-flex d-lg-flex d-md-flex d-none align-items-center w3-large">
                <i class="fas fa-pen-square fa-2x text-warning me-2"></i>
                <strong class="card-title mx-auto">Editando categoría de compra</strong>
            </div>
            <div class="d-xl-none d-lg-none d-md-none d-flex align-items-center w3-medium">
                <i class="fas fa-pen-square fa-2x text-warning me-2"></i>
                <strong class="card-title mx-auto">Editando categoría de compra</strong>
            </div>
        </header>
        <div class="card-body">
            <div class="input-group mb-3">
                <span class="input-group-text w3-light-gray">Nombre:</span>
                <input type="text" v-model="name" class="form-control" />
            </div>
        </div>
        <footer class="card-footer">
            <div v-if="load">
                <button class="btn btn-secondary btn-rounded btn-block" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Cargando...
                </button>
            </div>
            <div v-else class="row">
                <div class="col">
                    <button class="btn btn-danger btn-sm btn-rounded btn-block" @click.prevent="cancel">
                        <i class="fas fa-ban"></i>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="col">
                    <button class="btn btn-success btn-sm btn-rounded btn-block" @click.prevent="save">
                        <i class="fas fa-save"></i>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </footer>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        p_reload: Function,
        p_item: Object,
    },
    created() {
        this.name = this.p_item.name;
    },
    data() {
        return {
            name: '',
            load: false,
        }
    },
    methods: {
        save() {
            if (!this.name) {
                $swal.fire({
                    icon: 'error',
                    title: 'Formulario incorrecto!',
                    text: 'El campo nombre se encuentra vacío',
                });
            } else {
                this.load = true;
                $axios.put(this.$masterRoute + '/api/catcompras/' + this.p_item.id, {
                    name: this.name,
                }, {
                    headers: {
                        Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                    }
                }).then(response => {
                    if (response.data.status) {
                        $swal.fire({
                            icon: 'success',
                            title: response.data.title,
                            text: response.data.menssage,
                        });
                        this.p_reload();
                        this.cancel();
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: response.data.title,
                            text: response.data.menssage,
                        });
                    }
                    this.load = false;
                }).catch(error => {
                    console.error(error);
                    if (error.response.status === 401) {
                        $swal.fire({
                            icon: 'error',
                            title: 'Sesión expirada',
                            text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                        }).then((result) => {
                            this.$router.push({
                                name: 'login'
                            });
                        });
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: 'Error Inesperado!!',
                            text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                        });
                    }
                    this.load = false;
                })
            }
        },
        cancel() {
            this.$router.go(-1);
        }
    }
}
</script>
