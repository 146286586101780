require('./bootstrap');

import Vue from 'vue';

//Componentes en general
Vue.component('app', require('./components/app').default);
Vue.component('navbar', require('./components/navbar').default);
Vue.component('dropdown-checkboxes', require('./components/dropdownCheckboxes').default);
/*--------------------------------------------------------------------------------------------------------------------------*/

//Activos
Vue.component('activos-tableData', require('./components/activos/admin/activosTable').default);
Vue.component('activos-cols-tables', require('./components/activos/admin/colsTables').default);
Vue.component('activos-headers-tables', require('./components/activos/admin/headersTables').default);
Vue.component('inputForm-createActivo', require('./components/activos/admin/inputFormToCreateActivo').default);
Vue.component('inputForm-editActivo', require('./components/activos/admin/inputFormToEditActivo').default);
//structures
Vue.component('structure-tree', require('./components/activos/structures/structureTree').default);
Vue.component('move-structureTree', require('./components/activos/structures/moveStructureTree').default);
//FormatData
Vue.component('formatData-table', require('./components/activos/formatData/tableData').default);
Vue.component('formatData-cols', require('./components/activos/formatData/colsData').default);
/*-------------------------------------------------------------------------------------------------------------------------*/

//Usuarios
Vue.component('users-tableData', require('./components/users/admin/tableData').default);
Vue.component('users-colsTable', require('./components/users/admin/colsTables').default);
Vue.component('inputform-extradata-users', require('./components/users/admin/extraData').default);
Vue.component('users-cards-permisos', require('./components/users/admin/cardPermiso').default);
//Componentes Roles y permisos
Vue.component('view-permisos', require('./components/users/rolesPermisos/viewPermisos').default);
Vue.component('check-permisos', require('./components/users/rolesPermisos/checkPermisos').default);
Vue.component('config-permisos', require('./components/users/rolesPermisos/configPermisos').default);
/*-------------------------------------------------------------------------------------------------------------------------*/

//Organizaciones
Vue.component('org-treeData', require('./components/organizaciones/admin/treeData').default);
Vue.component('org-moveTreeData', require('./components/organizaciones/admin/moveTreeData').default);
Vue.component('org-usersinfo', require('./components/organizaciones/usersInfo').default);
Vue.component('org-activosinfo', require('./components/organizaciones/activosInfo').default);
Vue.component('org-reportesinfo', require('./components/organizaciones/reportesInfo').default);
/*-------------------------------------------------------------------------------------------------------------------------*/

//Reportes
Vue.component('reports-pendientes', require('./components/reportes/admin/pendientes').default);
Vue.component('reports-proceso', require('./components/reportes/admin/proceso').default);
Vue.component('reports-terminados', require('./components/reportes/admin/terminados').default);
Vue.component('repots-createAnswerQuestion', require('./components/reportes/admin/answersItem').default);
Vue.component('reports-type', require('./components/reportes/configuraciones/reportType').default);
Vue.component('reports-configQuestItem', require('./components/reportes/configuraciones/questionItem').default);
/*-------------------------------------------------------------------------------------------------------------------------*/

//Devices
Vue.component('devices-rows', require('./components/devices/rowTable').default);
Vue.component('devices-item', require('./components/devices/itemList').default);
/*-------------------------------------------------------------------------------------------------------------------------*/

//Compras
Vue.component('compras-presupuesto', require('./components/compras/presupuestos').default);
Vue.component('compras-proveedor-extradata', require('./components/compras/proveedoresExtradata').default);
Vue.component('compras-presupuesto-producto', require('./components/compras/productos').default);
Vue.component('compras-presupuestos-view', require('./components/compras/viewsPresupuestos').default);
/*-------------------------------------------------------------------------------------------------------------------------*/

Vue.prototype.$masterRoute = $('#masterRoute').val();

import router from './routes'

const app = new Vue({
    router
}).$mount('#app');