<template>
<div class="w3-modal d-block">
    <div class="w3-modal-content w3-animate-zoom card border mb-3 mt-lg-auto mt-3" :class="night ? 'bg-dark-gray' : ''">
        <header class="card-header" :class="night ? 'w3-dark-gray' : ''">
            <button @click.prevent="cancel" class="btn btn-danger btn-sm px-2 float-end">
                <i class="fas fa-times"></i>
            </button>
            <h5 v-if="form" class="card-title text-center">Responde el formulario</h5>
            <h5 v-else class="card-title text-center">Selecciona el tipo de problema</h5>
        </header>
        <div v-if="form" class="card-body">
            <div class="alert alert-danger" role="alert">
                <i class="fas fa-exclamation-circle fa-lg me-2"></i>
                <strong>Es necesario llenar todos los campos</strong>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="d-lg-block d-none">
                        <div class="input-group mb-3">
                            <span class="input-group-text w3-light-gray w-75">Selecciona la organización del reporte</span>
                            <select class="form-select" v-model="orgReport">
                                <option v-for="(opt, index) in orgs" :key="index" :value="opt.id">{{ opt.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="mb-3 d-lg-none d-block">
                        <label class="form-label">Selecciona la organización del reporte</label>
                        <select class="form-select">
                            <option selected></option>
                            <option value="Sí">Sí</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                </div>
                <repots-createAnswerQuestion v-for="(quest, index) in selected.questions" :key="index" :$quest="quest" :$index="index" :$putAnwers="putAnwers"></repots-createAnswerQuestion>
                <div class="col-12">
                    <div class="d-lg-block d-none">
                        <div class="input-group mb-3">
                            <span class="input-group-text w3-light-gray w-75">¿Puedes continuar realizando tu trabajo y/o realizar ventas en el caso de sucursal?</span>
                            <select class="form-select" v-model="sales" :class="!sales ? 'is-invalid' : 'is-valid'">
                                <option selected></option>
                                <option value="Sí">Sí</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>
                    <div class="mb-3 d-lg-none d-block">
                        <label class="form-label">¿Puedes continuar realizando tu trabajo y/o realizar ventas en el caso de sucursal?</label>
                        <select class="form-select" v-model="sales" :class="!sales ? 'is-invalid' : 'is-valid'">
                            <option selected></option>
                            <option value="Sí">Sí</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                </div>
                <div class="col-12">
                    <div class="d-lg-block d-none">
                        <div class="input-group mb-3">
                            <span class="input-group-text w3-light-gray w-75">¿Tú o tus compañeros corren algún riesgo a causa de este problema?</span>
                            <select class="form-select" v-model="danger" :class="!danger ? 'is-invalid' : 'is-valid'">
                                <option selected></option>
                                <option value="Sí">Sí</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>
                    <div class="mb-3 d-lg-none d-block">
                        <label class="form-label">¿Tú o tus compañeros corren algún riesgo a causa de este problema?</label>
                        <select class="form-select" v-model="danger" :class="!danger ? 'is-invalid' : 'is-valid'">
                            <option selected></option>
                            <option value="Sí">Sí</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                </div>
                <div class="col-12">
                    <div class="mb-3">
                        <strong class="form-label" :class="night ? 'text-white' : ''">Describe el Problema</strong>
                        <textarea v-model="reportData.descripcion" class="form-control" maxlength="500" rows="5"></textarea>
                        <small class="float-end me-2">{{ reportData.descripcion.length }}/500</small>
                    </div>
                </div>
                <div v-if="imagesView.length > 0" class="row mb-3">
                    <div v-for="(img, index) in imagesView" :key="index" class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-4">
                        <div class="bg-image">
                            <img :src="img" class="img-fluid" alt="Sample" />
                            <div class="mask">
                                <div class="d-flex justify-content-end align-items-start h-100 pt-2 pe-2">
                                    <button type="button" class="btn btn-danger btn-floating btn-sm float-end" @click.prevent="delImg(index)">
                                        <i class="fas fa-times fa-lg"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-center">
                    <button class="btn btn-outline-black btn-lg" :class="night ? 'text-white' : ''" @click.prevent="addImg">
                        <i class="fas fa-camera fa-lg me-2"></i>
                        <span>Agregar imagenes</span>
                    </button>
                    <input type="file" class="form-control d-none" ref="files" @change="selectImg" accept="image/*" id="selectImg" multiple />
                </div>
            </div>
        </div>
        <div v-else class="card-body">
            <div v-if="load" class="text-center">
                <div class="spinner-border text-secondary" style="width: 3rem; height: 3rem;" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div v-else class="row">
                <div v-for="(item, index) in reportsType" :key="index" class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-4 mb-2">
                    <figure class="figure" @click.prevent="selectItem(item)">
                        <img :src="item.image" class="figure-img img-fluid rounded shadow-3 mb-3 w3-white" alt="..." />
                        <figcaption class="figure-caption text-center" :class="night ? 'text-white' : ''">{{ item.name }}</figcaption>
                    </figure>
                </div>
            </div>
        </div>
        <footer class="card-footer" v-if="form">
            <div v-if="load">
                <button class="btn btn-secondary btn-rounded btn-block" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Cargando...
                </button>
            </div>
            <div v-else class="row px-0">
                <div class="col-6">
                    <button class="btn btn-danger btn-rounded btn-sm btn-block" @click.prevent="cancel">
                        <i class="fas fa-ban fa-lg"></i>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="col-6">
                    <button class="btn btn-primary btn-rounded btn-sm btn-block" :disabled="!isSave" @click.prevent="saveReport">
                        <i class="fas fa-paper-plane fa-lg"></i>
                        <span>Enviar</span>
                    </button>
                </div>
            </div>
        </footer>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        _reload: Function,
    },
    created() {
        this.nightMode();
        this.getTypeReport();
        this.getOrgs();
    },
    data() {
        return {
            night: null,
            reportsType: [],
            load: false,
            form: false,
            selected: {},
            images: [],
            imagesView: [],
            reportData: {
                no_report: '',
                answers: [],
                typeId: '',
                descripcion: '',
                urgencia: '',
            },
            sales: '',
            danger: '',
            answersItem: true,
            loadfiles: 0,
            orgs: [],
            orgReport: '',
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.nightMode();
        });
    },
    methods: {
        nightMode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        saveReport() {
            this.load = true;
            if (this.sales == 'No' || this.danger == 'Sí') {
                this.reportData.urgencia = 'Alta';
            } else {
                this.reportData.urgencia = 'Baja';
            }
            this.reportData.answers.push({
                question: '¿Puedes continuar realizando tu trabajo y/o realizar ventas en el caso de sucursal?',
                answer: this.sales,
            });
            this.reportData.answers.push({
                question: '¿Tú o tus compañeros corren algún riesgo a causa de este problema?',
                answer: this.danger,
            });
            let formData = new FormData();
            formData.append('noreport', this.reportData.no_report);
            formData.append('urgency', this.reportData.urgencia);
            formData.append('typeId', this.reportData.typeId);
            formData.append('descripcion', this.reportData.descripcion);
            formData.append('answers', JSON.stringify(this.reportData.answers));
            formData.append('orgReport', this.orgReport);
            $axios.post(this.$masterRoute + '/api/reports', formData, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                if (response.data.status) {
                    let id = response.data.id;
                    if (this.images.length > 0) {
                        this.loadfiles = this.images.length;
                        for (let x = 0; x < this.images.length; x++) {
                            let formImg = new FormData();
                            formImg.append('image', this.images[x]);
                            formImg.append('id', id);
                            $axios.post(this.$masterRoute + '/api/reports/putimg', formImg, {
                                headers: {
                                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                                }
                            }).then(response => {
                                if (response.data.status) {
                                    this.loadfiles = this.loadfiles - 1;
                                    console.log(this.loadfiles);
                                    if (this.loadfiles == 0) {
                                        $swal.fire(
                                            'Acción exitosa!',
                                            'El reporte fue creado correctamente',
                                            'success'
                                        );
                                        this.load = false;
                                        this._reload();
                                        this.cancel();
                                    }
                                } else {
                                    $swal.fire({
                                        icon: 'error',
                                        title: response.data.title,
                                        text: response.data.menssage,
                                    });
                                    this.load = false;
                                }
                            }).catch(error => {
                                if (error.response.status === 401) {
                                    $swal.fire({
                                        icon: 'error',
                                        title: 'Sesión expirada',
                                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                                    }).then((result) => {
                                        this.$router.push({
                                            name: 'login'
                                        });
                                    });
                                } else {
                                    $swal.fire({
                                        icon: 'error',
                                        title: 'Error Inesperado!!',
                                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                                    });
                                }
                                console.log(error.response);
                                this.load = false;
                            });
                        }
                    } else {
                        $swal.fire(
                            'Acción exitosa!',
                            'El reporte fue creado correctamente',
                            'success'
                        );
                        this.load = false;
                        this._reload();
                        this.cancel();
                    }
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: response.data.title,
                        text: response.data.menssage,
                    });
                    this.load = false;
                }
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });

        },
        getTypeReport() {
            this.load = true;
            $axios.get(this.$masterRoute + '/api/reportstype', {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.reportsType = response.data;
                this.load = false;
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });
        },
        getOrgs() {
            $axios.get(this.$masterRoute + '/api/reports/jerarquia' + sessionStorage.organizaciones, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.orgs = response.data;
                this.orgReport = this.orgs[0].id;
            }).catch(error => {
                console.log(error);
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
            });
        },
        putAnwers(obj, index) {
            this.answersItem = true;
            this.reportData.answers[index] = obj;
            this.reportData.answers.forEach(element => {
                if (!element.answer) {
                    this.answersItem = false;
                }
            });
        },
        addImg() {
            $('#selectImg').click();
        },
        selectItem(item) {
            this.selected = item;
            this.selected.questions = JSON.parse(this.selected.questions);
            this.reportData.typeId = item.id;
            this.reportData.no_report = 'RPT/' + item.clave + '-';
            this.form = true;
        },
        selectImg(event) {
            if (event.target.files.length > 12 || this.imagesView.length > 12 || this.images.length > 12 || event.target.files.length + this.images.length > 12) {
                $swal.fire({
                    icon: 'error',
                    title: 'Número de imagenes excedido!!',
                    text: 'Solo se permite agregar hasta 12 imagenes',
                });
                return;
            }
            for (let x = 0; x < event.target.files.length; x++) {
                this.images.push(event.target.files[x]);
                this.imagesView.push(URL.createObjectURL(event.target.files[x]));
            }
            console.log(this.images);
            $('#selectImg').val(null);
        },
        delImg(index) {
            this.images.splice(index, 1);
            this.imagesView.splice(index, 1);
        },
        cancel() {
            this.$router.go(-1);
        }
    },
    computed: {
        isSave() {
            if (!this.sales || !this.danger || !this.answersItem) {
                return false;
            }
            return true;
        }
    }
}
</script>
