<template>
<div class="w3-modal d-block">
    <div class="w3-modal-content w3-animate-zoom card border my-3">
        <header class="card-header">
            <button @click.prevent="cancel" class="btn btn-danger btn-sm px-2 float-end">
                <i class="fas fa-times"></i>
            </button>
            <div class="d-xl-flex d-lg-flex d-md-flex d-none align-items-center w3-large">
                <i class="fas fa-plus-square fa-2x text-primary me-auto"></i>
                <strong class="card-title me-auto">Creando nueva limitante</strong>
            </div>
            <div class="d-xl-none d-lg-none d-md-none d-flex align-items-center w3-medium">
                <i class="fas fa-plus-square fa-2x text-primary me-auto"></i>
                <strong class="card-title me-auto">Creando nueva limitante</strong>
            </div>
        </header>
        <div class="card-body">
            <div class="row mb-3">
                <div class="col-12 mb-3">
                    <h6 class="text-center">Selecciona el usuario que autoriza la limitante</h6>
                    <select class="form-select" v-model="userIdAuth" aria-label="Default select example">
                        <option v-for="(item, index) in usuarios" :key="'auth' + index" :value="item.id">{{ item.name }}</option>
                    </select>
                </div>
                <div class="col-6">
                    <div class="input-group">
                        <span class="input-group-text w3-light-gray">Cantidad mínima:</span>
                        <input type="number" v-model="minLim" class="form-control form-control-sm" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="input-group">
                        <span class="input-group-text w3-light-gray">Cantidad máxima:</span>
                        <input type="number" v-model="maxLim" class="form-control form-control-sm" />
                    </div>
                </div>
            </div>
            <div class="row">
                <h6 class="text-center mb-3">Selecciona las organizaciones a la cual se aplicará la limitante</h6>
                <div v-if="loadOrg" class="col-12 text-center">
                    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div v-else v-for="(item, index) in organizaciones" :key="'lim' + index" class="col-6 col-lg-4">
                    <div class="form-check mb-2">
                        <input class="form-check-input" type="checkbox" @change="check($event, item.id)" />
                        <label class="form-check-label" for="flexCheckDefault">
                            {{ item.name }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <footer class="card-footer">
            <div v-if="load">
                <button class="btn btn-secondary btn-rounded btn-block" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Cargando...
                </button>
            </div>
            <div v-else class="row">
                <div class="col">
                    <button class="btn btn-danger btn-sm btn-rounded btn-block" @click.prevent="cancel">
                        <i class="fas fa-ban"></i>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="col">
                    <button class="btn btn-success btn-sm btn-rounded btn-block" @click.prevent="save">
                        <i class="fas fa-save"></i>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </footer>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        p_reload: Function,
    },
    created() {
        this.getorganizaciones();
    },
    data() {
        return {
            organizaciones: [],
            usuarios: [],
            night: false,
            load: false,
            loadOrg: true,
            minLim: '',
            maxLim: '',
            orgLimIds: [],
            userIdAuth: '',
        }
    },
    methods: {
        save() {
            let error = this.validate();
            if (!error) {
                this.load = true;
                $axios.post(this.$masterRoute + '/api/limitantecompras', {
                    minlim: this.minLim,
                    maxlim: this.maxLim,
                    orgids: JSON.stringify(this.orgLimIds),
                    userauth: this.userIdAuth,
                }, {
                    headers: {
                        Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                    }
                }).then(response => {
                    if (response.data.status) {
                        $swal.fire({
                            icon: 'success',
                            title: response.data.title,
                            text: response.data.menssage,
                        });
                        this.p_reload();
                        this.cancel();
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: response.data.title,
                            text: response.data.menssage,
                        });
                    }
                    this.load = false;
                }).catch(error => {
                    console.error(error);
                    if (error.response.status === 401) {
                        $swal.fire({
                            icon: 'error',
                            title: 'Sesión expirada',
                            text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                        }).then((result) => {
                            this.$router.push({
                                name: 'login'
                            });
                        });
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: 'Error Inesperado!!',
                            text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                        });
                    }
                });
            } else {
                $swal.fire({
                    icon: 'error',
                    title: 'Formulario incorrecto!',
                    text: error,
                });
            }
        },
        validate() {
            if (!this.userIdAuth) {
                return 'No has seleccionado el usuario que autoriza';
            }
            if (!this.minLim) {
                this.minLim == 0;
            }
            if (!this.maxLim) {
                return 'No ha ingresado la cantidad máxima';
            }
            if (this.orgLimIds.length == 0) {
                return 'No ha seleccionado ninguna organización';
            }
            return '';
        },
        getorganizaciones() {
            this.loadOrg = true;
            $axios.get(this.$masterRoute + '/api/organizaciones', {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.organizaciones = response.data;
                this.getUsers();
                this.loadOrg = false;
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });
        },
        getUsers() {
            $axios.get(this.$masterRoute + '/api/users/limitantes', {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.usuarios = response.data;
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });
        },
        check(e, id) {
            if (e.target.checked) {
                this.orgLimIds.push(id);
            } else {
                let ind = this.orgLimIds.indexOf(id);
                this.orgLimIds.splice(ind, 1);
            }
        },
        cancel() {
            this.$router.go(-1);
        }
    }
}
</script>
