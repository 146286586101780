<template>
<div>
    <div v-if="load" class="d-flex align-items-center justify-content-center mb-3">
        <div class="spinner-border text-secondary" style="width: 3rem; height: 3rem;" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div v-else>
        <div v-if="!treeData.length" class="w3-medium text-center">
            <i class="fas fa-ban fa-lg me-2"></i>
            <strong>Sin asignaciones</strong>
        </div>
        <structure-tree v-for="(node, index) in treeData" :key="index" :$node="node" :$putElem="putParentId" :$elemSelect="selectElement"></structure-tree>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        $org: Object,
    },
    created() {
        if (!this.$org.parentId) {
            this.getAllStructures();
        } else {
            this.getStructureByOrg();
        }
    },
    watch: {
        $org() {
            this.load = true;
            if (!this.$org.parentId) {
                this.getAllStructures();
            } else {
                this.getStructureByOrg();
            }
        }
    },
    data() {
        return {
            treeData: [],
            selectElement: {},
            parentId: '',
            load: true,
        }
    },
    methods: {
        getStructureByOrg() {
            $axios.get(this.$masterRoute + '/api/organizaciones/activos/' + this.$org.structureIds, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.treeData = this.generateTree(response.data);
                this.load = false;
            }).catch(error => {
                console.log(error);
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Clave de seguridad expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });
        },
        getAllStructures() {
            $axios.get(this.$masterRoute + '/api/structures', {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.treeData = this.generateTree(response.data);
                this.load = false;
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Clave de seguridad expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });
        },
        putParentId(element) {
            this.parentId = element.id;
            this.selectElement = element;
        },
        generateTree(data) {
            let map = {},
                node,
                roots = [],
                i;
            for (i = 0; i < data.length; i++) {
                map[data[i].id] = i;
                data[i].children = [];
            }
            for (i = 0; i < data.length; i++) {
                node = data[i];
                if (node.parentId) {
                    data[map[node.parentId]].children.push(node);
                } else {
                    roots.push(node);
                }
            }
            return roots;
        },
    }
}
</script>
