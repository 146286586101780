<template>
<div>
    <div v-if="firstload" class="d-flex justify-content-center">
        <div class="spinner-border text-secondary" style="width: 3rem; height: 3rem;" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div v-else>
        <div class="table-responsive overflow-auto d-none d-lg-block" :style="'height:' + screenheightsize + 'px;'">
            <table class="table table-hover table-sm align-middle" :class="$night ? 'table-dark' : 'table-light'" :style="'width:' + screenwidthsize + 'px;'">
                <thead class="w3-medium text-center" :class="$night ? 'w3-dark-gray' : 'w3-light-gray'" style="position: sticky;top: 0;z-index: 10;">
                    <tr style="position: sticky;top: 0;z-index: 10;" :class="$night ? 'border-dark' : 'border'">
                        <th scope="col" class="p-1 text-center" :class="$night ? 'w3-dark-gray text-white' : 'w3-light-gray'" style="position:sticky;left:0;width:50px;position: sticky;top: 0;z-index: 15;">
                            <label class="fw-bold">Opciones</label>
                            <button v-if="filterActive" class="btn btn-outline-dark btn-rounded btn-sm" :class="$night ? 'w3-white' : ''" @click.prevent="cleanFilters">
                                <i class="fas fa-eraser fa-lg"></i>
                            </button>
                        </th>
                        <th scope="col" style="width: 175px;position: sticky;top: 0;z-index: 10;">
                            <div class="d-flex align-items-center mb-1">
                                <strong :class="!$permisos.filter.status?'ms-auto me-auto':''">No. reporte</strong>
                                <i v-if="sortReport == 0 && $permisos.filter.status" class="fas fa-sort ms-auto" @click.prevent="putSortReporte(1)"></i>
                                <i v-if="sortReport == 1 && $permisos.filter.status" class="fas fa-sort-up ms-auto" @click.prevent="putSortReporte(2)"></i>
                                <i v-if="sortReport == 2 && $permisos.filter.status" class="fas fa-sort-down ms-auto" @click.prevent="putSortReporte(0)"></i>
                            </div>
                            <input v-if="$permisos.filter.status" v-model="searchReporte" type="search" class="form-control form-control-sm" placeholder="Buscar">
                        </th>
                        <th scope="col" v-if="$permisos.viewrem.status" style="width:175px;position: sticky;top: 0;z-index: 10;">
                            <div class="d-flex align-items-center justify-content-center mb-1">
                                <strong>Remitente</strong>
                            </div>
                            <select v-if="$permisos.filter.status" v-model="searchRemitente" class="form-select form-select-sm" :class="!searchDestinatario ? 'd-block' : 'd-none'">
                                <option value="" selected>Todos</option>
                                <option v-for="(item, index) in remitentes" :key="index" :value="item.id">{{ item.name }}</option>
                            </select>
                        </th>
                        <th scope="col" v-if="$permisos.viewdest.status" style="width:175px;position: sticky;top: 0;z-index: 10;">
                            <div class="d-flex align-items-center justify-content-center mb-1">
                                <strong>Destinatario</strong>
                            </div>
                            <select v-if="$permisos.filter.status" v-model="searchDestinatario" class="form-select form-select-sm" :class="!searchRemitente ? 'd-block' : 'd-none'">
                                <option value="" selected>Todos</option>
                                <option v-for="(item, index) in destinatarios" :key="index" :value="item.id">{{ item.name }}</option>
                            </select>
                        </th>
                        <th v-if="$permisos.viewprio.status" scope="col" style="width:150px;position: sticky;top: 0;z-index: 10;">
                            <div class="d-flex align-items-center justify-content-center mb-1">
                                <strong>Prioridad</strong>
                            </div>
                            <select v-if="$permisos.filter.status" v-model="searchEmergency" class="form-select form-select-sm">
                                <option value="" selected>Todos</option>
                                <option value="Alta">Alta</option>
                                <option value="Media">Media</option>
                                <option value="Baja">Baja</option>
                            </select>
                        </th>
                        <th scope="col" class="text-center" style="position: sticky;top: 0;z-index: 10;">
                            <div class="d-flex align-items-center justify-content-center mb-1">
                                <strong>Información</strong>
                            </div>
                            <div v-if="$permisos.filter.status" class="d-flex align-items-center justify-content-center">
                                <input type="search" class="form-control form-control-sm" v-model="searchInfo" placeholder="Buscar" style="width: 175px;">
                            </div>
                        </th>
                        <th scope="col" style="width:175px;position: sticky;top: 0;z-index: 10;">
                            <div class="d-flex align-items-center mb-1">
                                <strong :class="!$permisos.filter.status?'ms-auto me-auto':''">Terminado</strong>
                                <i v-if="sortCreacion == 0 && $permisos.filter.status" class="fas fa-sort ms-auto" @click.prevent="putSortCreacion(1)"></i>
                                <i v-if="sortCreacion == 1 && $permisos.filter.status" class="fas fa-sort-up ms-auto" @click.prevent="putSortCreacion(2)"></i>
                                <i v-if="sortCreacion == 2 && $permisos.filter.status" class="fas fa-sort-down ms-auto" @click.prevent="putSortCreacion(0)"></i>
                            </div>
                            <input v-if="$permisos.filter.status" v-model="searchCreacion" type="Date" class="form-control form-control-sm" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" placeholder="Buscar">
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="load">
                        <td colspan="8" class="text-center">
                            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                    <tr v-else v-for="(item, index) in reports" :key="index">
                        <td class="text-center" style="position:sticky;left:0px;width: 50px;" :class="$night ? 'w3-dark-gray' : 'w3-light-gray'">
                            <a class="w3-medium" :class="$night ? 'text-white-50' : 'text-black-50'" style="cursor: pointer;" @click.prevent="viewComments(item.comments, item.id)">
                                <i class="fas fa-comment-alt fa-lg"></i>
                                <span v-if="JSON.parse(item.comments).length > 0" class="badge rounded-pill badge-notification bg-info">{{ JSON.parse(item.comments).length }}</span>
                            </a>
                        </td>
                        <td class="text-center" style="width:175px;" @click.prevent="viewReport(item)">
                            <small>{{ item.no_report }}</small>
                        </td>
                        <td v-if="$permisos.viewrem.status" class="text-center" style="width:50px;" @click.prevent="viewReport(item)">
                            <small>{{ item.remitente.name }}</small>
                        </td>
                        <td v-if="$permisos.viewdest.status" class="text-center" style="width:50px;" @click.prevent="viewReport(item)">
                            <small>{{ item.destinatario.name }}</small>
                        </td>
                        <td v-if="$permisos.viewprio.status" class="text-center" style="width:50px;" @click.prevent="viewReport(item)">
                            <span v-if="item.urgencia == 'Alta'" class="badge rounded-pill bg-danger w-100">Alta</span>
                            <span v-if="item.urgencia == 'Media'" class="badge rounded-pill bg-warning w-100">Media</span>
                            <span v-if="item.urgencia == 'Baja'" class="badge rounded-pill bg-success w-100">Baja</span>
                        </td>
                        <td scope="row" class="text-left" style="font-weight: normal;" @click.prevent="viewReport(item)">
                            <strong>{{ item.rptname }} - </strong>
                            <small>{{ item.descripcion }}</small>
                        </td>
                        <td class="text-center" style="width:175px;" @click.prevent="viewReport(item)">
                            <small>{{ getMomentDate(item.finished_in) }}</small>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ul class="list-group overflow-auto d-block d-lg-none" :style="'height:' + screenheightsize + 'px;'">
            <li v-for="(item, index) in reports" :key="index" class="list-group-item list-group-item-action" :class="$night ? 'w3-dark-gray' : 'w3-white'">
                <div class="d-flex align-items-center mb-2">
                    <a class="w3-medium" :class="$night ? 'text-white-50' : 'text-black-50'" style="cursor: pointer;" @click.prevent="viewComments(item.comments, item.id)">
                        <i class="fas fa-comment-alt fa-lg"></i>
                        <span v-if="JSON.parse(item.comments).length > 0" class="badge rounded-pill badge-notification bg-info">{{ JSON.parse(item.comments).length }}</span>
                    </a>
                    <small class="ms-auto">{{ getMomentDate(item.finished_in) }}</small>
                </div>
                <div v-if="$permisos.viewprio.status" class="d-flex align-items-center mb-1" @click.prevent="viewReport(item)">
                    <strong class="me-auto">{{ item.no_report }}</strong>
                    <span v-if="item.urgencia == 'Alta'" class="badge rounded-pill bg-danger w-25">Alta</span>
                    <span v-if="item.urgencia == 'Media'" class="badge rounded-pill bg-warning w-25">Media</span>
                    <span v-if="item.urgencia == 'Baja'" class="badge rounded-pill bg-info w-25">Baja</span>
                </div>
                <div class="d-flex align-items-center justify-content-center mb-2" @click.prevent="viewReport(item)">
                    <div v-if="$permisos.viewrem.status" class="text-center me-3">
                        <strong>Remitente</strong>
                        <br>
                        <small>{{ item.remitente.name }}</small>
                    </div>
                    <div v-if="$permisos.viewdest.status" class="text-center">
                        <strong>Destinatario</strong>
                        <br>
                        <small>{{ item.destinatario.name }}</small>
                    </div>
                </div>
                <p @click.prevent="viewReport(item)">
                    <strong>{{ item.rptname }}</strong>
                    <br>
                    <small>{{ item.descripcion }}</small>
                </p>
            </li>
        </ul>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
import moment from 'moment'
export default {
    props: {
        $night: Boolean,
        $counter: Number,
        $getCounter: Function,
        $search: String,
        $permisos: Object,
    },
    created() {
        setTimeout(this.getReports, 2000);
        moment.locale('es-mx');
        this.debouncedGetReport = _.debounce(this.getReports, 500);
    },
    watch: {
        searchReporte() {
            this.debouncedGetReport();
        },
        searchEmergency() {
            this.getReports();
        },
        searchInfo() {
            this.debouncedGetReport();
        },
        searchCreacion() {
            this.debouncedGetReport();
        },
        searchRemitente() {
            this.getReports();
        },
        searchDestinatario() {
            this.getReports();
        },
        $search() {
            this.debouncedGetReport();
        }
    },
    data() {
        return {
            firstload: true,
            load: false,
            status: 'terminado',
            reports: [],
            remitentes: [],
            destinatarios: [],
            searchReporte: '',
            searchRemitente: '',
            searchDestinatario: '',
            searchEmergency: '',
            searchInfo: '',
            searchCreacion: '',
            column: 'reports.id',
            sort: 'desc',
            sortReport: 0,
            sortCreacion: 0,
            screenwidthsize: 0,
            screenheightsize: 0,

        }
    },
    mounted() {
        window.Echo.channel('channel-reports').listen('OrderStatusChangeEvent', (e) => {
            this.getReports();
        });
        this.changeSize();
        window.addEventListener("resize", this.changeSize);
    },
    methods: {
        getReports() {
            this.load = true;
            $axios.get(this.$masterRoute + '/api/reports?report=' + this.searchReporte +
                '&urgencia=' + this.searchEmergency +
                '&info=' + this.searchInfo +
                '&create=' + this.searchCreacion +
                '&column=' + this.column +
                '&sort=' + this.sort +
                '&remitente=' + this.searchRemitente +
                '&destinatario=' + this.searchDestinatario +
                '&search=' + this.$search +
                '&orgids=' + sessionStorage.organizaciones +
                '&status=' + this.status, {
                    headers: {
                        Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                    }
                }).then(response => {
                this.reports = response.data.reports.data;
                this.destinatarios = response.data.destinatarios;
                this.remitentes = response.data.remitentes;
                this.firstload = false;
                this.load = false;
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });
        },
        viewReport(item) {
            this.$router.push({
                name: 'reportView',
                params: {
                    _reportData: item,
                }
            });
        },
        viewComments(obj, id) {
            this.$router.push({
                name: 'reportComments',
                params: {
                    _comments: JSON.parse(obj),
                    _reload: this.getReports,
                    _reportId: id,
                }
            });
        },
        putSortReporte(index) {
            this.sortCreacion = 0;
            this.sortReport = index;
            switch (index) {
                case 1:
                    this.column = 'reports.no_report';
                    this.sort = 'asc';
                    break;
                case 2:
                    this.column = 'reports.no_report';
                    this.sort = 'desc';
                    break;
                default:
                    this.column = 'reports.id';
                    this.sort = 'desc';
                    break;
            }
            this.getReports();
        },
        putSortCreacion(index) {
            this.sortReport = 0;
            this.sortCreacion = index;
            switch (index) {
                case 1:
                    this.column = 'reports.created_at';
                    this.sort = 'asc';
                    break;
                case 2:
                    this.column = 'reports.created_at';
                    this.sort = 'desc';
                    break;
                default:
                    this.column = 'reports.id';
                    this.sort = 'desc';
                    break;
            }
            this.getReports();
        },
        changeSize() {
            if (window.innerWidth < 1336) {
                this.screenwidthsize = 1336;
            } else {
                this.screenwidthsize = window.innerWidth - 30;
            }
            this.screenheightsize = window.innerHeight - 260;
        },
        cleanFilters() {
            this.searchReporte = '';
            this.searchRemitente = '';
            this.searchDestinatario = '';
            this.searchEmergency = '';
            this.searchInfo = '';
            this.searchCreacion = '';
            this.sortCreacion = 0;
            this.sortReport = 0;
        },
        getMomentDate(date) {
            return moment(date).calendar();
        },
    },
    computed: {
        filterActive() {
            if (!this.searchReporte && !this.searchRemitente && !this.searchDestinatario && !this.searchEmergency && !this.searchInfo && !this.searchCreacion) {
                return false;
            }
            return true;
        }
    },
}
</script>
