<template>
<div class="w3-modal d-block">
    <div class="w3-modal-content w3-animate-zoom card border my-3">
        <header class="card-header">
            <button @click.prevent="cancel" class="btn btn-danger btn-sm px-2 float-end">
                <i class="fas fa-times"></i>
            </button>
            <h5 class="card-title text-center">Comentarios</h5>
        </header>
        <div class="card-body w3-light-gray overflow-auto" style="height: 320px;">
            <div v-if="!commentsObj.length" class="d-flex align-items-center justify-content-center h-100 w3-medium">
                <i class="fas fa-ban fa-lg me-2"></i>
                <strong>Sin comentarios</strong>
            </div>
            <ul v-else class="list-group">
                <li v-for="(item, index) in commentsObj" :key="index" class="list-group-item">
                    <div class="d-flex align-items-center">
                        <strong class="me-auto">{{ item.org }}</strong>
                        <small>{{ getMomentDate(item.date) }}</small>
                    </div>
                    <span>{{ item.comment }}</span>
                </li>
            </ul>
        </div>
        <footer class="card-footer">
            <div class="input-group">
                <input type="text" v-model="commentText" id="inputComment" class="form-control" placeholder="Escribe un comentario..." />
                <button class="btn btn-primary" type="button" @click.prevent="addComment">
                    <i class="fas fa-paper-plane fa-lg"></i>
                    <span class="d-lg-inline d-none">Enviar</span>
                </button>
            </div>
        </footer>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
import moment from 'moment'
export default {
    props: {
        _comments: Array,
        _reportId: Number,
        _reload: Function,
    },
    created() {
        this.commentsObj = this._comments;
        moment.locale('es-mx');
        let authdata = JSON.parse(sessionStorage.authUser);
        this.orgUser = authdata.orgname;
    },
    data() {
        return {
            commentText: '',
            orgUser: '',
            commentsObj: [],
        }
    },
    methods: {
        addComment() {
            let obj = this.commentsObj;
            obj.push({
                org: this.orgUser,
                comment: this.commentText,
                date: this.getDate(),
            });
            console.log(obj);
            $axios.put(this.$masterRoute + '/api/reports/comments' + this._reportId, {
                commentsData: JSON.stringify(obj),
            }, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                if (response.data.status) {
                    this.commentText = '';
                    this.commentsObj = obj;
                    $('#inputComment').focus();
                    this._reload();
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: response.data.title,
                        text: response.data.menssage,
                    });
                }
            }).catch(error => {
                console.log(error.response);
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
            });
        },
        cancel() {
            this.$router.go(-1);
        },
        getDate() {
            var date;
            date = new Date();
            date = date.getFullYear() + '-' +
                ('00' + (date.getMonth() + 1)).slice(-2) + '-' +
                ('00' + date.getDate()).slice(-2) + ' ' +
                ('00' + date.getHours()).slice(-2) + ':' +
                ('00' + date.getMinutes()).slice(-2) + ':' +
                ('00' + date.getSeconds()).slice(-2);
            return date;
        },
        getMomentDate(date) {
            return moment(date).calendar();
        },
    },
}
</script>
