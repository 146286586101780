<template>
<div>
    <!--Spinner-->
    <div v-if="load" class="text-center">
        <div class="spinner-border text-secondary" style="width: 3rem; height: 3rem;" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div v-else>
        <!--Titulo-->
        <div class="d-flex align-items-center mb-2">
            <button v-if="$rol.id && $permissions.create.status" type="button" class="btn btn-primary btn-rounded btn-sm d-xl-block d-none" @click.prevent="createUser">
                <i class="fas fa-user-plus fa-lg me-2"></i>
                <span>Crear usuario</span>
            </button>
            <button v-if="$rol.id && $permissions.create.status" type="button" class="btn btn-primary btn-floating d-xl-none d-block" @click.prevent="createUser">
                <i class="fas fa-user-plus fa-lg"></i>
            </button>
            <strong class="w3-large ms-auto me-auto">
                <i class="fas fa-info-circle fa-lg me-2"></i>
                <span>Información de los usuarios</span>
            </strong>
        </div>
        <!--Header-->
        <div class="d-xl-flex d-lg-flex d-md-flex mb-3">
            <div class="me-auto mb-2">
                <div class="input-group input-group-sm rounded">
                    <span class="input-group-text border-0" :class="$night ? 'text-white-50' : ''">Filas por página: </span>
                    <select class="form-select form-select-sm rounded" v-model="itemsPerPage">
                        <option value="10" selected>10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </select>
                </div>
            </div>
            <div class="ms-auto mb-2">
                <div class="input-group input-group-sm rounded">
                    <input type="search" class="form-control rounded" v-model="searchItem" placeholder="Buscar" style="min-width: 175px;" />
                    <span class="input-group-text border-0" :class="$night ? 'text-white-50' : ''" id="search-addon">
                        <i class="fas fa-user fa-lg"></i>
                        <i class="fas fa-search"></i>
                    </span>
                </div>
            </div>
        </div>
        <!--Body-->
        <div class="table-responsive">
            <table class="table table-sm align-middle table-hover">
                <thead>
                    <tr :class="$night ? 'w3-dark-gray text-white' : 'w3-light-gray'">
                        <th scope="col" class="p-1 text-center w3-card-4" :class="$night ? 'w3-dark-gray text-white' : 'w3-light-gray'" style="position:sticky;left:0px;width:50px;"></th>
                        <th scope="col" class="p-1 text-center" style="width: 10px;">
                            <strong>#</strong>
                        </th>
                        <th scope="col" class="p-1 text-center">
                            <strong>Usuario</strong>
                        </th>
                        <th scope="col" class="p-1 text-center">
                            <strong>Nombre</strong>
                        </th>
                        <th scope="col" class="p-1 text-center">
                            <strong>Organizacion</strong>
                        </th>
                    </tr>
                </thead>
                <tbody v-if="users.length">
                    <users-colsTable v-for="(user, index) in users" :key="index" :$user="user" :$index="index" :$reload="getUsers" :$rolReload="$rolReload" :$permissions="$permissions" :$night="$night"></users-colsTable>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="5" class="text-center">
                            <span>
                                <i class="fas fa-ban"></i> Sin resultados
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!--Footer-->
        <div class="d-xl-flex d-lg-flex d-md-flex align-items-center mt-3">
            <div class="me-auto ms-2 mb-xl-0 mb-lg-0 mb-2">
                <small>Mostrando {{ pagination.from }}-{{ pagination.to }} de {{ pagination.total }}</small>
            </div>
            <div class="ms-auto mb-xl-0 mb-lg-0 mb-2">
                <nav class="d-flex">
                    <ul class="pagination pagination-circle pagination-sm mb-0 ms-auto me-auto">
                        <li class="page-item" v-if="pagination.current_page > 1" @click.prevent="changePageActivosData(pagination.current_page - 1)">
                            <a class="page-link text-secondary" href="#" tabindex="-1" aria-disabled="true">Anterior</a>
                        </li>
                        <li class="page-item" style="z-index: 1;" v-for="(page, index) in pagesNumberActivosData" :key="index" :class="[page == isActivedActivosData ? 'active' : '']" @click.prevent="changePageActivosData(page)">
                            <a class="page-link" href="#">{{ page }}</a>
                        </li>
                        <li class="page-item" v-if="pagination.current_page < pagination.last_page" @click.prevent="changePageActivosData(pagination.current_page + 1)">
                            <a class="page-link text-secondary" href="#">Siguiente</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        $night: Boolean,
        $permissions: Object,
        $rol: Object,
        $rolReload: Function,
    },
    created() {
        this.getUsers();
        this.debounceGetUsers = _.debounce(this.getUsers, 500);
    },
    watch: {
        $rol() {
            this.load = true;
            this.getUsers();
        },
        itemsPerPage() {
            this.getUsers();
        },
        searchItem() {
            this.debounceGetUsers();
        },
    },
    data() {
        return {
            users: [],
            pagination: {},
            searchItem: '',
            itemsPerPage: 10,
            offset: 6,
            load: true,
        }
    },
    methods: {
        getUsers(page) {
            if (this.$rol.id) {
                $axios.get(this.$masterRoute + '/api/users/' + this.$rol.id + '?page=' + page + '&item=' + this.searchItem + '&itemsperpage=' + this.itemsPerPage, {
                    headers: {
                        Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                    }
                }).then(response => {
                    this.users = response.data.users.data;
                    this.pagination = response.data.pagination
                    this.load = false;
                }).catch(error => {
                    if (error.response.status === 401) {
                        $swal.fire({
                            icon: 'error',
                            title: 'Sesión expirada',
                            text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                        }).then((result) => {
                            this.$router.push({
                                name: 'login'
                            });
                        });
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: 'Error Inesperado!!',
                            text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                        });
                    }
                    console.log(error.response);
                    this.load = false;
                });
            } else {
                $axios.get(this.$masterRoute + '/api/users?page=' + page + '&item=' + this.searchItem + '&itemsperpage=' + this.itemsPerPage, {
                    headers: {
                        Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                    }
                }).then(response => {
                    this.users = response.data.users.data;
                    this.pagination = response.data.pagination
                    this.load = false;
                }).catch(error => {
                    if (error.response.status === 401) {
                        $swal.fire({
                            icon: 'error',
                            title: 'Sesión expirada',
                            text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                        }).then((result) => {
                            this.$router.push({
                                name: 'login'
                            });
                        });
                    } else {
                        $swal.fire({
                            icon: 'error',
                            title: 'Error Inesperado!!',
                            text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                        });
                    }
                    console.log(error.response);
                    this.load = false;
                });
            }

        },
        createUser() {
            this.$router.push({
                name: 'createUser',
                params: {
                    _reload: this.getUsers,
                    _rolReload: this.$rolReload,
                    _rolId: this.$rol.id,
                },
            })
        },
        changePageActivosData(page) {
            this.load = true;
            this.pagination.current_page = page;
            this.getUsers(page);
        },
    },
    computed: {
        isActivedActivosData: function () {
            return this.pagination.current_page;
        },
        pagesNumberActivosData: function () {
            if (!this.pagination.to) {
                return [];
            }

            var from = this.pagination.current_page - (this.offset / 2);
            if (from < 1) {
                from = 1;
            }

            var to = from + (this.offset);
            if (to >= this.pagination.last_page) {
                to = this.pagination.last_page;
            }

            var pagesArray = [];
            while (from <= to) {
                pagesArray.push(from);
                from++;
            }
            return pagesArray;
        },
    },
}
</script>
