<template>
<tr>
    <td class="p-1 pt-2 text-center w3-card-4" :class="$night ? 'w3-dark-gray' : 'w3-light-gray'" style="position:sticky;left:0px;width: 50px;">
        <div class="d-flex">
            <button v-if="$permissions.changePass.status" class="btn btn-info btn-sm btn-block d-flex px-2" @click.prevent="changePass">
                <i class="fas fa-exchange-alt fa-lg"></i>
                <i class="fas fa-key fa-lg"></i>
            </button>
            <div class="d-flex">
                <button v-if="$permissions.edit.status" class="btn btn-warning btn-sm px-2" @click.prevent="editUser">
                    <i class="fas fa-pen fa-lg"></i>
                </button>
                <button v-if="$permissions.delete.status" class="btn btn-danger btn-sm px-2" @click.prevent="delUser">
                    <i class="fas fa-trash-alt fa-lg"></i>
                </button>
            </div>
        </div>
    </td>
    <td class="text-center p-1 pt-2" :class="$night ? 'text-white-50' : ''" style="width: 50px;" @click.prevent="viewUser">{{ $index + 1 }}</td>
    <td class="p-1 text-center" :class="$night ? 'text-white-50' : ''" @click.prevent="viewUser">
        <img :src="$user.imagelink" class="rounded-circle img-fluid me-2" style="width: 30px;" />
        <strong>{{ $user.user }}</strong>
    </td>
    <td class="p-1 pt-2 text-center" :class="$night ? 'text-white-50' : ''" @click.prevent="viewUser">{{ $user.name }}</td>
    <td class="p-1 pt-2 text-center" :class="$night ? 'text-white-50' : ''" @click.prevent="viewUser">{{ $user.org }}</td>
</tr>
</template>

<script>
import $swal from 'sweetalert2'
import $axios from 'axios'
export default {
    props: {
        $night: Boolean,
        $user: Object,
        $index: Number,
        $reload: Function,
        $rolReload: Function,
        $permissions: Object,
    },
    data() {
        return {

        }
    },
    methods: {
        editUser() {
            this.$router.push({
                name: 'editUser',
                params: {
                    _user: this.$user,
                    _reload: this.$reload,
                    _rolReload: this.$rolReload,
                },
            })
        },
        delUser() {
            $swal.fire({
                title: '¿Estás seguro?',
                text: "Estás a punto de eliminar un usuario!!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#03cc9a',
                cancelButtonColor: '#ED1848',
                confirmButtonText: 'Si, eliminarlo!'
            }).then((result) => {
                if (result.isConfirmed) {
                    $axios.delete(this.$masterRoute + '/api/users/' + this.$user.id, {
                        headers: {
                            Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                        }
                    }).then(response => {
                        if (response.data.status) {
                            $swal.fire(
                                'Acción exitosa!',
                                response.data.menssage,
                                'success'
                            );
                            this.$reload();
                            this._rolReload();
                        } else {
                            $swal.fire({
                                icon: 'error',
                                title: 'Error!!',
                                text: response.data.menssage,
                            });
                        }
                    }).catch(error => {
                        console.log(error);
                        if (error.response.status === 401) {
                            $swal.fire({
                                icon: 'error',
                                title: 'Sesión expirada',
                                text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                            }).then((result) => {
                                this.$router.push({
                                    name: 'login'
                                });
                            });
                        } else {
                            $swal.fire({
                                icon: 'error',
                                title: 'Error Inesperado!!',
                                text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                            });
                        }
                        console.log(error.response);
                    });
                }
            });
        },
        changePass() {
            this.$router.push({
                name: 'changePass',
                params: {
                    _user: this.$user,
                },
            });
        },
        viewUser() {
            this.$router.push({
                name: 'viewUser',
                params: {
                    _user: this.$user,
                },
            });
        },
    },
}
</script>
