<template>
<div>
    <!--Carpeta de activos-->
    <div v-if="isChildren" :id="'btnOpen' + $node.id" class="w3-left-align d-flex w3-medium">
        <div @click.prevent="openSeccion($node, isOpen)" class="text-decoration-none me-2 d-flex align-items-center text-black-50">
            <i :class="[isOpen ? 'fas fa-caret-down' : 'fas fa-caret-right']" class="me-1"></i>
            <i :class="[isOpen ? 'fas fa-folder-open' : 'fas fa-folder']"></i>
        </div>
        <strong @click.prevent="$putElem($node)" style="cursor:pointer;" :class="$node.id === $elemSelect.id ? 'bg-secondary text-white' : ''" class="ps-2 w-100">{{ $node.name }}</strong>
    </div>
    <!--Grupo de activos-->
    <div :id="'btnClose' + $node.id" v-if="!isChildren" @click.prevent="$putElem($node)" class="w3-left-align w3-medium" style="cursor:pointer;" :class="$node.id === $elemSelect.id ? 'bg-secondary text-white' : ''">
        <i class="fas fa-cubes fa-lg me-2" :class="$node.id === $elemSelect.id ? 'text-white' : 'text-black-50'"></i>
        <strong>{{ $node.name }}</strong>
        <span v-if="$node.counter" class="badge bg-info d-lg-inline d-none">{{ $node.counter }} activos</span>
        <span v-if="$node.counter" class="badge rounded-pill badge-notification bg-info d-lg-none d-inline w3-small ms-1">{{ $node.counter }}</span>
    </div>

    <div :id="'seccionstr' + $node.id" class="container mb-3 pe-0 mx-0" v-if="isChildren">
        <structure-tree v-for="(child, index) in $node.children" :key="index" :$node="child" :$putElem="$putElem" :$elemSelect="$elemSelect"></structure-tree>
    </div>
</div>
</template>

<script>
export default {
    props: {
        $node: Object,
        $putElem: Function,
        $elemSelect: Object,
    },
    data() {
        return {
            isOpen: true,
        };
    },
    methods: {
        openSeccion: function (object, open) {
            if (open) {
                $("#seccionstr" + object.id).hide();
                this.isOpen = false;
            } else {
                $("#seccionstr" + object.id).show();
                this.isOpen = true;
            }
        },
    },
    computed: {
        isChildren: function () {
            return this.$node.children && this.$node.children.length;
        },
    },
};
</script>
