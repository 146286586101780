<template>
<div v-if="$item.proveedor != 0" class="col-12 mb-3">
    <div class="container-fluid border border-3">
        <div class="row my-3">
            <div class="col-12 col-md-6">
                <h5 class="fw-bold me-auto">Presupuesto {{ $index + 1 }}</h5>
            </div>
            <div class="col-12 col-md-6 text-end">
                <button v-if="$isAuth" class="btn btn-primary btn-rounded" @click="authorized">
                    <i class="far fa-check-square fa-lg me-2"></i>
                    Autorizar presupuesto
                </button>
            </div>
        </div>
        <table class="table table-sm table-borderless" :class="night ? 'text-white' : ''">
            <thead>
                <tr>
                    <th colspan="2">
                        <h5 class="border-bottom border-2 fw-bold">Proveedor</h5>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="fw-bold">Nombre comercial:</td>
                    <td>{{ $item.proveedor.comercialname }}</td>
                </tr>
                <tr>
                    <td class="fw-bold">Razón social:</td>
                    <td>{{ $item.proveedor.razonsocial }}</td>
                </tr>
                <tr>
                    <td class="fw-bold">Correo Electrónico:</td>
                    <td>{{ $item.proveedor.email }}</td>
                </tr>
                <tr>
                    <td class="fw-bold">Domicilio:</td>
                    <td>{{ $item.proveedor.address }}</td>
                </tr>
                <tr>
                    <td class="fw-bold">Teléfono:</td>
                    <td>{{ $item.proveedor.phone }}</td>
                </tr>
                <tr>
                    <td class="fw-bold">Facturación:</td>
                    <td v-if="$item.proveedor.factura === 1">Si realiza facturas</td>
                    <td v-else>No realiza facturas</td>
                </tr>
                <tr>
                    <td class="fw-bold">Tipo de pago:</td>
                    <td v-if="$item.proveedor.payType === 1">Trasferencia o cheque</td>
                    <td v-else>Tarjeta o efectivo</td>
                </tr>
                <tr v-if="$item.proveedor.payType === 1">
                    <td class="fw-bold">No. de cuenta:</td>
                    <td>{{ payData.noCuenta }}</td>
                </tr>
                <tr v-if="$item.proveedor.payType === 1">
                    <td class="fw-bold">Banco:</td>
                    <td>{{ payData.bank }}</td>
                </tr>
                <tr v-if="$item.proveedor.payType === 1">
                    <td class="fw-bold">Beneficiario:</td>
                    <td>{{ payData.beneficiario }}</td>
                </tr>
                <tr v-if="$item.proveedor.payType === 1">
                    <td class="fw-bold">CLABE:</td>
                    <td>{{ payData.CLABE }}</td>
                </tr>
                <tr v-for="(item, index) in extradata" :key="index">
                    <td class="fw-bold">{{ item.title }}</td>
                    <td>{{ item.text }}</td>
                </tr>
            </tbody>
        </table>
        <div class="table-responsive">
            <table class="table table-bordered table-sm" :class="night ? 'text-white w3-border-white' : 'w3-border-black'">
                <thead :class="night ? 'w3-dark-gray' : 'w3-light-gray'">
                    <tr>
                        <th class="text-center fw-bold" colspan="5">Productos</th>
                    </tr>
                    <tr class="text-center">
                        <th>#</th>
                        <th>Nombre</th>
                        <th>Cantidad</th>
                        <th>Precio</th>
                        <th>Importe</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(elem, index) in $item.productos" :key="index">
                        <td class="text-center">{{ index + 1 }}</td>
                        <td>{{ elem.producto.name }}</td>
                        <td class="text-center">{{ elem.cantidad }}</td>
                        <td v-if="elem.iva === 1" class="text-end">{{ (elem.precio / 1.16).toFixed(2) }}</td>
                        <td v-else class="text-end">{{ elem.precio }}</td>
                        <td v-if="elem.iva === 1" class="text-end">{{ (elem.cantidad * (elem.precio / 1.16)).toFixed(2) }}</td>
                        <td v-else class="text-end">{{ (elem.cantidad * elem.precio).toFixed(2) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row mb-3">
            <div class="offset-lg-8 col-lg-4 offset-md-5 col-md-7 offset-0 col-12">
                <table class="table table-bordered table-sm" :class="night ? 'text-white w3-border-white' : 'w3-border-black'">
                    <tbody>
                        <tr v-if="$item.proveedor.factura === 1">
                            <td class="fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Subtotal</td>
                            <td class="text-end">{{ '$ ' + subtotal }}</td>
                        </tr>
                        <tr v-if="$item.proveedor.factura === 1">
                            <td class="fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">IVA 16%</td>
                            <td class="text-end">{{ '$ ' + iva }}</td>
                        </tr>
                        <tr v-if="$item.proveedor.factura === 1">
                            <td class="fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Total</td>
                            <td class="text-end">{{ '$ ' + total }}</td>
                        </tr>
                        <tr v-if="$item.proveedor.factura === 0">
                            <td class="fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Total</td>
                            <td class="text-end">{{ '$ ' + subtotal }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12">
                <table class="table table-bordered table-sm" :class="night ? 'text-white w3-border-white' : 'w3-border-black'">
                    <tbody>
                        <tr>
                            <td class="fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Entrega</td>
                            <td>{{ $item.tiempo }}</td>
                        </tr>
                        <tr>
                            <td class="fw-bold" :class="night ? 'w3-dark-gray' : 'w3-light-gray'">Notas</td>
                            <td>{{ $item.notas }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="container-fluid border border-2 py-2 mb-3">
            <input type="file" class="d-none" id="imgfile" accept="image/*" @change="putImage">
            <input type="file" class="d-none" id="pdffile" accept="application/pdf" @change="putPdf">
            <div class="d-flex align-items-center">
                <h6>Imagenes y/o archivos adjuntos</h6>
                <!--<button class="btn btn-rounded btn-outline-primary btn-sm ms-auto" @click="addImg">
                    <i class="fas fa-plus fa-lg"></i>
                    <i class="far fa-image fa-lg"></i>
                </button>
                <button class="btn btn-rounded btn-outline-primary btn-sm ms-2" @click="addPdf">
                    <i class="fas fa-plus fa-lg"></i>
                    <i class="far fa-file-pdf fa-lg text-danger"></i>
                </button>-->
            </div>
            <div class="row">
                <div v-for="(item, index) in $item.files" :key="index" class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-4 mb-2">
                    <div class="bg-image">
                        <img v-if="item.type == 'img'" :src="item.url" class="img-fluid" alt="Sample" @click="showImgFile(item.url)" />
                        <img v-if="item.type == 'pdf'" src="/image/pdf.png" class="img-fluid" alt="Sample" @click="showImgFile(item.url)" />
                        <!--<div class="mask">
                            <div class="d-flex justify-content-end align-items-start h-100 pt-2 pe-2">
                                <button type="button" class="btn btn-danger btn-floating btn-sm float-end" @click.prevent="delFile(index)">
                                    <i class="fas fa-times fa-lg"></i>
                                </button>
                            </div>
                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        $item: Object,
        $index: Number,
        $ordcomId: Number,
        $isAuth: Boolean,
        $reload: Function,
    },
    created() {
        this.darkmode();
        this.getSubtotal();
        if (this.$item.proveedor != 0) {
            this.payData = JSON.parse(this.$item.proveedor.bankData);
            this.extradata = JSON.parse(this.$item.proveedor.extradata);
        }
    },
    data() {
        return {
            night: null,
            subtotal: 0,
            iva: 0,
            total: 0,
            payData: {},
            extradata: [],
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        getSubtotal() {
            let sub = 0;
            this.$item.productos.forEach(element => {
                if (element.iva == 1) {
                    sub = sub + ((element.cantidad * element.precio) / 1.16);
                } else {
                    sub = sub + (element.cantidad * element.precio);
                }
            });
            this.subtotal = sub.toFixed(2);
            this.iva = (sub * 0.16).toFixed(2);
            this.total = (sub * 1.16).toFixed(2);
        },
        showImgFile(url) {
            window.open(url, "PDF document", "width=" + window.innerWidth / 2 + ", height=" + window.innerHeight / 2);
        },
        addImg() {
            $('#imgfile').click();
        },
        addPdf() {
            $('#pdffile').click();
        },
        putImage(e) {
            let fl = e.target.files[0];
            this.files.push({
                file: fl,
                name: fl.name,
                type: 'img',
                url: URL.createObjectURL(fl),
            });
            this.$putJsonFiles(this.files, this.$index);
        },
        putPdf(e) {
            let fl = e.target.files[0];
            this.files.push({
                file: fl,
                name: fl.name,
                type: 'pdf',
                url: URL.createObjectURL(fl),
            });
            this.$putJsonFiles(this.files, this.$index);
        },
        delFile(index) {
            this.files.splice(index, 1);
            this.$putJsonFiles(this.files, this.$index);
        },
        authorized() {
            $axios.post(this.$masterRoute + '/api/compras', {
                ordcomId: this.$ordcomId,
                proveedor: JSON.stringify(this.$item.proveedor),
                products: JSON.stringify(this.$item.productos),
                times: this.$item.tiempo,
                notes: this.$item.notas,
            }, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                if (response.data.status) {
                    $swal.fire({
                        icon: 'success',
                        title: response.data.title,
                        text: response.data.menssage,
                    });
                    this.$reload();
                    this.$router.go(-1);
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: response.data.title,
                        text: response.data.menssage,
                    });
                }
                this.load = false;
            }).catch(error => {
                console.error(error);
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
            });
        }
    }
}
</script>
