<template>
<div class="card" :class="night ? 'bg-dark-gray' : ''">
    <div class="card-body">
        <div class="d-flex align-items-center mb-2">
            <button class="btn btn-secondary btn-rounded btn-sm ms-auto me-2 d-md-block d-none" @click.prevent="returnView">
                <i class="fas fa-undo-alt fa-lg me-2"></i>
                <span>Regresar a filtros</span>
            </button>
            <button class="btn btn-secondary btn-rounded btn-floating ms-auto me-2 d-md-none d-block" @click.prevent="returnView">
                <i class="fas fa-undo-alt fa-lg me-2"></i>
            </button>
        </div>
        <div class="table-responsive overflow-auto d-none d-md-block" :style="'height:' + screenheightsize + 'px;'">
            <table class="table table-hover table-bordered table-sm align-middle" :class="night ? 'table-dark' : 'table-light'" :style="'width:' + screenwidthsize + 'px;'">
                <thead class="w3-medium text-center" :class="night ? 'w3-dark-gray' : 'w3-light-gray'" style="position: sticky;top: 0;z-index: 10;">
                    <tr style="position: sticky;top: 0;z-index: 10;" :class="night ? 'border-dark' : 'border'">
                        <th scope="col" style="width: 175px;position: sticky;top: 0;z-index: 10;">
                            <div class="d-flex align-items-center mb-1">
                                <strong>No. reporte</strong>
                            </div>
                        </th>
                        <th scope="col" style="width:175px;position: sticky;top: 0;z-index: 10;">
                            <div class="d-flex align-items-center justify-content-center mb-1">
                                <strong>Remitente</strong>
                            </div>
                        </th>
                        <th scope="col" style="width:175px;position: sticky;top: 0;z-index: 10;">
                            <div class="d-flex align-items-center justify-content-center mb-1">
                                <strong>Destinatario</strong>
                            </div>
                        </th>
                        <th scope="col" style="width:150px;position: sticky;top: 0;z-index: 10;">
                            <div class="d-flex align-items-center justify-content-center mb-1">
                                <strong>Prioridad</strong>
                            </div>
                        </th>
                        <th scope="col" class="text-center" style="position: sticky;top: 0;z-index: 10;">
                            <div class="d-flex align-items-center justify-content-center mb-1">
                                <strong>Información</strong>
                            </div>
                        </th>
                        <th scope="col" style="width:175px;position: sticky;top: 0;z-index: 10;">
                            <strong>Creado</strong>
                        </th>
                        <th scope="col" style="width:175px;position: sticky;top: 0;z-index: 10;">
                            <strong>Iniciado</strong>
                        </th>
                        <th scope="col" style="width:175px;position: sticky;top: 0;z-index: 10;">
                            <strong>Terminado</strong>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in dataItems" :key="index">
                        <td class="text-center" style="width:175px;" @click.prevent="viewReport(item)">
                            <small>{{ item.no_report }}</small>
                        </td>
                        <td class="text-center" style="width:175px;" @click.prevent="viewReport(item)">
                            <small>{{ item.remitente.name }}</small>
                        </td>
                        <td class="text-center" style="width:175px;" @click.prevent="viewReport(item)">
                            <small>{{ item.destinatario.name }}</small>
                        </td>
                        <td class="text-center" style="width:150px;" @click.prevent="viewReport(item)">
                            <span v-if="item.urgencia == 'Alta'" class="badge rounded-pill bg-danger w-100">Alta</span>
                            <span v-if="item.urgencia == 'Media'" class="badge rounded-pill bg-warning w-100">Media</span>
                            <span v-if="item.urgencia == 'Baja'" class="badge rounded-pill bg-success w-100">Baja</span>
                        </td>
                        <td scope="row" class="text-left" style="font-weight: normal;" @click.prevent="viewReport(item)">
                            <div class="d-flex align-items-center">
                                <strong class="me-2">{{ item.rptname }}</strong>
                                <small>{{ item.descripcion }}</small>
                            </div>
                        </td>
                        <td class="text-center" style="width:175px;">
                            <small>{{ getMomentDate(item.created_at) }}</small>
                        </td>
                        <td class="text-center" style="width:175px;">
                            <small>{{ getMomentDate(item.started_in) }}</small>
                        </td>
                        <td class="text-center" style="width:175px;">
                            <small>{{ getMomentDate(item.finished_in) }}</small>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        dataItems: {
            type: Array,
            required: true,
        },
    },
    created() {
        this.darkmode();
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.darkmode();
        });
        this.changeSize();
        window.addEventListener("resize", this.changeSize);
    },
    data() {
        return {
            night: false,
            screenheightsize: 0,
            screenwidthsize: 0,
        }
    },
    methods: {
        darkmode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        getMomentDate(date) {
            return moment(date).format('DD/MM/YYYY HH:mm A');
        },
        changeSize() {
            if (screen.width < 1336) {
                this.screenwidthsize = 1336;
            } else {
                this.screenwidthsize = screen.width - 30;
            }
            this.screenheightsize = window.innerHeight - 185;
        },
        returnView() { 
            this.$router.go(-1);
        }
    }
}
</script>
