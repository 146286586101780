import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    routes: [{
            path: '/',
            name: 'keyDevice',
            component: require('./views/keyDevice').default,
            props: true,
        }, {
            path: '/login',
            name: 'login',
            component: require('./views/login').default,
        }, {
            path: '/dashboard',
            name: 'dashboard',
            component: require('./views/dashboard').default,
            children: [{
                    path: '/adminPanelDevices',
                    name: 'adminPanelDevices',
                    component: require('./views/pages/devices/dashboard').default,
                    props: true,
                    children: [

                    ],
                }, {
                    path: '/adminPanelOrganizaciones',
                    name: 'adminPanelOrganizaciones',
                    component: require('./views/pages/organizaciones/dashboard').default,
                    props: true,
                    children: [{
                            path: '/organizaciones/create',
                            name: 'createOrg',
                            component: require('./views/pages/organizaciones/admin/createOrg').default,
                            props: true,
                        },
                        {
                            path: '/organizaciones/edit',
                            name: 'editOrg',
                            component: require('./views/pages/organizaciones/admin/editOrg').default,
                            props: true,
                        }, {
                            path: '/organizaciones/move',
                            name: 'moveOrg',
                            component: require('./views/pages/organizaciones/admin/moveOrg').default,
                            props: true,
                        }
                    ]
                }, {
                    path: '/adminPanelUsers',
                    name: 'adminPanelUsers',
                    component: require('./views/pages/users/dashboard').default,
                    props: true,
                    children: [{
                            path: '/createRol',
                            name: 'createRol',
                            component: require('./views/pages/users/roles-permisos/createRol').default,
                            props: true,
                        }, {
                            path: '/editRol',
                            name: 'editRol',
                            component: require('./views/pages/users/roles-permisos/editRol').default,
                            props: true,
                        },
                        {
                            path: '/users/view',
                            name: 'viewUser',
                            component: require('./views/pages/users/admin/viewUser').default,
                            props: true,
                        }, {
                            path: '/users/create',
                            name: 'createUser',
                            component: require('./views/pages/users/admin/createUser').default,
                            props: true,
                        }, {
                            path: '/users/edit',
                            name: 'editUser',
                            component: require('./views/pages/users/admin/editUser').default,
                            props: true,
                        }, {
                            path: '/users/changePass',
                            name: 'changePass',
                            component: require('./views/pages/users/admin/changePass').default,
                            props: true,
                        },
                    ],
                }, {
                    path: '/adminPanelActivos',
                    name: 'adminPanelActivos',
                    component: require('./views/pages/activos/dashboard').default,
                    props: true,
                    children: [{
                            path: '/activos/create',
                            name: 'createActivo',
                            component: require('./views/pages/activos/admin/createActivo').default,
                            props: true,
                        },
                        {
                            path: '/activos/edit',
                            name: 'editActivo',
                            component: require('./views/pages/activos/admin/editActivo').default,
                            props: true,
                        },
                        {
                            path: '/structures/create',
                            name: 'structureCreate',
                            component: require('./views/pages/activos/structures/create').default,
                            props: true,
                        }, {
                            path: '/structures/move',
                            name: 'structureMove',
                            component: require('./views/pages/activos/structures/move').default,
                            props: true,
                        }, {
                            path: '/structures/assign',
                            name: 'structureAssign',
                            component: require('./views/pages/activos/structures/assign').default,
                            props: true,
                        }, {
                            path: '/structures/edit',
                            name: 'structureEdit',
                            component: require('./views/pages/activos/structures/edit').default,
                            props: true,
                        },
                        {
                            path: '/formatData/create',
                            name: 'createFormatData',
                            component: require('./views/pages/activos/formData/create').default,
                            props: true,
                        },
                        {
                            path: '/formatData/edit',
                            name: 'editFormatData',
                            component: require('./views/pages/activos/formData/edit').default,
                            props: true,
                        }
                    ],
                }, {
                    path: '/adminPanelReportes',
                    name: 'adminPanelReportes',
                    component: require('./views/pages/Reportes/dashboard').default,
                    props: true,
                    children: [{
                        path: '/report/create',
                        name: 'createReport',
                        component: require('./views/pages/reportes/admin/createReport').default,
                        props: true,
                    }, {
                        path: '/report/comments',
                        name: 'reportComments',
                        component: require('./views/pages/reportes/admin/comentariosView').default,
                        props: true,
                    }, {
                        path: '/report/view',
                        name: 'reportView',
                        component: require('./views/pages/reportes/admin/viewReport').default,
                        props: true,
                    }, {
                        path: '/typeReport/create',
                        name: 'createTypeReport',
                        component: require('./views/pages/reportes/typeReport/createTypeReport').default,
                        props: true,
                    }, {
                        path: '/typeReport/edit',
                        name: 'editTypeReport',
                        component: require('./views/pages/reportes/typeReport/editTypeReport').default,
                        props: true,
                    }, {
                        path: '/typeReport/changeImg',
                        name: 'changeImgTypeReport',
                        component: require('./views/pages/reportes/typeReport/changeImg').default,
                        props: true,
                    }],
                }, {
                    path: '/adminPanelCompras',
                    name: 'adminPanelCompras',
                    component: require('./views/pages/compras/dashboard').default,
                    props: true,
                    children: [{
                        path: '/ordcompras/index',
                        name: 'indexOrdCompras',
                        component: require('./views/pages/compras/ordCompras/index').default,
                        props: true,
                        children: [{
                            path: '/ordcompras/showPresupuesto',
                            name: 'showPresupuestoOrdCompras',
                            component: require('./views/pages/compras/ordCompras/presupuestos/show').default,
                            props: true,
                        }],
                    }, {
                        path: '/ordcompras/create',
                        name: 'createOrdCompras',
                        component: require('./views/pages/compras/ordCompras/create').default,
                        props: true,
                        children: [{
                            path: '/proveedores/create',
                            name: 'createProveedorOrdCompras',
                            component: require('./views/pages/proveedores/admin/create').default,
                            props: true,
                        }, {
                            path: '/productos/create',
                            name: 'createProductoOrdCompras',
                            component: require('./views/pages/proveedores/productos/admin/create').default,
                            props: true,
                        }]
                    }, {
                        path: '/myauthorized/index',
                        name: 'myauthorizedIndex',
                        component: require('./views/pages/compras/myAuthorized/index').default,
                        props: true,
                        children: [{
                            path: '/myauthorized/check',
                            name: 'checkOrdCompra',
                            component: require('./views/pages/compras/myAuthorized/presupuestos/show').default,
                            props: true,
                        }],
                    }, {
                        path: '/compras/index',
                        name: 'comprasIndex',
                        component: require('./views/pages/compras/admin/index').default,
                        props: true,
                        children: [{
                            path: '/compras/show',
                            name: 'showCompras',
                            component: require('./views/pages/compras/admin/show').default,
                            props: true,
                        }],
                    }, {
                        path: '/configurations/index',
                        name: 'configurationsIndex',
                        component: require('./views/pages/compras/config/index').default,
                        props: true,
                        children: [{
                            path: '/config/authlim',
                            name: 'authlimConfigCompras',
                            component: require('./views/pages/compras/config/auth/index').default,
                            props: true,
                            children: [{
                                path: '/create/limitante',
                                name: 'createLimitanteCompras',
                                component: require('./views/pages/compras/config/auth/create').default,
                                props: true,
                            }, {
                                path: '/edit/limitante',
                                name: 'editLimitanteCompra',
                                component: require('./views/pages/compras/config/auth/edit').default,
                                props: true,
                            }],
                        }, {
                            path: '/config/admincom',
                            name: 'admincomConfigCompras',
                            component: require('./views/pages/compras/config/admin/index').default,
                            props: true,
                            children: [{
                                path: '/create/admin',
                                name: 'createAdminCompras',
                                component: require('./views/pages/compras/config/admin/create').default,
                                props: true,
                            }]
                        }, {
                            path: '/config/catcom',
                            name: 'catcomConfigCompras',
                            component: require('./views/pages/compras/config/categorias/index').default,
                            props: true,
                            children: [{
                                path: '/create/category',
                                name: 'createCategoryCom',
                                component: require('./views/pages/compras/config/categorias/create').default,
                                props: true,
                            }, {
                                path: '/edit/category',
                                name: 'editCategoryCom',
                                component: require('./views/pages/compras/config/categorias/edit').default,
                                props: true,
                            }],
                        }],
                    }],
                }, {
                    path: '/adminPanelProveedores',
                    name: 'adminPanelProveedores',
                    component: require('./views/pages/proveedores/dashboard').default,
                    props: true,
                    children: [{
                        path: 'proveedores/index',
                        name: 'indexProveedores',
                        component: require('./views/pages/proveedores/admin/index').default,
                        props: true,
                        children: [{
                                path: 'proveedores/create',
                                name: 'createProveedor',
                                component: require('./views/pages/proveedores/admin/create').default,
                                props: true,
                            },
                            {
                                path: 'proveedores/edit',
                                name: 'editProveedor',
                                component: require('./views/pages/proveedores/admin/edit').default,
                                props: true,
                            }
                        ],
                    }, {
                        path: 'productos/index',
                        name: 'indexProductos',
                        component: require('./views/pages/proveedores/productos/dashboard').default,
                        props: true,
                        children: [{
                            path: 'productos/create',
                            name: 'createProducto',
                            component: require('./views/pages/proveedores/productos/admin/create').default,
                            props: true,
                        }, {
                            path: 'productos/edit',
                            name: 'editProducto',
                            component: require('./views/pages/proveedores/productos/admin/edit').default,
                            props: true,
                        }],
                    }],
                }, {
                    path: '/adminPanelAnalytics',
                    name: 'adminPanelAnalytics',
                    component: require('./views/pages/analisisDatos/dashboard').default,
                    props: true,
                    children: [{
                        path: '/analytics/index',
                        name: 'indexAnalytics',
                        component: require('./views/pages/analisisDatos/admin/index').default,
                        props: true,
                    }, {
                        path: '/analytics/htmlformat',
                        name: 'htmlformatAnalytics',
                        component: require('./views/pages/analisisDatos/admin/htmlformat').default,
                        props: true,
                    }],
                }, {
                    path: '/adminPanelAyudaSoporte',
                    name: 'adminPanelAyudaSoporte',
                    component: require('./views/pages/ayudaSoporte/dashboard').default,
                    props: true,
                    children: [{
                        path: '/presentacion',
                        name: 'presentacion',
                        component: require('./views/pages/ayudaSoporte/presentacion').default,
                        props: true,
                    }, {
                        path: '/prox',
                        name: 'prox',
                        component: require('./views/pages/ayudaSoporte/prox').default,
                        props: true,
                    }, {
                        path: '/gralReports',
                        name: 'gralReports',
                        component: require('./views/pages/ayudaSoporte/reportsViews/gralReports').default,
                        props: true,
                    }, {
                        path: '/newReport',
                        name: 'newReport',
                        component: require('./views/pages/ayudaSoporte/reportsViews/newReport').default,
                        props: true,
                    }, {
                        path: '/newTypeReport',
                        name: 'newTypeReport',
                        component: require('./views/pages/ayudaSoporte/reportsViews/newTypeReport').default,
                        props: true,
                    }, {
                        path: '/editTypeReportManual',
                        name: 'editTypeReportManual',
                        component: require('./views/pages/ayudaSoporte/reportsViews/editTypeReport').default,
                        props: true,
                    }, {
                        path: '/viewReport',
                        name: 'viewReport',
                        component: require('./views/pages/ayudaSoporte/reportsViews/viewReport').default,
                        props: true,
                    }, {
                        path: '/startProcess',
                        name: 'startProcess',
                        component: require('./views/pages/ayudaSoporte/reportsViews/startProcess').default,
                        props: true,
                    }, {
                        path: '/finishProcess',
                        name: 'finishProcess',
                        component: require('./views/pages/ayudaSoporte/reportsViews/finishProcess').default,
                        props: true,
                    }, {
                        path: '/confirmProcess',
                        name: 'confirmProcess',
                        component: require('./views/pages/ayudaSoporte/reportsViews/confirmProcess').default,
                        props: true,
                    }, {
                        path: '/commentsReports',
                        name: 'commentsReports',
                        component: require('./views/pages/ayudaSoporte/reportsViews/commentsReports').default,
                        props: true,
                    }],
                },
                {
                    path: '/home',
                    name: 'home',
                    component: require('./views/home').default,
                    props: true,
                }
            ],
        },
        {
            path: '*',
            component: require('./views/404').default
        },
    ],
    mode: 'abstract'
})