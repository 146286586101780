<template>
<div class="w3-modal d-block">
    <div class="w3-modal-content w3-animate-zoom card border mb-3 mt-lg-auto mt-3">
        <header class="card-header" :class="night ? 'w3-dark-gray' : ''">
            <button @click.prevent="cancel" class="btn btn-danger btn-sm px-2 float-end">
                <i class="fas fa-times"></i>
            </button>
            <div class="d-xl-flex d-lg-flex d-md-flex d-none align-items-center w3-large">
                <i class="fas fa-plus-square fa-2x text-primary me-auto"></i>
                <strong class="me-auto">Creando tipo de reporte</strong>
            </div>
            <div class="d-xl-none d-lg-none d-md-none d-flex align-items-center w3-medium">
                <i class="fas fa-plus-square fa-2x text-primary me-auto"></i>
                <strong class="me-auto">Creando tipo de reporte</strong>
            </div>
        </header>

        <div class="card-body" :class="night ? 'bg-dark-gray' : ''">
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-1">
                    <div class="mb-3">
                        <div class="input-group">
                            <span class="input-group-text w3-light-gray">
                                <i class="fas fa-key fa-lg"></i>
                            </span>
                            <input type="text" v-model="clave" class="form-control" :class="clave.length < 3 ? 'is-invalid' : 'is-valid'" placeholder="Clave" maxlength="3" style="text-transform: uppercase;">
                            <div class="invalid-feedback ms-2">Requiere 3 caracteres</div>
                        </div>
                        <p class="float-end pe-2">{{ clave.length }}/3</p>
                    </div>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12 mb-1">
                    <div class="mb-3">
                        <div class="input-group">
                            <span class="input-group-text w3-light-gray">
                                <i class="fas fa-edit fa-lg"></i>
                            </span>
                            <input type="text" v-model="nombre" class="form-control" :class="nombre.length < 5 ? 'is-invalid' : 'is-valid'" placeholder="Nombre" maxlength="100">
                            <div class="invalid-feedback ms-2">Requiere mínimo 5 caracteres</div>
                        </div>
                        <p class="float-end pe-2">{{ nombre.length }}/100</p>
                    </div>
                </div>
                <div class="col-12 mb-3 text-center">
                    <figure v-if="imageView">
                        <img :src="imageView" alt="Preview Image" class="w-50 w3-white">
                    </figure>
                    <div class="input-group mb-3 d-none">
                        <input type="file" class="form-control" @change="selectFile" accept="image/*" id="selectFile" />
                    </div>
                    <button class="btn btn-outline-black btn-lg" :class="night ? 'text-white' : ''" @click.prevent="clickInput">
                        <i class="fas fa-camera fa-lg me-2"></i>
                        <span>Cargar imagen</span>
                    </button>
                </div>
                <div class="col-12 mb-3">
                    <h5 class="text-center" :class="night ? 'text-white' : ''">Selecciona la jerarquia de organización</h5>
                    <org-treeData class="pt-2" v-for="(item, index) in organizacionesTree" :key="index" :$node="item" :$elemSelect="selectOrg" :$putElem="putOrg" :$night="night"></org-treeData>
                </div>
            </div>
            <reports-configQuestItem v-for="(item, index) in questions" :key="index" :$index="index" :$item="item" :$putResp="putResp" :$delResp="delResp" :$putQuest="putQuestion" :$delQuestion="delQuestion"></reports-configQuestItem>
            <div v-if="questions.length < 3" class="d-flex align-items-center">
                <button type="button" class="btn btn-info btn-rounded me-auto ms-auto btn-sm" @click.prevent="addQuestion">
                    <i class="fas fa-plus fa-lg"></i>
                    <i class="fas fa-question fa-lg me-2"></i>
                    <span>Agregar nueva pregunta ({{ 3 - questions.length }})</span>
                </button>
            </div>
        </div>
        <footer class="card-footer" :class="night ? 'w3-dark-gray' : ''">
            <div v-if="load">
                <button class="btn btn-secondary btn-rounded btn-block" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Cargando...
                </button>
            </div>
            <div v-else class="row px-0">
                <div class="col-6">
                    <button class="btn btn-danger btn-rounded btn-sm btn-block" @click.prevent="cancel">
                        <i class="fas fa-ban fa-lg"></i>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="col-6">
                    <button class="btn btn-success btn-rounded btn-sm btn-block" :disabled="isDisableSave || !questionsIsSave" @click.prevent="saveTypeReport">
                        <i class="fas fa-save fa-lg"></i>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </footer>
    </div>
</div>
</template>

<script>
import $axios from 'axios'
import $swal from 'sweetalert2'
export default {
    props: {
        _reload: Function,
    },
    created() {
        this.nightMode();
        this.getorganizaciones();
    },
    data() {
        return {
            night: null,
            clave: '',
            nombre: '',
            image: null,
            orgId: '',
            questions: [],
            error: ['', '', ''],
            load: false,
            organizacionesTree: [],
            selectOrg: {},
            imageView: '',
            questionsIsSave: true,
        }
    },
    mounted() {
        window.Echo.channel('channel-darkmode').listen('DarkModeChangeEvent', (e) => {
            this.nightMode();
        });
    },
    methods: {
        nightMode() {
            if (localStorage.nightMode == 'true') {
                this.night = true;
            }
            if (localStorage.nightMode == 'false') {
                this.night = false;
            }
        },
        saveTypeReport() {
            let formData = new FormData();
            formData.append('clave', this.clave.toUpperCase());
            formData.append('name', this.nombre);
            formData.append('image', this.image);
            formData.append('orgId', this.orgId)
            formData.append('questions', JSON.stringify(this.questions));
            $axios.post(this.$masterRoute + '/api/reportstype', formData, {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                if (response.data.status) {
                    $swal.fire(
                        'Acción exitosa!',
                        response.data.menssage,
                        'success'
                    );
                    this._reload();
                    this.cancel();
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: response.data.title,
                        text: response.data.menssage,
                    });
                    this.load = false;
                }
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });
        },
        getorganizaciones() {
            $axios.get(this.$masterRoute + '/api/organizaciones', {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.authToken)
                }
            }).then(response => {
                this.organizacionesTree = this.generateTree(response.data);
            }).catch(error => {
                if (error.response.status === 401) {
                    $swal.fire({
                        icon: 'error',
                        title: 'Sesión expirada',
                        text: 'No tiene autorizado continuar en el sistema, inicie sesion!!',
                    }).then((result) => {
                        this.$router.push({
                            name: 'login'
                        });
                    });
                } else {
                    $swal.fire({
                        icon: 'error',
                        title: 'Error Inesperado!!',
                        text: 'El sistema obtuvo un error inesperado, por favor comunicate con soporte para solucionarlo',
                    });
                }
                console.log(error.response);
                this.load = false;
            });
        },
        selectFile(event) {
            this.image = event.target.files[0];
            this.imageView = URL.createObjectURL(this.image);
        },
        clickInput() {
            $('#selectFile').click();
        },
        addQuestion() {
            this.questions.push({
                question: '',
                answers: [],
            });
            this.questionsIsSave = false;
        },
        putQuestion(data, index) {
            this.questions[index].question = data;
            this.questionsIsSave = this.questionStatus();
        },
        delQuestion(index) {
            this.questions.splice(index, 1);
            this.questionsIsSave = this.questionStatus();
        },
        putResp(value, index) {
            this.questions[index].answers.push(value);
            this.questionsIsSave = this.questionStatus();
        },
        delResp(index, ix) {
            this.questions[index].answers.splice(ix, 1);
            this.questionsIsSave = this.questionStatus();
        },
        cancel() {
            this.$router.go(-1);
        },
        putOrg(elem) {
            this.selectOrg = elem;
            this.orgId = elem.id;
        },
        generateTree(data) {
            let map = {},
                node,
                roots = [],
                i;
            for (i = 0; i < data.length; i++) {
                map[data[i].id] = i;
                data[i].children = [];
            }
            for (i = 0; i < data.length; i++) {
                node = data[i];
                if (node.parentId) {
                    if (node.parentId) {
                        data[map[node.parentId]].children.push(node);
                    }
                } else {
                    roots.push(node);
                }
            }
            this.load = false;
            return roots;
        },
        questionStatus() {
            let flag = true;
            this.questions.forEach(element => {
                if (!element.question || element.answers.length < 2) {
                    flag = false;
                }
            });
            return flag;
        }
    },
    computed: {
        isDisableSave() {
            if (this.clave.length < 3 || this.nombre.length < 5 || !this.image || !this.orgId) {
                return true;
            }
            return false;
        }
    }
}
</script>
